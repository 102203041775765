import React, { createContext, useContext, useState } from "react";

const ProspectContext = createContext();

export const useProps = () => {
  return useContext(ProspectContext);
};

export const ProspectProvider = ({ children }) => {
  const [rowFiltereddata, setRowFiltered] = useState([]);
  const [index, setIndex] = useState([]);
  const [refresh, setRefresh] = useState([]);

  const updatePropsValue = (newValue) => {
    setRowFiltered(newValue);
  };
  const updateIndexValue = (newValue) => {
    setIndex(newValue);
  };
  const updateRefreshValue = () => {
    setRefresh(!refresh);
  };

  return (
    <ProspectContext.Provider
      value={{
        rowFiltereddata,
        updatePropsValue,
        index,
        updateIndexValue,
        updateRefreshValue,
        refresh,
        setIndex,
      }}
    >
      {children}
    </ProspectContext.Provider>
  );
};
