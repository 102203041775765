export const ADD_CUSTOMER = 1000;
export const EDIT_CUSTOMER = 1001;
export const VIEW_CUSTOMER = 1002;
export const DELETE_CUSTOMER = 1003;
export const EXPORT_CUSTOMER = 1004;
export const IMPORT_CUSTOMER = 1005;

export const ADD_USER = 1100;
export const EDIT_USER = 1101;
export const VIEW_USER = 1102;
export const DELETE_USER = 1103;
export const EXPORT_USER = 1104;
export const IMPORT_USER = 1105;

export const ADD_DAHIYAMITRA = 1200;
export const EDIT_DAHIYAMITRA = 1201;
export const VIEW_DAHIYAMITRA = 1202;
export const DELETE_DAHIYAMITRA = 1203;
export const EXPORT_DAHIYAMITRA = 1204;
export const IMPORT_DAHIYAMITRA = 1205;

export const ADD_PROSPECT = 1300;
export const EDIT_PROSPECT = 1301;
export const VIEW_PROSPECT = 1302;
export const DELETE_PROSPECT = 1303;
export const EXPORT_PROSPECT = 1304;
export const IMPORT_PROSPECT = 1305;
export const ALL_PROSPECT = 1306;

export const ADD_REFERENCE = 1400;
export const EDIT_REFERENCE = 1401;
export const VIEW_REFERENCE = 1402;
export const DELETE_REFERENCE = 1403;
export const EXPORT_REFERENCE = 1404;
export const IMPORT_REFERENCE = 1405;
export const ALL_REFERENCE = 1406;

export const ADD_CLIA = 1500;
export const EDIT_CLIA = 1501;
export const VIEW_CLIA = 1502;
export const DELETE_CLIA = 1503;
export const EXPORT_CLIA = 1504;
export const IMPORT_CLIA = 1505;
export const ALL_CLIA = 1506;

export const ADD_POLICY = 1600;
export const EDIT_POLICY = 1601;
export const VIEW_POLICY = 1602;
export const DELETE_POLICY = 1603;
export const EXPORT_POLICY = 1604;
export const IMPORT_POLICY = 1605;

export const ADD_INPUT = 1700;
export const EDIT_INPUT = 1701;
export const VIEW_INPUT = 1702;
export const DELETE_INPUT = 1703;
export const EXPORT_INPUT = 1704;
export const IMPORT_INPUT = 1705;

export const ADD_PREMIUMPOINT = 1800;
export const EDIT_PREMIUMPOINT = 1801;
export const VIEW_PREMIUMPOINT = 1802;
export const DELETE_PREMIUMPOINT = 1803;
export const EXPORT_PREMIUMPOINT = 1804;
export const IMPORT_PREMIUMPOINT = 1805;

export const ADD_POLICYSERVICE = 1900;
export const EDIT_POLICYSERVICE = 1901;
export const VIEW_POLICYSERVICE = 1902;
export const DELETE_POLICYSERVICE = 1903;
export const EXPORT_POLICYSERVICE = 1904;
export const IMPORT_POLICYSERVICE = 1905;

export const ADD_CLIA_TEAM = 2000;
export const EDIT_CLIA_TEAM = 2001;
export const VIEW_CLIA_TEAM = 2002;
export const DELETE_CLIA_TEAM = 2003;
export const EXPORT_CLIA_TEAM = 2004;
export const IMPORT_CLIA_TEAM = 2005;
export const ALL_CLIA_TEAM = 2006;

export const LIC_BOND_SERVICE = 9998;
export const LIC_ENACH_SERVICE = 9997;
export const LIC_MINOR_NOM_SERVICE = 9996;
export const LIC_MINOR_DAB_SERVICE = 9995;
export const LIC_POLICY_SERVICE = 9994;

export const ACCESS_ACCOUNT = 9999;
export const LEADSTATUS = 9993;

export const FOLLOWUP = 2101;
export const GREETING = 2201;
export const CALENDER = 2301;
