import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Badge, Calendar, Container, Content, Popover, Whisper } from "rsuite";
import "rsuite/Calendar/styles/index.css";

import { Button } from "@mui/material";
import {
  AddModerator,
  Delete,
  ListAltRounded,
  PersonAdd,
} from "@mui/icons-material";
import Header from "app/components/Header";

import useAuth from "app/hooks/useAuth";
import { ADD_PROSPECT, DELETE_PROSPECT, VIEW_PROSPECT } from "Permissions";
import { useNavigate } from "react-router-dom";

export default function CalendarView() {
  const { permissions, user } = useAuth();

  function getTodoList(date) {
    const day = date.getDate();

    switch (day) {
      case 10:
        return [
          { time: "10:30 am", title: "Meeting" },
          { time: "12:00 pm", title: "Lunch" },
        ];
      case 15:
        return [
          { time: "06:30 pm", title: "Reporting" },
          { time: "10:00 pm", title: "Going home" },
        ];
      default:
        return [];
    }
  }

  const navigate = useNavigate();
  function renderCell(date) {
    const list = getTodoList(date);
    const displayList = list.filter((item, index) => index < 2);

    if (list.length) {
      const moreCount = list.length - displayList.length;
      const moreItem = (
        <li>
          <Whisper
            placement="top"
            trigger="click"
            speaker={
              <Popover>
                {list.map((item, index) => (
                  <p key={index}>
                    <b>{item.time}</b> - {item.title}
                  </p>
                ))}
              </Popover>
            }
          >
            <a>{moreCount} more</a>
          </Whisper>
        </li>
      );

      return (
        <ul className="calendar-todo-list">
          {displayList.map((item, index) => (
            <li key={index}>
              <Badge /> <b>{item.time}</b> - {item.title}
            </li>
          ))}
          {moreCount ? moreItem : null}
        </ul>
      );
    }

    return null;
  }

  return (
    <Box
      component="section"
      sx={{
        p: 1,
        border: "0.5px dashed #ddd",
        width: "100%",
        backgroundColor: "#FEFAF6",
      }}
    >
      <Stack spacing={1}>
        <Header headerText="Calendar">
          {(permissions.includes(ADD_PROSPECT) ||
            user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("AddReference");
              }}
              variant="contained"
              startIcon={<PersonAdd />}
            >
              Add
            </Button>
          )}
          {(permissions.includes(ADD_PROSPECT) ||
            user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("AddProspect");
              }}
              variant="contained"
              startIcon={<AddModerator />}
            >
              Add
            </Button>
          )}
          {(permissions.includes(VIEW_PROSPECT) ||
            user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("List");
              }}
              variant="contained"
              startIcon={<ListAltRounded />}
            >
              List
            </Button>
          )}

          {(permissions.includes(DELETE_PROSPECT) ||
            user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("Deleted");
              }}
              variant="contained"
              startIcon={<Delete />}
            >
              Deleted
            </Button>
          )}
        </Header>

        <Container>
          <Content
            style={{
              flex: 1,
              width: "100%",
              height: 650,
              borderRadius: 10,
              border: "1px dashed #ddd",
            }}
          >
            <Calendar bordered renderCell={renderCell} />;
          </Content>
        </Container>
      </Stack>
    </Box>
  );
}
