import * as React from "react";
import AgGrid from "app/components/AgGrid";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import {
  Button,
  Col,
  DateRangePicker,
  Divider,
  Grid,
  Heading,
  Message,
  Panel,
  Row,
  Stack,
  useToaster,
} from "rsuite";
import SideDrawer from "app/components/SideDrawer";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, TextField } from "@mui/material";
import { Field, Formik } from "formik";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import moment from "moment";
import * as yup from "yup";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { DeleteForever } from "@mui/icons-material";
import useAuth from "app/hooks/useAuth";

import { useTheme } from "@emotion/react";
//import { useProps } from "./PolicyProvider";

import { addDays } from "date-fns";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";

import { startOfToday } from "date-fns";
import useAPINew from "app/hooks/useAPINew";
import { DELETE_PREMIUMPOINT, EDIT_PREMIUMPOINT } from "Permissions";
import { toAmnt } from "functions";
import { styled, lighten, darken } from "@mui/system";
const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});
function getLastFinancialYearDates() {
  const today = startOfToday();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  let startOfLastFY, endOfLastFY;

  if (currentMonth >= 3) {
    // From April to December
    startOfLastFY = new Date(currentYear - 1, 3, 1); // April 1st of last year
    endOfLastFY = new Date(currentYear, 2, 31); // March 31st of this year
  } else {
    // From January to March
    startOfLastFY = new Date(currentYear - 2, 3, 1); // April 1st two years ago
    endOfLastFY = new Date(currentYear - 1, 2, 31); // March 31st of last year
  }

  return {
    startOfLastFY,
    endOfLastFY,
  };
}

const { startOfLastFY, endOfLastFY } = getLastFinancialYearDates();

function getCurrentFinancialYearDates() {
  const today = startOfToday();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  let startOfCurrentFY, endOfCurrentFY;

  if (currentMonth >= 3) {
    // From April to December
    startOfCurrentFY = new Date(currentYear, 3, 1); // April 1st of this year
    endOfCurrentFY = new Date(currentYear + 1, 2, 31); // March 31st of next year
  } else {
    // From January to March
    startOfCurrentFY = new Date(currentYear - 1, 3, 1); // April 1st of last year
    endOfCurrentFY = new Date(currentYear, 2, 31); // March 31st of this year
  }

  return {
    startOfCurrentFY,
    endOfCurrentFY,
  };
}

const { startOfCurrentFY, endOfCurrentFY } = getCurrentFinancialYearDates();

const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
  },
  {
    label: "Last Fin Year",
    value: [startOfLastFY, endOfLastFY],
  },
  {
    label: "Current Fin Year",
    value: [startOfCurrentFY, endOfCurrentFY],
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
  },
  {
    label: "Tomorrow",
    value: [addDays(new Date(), 1), addDays(new Date(), 1)],
  },
  {
    label: "Next week",
    value: [
      startOfWeek(addDays(new Date(), 6)),
      endOfWeek(addDays(new Date(), 6)),
    ],
  },
  {
    label: "Next 7 days",
    value: [new Date(), addDays(new Date(), 6)],
  },
  {
    label: "Next 30 days",
    value: [new Date(), addDays(new Date(), 29)],
  },
  {
    label: "Next month",
    value: [
      startOfMonth(addMonths(new Date(), 1)),
      endOfMonth(addMonths(new Date(), 1)),
    ],
  },
];

const validationSchema = yup.object({});

export default function ListPremiumPoint() {
  const axiosPrivate = useAxiosPrivate();
  const { permissions, user } = useAuth();

  const [rowData, setRowData] = React.useState([{}]);
  const [total, setTotal] = React.useState([{}]);

  const PaymentByList = useAPINew("GetPaymentByList.php");
  const CashierList = useAPINew("GetCashierList.php");

  const UsersList = useAPINew("GetListOfUsersToAssign.php");
  const options = (UsersList.error ? [null] : UsersList.data).map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  const [loading, setLoading] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const formatNumber = (number) => {
    return Math.floor(number).toLocaleString();
  };
  const currencyFormatter = (params) => {
    return formatNumber(params.value);
  };
  const formatNumber1 = (number) => {
    if (number < 1 && number > -1) return 0;
    else return Math.ceil(number).toLocaleString();
  };
  const currencyFormatter1 = (params) => {
    return formatNumber1(params.value);
  };
  const [value, setValue] = React.useState([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 1950,
    maxValidYear: 2050,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
    buttons: ["apply", "reset", "clear"],
  };
  const columns = React.useMemo(() => {
    return [
      {
        id: "Date",
        field: "Date",
        minWidth: 120,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      { id: "CashierName", field: "CashierName", minWidth: 100, width: 100 },
      { id: "PolicyNo", field: "PolicyNo", minWidth: 150 },
      { id: "Name", field: "Name", minWidth: 200 },
      {
        id: "FUP",
        field: "FUP",
        minWidth: 120,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      { id: "ServiceBranch", field: "ServiceBranch", minWidth: 100 },
      {
        id: "PremiumAmount",
        field: "PremiumAmount",
        minWidth: 120,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
        filterParams: {
          // pass in additional parameters to the Number Filter
        },
      },
      {
        id: "Inst",
        field: "Inst",
        minWidth: 100,
        filter: "agNumberColumnFilter",
        filterParams: {
          // pass in additional parameters to the Number Filter
        },
      },
      {
        id: "CDACharges",
        field: "CDACharges",
        minWidth: 100,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
        filterParams: {
          // pass in additional parameters to the Number Filter
        },
      },
      {
        id: "XCharges",
        field: "XCharges",
        minWidth: 100,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
        filterParams: {
          // pass in additional parameters to the Number Filter
        },
      },
      {
        id: "CessAmt",
        field: "CessAmt",
        minWidth: 100,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
        filterParams: {
          // pass in additional parameters to the Number Filter
        },
      },
      {
        id: "LateFeeDiscount",
        field: "LateFeeDiscount",
        minWidth: 100,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
        filterParams: {
          // pass in additional parameters to the Number Filter
        },
      },
      {
        id: "GSTAmt",
        field: "GSTAmt",
        minWidth: 100,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
        filterParams: {
          // pass in additional parameters to the Number Filter
        },
      },
      {
        id: "TotAmtDR",
        field: "TotAmtDR",
        minWidth: 100,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
        filterParams: {
          // pass in additional parameters to the Number Filter
        },
      },
      {
        id: "Cash",
        field: "Cash",
        minWidth: 100,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
        filterParams: {
          // pass in additional parameters to the Number Filter
        },
      },
      {
        id: "Bank",
        field: "Bank",
        minWidth: 100,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
        filterParams: {
          // pass in additional parameters to the Number Filter
        },
      },
      {
        id: "Balance",
        field: "Balance",
        minWidth: 100,
        valueFormatter: currencyFormatter1,
        filter: "agNumberColumnFilter",
        filterParams: {
          // pass in additional parameters to the Number Filter
        },
      },
      { id: "PaymentBy", field: "PaymentBy", minWidth: 200 },
      { id: "Remark", field: "Remark", minWidth: 200 },

      { field: "AddedBy", minWidth: 150, editable: false },
      {
        field: "Added",
        headerName: "Added On",
        minWidth: 180,
        editable: false,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      {
        field: "AssignedToName",
        minWidth: 150,
        editable: false,
        headerName: "Assigned To",
      },
      { field: "UniqueID", hide: true, editable: false },
    ];
  }, []);
  const [initialValues, setInitialValues] = React.useState({});
  const toaster = useToaster();
  const GetDataByID = async (ID) => {
    try {
      const responce = await axiosPrivate.post("GetPremiumPointByID.php", {
        UID: ID,
      });
      //console.log(responce);
      const result = responce.data;
      if (result.status === 1) {
        const newData2 = {
          UniqueID: result.Data[0]?.UniqueID,
          CashierName: result.Data[0]?.CashierName
            ? {
                title: result.Data[0]?.CashierName,
                value: result.Data[0]?.CashierName,
              }
            : { title: "", value: "" },
          CDACharges: result.Data[0]?.CDACharges,
          CessAmt: result.Data[0]?.CessAmt,
          GSTAmt: result.Data[0]?.GSTAmt,
          Inst: result.Data[0]?.Inst,
          LateFee: result.Data[0]?.LateFee,
          LateFeeDiscount: result.Data[0]?.LateFeeDiscount,
          Name: result.Data[0]?.Name,
          PolicyNo: result.Data[0]?.PolicyNo,
          PremiumAmount: result.Data[0]?.PremiumAmount,
          ServiceBranch: result.Data[0]?.ServiceBranch,
          TotAmtDR: result.Data[0]?.TotAmtDR,
          XCharges: result.Data[0]?.XCharges,
          Cash: result.Data[0]?.Cash,
          Bank: result.Data[0]?.Bank,
          Balance: result.Data[0]?.Balance,
          Remark: result.Data[0]?.Remark,
          AssignedTo:
            result.Data[0].AssignedTo === ""
              ? null
              : {
                  value: result.Data[0].AssignedTo,
                  title: result.Data[0].AssignedToName,
                },
          PaymentBy: result.Data[0]?.PaymentBy
            ? {
                value: result.Data[0]?.PaymentBy,
                title: result.Data[0]?.PaymentBy,
              }
            : { value: "", title: "" },

          FUP:
            result.Data[0].FUP === "0000-00-00" || result.Data[0].FUP === null
              ? null
              : new Date(result.Data[0].FUP),
          Date:
            result.Data[0].Date === "0000-00-00" || result.Data[0].Date === null
              ? null
              : new Date(result.Data[0].Date),
        };
        setInitialValues(newData2);
      }
      if (result.status === 0) {
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Error!</strong> {result?.Error}
          </Message>
        );
      }
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  };

  const [selectedRowData, setSelectedRowData] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed)
      if (selectedRowData?.UniqueID) {
        GetDataByID(selectedRowData.UniqueID);
      }
    return () => {
      isSubscribed = false;
    };
  }, [selectedRowData?.UniqueID, selectedRowData]);

  const Remove = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this  details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: initialValues.UniqueID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemovePremiumPoint.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                  onClose();
                  onGridReady();
                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  const clearAll = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete all?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: initialValues.UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemoveAllPremiumPoint.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  const onGridReady = React.useCallback(
    (params) => {
      setLoading(true);
      axiosPrivate
        .post("LoadPremiumPoint.php", {
          Start: moment(value[0]).format("YYYY-MM-DD"),
          End: moment(value[1]).format("YYYY-MM-DD"),
        })
        .then((responce) => {
          const result = responce.data;
          if (result.status === 1) {
            setRowData(result.Data);
            setTotal(result.Total);
          } else setRowData([]);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
      setLoading(false);
    },
    [value]
  );

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Stack
            direction="row"
            spacing={4}
            style={{ flex: 0.4, justifyContent: "flex-start", padding: 5 }}
          >
            <DateRangePicker
              ranges={predefinedRanges}
              value={value}
              onChange={setValue}
              cleanable={false}
              showMeridian
              format="dd-MM-yyyy"
              defaultCalendarValue={[
                new Date("2022-02-01"),
                new Date("2022-03-01"),
              ]}
            />
            <button type="button" className="Button" onClick={onGridReady}>
              Apply
            </button>
          </Stack>
          <Heading
            level={5}
            style={{
              textAlign: "center",
              flex: 0.4,
            }}
          >
            {`Premium Point A0099719G`}
          </Heading>
          <Stack direction="row" spacing={4}>
            <p>
              <b>Total:</b>
              {toAmnt(total.TotAmtDR)}
            </p>
            <p>
              <b>Cash:</b>
              {toAmnt(total.Cash)}
            </p>
            <p>
              <b>Bank:</b>
              {toAmnt(total.Bank)}
            </p>
            <p>
              <b>Balance:</b>
              {toAmnt(total.Balance)}
            </p>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            style={{ flex: 0.2, justifyContent: "flex-end" }}
          >
            <Button
              disabled={!permissions.includes(10000)}
              onClick={clearAll}
              appearance="link"
              startIcon={<DeleteForever />}
            >
              Clear All
            </Button>
          </Stack>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <Panel>
          <AgGrid
            columns={columns}
            rowData={rowData}
            onGridReady={onGridReady}
            defaultColumn={["Name"]}
            GoTo="/Policy/View"
            showLoading={loading}
            onRowSelectedData={setSelectedRowData}
            handleViewModalOpen={handleOpen}
            settingsName="Policy"
            headerComponent={() => <p></p>}
            setFilteredRows={setFilteredRows}
            Source="PremiumPoint"
            exportAllowed={true}
          />
        </Panel>
      </div>
      <SideDrawer
        open={open}
        onClose={handleClose}
        title="Update Premium Point"
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            const newData = {
              ...values,
              AssignedTo: values.AssignedTo?.value,
              CashierName: values.CashierName.value,
              PaymentBy: values.PaymentBy.value,
              FUP: moment(values.FUP).format("YYYY-MM-DD"),
              Date: moment(values.Date).format("YYYY-MM-DD"),
            };
            console.log(newData);
            try {
              axiosPrivate
                .post("EditPremiumPoint.php", newData)
                .then((response) => {
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" bordered showIcon>
                        <strong>Success!</strong> {result.Success}
                      </Message>
                    );

                    onGridReady();
                    setSubmitting(false);
                  }
                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" bordered showIcon>
                        <strong>Error!</strong> {result.Error}
                      </Message>
                    );
                    setSubmitting(false);
                  }
                })
                .catch((error) => {
                  console.error("Error submitting data:", error);
                  setSubmitting(false);
                });
            } catch (e) {
              console.log(e);
            } finally {
              setSubmitting(false);
            }
            // updateDatainGrid(newData, values.UniqueID);
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleSubmit,
            isValidating,
            isSubmitting,
            touched,
            handleBlur,
            handleChange,
          }) => {
            // console.log(errors);
            // console.log(values);
            return (
              <form>
                <div>
                  <div className="row">
                    <Grid fluid style={{ padding: 4 }}>
                      <Row className="show-grid">
                        <Col xs={24} sm={24} md={24}>
                          <Row
                            gutter={0}
                            style={{
                              marginBottom: 10,
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateField
                                label="Date"
                                variant="standard"
                                size="small"
                                name="Date"
                                format="dd-MM-yyyy"
                                value={values.Date}
                                onChange={(value) =>
                                  setFieldValue("Date", value, true)
                                }
                                onBlur={handleBlur}
                                error={touched.Date && Boolean(errors.Date)}
                                helperText={touched.Date && errors.Date}
                              />
                            </LocalizationProvider>
                          </Row>
                          <Row
                            gutter={0}
                            style={{
                              marginBottom: 10,
                              marginLeft: -10,
                            }}
                          >
                            <Autocomplete
                              key="ID"
                              name={`CashierName`}
                              options={
                                CashierList.error ? [null] : CashierList.data
                              }
                              getOptionLabel={(option) => option?.title}
                              disableClearable={true}
                              value={
                                Boolean(values.CashierName)
                                  ? values.CashierName
                                  : { value: "", title: "" }
                              }
                              onBlur={handleBlur}
                              error={
                                touched.CashierName &&
                                Boolean(errors.CashierName)
                              }
                              helperText={
                                touched.CashierName && errors.CashierName
                              }
                              onChange={(_, newValue) => {
                                console.log(values);
                                console.log(errors);
                                setFieldValue(`CashierName`, newValue, true);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="CashierName"
                                  onBlur={handleBlur}
                                  error={
                                    touched.CashierName &&
                                    Boolean(errors.CashierName)
                                  }
                                  helperText={
                                    touched.CashierName && errors.CashierName
                                  }
                                  label="CashierName"
                                  variant="standard"
                                />
                              )}
                              style={{ width: 200 }}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              fullWidth
                              name={`PolicyNo`}
                              variant="standard"
                              InputLabelProps={{
                                shrink: Boolean(values.PolicyNo),
                              }}
                              label="Policy No"
                              size="small"
                              as={TextField}
                              placeholder="Enter Policy No"
                              onBlur={handleBlur}
                              error={
                                touched.PolicyNo && Boolean(errors.PolicyNo)
                              }
                              helperText={touched.PolicyNo && errors.PolicyNo}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              fullWidth
                              name={`Name`}
                              variant="standard"
                              InputLabelProps={{
                                shrink: Boolean(values.Name),
                              }}
                              label="Name"
                              size="small"
                              as={TextField}
                              placeholder="Enter Name"
                              onBlur={handleBlur}
                              error={touched.Name && Boolean(errors.Name)}
                              helperText={touched.Name && errors.Name}
                            />
                          </Row>
                          <Row
                            gutter={0}
                            style={{
                              marginBottom: 10,
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateField
                                label="FUP"
                                variant="standard"
                                size="small"
                                name="FUP"
                                format="dd-MM-yyyy"
                                value={values.FUP}
                                onChange={(value) =>
                                  setFieldValue("FUP", value, true)
                                }
                                onBlur={handleBlur}
                                error={touched.FUP && Boolean(errors.FUP)}
                                helperText={touched.FUP && errors.FUP}
                              />
                            </LocalizationProvider>
                          </Row>

                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`ServiceBranch`}
                              fullWidth
                              label="ServiceBranch"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.ServiceBranch),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Service Branch"
                              onBlur={handleBlur}
                              error={
                                touched.ServiceBranch &&
                                Boolean(errors.ServiceBranch)
                              }
                              helperText={
                                touched.ServiceBranch && errors.ServiceBranch
                              }
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`PremiumAmount`}
                              fullWidth
                              label="Premium Amount"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.PremiumAmount),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Premium Amount"
                              onBlur={handleBlur}
                              error={
                                touched.PremiumAmount &&
                                Boolean(errors.PremiumAmount)
                              }
                              helperText={
                                touched.PremiumAmount && errors.PremiumAmount
                              }
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`Inst`}
                              fullWidth
                              label="Inst"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.Inst),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Inst"
                              onBlur={handleBlur}
                              error={touched.Inst && Boolean(errors.Inst)}
                              helperText={touched.Inst && errors.Inst}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`CDACharges`}
                              fullWidth
                              label="CDACharges"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.CDACharges),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter CDACharges"
                              onBlur={handleBlur}
                              error={
                                touched.CDACharges && Boolean(errors.CDACharges)
                              }
                              helperText={
                                touched.CDACharges && errors.CDACharges
                              }
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`XCharges`}
                              fullWidth
                              label="XCharges"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.XCharges),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter XCharges"
                              onBlur={handleBlur}
                              error={
                                touched.XCharges && Boolean(errors.XCharges)
                              }
                              helperText={touched.XCharges && errors.XCharges}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`CessAmt`}
                              fullWidth
                              label="CessAmt"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.CessAmt),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter CessAmt"
                              onBlur={handleBlur}
                              error={touched.CessAmt && Boolean(errors.CessAmt)}
                              helperText={touched.CessAmt && errors.CessAmt}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`LateFee`}
                              fullWidth
                              label="LateFee"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.LateFee),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Late Fee"
                              onBlur={handleBlur}
                              error={touched.LateFee && Boolean(errors.LateFee)}
                              helperText={touched.LateFee && errors.LateFee}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`LateFeeDiscount`}
                              fullWidth
                              label="Late Fee Discount"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.LateFeeDiscount),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Late Fee Discount"
                              onBlur={handleBlur}
                              error={
                                touched.LateFeeDiscount &&
                                Boolean(errors.LateFeeDiscount)
                              }
                              helperText={
                                touched.LateFeeDiscount &&
                                errors.LateFeeDiscount
                              }
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`GSTAmt`}
                              fullWidth
                              label="GSTAmt"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.GSTAmt),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter GST Amt"
                              onBlur={handleBlur}
                              error={touched.GSTAmt && Boolean(errors.GSTAmt)}
                              helperText={touched.GSTAmt && errors.GSTAmt}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`TotAmtDR`}
                              fullWidth
                              label="Total Amt DR"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.TotAmtDR),
                              }}
                              variant="standard"
                              as={TextField}
                              onChange={(E) => {
                                handleChange(E);
                                let Amnt = E.target.value;
                                let Balance =
                                  parseFloat(Amnt) -
                                  parseFloat(values.Cash || 0) -
                                  parseFloat(values.Bank || 0);
                                setFieldValue("Balance", Balance);
                              }}
                              placeholder="Enter Total Amt DR"
                              onBlur={handleBlur}
                              error={
                                touched.TotAmtDR && Boolean(errors.TotAmtDR)
                              }
                              helperText={touched.TotAmtDR && errors.TotAmtDR}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`Cash`}
                              fullWidth
                              label="Cash"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.Cash),
                              }}
                              onChange={(E) => {
                                handleChange(E);
                                let Amnt = E.target.value;
                                let Balance =
                                  parseFloat(values.TotAmtDR) -
                                  parseFloat(Amnt || 0) -
                                  parseFloat(values.Bank || 0);
                                setFieldValue("Balance", Balance);
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Cash"
                              onBlur={handleBlur}
                              error={touched.Cash && Boolean(errors.Cash)}
                              helperText={touched.Cash && errors.Cash}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`Bank`}
                              fullWidth
                              label="Bank"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.Bank),
                              }}
                              variant="standard"
                              as={TextField}
                              onChange={(E) => {
                                handleChange(E);
                                let Amnt = E.target.value;
                                let Balance =
                                  parseFloat(values.TotAmtDR) -
                                  parseFloat(values.Cash || 0) -
                                  parseFloat(Amnt);
                                setFieldValue("Balance", Balance);
                              }}
                              placeholder="Enter Bank"
                              onBlur={handleBlur}
                              error={touched.Bank && Boolean(errors.Bank)}
                              helperText={touched.Bank && errors.Bank}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`Balance`}
                              fullWidth
                              label="Total Balance"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.Balance),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Balance"
                              onBlur={handleBlur}
                              error={touched.Balance && Boolean(errors.Balance)}
                              helperText={touched.Balance && errors.Balance}
                            />
                          </Row>
                          <Row
                            gutter={0}
                            style={{
                              marginBottom: 10,
                              marginLeft: -10,
                            }}
                          >
                            <Autocomplete
                              key="ID"
                              name={`PaymentBy`}
                              options={
                                PaymentByList.error
                                  ? [null]
                                  : PaymentByList.data
                              }
                              getOptionLabel={(option) => option?.title}
                              disableClearable={true}
                              value={
                                Boolean(values.PaymentBy)
                                  ? values.PaymentBy
                                  : { value: "", title: "" }
                              }
                              onBlur={handleBlur}
                              error={
                                touched.PaymentBy && Boolean(errors.PaymentBy)
                              }
                              helperText={touched.PaymentBy && errors.PaymentBy}
                              onChange={(_, newValue) => {
                                console.log(values);
                                console.log(errors);
                                setFieldValue(`PaymentBy`, newValue, true);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="PaymentBy"
                                  onBlur={handleBlur}
                                  error={
                                    touched.PaymentBy &&
                                    Boolean(errors.PaymentBy)
                                  }
                                  helperText={
                                    touched.PaymentBy && errors.PaymentBy
                                  }
                                  label="Payment By"
                                  variant="standard"
                                />
                              )}
                              style={{ width: 200 }}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`Remark`}
                              fullWidth
                              label="Remark"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.Remark),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Remark"
                              onBlur={handleBlur}
                              error={touched.Remark && Boolean(errors.Remark)}
                              helperText={touched.Remark && errors.Remark}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Stack direction="row" spacing={0}>
                              {user.Type === "SuperAdmin" && (
                                <Field
                                  name="AssignedTo"
                                  component={Autocomplete}
                                  key="ID"
                                  options={options.sort(
                                    (a, b) =>
                                      -b.firstLetter.localeCompare(
                                        a.firstLetter
                                      )
                                  )}
                                  groupBy={(option) => option.UserType}
                                  sx={{ width: 300 }}
                                  renderGroup={(params) => (
                                    <li key={params.key}>
                                      <GroupHeader>{params.group}</GroupHeader>
                                      <GroupItems>{params.children}</GroupItems>
                                    </li>
                                  )}
                                  getOptionLabel={(option) =>
                                    option?.title || ""
                                  }
                                  style={{ width: 200 }}
                                  onBlur={handleBlur} // so formik can see the forms touched state
                                  onChange={(_, value) => {
                                    setFieldValue(
                                      "AssignedTo",
                                      value || { value: "", title: "" }
                                    );
                                    console.log(values);
                                  }}
                                  value={
                                    Boolean(values.AssignedTo)
                                      ? values.AssignedTo
                                      : { value: "", title: "" }
                                  }
                                  disableClearable={true}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      name="AssignedTo"
                                      label="AssignedTo"
                                      variant="standard"
                                      error={
                                        touched.AssignedTo &&
                                        Boolean(errors.AssignedTo)
                                      }
                                      helperText={
                                        touched.AssignedTo && errors.AssignedTo
                                      }
                                    />
                                  )}
                                />
                              )}
                            </Stack>
                          </Row>
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                  <Divider />
                  &nbsp; &nbsp;
                  <>
                    {(permissions.includes(EDIT_PREMIUMPOINT) ||
                      user.Type === "SuperAdmin") && (
                      <LoadingButton
                        disabled={isSubmitting}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        loading={isValidating}
                        sx={{ my: 2 }}
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </LoadingButton>
                    )}
                    &nbsp; &nbsp;
                    {(permissions.includes(DELETE_PREMIUMPOINT) ||
                      user.Type === "SuperAdmin") && (
                      <Button
                        color="red"
                        appearance="primary"
                        sx={{ my: 2 }}
                        onClick={Remove}
                      >
                        {"Delete"}
                      </Button>
                    )}
                  </>
                </div>
              </form>
            );
          }}
        </Formik>
      </SideDrawer>
      <Styles />
    </>
  );
}
const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const cyan = {
  50: "#E9F8FC",
  100: "#BDEBF4",
  200: "#99D8E5",
  300: "#66BACC",
  400: "#1F94AD",
  500: "#0D5463",
  600: "#094855",
  700: "#063C47",
  800: "#043039",
  900: "#022127",
};

function useIsDarkMode() {
  const theme = useTheme();
  return theme.palette.mode === "dark";
}

function Styles() {
  // Replace this with your app logic for determining dark mode
  const isDarkMode = useIsDarkMode();
  return (
    <style>{`
        .Button {
          font-family: 'IBM Plex Sans', sans-serif;
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.5;
          background-color: ${cyan[500]};
          padding: 8px 16px;
          border-radius: 8px;
          color: white;
          transition: all 150ms ease;
          cursor: pointer;
          border: 1px solid ${cyan[500]};
          box-shadow: 0 2px 4px ${
            isDarkMode ? "rgba(0, 0, 0, 0.5)" : "rgba(13, 84, 99, 0.5)"
          }, inset 0 1.5px 1px ${cyan[400]}, inset 0 -2px 1px ${cyan[600]};

          &:hover {
            background-color: ${cyan[600]};
          }

          &:active {
            background-color: ${cyan[700]};
            box-shadow: none;
          }

          &:focus-visible {
            box-shadow: 0 0 0 4px ${isDarkMode ? cyan[300] : cyan[200]};
            outline: none;
            }
        }

        .CustomPopup{
          background-color: ${isDarkMode ? grey[900] : "#FFF"};
          border-radius: 8px;
          border: 1px solid ${isDarkMode ? grey[700] : grey[200]};
          box-shadow: ${
            isDarkMode
              ? `0 4px 8px rgb(0 0 0 / 0.7)`
              : `0 4px 8px rgb(0 0 0 / 0.1)`
          };
          padding: 0.75rem;
          color: ${isDarkMode ? cyan[100] : cyan[700]};
          font-size: 0.875rem;
          font-family: 'IBM Plex Sans', sans-serif;
          font-weight: 500;
          opacity: 1;
          margin: 0.5rem 0px;
        }
    `}</style>
  );
}
