import * as React from "react";
import AgGrid from "app/components/AgGrid";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { Panel } from "rsuite";
import { filterParams } from "functions";

export default function ListUsers() {
  const axiosPrivate = useAxiosPrivate();

  const [rowData, setRowData] = React.useState([{}]);

  const [loading, setLoading] = React.useState(false);

  // const abc = useReference();
  // console.log(abc);
  const ColorStatus = React.useMemo(() => {
    return ["#FC6E51", "#A0D468"];
  }, []);
  const columns = React.useMemo(() => {
    return [
      {
        headerName: "FirstName",
        field: "FirstName",
        minWidth: 180,
        editable: false,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      {
        headerName: "LastName",
        field: "LastName",
        minWidth: 180,
        editable: false,
      },

      { field: "Email", minWidth: 150, editable: false },

      {
        field: "Mobile",
        minWidth: 150,
        type: "numericColumn",
        editable: false,
      },

      { field: "Username", minWidth: 150, editable: false },
      { field: "UserType", minWidth: 150, editable: false },
      {
        field: "Enabled",
        minWidth: 150,
        editable: false,
        cellStyle: (params) => {
          if (params.value === "YES" || params.value === "YES") {
            //mark police cells as red
            return { color: ColorStatus[1], fontWeight: "bold" };
          } else {
            //mark police cells as red
            return { color: ColorStatus[0] };
          }
        },
      },
      {
        field: "Locked",
        minWidth: 150,
        editable: false,
        cellStyle: (params) => {
          if (params.value === "YES" || params.value === "YES") {
            //mark police cells as red
            return { color: ColorStatus[0], fontWeight: "bold" };
          } else {
            //mark police cells as red
            return { color: ColorStatus[1] };
          }
        },
      },

      {
        field: "DOB",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
        editable: false,
      },
      {
        field: "LastLogin",
        minWidth: 150,
        editable: false,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      { field: "UniqueID", hide: true, editable: false },
    ];
  }, []);
  const onGridReady = React.useCallback((params) => {
    setLoading(true);
    axiosPrivate
      .post("LoadUsers.php")
      .then((responce) => {
        const result = responce.data;
        if (result.status === 1) setRowData(result.Data);
        else setRowData([]);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  const [selectedRowData, setSelectedRowData] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);
  return (
    <>
      <Panel>
        <AgGrid
          columns={columns}
          rowData={rowData}
          onGridReady={onGridReady}
          defaultColumn={["Name"]}
          GoTo="/Users/View"
          showLoading={loading}
          onRowSelectedData={setSelectedRowData}
          settingsName="ViewUser"
          headerComponent={() => <p></p>}
          setFilteredRows={setFilteredRows}
          Source="User"
          exportAllowed={false}
        />
      </Panel>
    </>
  );
}
