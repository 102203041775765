import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Container, Content } from "rsuite";
import { Button } from "@mui/material";
import {
  Delete,
  ImportContacts,
  ListAltRounded,
  PersonAdd,
} from "@mui/icons-material";
import Header from "app/components/Header";

import useAuth from "app/hooks/useAuth";
import {
  ADD_CUSTOMER,
  DELETE_CUSTOMER,
  IMPORT_CUSTOMER,
  VIEW_CUSTOMER,
} from "Permissions";
import { Outlet, useNavigate } from "react-router-dom";

export default function Customers() {
  const { permissions, user } = useAuth();
  const navigate = useNavigate();
  return (
    <Box
      component="section"
      sx={{
        p: 1,
        border: "0.5px dashed #ddd",
        width: "100%",
        backgroundColor: "#FEFAF6",
      }}
    >
      <Stack spacing={1}>
        <Header headerText="Manage Customers">
          {(permissions.includes(ADD_CUSTOMER) ||
            user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("Add");
              }}
              variant="contained"
              startIcon={<PersonAdd />}
            >
              Add
            </Button>
          )}
          {(permissions.includes(VIEW_CUSTOMER) ||
            user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("List");
              }}
              variant="contained"
              startIcon={<ListAltRounded />}
            >
              List
            </Button>
          )}
          {(permissions.includes(IMPORT_CUSTOMER) ||
            user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("Import");
              }}
              variant="contained"
              startIcon={<ImportContacts />}
            >
              Import
            </Button>
          )}
          {(permissions.includes(DELETE_CUSTOMER) ||
            user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("Deleted");
              }}
              variant="contained"
              startIcon={<Delete />}
            >
              Deleted
            </Button>
          )}
        </Header>

        <Container>
          <Content
            style={{
              flex: 1,
              width: "100%",
              height: 650,
              borderRadius: 10,
              border: "1px dashed #ddd",
            }}
          >
            <Outlet />
          </Content>
        </Container>
      </Stack>
    </Box>
  );
}
