import React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { Heading } from "rsuite";
export default function Header({ children, headerText = "" }) {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#030637",
    ...theme.typography.body2,
    textAlign: "center",
    color: "#1e1e1e",
    borderColor: "#060d73",
    boxShadow:
      "0 2px 4px rgb(3, 6, 55,0.5), inset 0 1.5px 1px #060d73, inset 0 -2px 1px #070e84",
  }));
  return (
    <Item>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px 10px 0px 10px",
        }}
      >
        <Heading style={{ color: "#eee" }} level={3}>
          {headerText}
        </Heading>
        <Stack direction="row" spacing={2}>
          {children}
        </Stack>
      </div>
    </Item>
  );
}
