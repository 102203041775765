import * as React from "react";
import AgGrid from "app/components/AgGrid";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { Heading, Panel } from "rsuite";
import { useProps } from "./ServiceProvider";
import { filterParams } from "functions";

export default function MinorDAB() {
  const axiosPrivate = useAxiosPrivate();

  const [rowData, setRowData] = React.useState([{}]);

  const [loading, setLoading] = React.useState(false);

  const columns = React.useMemo(() => {
    return [
      {
        field: "PolicyNo",
        width: 200,
        editable: false,
        headerName: "Policy No",
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },

      {
        headerName: "Ref ID",
        field: "RefID",
        width: 180,
        editable: false,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },

      { field: "Place", width: 150, editable: false },
      {
        field: "Name",
        width: 200,
        editable: false,
        headerName: "Family Head Name",
      },
      {
        field: "PolicyHolderName",
        width: 150,
        editable: false,
        headerName: "PolicyHolder",
      },
      {
        field: "DOB",
        width: 130,
        editable: false,
        headerName: "DOB",
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      {
        field: "Age",
        width: 180,
        editable: false,
        headerName: "Age",
      },
      { field: "Caste", width: 150, editable: false },
      {
        field: "Village",
        width: 150,
        editable: false,
        headerName: "Village/City",
      },
      {
        field: "WhatsAppNo",
        width: 120,
        editable: false,
        headerName: "Mobile No",
      },
      {
        field: "Profession",
        width: 150,
        editable: false,
        headerName: "Profession",
      },
      {
        field: "WorkType",
        width: 150,
        editable: false,
        headerName: "Work Type",
      },
      {
        field: "AgencyCode",
        width: 150,
        editable: false,
        headerName: "Agency Code",
      },
      {
        field: "CompletionDate",
        width: 150,
        editable: false,
        headerName: "Completion Date",

        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      {
        field: "AgeDOC",
        width: 150,
        editable: false,
        headerName: "Age ON DOC",
      },

      {
        field: "DOC",
        width: 150,
        editable: false,
        headerName: "DOC",
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      {
        field: "PayingTerm",
        width: 150,
        editable: false,
        headerName: "Plan-Term",
      },

      {
        field: "Mode",
        width: 100,
        editable: false,
        headerName: "Mode",
      },

      { field: "MinorPolicy", width: 150, editable: false },

      { field: "AddedBy", width: 150, editable: false },
      {
        field: "AddedOn",
        width: 180,
        editable: false,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      { field: "UniqueID", hide: true, editable: false },
    ];
  }, []);

  const onGridReady = React.useCallback((params) => {
    // getSettings();
    setLoading(true);
    axiosPrivate
      .post("LoadDABPolicy.php")
      .then((responce) => {
        const result = responce.data;
        if (result.status === 1) setRowData(result.Data);
        else setRowData([]);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  const [selectedRowData, setSelectedRowData] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);

  const { updatePropsValue } = useProps();

  const handleUpdateMessage = () => {
    const NewData = filteredRows.map((dt) => dt.data.UniqueID);
    updatePropsValue(NewData);
  };
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      handleUpdateMessage();
      //console.log(filteredRows);
    }
    return () => {
      isSubscribed = false;
    };
  }, [filteredRows.length]);

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Heading
            level={5}
            style={{
              textAlign: "center",
              flex: 1,
            }}
          >
            Minor DAB
          </Heading>
          {/* {(permissions.includes(VIEW_POLICYSERVICE) ||
          user.Type === "SuperAdmin") && (
          <>
            <Button
              onClick={() => {
                navigate("/References/AddReference");
              }}
              variant="contained"
              startIcon={<ListAlt />}
            >
              List All
            </Button>
          </>
        )} */}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <Panel>
          <AgGrid
            columns={columns}
            rowData={rowData}
            onGridReady={onGridReady}
            defaultColumn={["Name"]}
            GoTo="/Services/UpdateDAB"
            showLoading={loading}
            onRowSelectedData={setSelectedRowData}
            settingsName="ENach"
            headerComponent={() => <p></p>}
            setFilteredRows={setFilteredRows}
            Source="MinorDAB"
            exportAllowed={true}
          />
        </Panel>
      </div>
    </>
  );
}
