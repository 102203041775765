import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  Col,
  Container,
  Content,
  Divider,
  Grid,
  Message,
  Row,
  useToaster,
  Button,
  Heading,
} from "rsuite";
import { Autocomplete, TextField } from "@mui/material";
import { Delete, DeleteForever, Upload } from "@mui/icons-material";
import Header from "app/components/Header";
import useAuth from "app/hooks/useAuth";
import AgGrid from "app/components/AgGrid";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { Panel } from "rsuite";
import { useNavigate } from "react-router-dom";
import { DELETE_PREMIUMPOINT, IMPORT_PREMIUMPOINT } from "Permissions";
import { MatxLoading } from "app/components";
//import { PolicyProvider } from "./PolicyProvider";
import { LoadingButton } from "@mui/lab";
import { Field, Formik } from "formik";
import moment from "moment";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import SideDrawer from "app/components/SideDrawer";
import * as yup from "yup";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { filterParams } from "functions";
const validationSchema = yup.object({});
const Responces = [
  { value: "Call Recieved", title: "Call Recieved", ID: 1 },
  { value: "Call Not Recieved", title: "Call Not Recieved", ID: 2 },
  { value: "Switch Off", title: "Switch Off", ID: 3 },
  { value: "Other", title: "Other", ID: 4 },
];
const Status = [
  { value: "DONE", title: "DONE", ID: 1 },
  { value: "PENDING", title: "PENDING", ID: 2 },
  { value: "REJECT", title: "REJECT", ID: 3 },
];
export default function LapsedPolicyDueList() {
  const { permissions, user } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [rowData, setRowData] = React.useState([{}]);

  const [loading, setLoading] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [initialValues, setInitialValues] = React.useState({});
  const toaster = useToaster();
  const GetDataByID = async (ID) => {
    try {
      const responce = await axiosPrivate.post("GetLapsedPolicyDueByID.php", {
        UID: ID,
      });
      //console.log(responce);
      const result = responce.data;
      if (result.status === 1) {
        const newData = {
          ...result.Data[0],
          Responce: result.Data[0].Responce,
          Status: result.Data[0].Status,

          CommDate:
            result.Data[0].CommDate === "0000-00-00" ||
            result.Data[0].CommDate === null
              ? null
              : new Date(result.Data[0].CommDate),
          ValidUpto:
            result.Data[0].ValidUpto === "0000-00-00" ||
            result.Data[0].ValidUpto === null
              ? null
              : new Date(result.Data[0].ValidUpto),
          FUP:
            result.Data[0].FUP === "0000-00-00" || result.Data[0].FUP === null
              ? null
              : new Date(result.Data[0].FUP),
          DateOfCall:
            result.Data[0].DateOfCall === "0000-00-00" ||
            result.Data[0].DateOfCall === null
              ? null
              : new Date(result.Data[0].DateOfCall),

          NextDate:
            result.Data[0].NextDate === "0000-00-00" ||
            result.Data[0].NextDate === null
              ? null
              : new Date(result.Data[0].NextDate),
          UID: ID,
        };
        setInitialValues(newData);
      }
      if (result.status === 0) {
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Error!</strong> {result?.Error}
          </Message>
        );
      }
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed)
      if (selectedRowData?.UniqueID) {
        GetDataByID(selectedRowData.UniqueID);
      }
    return () => {
      isSubscribed = false;
    };
  }, [selectedRowData?.UniqueID, selectedRowData]);
  const Remove = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this  details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: initialValues.UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemoveLapsedPolicyDue.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  const formatNumber = (number) => {
    return Math.floor(number).toLocaleString();
  };
  const currencyFormatter = (params) => {
    return formatNumber(params.value);
  };

  const columns = React.useMemo(() => {
    return [
      { id: "PolicyNo", field: "PolicyNo", minWidth: 150 },
      { id: "PolicyHolder", field: "PolicyHolder", Width: 200 },

      { id: "PTP", field: "PTP", minWidth: 200 },
      {
        id: "SumAssured",
        field: "SumAssured",
        minWidth: 200,
        valueFormatter: currencyFormatter,
      },
      { id: "MD", field: "MD", minWidth: 200 },
      { id: "BRN", field: "BRN", minWidth: 200 },
      {
        id: "FUP",
        field: "FUP",
        minWidth: 200,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      { id: "PremiumNo", field: "PremiumNo", minWidth: 200 },
      {
        id: "Premium",
        field: "Premium",
        minWidth: 200,
        valueFormatter: currencyFormatter,
      },
      { id: "IntRt", field: "IntRt", minWidth: 200 },
      {
        id: "IntAmount",
        field: "IntAmount",
        minWidth: 200,
        valueFormatter: currencyFormatter,
      },
      {
        id: "TotalPayable",
        field: "TotalPayable",
        minWidth: 200,
        valueFormatter: currencyFormatter,
      },
      {
        id: "ValidUpto",
        field: "ValidUpto",
        minWidth: 200,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      {
        id: "Comm",
        field: "Comm",
        minWidth: 200,
        valueFormatter: currencyFormatter,
      },
      {
        id: "CommDate",
        field: "CommDate",
        minWidth: 200,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      {
        id: "DateOfCall",
        field: "DateOfCall",
        minWidth: 200,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      { id: "Responce", field: "Responce", minWidth: 200 },
      {
        id: "NextDate",
        field: "NextDate",
        minWidth: 200,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      { id: "Status", field: "Status", minWidth: 200 },

      { field: "AddedBy", minWidth: 150, editable: false },
      {
        field: "AddedOn",
        minWidth: 180,
        editable: false,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      { field: "UniqueID", hide: true, editable: false },
    ];
  }, []);
  const clearAll = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete all?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const response = await axiosPrivate.post(
                    "/RemoveAllLapsedPolicyDue.php"
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    onGridReady();
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };
  const onGridReady = React.useCallback((params) => {
    setLoading(true);
    axiosPrivate
      .post("LoadLapseDueList.php")
      .then((responce) => {
        const result = responce.data;
        if (result.status === 1) setRowData(result.Data);
        else setRowData([]);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
    setLoading(false);
  }, []);

  const navigate = useNavigate();
  return (
    <Box
      component="section"
      sx={{
        p: 1,
        border: "0.5px dashed #ddd",
        width: "100%",
        backgroundColor: "#FEFAF6",
      }}
    >
      <Stack spacing={1}>
        <Header headerText="Lapsed Policy Due List">
          {(permissions.includes(IMPORT_PREMIUMPOINT) ||
            user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("/LapsedPolicyDueImport");
                }}
                variant="contained"
                startIcon={<Upload />}
              >
                Import
              </Button>
            </>
          )}
          {/*           {(permissions.includes(DELETE_PREMIUMPOINT) ||
            user.Type === "SuperAdmin") && (
            <Button
              disabled={true}
              onClick={() => {
                navigate("Deleted");
              }}
              variant="contained"
              startIcon={<Delete />}
            >
              Deleted
            </Button> 
          )}*/}
        </Header>

        <Container>
          <Content
            style={{
              flex: 1,
              width: "100%",
              height: 650,
              borderRadius: 10,
              border: "1px dashed #ddd",
            }}
          >
            {navigate.state === "loading" ? (
              <MatxLoading />
            ) : (
              <>
                <div
                  style={{
                    width: "100%",
                    border: "0.5px dashed #999",
                    borderRadius: 10,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Heading
                      level={5}
                      style={{
                        textAlign: "center",
                        flex: 0.8,
                      }}
                    >
                      {`Lapsed Due List`}
                    </Heading>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ flex: 0.2, justifyContent: "flex-end" }}
                    >
                      {(permissions.includes(DELETE_PREMIUMPOINT) ||
                        user.Type === "SuperAdmin") && (
                        <Button
                          onClick={clearAll}
                          appearance="link"
                          startIcon={<DeleteForever />}
                        >
                          Clear All
                        </Button>
                      )}
                    </Stack>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    maxHeight: 600,
                    border: "0.5px dotted #999",
                    marginTop: 5,
                    borderRadius: 10,
                    overflowY: "auto",
                  }}
                >
                  <Panel>
                    <AgGrid
                      columns={columns}
                      rowData={rowData}
                      onGridReady={onGridReady}
                      defaultColumn={["Name"]}
                      GoTo="/Policy/View"
                      showLoading={loading}
                      onRowSelectedData={setSelectedRowData}
                      handleViewModalOpen={handleOpen}
                      settingsName="LapsedPolicyDue"
                      headerComponent={() => <p></p>}
                      setFilteredRows={setFilteredRows}
                      Source="LapsedPolicyDue"
                      exportAllowed={true}
                    />
                  </Panel>
                </div>
              </>
            )}
          </Content>
        </Container>
      </Stack>
      <SideDrawer open={open} onClose={handleClose} title="Lapsed Policy Due">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            try {
              const newData = {
                ...values,
                DateOfCall: values.DateOfCall
                  ? moment(values?.DateOfCall).format("YYYY-MM-DD")
                  : null,
                NextDate: values?.NextDate
                  ? moment(values?.NextDate).format("YYYY-MM-DD")
                  : null,
                DOM: values?.DOM
                  ? moment(values?.DOM).format("YYYY-MM-DD")
                  : null,
                Responce: values.Responce.value,
                Status: values.Status.value,
              };

              axiosPrivate
                .post("UpdateLapsedPolicyDueStatus.php", newData)
                .then((response) => {
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" bordered showIcon>
                        <strong>Success!</strong> {result.Success}
                      </Message>
                    );

                    onGridReady();
                    setSubmitting(false);
                  }
                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" bordered showIcon>
                        <strong>Error!</strong> {result.Error}
                      </Message>
                    );
                    setSubmitting(false);
                  }
                })
                .catch((error) => {
                  console.error("Error submitting data:", error);
                  setSubmitting(false);
                });
            } catch (e) {
              console.log(e);
            } finally {
              // setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleSubmit,
            isValidating,
            isSubmitting,
            touched,
            handleBlur,
            handleChange,
          }) => {
            // console.log(errors);
            // console.log(values);
            return (
              <form>
                <div>
                  <div className="row">
                    <Grid fluid style={{ padding: 4 }}>
                      <Row className="show-grid">
                        <Col xs={24} sm={24} md={24}>
                          <Row
                            gutter={0}
                            style={{
                              marginBottom: 10,
                              alignItems: "flex-end",
                              display: "flex",
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Field
                                disabled={!editMode}
                                name={`ValidUpto`}
                                label="Valid Upto"
                                variant="standard"
                                size="small"
                                format="dd-MM-yyyy"
                                as={DateField}
                                value={values.ValidUpto}
                                onChange={(value) =>
                                  setFieldValue(`ValidUpto`, value, true)
                                }
                                onBlur={handleBlur}
                                error={
                                  touched.ValidUpto && Boolean(errors.ValidUpto)
                                }
                                helperText={
                                  touched.ValidUpto && errors.ValidUpto
                                }
                              />
                            </LocalizationProvider>
                          </Row>
                          <Row
                            gutter={0}
                            style={{
                              marginBottom: 10,
                              alignItems: "flex-end",
                              display: "flex",
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Field
                                disabled={!editMode}
                                name={`FUP`}
                                label="FUP"
                                variant="standard"
                                size="small"
                                format="dd-MM-yyyy"
                                as={DateField}
                                value={values.FUP}
                                onChange={(value) =>
                                  setFieldValue(`FUP`, value, true)
                                }
                                onBlur={handleBlur}
                                error={touched.FUP && Boolean(errors.FUP)}
                                helperText={touched.FUP && errors.FUP}
                              />
                            </LocalizationProvider>
                          </Row>
                          <Row
                            gutter={0}
                            style={{
                              marginBottom: 10,
                              alignItems: "flex-end",
                              display: "flex",
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Field
                                disabled={!editMode}
                                name={`CommDate`}
                                label="CommDate"
                                variant="standard"
                                size="small"
                                format="dd-MM-yyyy"
                                as={DateField}
                                value={values.CommDate}
                                onChange={(value) =>
                                  setFieldValue(`CommDate`, value, true)
                                }
                                onBlur={handleBlur}
                                error={
                                  touched.CommDate && Boolean(errors.CommDate)
                                }
                                helperText={touched.CommDate && errors.CommDate}
                              />
                            </LocalizationProvider>
                          </Row>

                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              disabled={!editMode}
                              fullWidth
                              name={`PolicyNo`}
                              variant="standard"
                              label="PolicyNo"
                              size="small"
                              as={TextField}
                              placeholder="Enter PolicyNo"
                              InputLabelProps={{
                                shrink: Boolean(values.PolicyNo),
                              }}
                              onBlur={handleBlur}
                              error={
                                touched.PolicyNo && Boolean(errors.PolicyNo)
                              }
                              helperText={touched.PolicyNo && errors.PolicyNo}
                            />
                          </Row>

                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              disabled={!editMode}
                              fullWidth
                              name={`PolicyHolder`}
                              variant="standard"
                              label="Policy Holder"
                              size="small"
                              as={TextField}
                              placeholder="Enter Policy Holder"
                              InputLabelProps={{
                                shrink: Boolean(values.PolicyHolder),
                              }}
                              onBlur={handleBlur}
                              error={
                                touched.PolicyHolder &&
                                Boolean(errors.PolicyHolder)
                              }
                              helperText={
                                touched.PolicyHolder && errors.PolicyHolder
                              }
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              disabled={!editMode}
                              fullWidth
                              name={`PTP`}
                              variant="standard"
                              label="PTP"
                              size="small"
                              as={TextField}
                              placeholder="Enter PTP"
                              InputLabelProps={{
                                shrink: Boolean(values.PTP),
                              }}
                              onBlur={handleBlur}
                              error={touched.PTP && Boolean(errors.PTP)}
                              helperText={touched.PTP && errors.PTP}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              disabled={!editMode}
                              fullWidth
                              name={`MD`}
                              variant="standard"
                              label="MD"
                              size="small"
                              as={TextField}
                              placeholder="Enter MD"
                              InputLabelProps={{
                                shrink: Boolean(values.MD),
                              }}
                              onBlur={handleBlur}
                              error={touched.MD && Boolean(errors.MD)}
                              helperText={touched.MD && errors.MD}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              disabled={!editMode}
                              fullWidth
                              name={`BRN`}
                              variant="standard"
                              label="BRN"
                              size="small"
                              as={TextField}
                              placeholder="Enter BRN"
                              InputLabelProps={{
                                shrink: Boolean(values.BRN),
                              }}
                              onBlur={handleBlur}
                              error={touched.BRN && Boolean(errors.BRN)}
                              helperText={touched.BRN && errors.BRN}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              disabled={!editMode}
                              fullWidth
                              name={`SumAssured`}
                              variant="standard"
                              label="Sum Assured"
                              size="small"
                              as={TextField}
                              placeholder="Enter Sum Assured"
                              InputLabelProps={{
                                shrink: Boolean(values.SumAssured),
                              }}
                              onBlur={handleBlur}
                              error={
                                touched.SumAssured && Boolean(errors.SumAssured)
                              }
                              helperText={
                                touched.SumAssured && errors.SumAssured
                              }
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              disabled={!editMode}
                              fullWidth
                              name={`PremiumNo`}
                              variant="standard"
                              label="Premium No"
                              size="small"
                              as={TextField}
                              placeholder="Enter Premium No"
                              InputLabelProps={{
                                shrink: Boolean(values.PremiumNo),
                              }}
                              onBlur={handleBlur}
                              error={
                                touched.PremiumNo && Boolean(errors.PremiumNo)
                              }
                              helperText={touched.PremiumNo && errors.PremiumNo}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              disabled={!editMode}
                              fullWidth
                              name={`Premium`}
                              variant="standard"
                              label="Premium Amount"
                              size="small"
                              as={TextField}
                              placeholder="Enter Premium Amount"
                              InputLabelProps={{
                                shrink: Boolean(values.Premium),
                              }}
                              onBlur={handleBlur}
                              error={touched.Premium && Boolean(errors.Premium)}
                              helperText={touched.Premium && errors.Premium}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              disabled={!editMode}
                              fullWidth
                              name={`IntRt`}
                              variant="standard"
                              label="Int Rt"
                              size="small"
                              as={TextField}
                              placeholder="Enter Int Rt"
                              InputLabelProps={{
                                shrink: Boolean(values.IntRt),
                              }}
                              onBlur={handleBlur}
                              error={touched.IntRt && Boolean(errors.IntRt)}
                              helperText={touched.IntRt && errors.IntRt}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              disabled={!editMode}
                              fullWidth
                              name={`IntAmount`}
                              variant="standard"
                              label="Int Amount"
                              size="small"
                              as={TextField}
                              placeholder="Enter Int Amount"
                              InputLabelProps={{
                                shrink: Boolean(values.IntAmount),
                              }}
                              onBlur={handleBlur}
                              error={
                                touched.IntAmount && Boolean(errors.IntAmount)
                              }
                              helperText={touched.IntAmount && errors.IntAmount}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              disabled={!editMode}
                              fullWidth
                              name={`TotalPayable`}
                              variant="standard"
                              label="Total Payable"
                              size="small"
                              as={TextField}
                              placeholder="Enter Total Payable"
                              InputLabelProps={{
                                shrink: Boolean(values.TotalPayable),
                              }}
                              onBlur={handleBlur}
                              error={
                                touched.TotalPayable &&
                                Boolean(errors.TotalPayable)
                              }
                              helperText={
                                touched.TotalPayable && errors.TotalPayable
                              }
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              disabled={!editMode}
                              fullWidth
                              name={`Comm`}
                              variant="standard"
                              label="Total Comm"
                              size="small"
                              as={TextField}
                              placeholder="Enter Comm"
                              InputLabelProps={{
                                shrink: Boolean(values.Comm),
                              }}
                              onBlur={handleBlur}
                              error={touched.Comm && Boolean(errors.Comm)}
                              helperText={touched.Comm && errors.Comm}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              disabled={!editMode}
                              fullWidth
                              name={`Mobile`}
                              variant="standard"
                              label="Mobile"
                              size="small"
                              as={TextField}
                              placeholder="Enter Mobile"
                              InputLabelProps={{
                                shrink: Boolean(values.Mobile),
                              }}
                              onBlur={handleBlur}
                              error={touched.Mobile && Boolean(errors.Mobile)}
                              helperText={touched.Mobile && errors.Mobile}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name="Responce"
                              disabled={!editMode}
                              component={Autocomplete}
                              key="ID"
                              freeSolo
                              options={Responces}
                              getOptionLabel={(option) => option.title || ""}
                              style={{ width: 150 }}
                              onBlur={handleBlur} // so formik can see the forms touched state
                              onChange={(_, value) => {
                                setFieldValue(
                                  "Responce",
                                  value || { value: "", title: "" }
                                );
                              }}
                              value={
                                Boolean(values.Responce)
                                  ? values.Responce
                                  : { value: "", title: "" }
                              }
                              disableClearable={true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="Responce"
                                  label="Call Responce"
                                  variant="standard"
                                  error={
                                    touched.Responce && Boolean(errors.Responce)
                                  }
                                  helperText={
                                    touched.Responce && errors.Responce
                                  }
                                />
                              )}
                            />
                          </Row>
                          {/*                           <Row
                            gutter={0}
                            style={{
                              marginBottom: 10,
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Field
                                disabled={!editMode}
                                name={`DateTime`}
                                label="Date Time of Discussion"
                                variant="standard"
                                size="small"
                                InputLabelProps={{
                                  shrink: Boolean(values.DateTime),
                                }}
                                format="dd-MM-yyyy hh:mm aa"
                                as={DateField}
                                value={values.DateTime}
                                onChange={(value) => {
                                  setFieldValue(`DateTime`, value, true);
                                }}
                                onBlur={handleBlur}
                                error={
                                  touched.DateTime && Boolean(errors.DateTime)
                                }
                                helperText={touched.DateTime && errors.DateTime}
                              />
                            </LocalizationProvider>
                          </Row> */}
                          <Row
                            gutter={0}
                            style={{
                              marginBottom: 10,
                              alignItems: "flex-end",
                              display: "flex",
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Field
                                disabled={!editMode}
                                name={`DateOfCall`}
                                label="Call Date"
                                variant="standard"
                                size="small"
                                format="dd-MM-yyyy"
                                as={DateField}
                                value={values.DateOfCall}
                                onChange={(value) =>
                                  setFieldValue(`DateOfCall`, value, true)
                                }
                                onBlur={handleBlur}
                                error={
                                  touched.DateOfCall &&
                                  Boolean(errors.DateOfCall)
                                }
                                helperText={
                                  touched.DateOfCall && errors.DateOfCall
                                }
                              />
                            </LocalizationProvider>
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name="Status"
                              disabled={!editMode}
                              component={Autocomplete}
                              key="ID"
                              freeSolo
                              options={Status}
                              getOptionLabel={(option) => option.title || ""}
                              style={{ width: 150 }}
                              onBlur={handleBlur} // so formik can see the forms touched state
                              onChange={(_, value) => {
                                setFieldValue(
                                  "Status",
                                  value || { value: "", title: "" }
                                );
                              }}
                              value={
                                Boolean(values.Status)
                                  ? values.Status
                                  : { value: "", title: "" }
                              }
                              disableClearable={true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="Status"
                                  label="Status"
                                  variant="standard"
                                  error={
                                    touched.Status && Boolean(errors.Status)
                                  }
                                  helperText={touched.Status && errors.Status}
                                />
                              )}
                            />
                          </Row>
                          {values?.Status?.value === "PENDING" && (
                            <Row
                              gutter={0}
                              style={{
                                marginBottom: 10,
                                alignItems: "flex-end",
                                display: "flex",
                              }}
                            >
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <Field
                                  disabled={!editMode}
                                  name={`NextDate`}
                                  label="Next Date"
                                  variant="standard"
                                  size="small"
                                  format="dd-MM-yyyy"
                                  as={DateField}
                                  value={values.NextDate}
                                  onChange={(value) =>
                                    setFieldValue(`NextDate`, value, true)
                                  }
                                  onBlur={handleBlur}
                                  error={
                                    touched.NextDate && Boolean(errors.NextDate)
                                  }
                                  helperText={
                                    touched.NextDate && errors.NextDate
                                  }
                                />
                              </LocalizationProvider>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                  <Divider />
                  &nbsp; &nbsp;
                  {editMode ? (
                    <>
                      <LoadingButton
                        disabled={isSubmitting}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        loading={isValidating}
                        sx={{ my: 2 }}
                      >
                        {isSubmitting ? "Submitting..." : "Update"}
                      </LoadingButton>
                      &nbsp; &nbsp;
                      <Button
                        color="blue"
                        appearance="primary"
                        onClick={() => {
                          setEditMode(false);
                        }}
                        sx={{ my: 2 }}
                      >
                        {"Cancel"}
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        color="red"
                        appearance="primary"
                        onClick={Remove}
                        sx={{ my: 2 }}
                      >
                        {"Delete"}
                      </Button>
                    </>
                  ) : (
                    <Button
                      color="blue"
                      appearance="primary"
                      onClick={() => {
                        setEditMode(true);
                      }}
                      sx={{ my: 2 }}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </form>
            );
          }}
        </Formik>
      </SideDrawer>
    </Box>
  );
}
