import { CssBaseline, ThemeProvider } from "@mui/material";
import useSettings from "app/hooks/useSettings";

const MatxTheme = ({ children }) => {
  const { settings } = useSettings();
  let activeTheme = { ...settings.themes[settings.activeTheme] };
  activeTheme = {
    ...activeTheme,
    typography: {
      ...activeTheme.typography,
      fontSize: (activeTheme.typography?.fontSize || 14) + 2, // Increase font size by 2
      fontWeightLight: (activeTheme.typography?.fontWeightLight || 300) + 2,
      fontWeightRegular: (activeTheme.typography?.fontWeightRegular || 400) + 2,
      fontWeightMedium: (activeTheme.typography?.fontWeightMedium || 500) + 2,
      fontWeightBold: (activeTheme.typography?.fontWeightBold || 700) + 2,
    },
    components: {
      ...activeTheme.components,
      MuiInputLabel: {
        ...activeTheme.components?.MuiInputLabel,
        styleOverrides: {
          ...activeTheme.components?.MuiInputLabel?.styleOverrides,
          root: {
            ...activeTheme.components?.MuiInputLabel?.styleOverrides?.root,
            fontWeight: activeTheme.typography?.fontWeightBold || 702, // Adjusting based on new font weight
            fontSize: `${(activeTheme.typography?.fontSize || 14) + 2}px`, // Use the updated font size
          },
        },
      },
    },
  };
  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MatxTheme;
