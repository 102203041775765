import { Fragment } from "react";
import React from "react";
import {
  Box,
  Fab,
  Card,
  Grid,
  styled,
  Avatar,
  Checkbox,
  IconButton,
} from "@mui/material";
import { DateRange, MoreVert, StarOutline } from "@mui/icons-material";

import { Span } from "app/components/Typography";
import { Link } from "react-router-dom";

// STYLED COMPONENTS
const ProjectName = styled(Span)(({ theme }) => ({
  marginLeft: 24,
  fontWeight: "500",
  textTransform: "capitalize",
  [theme.breakpoints.down("sm")]: { marginLeft: 4 },
}));

const StyledAvatar = styled(Avatar)(() => ({
  width: "32px !important",
  height: "32px !important",
}));

export default function RowCards({ data }) {
  return data.map((data, index) => (
    <Fragment key={data.SrNo}>
      <Card sx={{ py: 1, px: 2 }} className="project-card">
        <Grid container alignItems="center">
          <Grid item md={5} xs={7}>
            <Box display="flex" alignItems="center">
              <StyledAvatar
                src={`https://backend.dahiyainsurance.com/Upload/profile/${data.Picture}`}
              />

              <Link to={`/Prospects/ViewProspect/${data.UniqueID}`}>
                <ProjectName>{data.Name}</ProjectName>
              </Link>
            </Box>
          </Grid>

          <Grid item md={3} xs={4}>
            <Box color="text.secondary">{data.ModifiedOn}</Box>
          </Grid>

          <Grid item xs={3} sx={{ display: { xs: "none", sm: "block" } }}>
            <Box
              display="flex"
              position="relative"
              marginLeft="-0.875rem !important"
            >
              <StyledAvatar
                src={`https://backend.dahiyainsurance.com/Upload/profile/${data.UserPic}`}
              />
            </Box>
          </Grid>

          <Grid item xs={1}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton>
                <MoreVert />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Card>

      <Box py={1} />
    </Fragment>
  ));
}
