import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Container, Content } from "rsuite";
import { Button } from "@mui/material";
import {
  ContentPaste,
  Delete,
  ListAltRounded,
  PersonAdd,
  Upload,
  WorkHistory,
} from "@mui/icons-material";
import Header from "app/components/Header";
import useAuth from "app/hooks/useAuth";

import { Outlet, useNavigate } from "react-router-dom";
import {
  ADD_PREMIUMPOINT,
  DELETE_PREMIUMPOINT,
  IMPORT_PREMIUMPOINT,
  VIEW_PREMIUMPOINT,
} from "Permissions";
import { MatxLoading } from "app/components";
//import { PolicyProvider } from "./PolicyProvider";

export default function PremiumPoint() {
  const { permissions, user } = useAuth();
  const navigate = useNavigate();
  return (
    <Box
      component="section"
      sx={{
        p: 1,
        border: "0.5px dashed #ddd",
        width: "100%",
        backgroundColor: "#FEFAF6",
      }}
    >
      <Stack spacing={1}>
        <Header headerText="Premium Point">
          {(permissions.includes(VIEW_PREMIUMPOINT) ||
            user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("List");
              }}
              variant="contained"
              startIcon={<ListAltRounded />}
            >
              List
            </Button>
          )}
          {(permissions.includes(IMPORT_PREMIUMPOINT) ||
            user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("Import");
                }}
                variant="contained"
                startIcon={<Upload />}
              >
                Import
              </Button>
            </>
          )}
          {(permissions.includes(IMPORT_PREMIUMPOINT) ||
            user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("ImportByPaste");
                }}
                variant="contained"
                startIcon={<ContentPaste />}
              >
                Paste & Import
              </Button>
            </>
          )}
        </Header>

        <Container>
          <Content
            style={{
              flex: 1,
              width: "100%",
              height: 650,
              borderRadius: 10,
              border: "1px dashed #ddd",
            }}
          >
            {navigate.state === "loading" ? <MatxLoading /> : <Outlet />}
          </Content>
        </Container>
      </Stack>
    </Box>
  );
}
