import * as React from "react";
import AgGrid from "app/components/AgGrid";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { Tabs, Panel, Grid, Row, Col, Message, useToaster } from "rsuite";
import { useLocation, useNavigate } from "react-router-dom";

import * as yup from "yup";
import { filterParams } from "functions";

export default function DeletedCLIA() {
  const axiosPrivate = useAxiosPrivate();

  const [rowData, setRowData] = React.useState([{}]);

  const [loading, setLoading] = React.useState(false);

  // const abc = useReference();
  // console.log(abc);
  const ColorStatus = React.useMemo(() => {
    return ["#FC6E51", "#A0D468"];
  }, []);
  const columns = React.useMemo(() => {
    return [
      {
        headerName: "Fullname",
        field: "fullname",
        minWidth: 180,
        editable: false,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },

      { field: "Email", minWidth: 250, editable: false },

      {
        field: "Mobile",
        minWidth: 150,
        type: "numericColumn",
        editable: false,
      },
      {
        field: "firmname",
        minWidth: 150,
        editable: false,
        headerName: "Firm Name",
      },

      {
        field: "officeno",
        minWidth: 150,
        type: "numericColumn",
        editable: false,
        headerName: "Office No",
      },
      {
        field: "officialemail",
        minWidth: 250,
        editable: false,
        headerName: "Official Email",
      },

      {
        field: "DOB",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
        editable: false,
      },
      {
        field: "anniversary",
        headerName: "Anniversary",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
        editable: false,
      },
      {
        field: "joiningdate",
        headerName: "DOJ",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams:filterParams,
        editable: false,
      },

      { field: "Username", minWidth: 150, editable: false },
      { field: "UserType", minWidth: 150, editable: false },
      {
        field: "Enabled",
        minWidth: 150,
        editable: false,
        cellStyle: (params) => {
          if (params.value === "YES" || params.value === "YES") {
            //mark police cells as red
            return { color: ColorStatus[1], fontWeight: "bold" };
          } else {
            //mark police cells as red
            return { color: ColorStatus[0] };
          }
        },
      },

      { field: "LastLogin", minWidth: 150, editable: false, hide: true },

      { field: "UniqueID", hide: true, editable: false },
    ];
  }, []);
  const onGridReady = React.useCallback((params) => {
    setLoading(true);
    axiosPrivate
      .post("LoadCLIA.php")
      .then((responce) => {
        const result = responce.data;
        if (result.status === 1) setRowData(result.Data);
        else setRowData([]);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  const [selectedRowData, setSelectedRowData] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);
  return (
    <>
      <Panel>
        <AgGrid
          columns={columns}
          rowData={rowData}
          onGridReady={onGridReady}
          defaultColumn={["Name"]}
          GoTo="/CLIA/View"
          showLoading={loading}
          onRowSelectedData={setSelectedRowData}
          settingsName="CLIA"
          headerComponent={() => <p></p>}
          setFilteredRows={setFilteredRows}
          Source="CLIA"
          exportAllowed={false}
        />
      </Panel>
    </>
  );
}
