import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Container, Content } from "rsuite";
import { Button } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";
import Header from "app/components/Header";
import useAuth from "app/hooks/useAuth";
import { ADD_REFERENCE } from "Permissions";
import { Outlet, useNavigate } from "react-router-dom";
import { ServiceProvider } from "./ServiceProvider";

export default function Services() {
  const { permissions, user } = useAuth();
  const navigate = useNavigate();
  return (
    <Box
      component="section"
      sx={{
        p: 1,
        border: "0.5px dashed #ddd",
        width: "100%",
        backgroundColor: "#FEFAF6",
      }}
    >
      <Stack spacing={1}>
        <Header headerText="Manage Services">
          {(permissions.includes(ADD_REFERENCE) ||
            user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("/References/AddReference");
                }}
                variant="contained"
                startIcon={<PersonAdd />}
              >
                Add Reference
              </Button>
            </>
          )}
        </Header>
        {/* {(permissions.includes(VIEW_POLICYSERVICE) ||
            user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("/Services/PolicyServices");
                }}
                variant="contained"
                startIcon={<ListAlt />}
              >
                View All
              </Button>
            </>
          )} */}
        <Container>
          <Content
            style={{
              flex: 1,
              width: "100%",
              height: 650,
              borderRadius: 10,
              border: "1px dashed #ddd",
            }}
          >
            <ServiceProvider>
              <Outlet />
            </ServiceProvider>
          </Content>
        </Container>
      </Stack>
    </Box>
  );
}
