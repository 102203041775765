import { useEffect, useRef, useReducer } from "react";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
export const useApi = (api, refresh = false) => {
  const axiosPrivate = useAxiosPrivate();
  const cacheData = useRef({});
  const initialState = {
    status: "idle",
    error: null,
    data: [],
  };
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "FETCHING":
        return { ...initialState, status: "fetching" };
      case "FETCHED":
        return { ...initialState, status: "fetched", data: action.payload };
      case "FETCH_ERROR":
        return { ...initialState, status: "error", error: action.payload };
      default:
        return state;
    }
  }, initialState);
  useEffect(() => {
    let revokeRequest = false;
    if (!api || !api.trim()) return;
    const renderData = async () => {
      dispatch({ type: "FETCHING" });
      if (cacheData.current[api]) {
        const data = cacheData.current[api];
        dispatch({ type: "FETCHED", payload: data });
      } else {
        try {
          //const res = await fetch(api);
          //  const data = await res.json();
          const res = await axiosPrivate.post(api);
          const data1 = res.data;
          const data = data1.Data;
          cacheData.current[api] = data;
          if (revokeRequest) return;
          dispatch({ type: "FETCHED", payload: data });
        } catch (error) {
          if (revokeRequest) return;
          dispatch({ type: "FETCH_ERROR", payload: error.message });
        }
      }
    };
    renderData();
    return function cleanup() {
      revokeRequest = true;
    };
  }, [api, refresh]);
  return state;
};
