import * as React from "react";

import Stack from "@mui/material/Stack";

import useAuth from "app/hooks/useAuth";
import { ADD_USER, DELETE_USER, EDIT_USER, VIEW_USER } from "Permissions";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  useTheme,
  Button as Btn,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { Formik } from "formik";
import "moment/locale/en-in";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  Divider,
  Grid,
  Heading,
  Row,
} from "rsuite";

import { Uploader, Message, Loader, useToaster } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";

import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { DeleteForever, People } from "@mui/icons-material";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SideDrawer from "app/components/SideDrawer";

// TODO: Get tehsil, dist and state from Village name
function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const today = new Date();
today.setHours(0, 0, 0, 0);

const validationSchema = yup.object({
  LoginEnabled: yup.string().required("Required field"),
  Usertype: yup.string().required("Valid Ref required"),
  Firstname: yup.string().required("Valid Name required"),
  Lastname: yup.string().required("Valid Name required"),
  Userid: yup
    .string()
    .required("Valid userID required")
    .min(5, "UserID too short"),
  Email: yup.string().email().required("Valid Email ID required"),

  Mobile: yup
    .string()
    .matches(/^\d{10}$/, "Phone number is not valid")
    .nullable(true),
});

export default function AddUser() {
  const { permissions, user } = useAuth();
  const { UID } = useParams();

  const axiosPrivate = useAxiosPrivate();
  const [value, setValue] = React.useState([]);
  const toaster = useToaster();
  //const uploader = React.useRef();
  const [fileList1, setFileList1] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(null);
  const [docs, setDocs] = React.useState([]);
  const [data, setData] = React.useState({});

  const [show, setShow] = React.useState(false);

  const theme = useTheme();
  const [initialValues, setInitialValues] = React.useState({
    Firstname: "",
    Lastname: "",
    Email: "",
    Mobile: "",
    LoginEnabled: "1",
    Usertype: "1",
    Userid: "",
    Password: "",
    NewPassword: "",
  });
  const initialState = {
    EditMode: false,
    Attachment: false,
  };
  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "EDITABLE":
        return { ...initialState, EditMode: true };
      case "LOCKED":
        return { ...initialState, EditMode: false };
      case "ATTACH":
        return { ...initialState, Attachment: true };
      case "DONE":
        return { ...initialState, Attachment: false };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  }, initialState);

  function handleAttachment() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`Are you done with attaching documents?`}</p>
            <button
              onClick={() => {
                dispatch({ type: "DONE" });
                onClose();
              }}
            >
              Yes
            </button>
            <button onClick={onClose}>No</button>
          </div>
        );
      },
    });
  }
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getUser();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);
  const getUser = async () => {
    try {
      const responce = await axiosPrivate.post("GetUserByID.php", { UID });
      //console.log(responce);
      const result = responce.data;
      if (result.status === 1) {
        const newData = {
          ...result.Data[0],
          Firstname: result.Data[0].FirstName,
          Lastname: result.Data[0].LastName,
          Usertype: result.Data[0].UserType,
          LoginEnabled: result.Data[0].Enabled === "1" ? "YES" : "NO",

          DOB:
            result.Data[0].dob === "0000-00-00" || result.Data[0].dob === null
              ? null
              : result.Data[0].dob,
        };
        setInitialValues(newData);
        setValue(result.Role);
        setFileInfo(
          `https://backend.dahiyainsurance.com/Upload/profile/${result.Data[0].Picture}`
        );
        setDocs(result.Docs);
        setData({ ...data, ...result.Data[0] });
      }
      if (result.status === 0) {
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Error!</strong> {result?.Error}
          </Message>
        );
      }
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  };
  function handleRemoveDoc(ID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this document.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemovePolicyDoc.php",
                    datatosend
                  );
                  const result = response.data;
                  onClose();
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    const newDocs = docs.filter(function (item) {
                      return item.UniqueID !== UID;
                    });
                    setDocs(newDocs);
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }

  const Remove = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemoveProspect.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };
  const Restore = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to restore this details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RestoreProspect.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  onClose();
                }
              }}
            >
              Yes, Restore it!
            </button>
          </div>
        );
      },
    });
  };

  const LockUnlock = (val) => {
    axiosPrivate
      .post("LockUnlockUser.php", {
        val,
        id: UID,
      })
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          console.log(result);
          toaster.push(
            <Message type="success" bordered showIcon>
              <strong>Success!</strong> {result?.Success}
            </Message>
          );
          getUser();
        }
        if (result.status === 0) {
          toaster.push(
            <Message type="error" bordered showIcon>
              <strong>Error!</strong> {result.Error}
            </Message>
          );
        }
        // resetForm();
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  const EnableDisable = (val) => {
    axiosPrivate
      .post("EnableDisableUser.php", {
        val,
        id: UID,
      })
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          console.log(result);
          toaster.push(
            <Message type="success" bordered showIcon>
              <strong>Success!</strong> {result?.Success}
            </Message>
          );
          getUser();
        }
        if (result.status === 0) {
          toaster.push(
            <Message type="error" bordered showIcon>
              <strong>Error!</strong> {result.Error}
            </Message>
          );
        }
        // resetForm();
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Heading
            level={5}
            style={{
              textAlign: "center",
              flex: 0.8,
            }}
          >
            {`Modify User - (${initialValues.Firstname} - ${initialValues.Lastname})`}
          </Heading>
          <Stack
            direction="row"
            spacing={2}
            style={{ flex: 0.2, justifyContent: "flex-end" }}
          >
            <Button
              onClick={() => {
                navigate(`/FamilyMember/User/${UID}`);
              }}
              appearance="link"
              startIcon={<People />}
            >
              Family Details
            </Button>
          </Stack>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <Grid fluid style={{ padding: 10 }}>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={16}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                  //console.log(values);
                  axiosPrivate
                    .post("EditUser.php", {
                      ...values,

                      DOB: values?.DOB
                        ? moment(values.DOB).format("YYYY-MM-DD")
                        : null,
                      Role: value,
                    })
                    .then((response) => {
                      const result = response.data;
                      if (result.status === 1) {
                        console.log(result);
                        toaster.push(
                          <Message type="success" bordered showIcon>
                            <strong>Success!</strong> {result?.Success}
                          </Message>
                        );
                        dispatch({ type: "LOCKED" });
                        dispatch({ type: "ATTACH" });
                      }
                      if (result.status === 0) {
                        toaster.push(
                          <Message type="error" bordered showIcon>
                            <strong>Error!</strong> {result.Error}
                          </Message>
                        );
                      }
                      setSubmitting(false);
                      // resetForm();
                    })
                    .catch((error) => {
                      console.error("Error submitting data:", error);
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  errors,
                  isSubmitting,
                  isValidating,
                  setErrors,
                  setTouched,
                }) => (
                  <form className="target">
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading
                        level={6}
                        style={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Login?
                      </Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-standard-label">
                            Login Enabled?
                          </InputLabel>
                          <Select
                            disabled={!state.EditMode}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="LoginEnabled"
                            name="LoginEnabled"
                            value={values?.LoginEnabled || ""}
                            onChange={handleChange}
                            error={
                              touched.LoginEnabled &&
                              Boolean(errors.LoginEnabled)
                            }
                            helperText={
                              touched.LoginEnabled && errors.LoginEnabled
                            }
                          >
                            {["YES", "NO"].map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, ["YES", "NO"], theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        &nbsp; &nbsp;
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-standard-label">
                            User Type
                          </InputLabel>
                          <Select
                            disabled={!state.EditMode}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="Usertype"
                            name="Usertype"
                            value={values?.Usertype || ""}
                            onChange={handleChange}
                            error={touched.Usertype && Boolean(errors.Usertype)}
                            helperText={touched.Usertype && errors.Usertype}
                          >
                            {[
                              "Admin",
                              "Agent",
                              "Chief Manager",
                              "Head Cashier",
                              "Field Manager",
                              "Sales Manager",
                              "Account Manager",
                            ].map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(
                                  name,
                                  [
                                    "Admin",
                                    "Agent",
                                    "Chief Manager",
                                    "Head Cashier",
                                    "Field Manager",
                                    "Sales Manager",
                                    "Account Manager",
                                  ],
                                  theme
                                )}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            disabled={!state.EditMode}
                            label="Date Of Birth"
                            variant="standard"
                            size="small"
                            name="DOB"
                            format="dd-MM-yyyy"
                            value={values.DOB}
                            onChange={(value) => {
                              setFieldValue("DOB", value, true);
                              console.log(errors);
                            }}
                            onBlur={handleBlur}
                            error={touched.DOB && Boolean(errors.DOB)}
                            helperText={touched.DOB && errors.DOB}
                          />
                        </LocalizationProvider>
                        &nbsp; &nbsp;
                        <b>
                          {values.DOB &&
                            `${moment().diff(
                              values.DOB ? new Date(values.DOB) : null,
                              "years"
                            )} Year(s)`}
                        </b>
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Personal details</Heading>
                    </Row>
                    {/* <br /> */}
                    <Row gutter={1} className="show-grid">
                      <Stack direction="row" spacing={2}>
                        <TextField
                          disabled={!state.EditMode}
                          label="First Name"
                          size="small"
                          variant="standard"
                          name="Firstname"
                          value={values.Firstname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.Firstname && Boolean(errors.Firstname)}
                          helperText={touched.Firstname && errors.Firstname}
                        />{" "}
                        &nbsp;
                        <TextField
                          disabled={!state.EditMode}
                          label="Last Name"
                          size="small"
                          variant="standard"
                          onBlur={handleBlur}
                          name="Lastname"
                          value={values.Lastname}
                          onChange={handleChange}
                          error={touched.Lastname && Boolean(errors.Lastname)}
                          helperText={touched.Lastname && errors.Lastname}
                        />
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Login details</Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={1}>
                        <TextField
                          disabled={!state.EditMode}
                          label="User ID"
                          size="small"
                          variant="standard"
                          style={{ width: 100 }}
                          name="Userid"
                          value={values.Userid}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.Userid && Boolean(errors.Userid)}
                          helperText={touched.Userid && errors.Userid}
                        />{" "}
                        &nbsp;&nbsp;
                        <TextField
                          disabled={!state.EditMode}
                          label="Mobile"
                          size="small"
                          variant="standard"
                          style={{ width: 150 }}
                          name="Mobile"
                          value={values.Mobile}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.Mobile && Boolean(errors.Mobile)}
                          helperText={touched.Mobile && errors.Mobile}
                        />{" "}
                        &nbsp;&nbsp;
                      </Stack>
                    </Row>

                    <Row gutter={1} style={{ marginTop: 20, marginBottom: 10 }}>
                      <TextField
                        disabled={!state.EditMode}
                        label="Email ID"
                        size="small"
                        variant="standard"
                        style={{ width: 250 }}
                        name="Email"
                        value={values.Email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.Email && Boolean(errors.Email)}
                        helperText={touched.Email && errors.Email}
                      />{" "}
                    </Row>
                    <Row gutter={1} style={{ marginTop: 20, marginBottom: 10 }}>
                      <TextField
                        disabled={!state.EditMode}
                        label="New Password"
                        size="small"
                        variant="standard"
                        style={{ width: 250 }}
                        name="NewPassword"
                        value={values.NewPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.NewPassword && Boolean(errors.NewPassword)
                        }
                        helperText={touched.NewPassword && errors.NewPassword}
                      />{" "}
                    </Row>
                    <Row gutter={1} style={{ marginTop: 10, marginBottom: 10 }}>
                      <Stack direction="row" spacing={4}>
                        {state.EditMode ? (
                          <>
                            <LoadingButton
                              disabled={isSubmitting}
                              type="submit"
                              color="secondary"
                              variant="contained"
                              onClick={handleSubmit}
                              loading={isValidating}
                              sx={{ my: 2 }}
                            >
                              {isSubmitting ? "Submitting..." : "Submit"}
                            </LoadingButton>
                            <Btn
                              color="secondary"
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                setErrors({});
                                setTouched({}, false);
                                dispatch({ type: "LOCKED" });
                                dispatch({ type: "DONE" });
                              }}
                              sx={{ my: 2 }}
                            >
                              {"CANCEL"}
                            </Btn>
                            <Btn
                              color="secondary"
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                setShow(true);
                              }}
                              sx={{ my: 2 }}
                            >
                              {"PERMISSIONS"}
                            </Btn>
                          </>
                        ) : (
                          <>
                            <Btn
                              color="secondary"
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch({ type: "EDITABLE" });
                              }}
                              sx={{ my: 2 }}
                            >
                              {"Edit"}
                            </Btn>
                            {data.Locked === "0" ? (
                              <Btn
                                color="success"
                                variant="outlined"
                                onClick={(e) => {
                                  e.preventDefault();
                                  LockUnlock("1");
                                }}
                                sx={{ my: 2 }}
                              >
                                {`${data.Locked === "0" ? "Lock" : "Unlock"}`}
                              </Btn>
                            ) : (
                              <Btn
                                color="error"
                                variant="outlined"
                                onClick={(e) => {
                                  e.preventDefault();
                                  LockUnlock("0");
                                }}
                                sx={{ my: 2 }}
                              >
                                {`${data.Locked === "0" ? "Lock" : "Unlock"}`}
                              </Btn>
                            )}
                            {data.Enabled === "0" ? (
                              <Btn
                                color="error"
                                variant="outlined"
                                onClick={(e) => {
                                  e.preventDefault();
                                  EnableDisable("1");
                                }}
                                sx={{ my: 2 }}
                              >
                                {`${
                                  data.Enabled === "0" ? "Enable" : "Disable"
                                }`}
                              </Btn>
                            ) : (
                              <Btn
                                color="success"
                                variant="outlined"
                                onClick={(e) => {
                                  e.preventDefault();
                                  EnableDisable("0");
                                }}
                                sx={{ my: 2 }}
                              >
                                {`${
                                  data.Enabled === "0" ? "Enable" : "Disable"
                                }`}
                              </Btn>
                            )}
                            {state.Attachment && (
                              <Btn
                                color="secondary"
                                variant="contained"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleAttachment();
                                }}
                                sx={{ my: 2 }}
                              >
                                {"DONE"}
                              </Btn>
                            )}
                          </>
                        )}
                      </Stack>
                    </Row>
                  </form>
                )}
              </Formik>
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Row className="show-grid">
                {UID && (
                  <Col xs={24} sm={24} md={24}>
                    <Uploader
                      fileListVisible={false}
                      disabled={!state.Attachment}
                      multiple={false}
                      listType="picture"
                      action="https://backend.dahiyainsurance.com/Upload/AddProfilePic.php"
                      withCredentials={true}
                      headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                      data={{ UID, Type: "User" }}
                      onUpload={(File) => {
                        setUploading(true);
                        previewFile(File.blobFile, (value) => {
                          setFileInfo(value);
                        });
                      }}
                      onSuccess={(response, file) => {
                        setUploading(false);
                        toaster.push(
                          <Message type="success">
                            Uploaded successfully
                          </Message>
                        );
                        console.log(response);
                      }}
                      onError={(e) => {
                        console.log(e);
                        setFileInfo(null);
                        setUploading(false);
                        toaster.push(
                          <Message type="error">Upload failed</Message>
                        );
                      }}
                      onRemove={(file) => {
                        console.log(file);
                      }}
                    >
                      <button style={{ width: 300, height: 300 }}>
                        {uploading && <Loader backdrop center />}
                        {fileInfo ? (
                          <img
                            alt=""
                            src={fileInfo}
                            width="100%"
                            height="100%"
                            style={{ objectFit: "cover" }}
                          />
                        ) : (
                          <AvatarIcon style={{ fontSize: 80 }} />
                        )}
                      </button>
                    </Uploader>

                    <Uploader
                      disabled={!state.Attachment}
                      removable={!state.Attachment}
                      onRemove={(file) => {
                        console.log(file);
                      }}
                      listType="picture-text"
                      fileList={fileList1}
                      multiple={true}
                      data={{ UID, Type: "User" }}
                      onChange={setFileList1}
                      withCredentials={true}
                      headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                      action="https://backend.dahiyainsurance.com/Upload/UploadDocs.php"
                      renderFileInfo={(file, fileElement) => (
                        <span style={{ width: "100%", fontSize: 14 }}>
                          Name: {file.name}
                        </span>
                      )}
                    />
                  </Col>
                )}
              </Row>
              <Row
                className="show-grid"
                style={{ margin: "10px 0px 10px 0px" }}
              >
                <Col xs={24} sm={24} md={24}>
                  {
                    <ul>
                      {docs.map((data) => (
                        <li key={data.UniqueID}>
                          {/* <Link to={data.UniqueID}>{data.Name}</Link> */}
                          <a
                            rel="noreferrer"
                            href={`https://backend.dahiyainsurance.com/Upload/assets/${data.Path}`}
                            target="_blank"
                            style={{ cursor: "pointer" }}
                            /* onClick={(e) => getDocs(e, data.UniqueID)} */
                          >
                            {data.Name}
                          </a>
                          {(permissions.includes(EDIT_USER) ||
                            user.Type === "SuperAdmin") && (
                            <a
                              href=""
                              style={{
                                paddingLeft: 5,
                                textDecoration: "none",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleRemoveDoc(data.UniqueID);
                              }}
                            >
                              {state.Attachment && (
                                <DeleteForever style={{ fontSize: 15 }} />
                              )}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
      <SideDrawer
        title="Manage Permissions"
        open={show}
        onClose={() => {
          setShow(false);
        }}
        size="lg"
      >
        <Grid
          xs={12}
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 10,
          }}
        >
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
            pt={3}
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <Grid
              xs={12}
              container
              style={{
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
              }}
            >
              <Grid
                xs={12}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <CheckboxGroup
                  inline
                  name="checkboxList"
                  value={value}
                  onChange={(value) => {
                    setValue(value);
                  }}
                >
                  <Stack>
                    <table>
                      <thead>
                        <tr height={50}>
                          <th width={150}>Section</th>
                          <th width={100}>Add</th>
                          <th width={100}>Edit</th>
                          <th width={100}>View</th>
                          <th width={100}>Delete</th>
                          <th width={100}>Export</th>
                          <th width={100}>Import</th>
                          <th width={100}>ALL</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr height={60}>
                          <td>User</td>
                          <td>
                            <Checkbox value="1100"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1101"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1102"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1103"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1104"></Checkbox>
                          </td>
                          <td>
                            <Checkbox disabled={true} value="1105"></Checkbox>
                          </td>
                        </tr>
                        <tr height={60}>
                          <td>Dahiya Mitra</td>
                          <td>
                            <Checkbox value="1200"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1201"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1202"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1203"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1204"></Checkbox>
                          </td>
                          <td>
                            <Checkbox disabled={true} value="1205"></Checkbox>
                          </td>
                        </tr>
                        <tr height={60}>
                          <td>Prospect</td>
                          <td>
                            <Checkbox value="1300"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1301"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1302"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1303"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1304"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1305"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1306"></Checkbox>
                          </td>
                        </tr>
                        <tr height={60}>
                          <td>Reference</td>
                          <td>
                            <Checkbox value="1400"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1401"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1402"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1403"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1404"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1405"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1406"></Checkbox>
                          </td>
                        </tr>
                        <tr height={60}>
                          <td>CLIA</td>
                          <td>
                            <Checkbox value="1500"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1501"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1502"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1503"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1504"></Checkbox>
                          </td>
                          <td>
                            <Checkbox disabled={true} value="1505"></Checkbox>
                          </td>
                        </tr>
                        <tr height={60}>
                          <td>Policy</td>
                          <td>
                            <Checkbox value="1600"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1601"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1602"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1603"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1604"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1605"></Checkbox>
                          </td>
                        </tr>
                        <tr height={60}>
                          <td>Inputs</td>
                          <td>
                            <Checkbox value="1700"></Checkbox>
                          </td>
                          <td>
                            <Checkbox disabled value="1701"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1702"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1703"></Checkbox>
                          </td>
                          <td>
                            <Checkbox disabled value="1704"></Checkbox>
                          </td>
                          <td>
                            <Checkbox disabled value="1705"></Checkbox>
                          </td>
                        </tr>
                        <tr height={60}>
                          <td>PremiumPoint</td>
                          <td>
                            <Checkbox value="1800"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1801"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1802"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1803"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1804"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1805"></Checkbox>
                          </td>
                        </tr>
                        <tr height={60}>
                          <td>PolicyService</td>
                          <td>
                            <Checkbox value="1900"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1901"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1902"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1903"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1904"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1905"></Checkbox>
                          </td>
                        </tr>
                        {/*                         <tr height={60}>
                          <td></td>
                          <td>
                            <Checkbox value="1900"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1901"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1902"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1903"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1904"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1905"></Checkbox>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                    <Divider />
                    <table>
                      <tr height={60}>
                        <td>
                          <Checkbox value="9993">Lead Status</Checkbox>
                        </td>
                        <td>
                          <Checkbox value="2101">FOLLOWUP</Checkbox>
                        </td>
                        <td>
                          <Checkbox value="2201">GREETING</Checkbox>
                        </td>
                        <td>
                          <Checkbox value="2301">CALENDER</Checkbox>
                        </td>
                        {/*                         <td>
                          <Checkbox value="1901"></Checkbox>
                        </td>
                        <td>
                          <Checkbox value="1902"></Checkbox>
                        </td>
                        <td>
                          <Checkbox value="1903"></Checkbox>
                        </td>
                        <td>
                          <Checkbox value="1904"></Checkbox>
                        </td>
                        <td>
                          <Checkbox value="1905"></Checkbox>
                        </td> */}
                      </tr>
                    </table>
                  </Stack>
                </CheckboxGroup>
              </Grid>
            </Grid>
            {/*             <Grid
              xs={12}
              container
              style={{
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
              }}
            >
              <Grid
                xs={12}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <CheckboxGroup
                  style={{ width: "100%" }}
                  inline
                  name="checkboxList"
                  value={value}
                  onChange={(value) => {
                    setValue(value);
                  }}
                >
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td>Export : </td>
                      <td>
                        <Checkbox value="1800">Customer</Checkbox>
                      </td>
                      <td>
                        <Checkbox value="1801">Broker</Checkbox>
                      </td>
                      <td>
                        <Checkbox value="1802">Agent</Checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox value="2505">AC_PAYOUT</Checkbox>
                      </td>
                      <td>
                        <Checkbox value="1803">Policy</Checkbox>
                      </td>
                      <td>
                        <Checkbox value="1804">Event</Checkbox>
                      </td>
                      <td>
                        <Checkbox value="1805">Vehicle</Checkbox>
                      </td>
                    </tr>
                  </table>
                </CheckboxGroup>
              </Grid>
            </Grid> */}

            {/* ==================================================================== */}

            {/*             <Grid
              xs={12}
              container
              style={{
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 10,
              }}
            >
              <Grid
                xs={12}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <CheckboxGroup
                  style={{ width: "100%" }}
                  inline
                  name="checkboxList"
                  value={value}
                  onChange={(value) => {
                    setValue(value);
                  }}
                >
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td>Account :</td>
                      <td>
                        <Checkbox value="2401">Customer</Checkbox>
                      </td>
                      <td>
                        <Checkbox value="2402">Broker</Checkbox>
                      </td>
                      <td>
                        <Checkbox value="2403">Agent</Checkbox>
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>
                        <Checkbox value="2404">Self</Checkbox>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </table>
                </CheckboxGroup>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </SideDrawer>
    </>
  );
}
