import * as React from "react";
import AgGrid from "app/components/AgGrid";
import {
  Panel,
  Row,
  Grid,
  Col,
  useToaster,
  Message,
  Stack,
  Button,
  Heading,
} from "rsuite";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import SideDrawer from "app/components/SideDrawer";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import * as yup from "yup";
import { useProps } from "./ServiceProvider";
import { DesignServices, ListAlt } from "@mui/icons-material";
import { ADD_POLICYSERVICE } from "Permissions";
import useAuth from "app/hooks/useAuth";
import { filterParams } from "functions";

const validationSchema = yup.object({
  Status: yup.string().required("Status is required"),
});
const Status = ["HOT", "WARM", "COLD", "STORE"];
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const formatNumber = (number) => {
  return Math.floor(number).toLocaleString();
};
const currencyFormatter = (params) => {
  return formatNumber(params.value);
};
export default function PolicyServices() {
  const [loading, setLoading] = React.useState(false);
  const [rowData, setRowData] = React.useState([{}]);
  const { permissions, user } = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const handleViewModalOpen = () => setOpenViewModal(true);
  const handleViewModalClose = () => setOpenViewModal(false);
  const theme = useTheme();
  const toaster = useToaster();
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({
    Status: null,
    Result: null,
    LeadStatus: null,
  });
  const ColorStatus = React.useMemo(() => {
    return ["#FC6E51", "#FFCE54", "#A0D468", "#4FC1E9"];
  }, []);
  const columns = React.useMemo(() => {
    return [
      {
        headerName: "Service ID",
        field: "ServiceID",
        minWidth: 180,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      {
        headerName: "Ref ID",
        field: "Reference",
        minWidth: 180,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },

      {
        field: "EnquiryDate",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      {
        field: "PolicyNo",
        minWidth: 150,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      {
        field: "Status",
        minWidth: 150,
        editable: true,
      },
      { field: "AgencyCode", minWidth: 150 },
      { field: "Branch", minWidth: 150 },
      {
        field: "DOC",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      {
        field: "FUP",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      {
        headerName: "Service Type",
        field: "ServiceWork",
        minWidth: 180,
      },

      { field: "Caste", minWidth: 150 },
      { field: "Address", minWidth: 150 },
      { field: "Village", minWidth: 150 },
      { field: "Tehsil", minWidth: 150 },
      { field: "District", minWidth: 150 },
      { field: "State", minWidth: 150 },
      { field: "MobileNo", minWidth: 150 },
      { field: "FamilyMember", minWidth: 150 },
      { field: "PolicyHolder", minWidth: 150, editable: false },
      { field: "FormSubmitDate", minWidth: 150, editable: false },
      {
        field: "MaturityAmount",
        minWidth: 100,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      { field: "Medium", minWidth: 100, editable: false },
      { field: "INFORCE", minWidth: 200, editable: false },
      {
        field: "ServiceDate",
        minWidth: 150,
        editable: false,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      { field: "Status", minWidth: 100, editable: false },
      {
        field: "SumAssured",
        minWidth: 100,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      { field: "VMProID", minWidth: 250, editable: false },
      { field: "AddedBy", minWidth: 150 },
      {
        field: "Added",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      { field: "UniqueID", hide: true },
    ];
  }, []);
  const { updatePropsValue } = useProps();

  const handleUpdateMessage = () => {
    const NewData = filteredRows.map((dt) => dt.data.UniqueID);
    updatePropsValue(NewData);
    //console.log(propsValue);
  };
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      handleUpdateMessage();
      //console.log(filteredRows);
    }
    return () => {
      isSubscribed = false;
    };
  }, [filteredRows.length]);
  React.useEffect(() => {
    // console.log(selectedRowData);
    let isSubscribed = true;
    if (isSubscribed)
      if (selectedRowData?.Name) {
        setInitialValues({
          UID: selectedRowData?.UniqueID,
          Status: selectedRowData?.Status,
        });
      }
    return () => {
      isSubscribed = false;
    };
  }, [selectedRowData?.Name]);

  const UpdateStatus = (data) => {
    axiosPrivate
      .post("UpdateStatusReference.php", data)
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          console.log(result);
          toaster.push(
            <Message type="success" bordered showIcon>
              <strong>Success!</strong> {result?.Success}
            </Message>
          );
        }
        if (result.status === 0) {
          toaster.push(
            <Message type="error" bordered showIcon>
              <strong>Error!</strong> {result.Error}
            </Message>
          );
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  const handleChildData = (data) => {
    UpdateStatus(data);
  };

  const onGridReady = React.useCallback((params) => {
    setLoading(true);
    axiosPrivate
      .post("LoadPolicyServices.php")
      .then((responce) => {
        const result = responce.data;
        if (result.status === 1) setRowData(result.Data);
        else setRowData([]);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Heading
            level={5}
            style={{
              textAlign: "center",
              flex: 0.8,
            }}
          >
            {`All Policy Services`}
          </Heading>
          <Stack
            direction="row"
            spacing={2}
            style={{ flex: 0.2, justifyContent: "flex-end" }}
          >
            {(permissions.includes(ADD_POLICYSERVICE) ||
              user.Type === "SuperAdmin") && (
              <Button
                onClick={() => {
                  navigate(`/Services/AddPolicyService`);
                }}
                appearance="link"
                startIcon={<DesignServices />}
              >
                New Service
              </Button>
            )}
            <Button
              onClick={() => {
                navigate("/Services/PendingPolicyServices");
              }}
              variant="contained"
              startIcon={<ListAlt />}
            >
              Pending
            </Button>
          </Stack>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <Panel>
          <AgGrid
            columns={columns}
            rowData={rowData}
            onGridReady={onGridReady}
            defaultColumn={["Name"]}
            GoTo="/Services/ViewPolicyService"
            showLoading={loading}
            onRowSelectedData={setSelectedRowData}
            handleViewModalOpen={handleViewModalOpen}
            onDataChange={handleChildData}
            headerComponent={() => <p></p>}
            setFilteredRows={setFilteredRows}
            Source="PolicyService"
          />
        </Panel>
        <SideDrawer
          open={openViewModal}
          onClose={handleViewModalClose}
          title="Change status"
        >
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              console.log(values);
              axiosPrivate
                .post("UpdateStatusReference.php", {
                  UID: values.UID,
                  Status: values.Status,
                })
                .then((response) => {
                  const result = response.data;
                  if (result.status === 1) {
                    console.log(result);
                    toaster.push(
                      <Message type="success" bordered showIcon>
                        <strong>Success!</strong> {result?.Success}
                      </Message>
                    );
                  }
                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" bordered showIcon>
                        <strong>Error!</strong> {result.Error}
                      </Message>
                    );
                  }
                  setSubmitting(false);
                  resetForm();
                })
                .catch((error) => {
                  console.error("Error submitting data:", error);
                  setSubmitting(false);
                });
              console.log(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              handleSubmit,
              isValidating,
              isSubmitting,
              touched,
              setFieldValue,
              handleBlur,
              handleChange,
            }) => {
              console.log(errors);
              console.log(values);
              return (
                <form>
                  <div>
                    <div className="row">
                      <Grid fluid style={{ padding: 4 }}>
                        <Row className="show-grid">
                          <Col xs={24} sm={24} md={24}>
                            <Row gutter={0} style={{ marginBottom: 10 }}>
                              <FormControl
                                variant="standard"
                                sx={{ minWidth: 120 }}
                              >
                                <InputLabel id="demo-simple-select-standard-label">
                                  Status
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  label="Status"
                                  name="Status"
                                  value={values?.Status || ""}
                                  onChange={handleChange}
                                  error={
                                    touched.Status && Boolean(errors.Status)
                                  }
                                  helperText={touched.Status && errors.Status}
                                >
                                  {Status.map((name) => (
                                    <MenuItem
                                      key={name}
                                      value={name}
                                      style={getStyles(name, Status, theme)}
                                    >
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Row>
                          </Col>
                        </Row>
                      </Grid>
                    </div>
                    &nbsp; &nbsp;
                    <>
                      <LoadingButton
                        disabled={isSubmitting}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        loading={isValidating}
                        sx={{ my: 2 }}
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </LoadingButton>
                    </>
                  </div>
                </form>
              );
            }}
          </Formik>
        </SideDrawer>
      </div>
    </>
  );
}
