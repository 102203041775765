import * as React from "react";

import Stack from "@mui/material/Stack";

import useAuth from "app/hooks/useAuth";
import { EDIT_POLICY } from "Permissions";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  Autocomplete,
  InputLabel,
  FormControl,
  TextField,
  useTheme,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { Field, Formik } from "formik";
import "moment/locale/en-in";
import { Button, Col, Grid, Heading, Row } from "rsuite";

import { Uploader, Message, Loader, useToaster, Button as Btn } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";

import { LoadingButton } from "@mui/lab";
import moment from "moment";
import {
  DeleteForever,
  RemoveRedEye,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import useAPINew from "app/hooks/useAPINew";
import { useProps } from "./ServiceProvider";

function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const today = new Date();
today.setHours(0, 0, 0, 0);

export default function UpdateNomination() {
  const { user, permissions } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const { UID } = useParams();
  const [docs, setDocs] = React.useState([]);
  const [data, setData] = React.useState({});
  const [familyList, setFamilyList] = React.useState([]);
  const toaster = useToaster();
  const [fileList1, setFileList1] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(null);
  const ReferenceList = useAPINew("GetReferenceList.php");
  const AgencyCodeList = useAPINew("GetAgencyCodeList.php");
  const theme = useTheme();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = React.useState({});
  const Responces = [
    { value: "Call Recieved", title: "Call Recieved", ID: 1 },
    { value: "Call Not Recieved", title: "Call Not Recieved", ID: 2 },
    { value: "Switch Off", title: "Switch Off", ID: 3 },
    { value: "Other", title: "Other", ID: 4 },
  ];
  const NominationStatus = [
    { value: "PROCESS", title: "Approval", ID: 1 },
    { value: "PENDING", title: "PENDING", ID: 2 },
    { value: "DONE", title: "DONE", ID: 4 },
    { value: "REJECT", title: "REJECT", ID: 3 },
  ];
  const initialState = {
    EditMode: false,
    Attachment: false,
    Nomination: false,
  };
  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "EDITABLE":
        return { ...initialState, EditMode: true };
      case "Nomination":
        return { ...initialState, Nomination: true };
      case "LOCKED":
        return {
          ...initialState,
          EditMode: false,
          Attachment: true,
          Nomination: false,
        };
      case "DONE":
        return { ...initialState, Attachment: false, Nomination: false };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  }, initialState);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getDahiyaMitra();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const { rowFiltereddata, updateIndexValue, index, setIndex } = useProps();
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getDahiyaMitra();
      //console.log(rowFiltereddata);
      updateIndexValue(rowFiltereddata.indexOf(UID));
    }
    return () => {
      isSubscribed = false;
    };
  }, []);
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getDahiyaMitra();
    }
    return () => {
      isSubscribed = false;
    };
  }, [index]);

  const goToPrevious = () => {
    setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    console.log(index);
    console.log(rowFiltereddata[index]);
    navigate(`/Services/UpdateNomination/${rowFiltereddata[index]}`);
  };

  const goToNext = () => {
    setIndex((prevIndex) =>
      prevIndex < rowFiltereddata.length - 1 ? prevIndex + 1 : prevIndex
    );
    console.log(index);
    console.log(rowFiltereddata[index]);
    navigate(`/Services/UpdateNomination/${rowFiltereddata[index]}`);
  };

  const getDahiyaMitra = async () => {
    try {
      const responce = await axiosPrivate.post("GetPolicyByID.php", {
        UID,
      });

      //console.log(responce);
      const result = responce.data;
      if (result.status === 1) {
        GetFamilyDetails(result.Data[0].ReferenceID);
        const newData = {
          ...result.Data[0],

          AgencyCode: {
            value: result.Data[0].AgencyCode,
            title: result.Data[0].AgencyCode,
          },
          FamilyMemberID: {
            value: result.Data[0].FamilyMemberID,
            title: result.Data[0].FamilyMember,
          },
          ReferenceID: {
            value: result.Data[0].ReferenceID,
            title: result.Data[0].Reference,
          },

          NominationResponce: {
            value: result.Data[0].NominationResponce,
            title: result.Data[0].NominationResponce,
          },
          NominationStatus: {
            value: result.Data[0].NominationStatus,
            title: result.Data[0].NominationStatus,
          },
          DOC:
            result.Data[0].DOC === "0000-00-00" || result.Data[0].DOC === null
              ? null
              : result.Data[0].DOC,

          NominationDateofCall:
            result.Data[0].NominationDateofCall === "0000-00-00" ||
            result.Data[0].NominationDateofCall === null
              ? null
              : result.Data[0].NominationDateofCall,

          NominationFormSubmitDate:
            result.Data[0].NominationFormSubmitDate === "0000-00-00" ||
            result.Data[0].NominationFormSubmitDate === null
              ? null
              : result.Data[0].NominationFormSubmitDate,

          CompletionDate:
            result.Data[0].CompletionDate === "0000-00-00" ||
            result.Data[0].CompletionDate === null
              ? null
              : result.Data[0].CompletionDate,
        };
        setInitialValues(newData);

        setFileInfo(
          `https://backend.dahiyainsurance.com/Upload/profile/${result.Data[0].Picture}`
        );
        setDocs(result.Docs);
        setData({ ...data, ...result.Data[0] });
        // setIsDeleted(result.Data[0].Deleted);
      }
      if (result.status === 0) {
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Error!</strong> {result?.Error}
          </Message>
        );
      }
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  };

  function GetFamilyDetails(FamilyHeadID) {
    axiosPrivate
      .post("GetRelatedFamilyList.php", { UID: FamilyHeadID })
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          setFamilyList(result.Data);
        }
        if (result.status === 0) {
          setFamilyList([{ value: "", title: "" }]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleAttachment() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`Are you done with attaching documents?`}</p>
            <button
              onClick={() => {
                dispatch({ type: "DONE" });
                onClose();
                setFileList1([]);
              }}
            >
              Yes
            </button>
            <button onClick={onClose}>No</button>
          </div>
        );
      },
    });
  }
  function handleRemoveDoc(ID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this document.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemovePolicyDoc.php",
                    datatosend
                  );
                  const result = response.data;
                  onClose();
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    const newDocs = docs.filter(function (item) {
                      return item.UniqueID !== UID;
                    });
                    setDocs(newDocs);
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Heading
            level={5}
            style={{
              textAlign: "center",
              flex: 1,
            }}
          >
            Update Bond Status
          </Heading>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <Grid fluid style={{ padding: 10 }}>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={16}>
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                  axiosPrivate
                    .post("EditPolicyNominationStatus.php", {
                      ...values,
                      NominationResponce: values.NominationResponce.value,
                      NominationStatus: values.NominationStatus.value,
                      NominationDateofCall: values?.NominationDateofCall
                        ? moment(values.NominationDateofCall).format(
                            "YYYY-MM-DD"
                          )
                        : null,

                      NominationFormSubmitDate: values?.NominationFormSubmitDate
                        ? moment(values.NominationFormSubmitDate).format(
                            "YYYY-MM-DD"
                          )
                        : null,
                    })
                    .then((response) => {
                      const result = response.data;
                      if (result.status === 1) {
                        console.log(result);
                        toaster.push(
                          <Message type="success" bordered showIcon>
                            <strong>Success!</strong> {result?.Success}
                          </Message>
                        );
                        dispatch({ type: "LOCKED" });
                      }
                      if (result.status === 0) {
                        toaster.push(
                          <Message type="error" bordered showIcon>
                            <strong>Error!</strong> {result.Error}
                          </Message>
                        );
                      }
                      setSubmitting(false);
                      // resetForm();
                    })
                    .catch((error) => {
                      console.error("Error submitting data:", error);
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  errors,
                  isSubmitting,
                  isValidating,
                  setErrors,
                  setTouched,
                }) => (
                  <form className="target">
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading
                        level={6}
                        style={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Reference ID
                      </Heading>
                    </Row>
                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={2}>
                        <Field
                          name="ReferenceID"
                          disabled={!state.EditMode}
                          component={Autocomplete}
                          key="ID"
                          options={
                            ReferenceList?.error
                              ? [{ value: "", title: "" }]
                              : ReferenceList.data
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 400 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "ReferenceID",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                            GetFamilyDetails(value.value);
                            setFieldValue("FamilyMemberID", {
                              value: "",
                              title: "",
                            });
                          }}
                          value={
                            Boolean(values.ReferenceID)
                              ? values.ReferenceID
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="ReferenceID"
                              label="ReferenceID"
                              variant="standard"
                              error={
                                touched.ReferenceID &&
                                Boolean(errors.ReferenceID)
                              }
                              helperText={
                                touched.ReferenceID && errors.ReferenceID
                              }
                            />
                          )}
                        />
                        &nbsp;
                        {values?.ReferenceID?.value && (
                          <Button
                            appearance="link"
                            onClick={() => {
                              console.log(values);
                              navigate(
                                "/Prospects/ViewProspect/" +
                                  values?.ReferenceID?.value
                              );
                            }}
                          >
                            <RemoveRedEye />
                          </Button>
                        )}
                        &nbsp; &nbsp;
                        <Field
                          name="FamilyMemberID"
                          disabled={!state.EditMode}
                          component={Autocomplete}
                          key="ID"
                          options={
                            familyList?.length > 0
                              ? familyList
                              : [{ value: "", title: "" }]
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={async (_, value) => {
                            setFieldValue(
                              "FamilyMemberID",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                            try {
                              const responce = await axiosPrivate.post(
                                "GetFamilyByID.php",
                                {
                                  UID: value.value,
                                }
                              );
                              //console.log(responce);
                              const result = responce.data;
                              if (result.status === 1) {
                                setFieldValue(
                                  "DOBR",
                                  result.Data[0].DOBR === "0000-00-00" ||
                                    result.Data[0].DOBR === null
                                    ? null
                                    : new Date(result.Data[0].DOBR)
                                );
                                const currentDate = moment();
                                const birthDate = moment(
                                  result.Data[0].DOBR === "0000-00-00" ||
                                    result.Data[0].DOBR === null
                                    ? null
                                    : new Date(result.Data[0].DOBR)
                                );
                                const age = currentDate.diff(
                                  birthDate,
                                  "years"
                                );
                                if (age >= 18) {
                                  setFieldValue("MinorPolicy", "NO");
                                } else {
                                  setFieldValue("MinorPolicy", "YES");
                                }
                              }
                              if (result.status === 0) {
                                toaster.push(
                                  <Message type="error" bordered showIcon>
                                    <strong>Error!</strong> {result?.Error}
                                  </Message>
                                );
                              }
                            } catch (error) {
                              console.log(error);
                              toaster.push(
                                <Message type="error" bordered showIcon>
                                  <strong>Error!</strong> Error occured
                                </Message>
                              );
                            }
                          }}
                          value={
                            Boolean(values.FamilyMemberID)
                              ? values.FamilyMemberID
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="FamilyMemberID"
                              label="Family Member"
                              variant="standard"
                              error={
                                touched.FamilyMemberID &&
                                Boolean(errors.FamilyMemberID)
                              }
                              helperText={
                                touched.FamilyMemberID && errors.FamilyMemberID
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Policy details</Heading>
                    </Row>
                    <Row
                      gutter={1}
                      className="show-grid"
                      style={{ marginBottom: 10 }}
                    >
                      <TextField
                        disabled={!state.EditMode}
                        label="Policy Holder Name"
                        size="small"
                        variant="standard"
                        name="PolicyHolderName"
                        value={values.PolicyHolderName}
                        InputLabelProps={{
                          shrink: Boolean(values.PolicyHolderName),
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.PolicyHolderName &&
                          Boolean(errors.PolicyHolderName)
                        }
                        helperText={
                          touched.PolicyHolderName && errors.PolicyHolderName
                        }
                        style={{ width: 300 }}
                      />{" "}
                    </Row>
                    <Row gutter={1} className="show-grid">
                      <Stack direction="row" spacing={2}>
                        <Field
                          disabled={!state.EditMode}
                          name="AgencyCode"
                          component={Autocomplete}
                          key="ID"
                          options={
                            AgencyCodeList?.error
                              ? [{ value: "", title: "" }]
                              : AgencyCodeList.data
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 250 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "AgencyCode",
                              value || { value: "", title: "" }
                            );
                          }}
                          value={
                            Boolean(values.AgencyCode)
                              ? values.AgencyCode
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="AgencyCode"
                              label="AgencyCode"
                              variant="standard"
                              error={
                                touched.AgencyCode && Boolean(errors.AgencyCode)
                              }
                              helperText={
                                touched.AgencyCode && errors.AgencyCode
                              }
                            />
                          )}
                        />
                        &nbsp; &nbsp;
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            disabled={!state.EditMode}
                            label="Completion Date"
                            variant="standard"
                            size="small"
                            name="CompletionDate"
                            format="dd-MM-yyyy"
                            style={{ width: 120 }}
                            value={values.CompletionDate}
                            onChange={(value) =>
                              setFieldValue("CompletionDate", value, true)
                            }
                            onBlur={handleBlur}
                            error={
                              touched.CompletionDate &&
                              Boolean(errors.CompletionDate)
                            }
                            helperText={
                              touched.CompletionDate && errors.CompletionDate
                            }
                          />
                        </LocalizationProvider>
                        &nbsp; &nbsp;
                        <TextField
                          disabled={!state.EditMode}
                          label="Policy No"
                          size="small"
                          variant="standard"
                          name="PolicyNo"
                          InputLabelProps={{
                            shrink: Boolean(values.PolicyNo),
                          }}
                          value={values.PolicyNo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.PolicyNo && Boolean(errors.PolicyNo)}
                          helperText={touched.PolicyNo && errors.PolicyNo}
                          style={{ width: 120 }}
                        />{" "}
                      </Stack>
                    </Row>
                    <Row
                      gutter={1}
                      className="show-grid"
                      style={{ marginTop: 10 }}
                    >
                      <Stack direction="row" spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            disabled={!state.EditMode}
                            label="DOC"
                            variant="standard"
                            size="small"
                            name="DOC"
                            format="dd-MM-yyyy"
                            value={values.DOC}
                            onChange={(value) =>
                              setFieldValue("DOC", value, true)
                            }
                            onBlur={handleBlur}
                            error={touched.DOC && Boolean(errors.DOC)}
                            helperText={touched.DOC && errors.DOC}
                          />
                        </LocalizationProvider>
                        &nbsp; &nbsp;
                        <TextField
                          disabled={!state.EditMode}
                          label="Paying Term"
                          size="small"
                          variant="standard"
                          name="PayingTerm"
                          InputLabelProps={{
                            shrink: Boolean(values.PayingTerm),
                          }}
                          value={values.PayingTerm}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.PayingTerm && Boolean(errors.PayingTerm)
                          }
                          helperText={touched.PayingTerm && errors.PayingTerm}
                          style={{ width: 200 }}
                        />{" "}
                        &nbsp; &nbsp;
                        <TextField
                          disabled={!state.EditMode}
                          label="Sum Assured"
                          size="small"
                          variant="standard"
                          name="SumAssured"
                          value={values.SumAssured}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputLabelProps={{
                            shrink: Boolean(values.SumAssured),
                          }}
                          error={
                            touched.SumAssured && Boolean(errors.SumAssured)
                          }
                          helperText={touched.SumAssured && errors.SumAssured}
                          style={{ width: 200 }}
                        />{" "}
                      </Stack>
                    </Row>
                    <br />

                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Other details</Heading>
                    </Row>
                    <Row
                      gutter={1}
                      style={{
                        marginTop: 20,
                        marginBottom: 10,
                        alignItems: "flex-end",
                        display: "flex",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateField
                          disabled={true}
                          label="DOBR"
                          variant="standard"
                          size="small"
                          style={{ width: 100 }}
                          name="DOBR"
                          format="dd-MM-yyyy"
                          value={values.DOBR}
                          onChange={(value) =>
                            setFieldValue("DOBR", value, true)
                          }
                          onBlur={handleBlur}
                          error={touched.DOBR && Boolean(errors.DOBR)}
                          helperText={touched.DOBR && errors.DOBR}
                        />
                      </LocalizationProvider>
                      &nbsp; &nbsp;
                      <b>
                        {values.DOBR &&
                          `${moment().diff(
                            values.DOBR ? new Date(values.DOBR) : null,
                            "years"
                          )} Year(s)`}
                      </b>
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      <FormControl variant="standard" sx={{ minWidth: 200 }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          MINOR POLICY HOLDER
                        </InputLabel>
                        <Select
                          disabled={true}
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          label="MinorPolicy"
                          style={{ width: 150 }}
                          name="MinorPolicy"
                          value={values?.MinorPolicy || ""}
                          onChange={handleChange}
                          error={
                            touched.MinorPolicy && Boolean(errors.MinorPolicy)
                          }
                          helperText={touched.MinorPolicy && errors.MinorPolicy}
                        >
                          {["YES", "NO"].map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, ["YES", "NO"], theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Row>
                    <Row
                      gutter={1}
                      className="show-grid"
                      style={{ marginTop: 10 }}
                    >
                      <Stack direction="row" spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            disabled={!state.Nomination}
                            label="Form Submit Date"
                            variant="standard"
                            size="small"
                            name="NominationFormSubmitDate"
                            format="dd-MM-yyyy"
                            value={values.NominationFormSubmitDate}
                            style={{ width: 120 }}
                            onChange={(value) =>
                              setFieldValue(
                                "NominationFormSubmitDate",
                                value,
                                true
                              )
                            }
                            onBlur={handleBlur}
                            error={
                              touched.NominationFormSubmitDate &&
                              Boolean(errors.NominationFormSubmitDate)
                            }
                            helperText={
                              touched.NominationFormSubmitDate &&
                              errors.NominationFormSubmitDate
                            }
                          />
                        </LocalizationProvider>
                        &nbsp; &nbsp;
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            disabled={!state.Nomination}
                            label="Date of Call"
                            variant="standard"
                            size="small"
                            name="NominationDateofCall"
                            format="dd-MM-yyyy"
                            style={{ width: 120 }}
                            value={values.NominationDateofCall}
                            onChange={(value) =>
                              setFieldValue("NominationDateofCall", value, true)
                            }
                            onBlur={handleBlur}
                            error={
                              touched.NominationDateofCall &&
                              Boolean(errors.NominationDateofCall)
                            }
                            helperText={
                              touched.NominationDateofCall &&
                              errors.NominationDateofCall
                            }
                          />
                        </LocalizationProvider>
                        &nbsp; &nbsp;
                        <Field
                          name="NominationResponce"
                          disabled={!state.Nomination}
                          component={Autocomplete}
                          key="ID"
                          freeSolo
                          options={Responces}
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 150 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "NominationResponce",
                              value || { value: "", title: "" }
                            );
                          }}
                          value={
                            Boolean(values.NominationResponce)
                              ? values.NominationResponce
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="NominationResponce"
                              label="Call Responce"
                              variant="standard"
                              error={
                                touched.NominationResponce &&
                                Boolean(errors.NominationResponce)
                              }
                              helperText={
                                touched.NominationResponce &&
                                errors.NominationResponce
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <Row
                      gutter={1}
                      className="show-grid"
                      style={{ marginTop: 10 }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Field
                          name="NominationStatus"
                          disabled={!state.Nomination}
                          component={Autocomplete}
                          key="ID"
                          freeSolo
                          options={NominationStatus}
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 150 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "NominationStatus",
                              value || { value: "", title: "" }
                            );
                          }}
                          value={
                            Boolean(values.NominationStatus)
                              ? values.NominationStatus
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="NominationStatus"
                              label="Nomination Status"
                              variant="standard"
                              error={
                                touched.NominationStatus &&
                                Boolean(errors.NominationStatus)
                              }
                              helperText={
                                touched.NominationStatus &&
                                errors.NominationStatus
                              }
                            />
                          )}
                        />
                        &nbsp; &nbsp;
                        <TextField
                          disabled={!state.Nomination}
                          label="Other Details"
                          size="small"
                          variant="standard"
                          name="NominationOtherDetails"
                          InputLabelProps={{
                            shrink: Boolean(values.NominationOtherDetails),
                          }}
                          multiline={true}
                          maxRows={4}
                          value={values.NominationOtherDetails}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.NominationOtherDetails &&
                            Boolean(errors.NominationOtherDetails)
                          }
                          helperText={
                            touched.NominationOtherDetails &&
                            errors.NominationOtherDetails
                          }
                          style={{ width: 200 }}
                        />{" "}
                      </Stack>
                    </Row>
                    <br />

                    <Row gutter={1} style={{ marginTop: 10, marginBottom: 10 }}>
                      <Stack direction="row" spacing={4}>
                        {state.Nomination ? (
                          <>
                            <LoadingButton
                              disabled={isSubmitting}
                              type="submit"
                              color="secondary"
                              variant="contained"
                              onClick={handleSubmit}
                              loading={isValidating}
                              sx={{ my: 2 }}
                            >
                              {isSubmitting ? "Submitting..." : "Submit"}
                            </LoadingButton>
                            <Btn
                              color="secondary"
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                setErrors({});
                                setTouched({}, false);

                                dispatch({ type: "DONE" });
                              }}
                              sx={{ my: 2 }}
                            >
                              {"CANCEL"}
                            </Btn>
                          </>
                        ) : (
                          <>
                            <Btn
                              color="secondary"
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch({ type: "Nomination" });
                              }}
                              sx={{ my: 2 }}
                            >
                              {"Edit"}
                            </Btn>
                            {state.Attachment && (
                              <Btn
                                color="secondary"
                                variant="contained"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleAttachment();
                                }}
                                sx={{ my: 2 }}
                              >
                                {"DONE"}
                              </Btn>
                            )}
                          </>
                        )}
                      </Stack>
                    </Row>
                  </form>
                )}
              </Formik>
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Row className="show-grid">
                {UID && (
                  <Col xs={24} sm={24} md={24}>
                    <Uploader
                      fileListVisible={false}
                      disabled={!state.Attachment}
                      multiple={false}
                      listType="picture"
                      action="https://backend.dahiyainsurance.com/Upload/AddProfilePic.php"
                      withCredentials={true}
                      headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                      data={{ UID, Type: "Policy" }}
                      onUpload={(File) => {
                        setUploading(true);
                        previewFile(File.blobFile, (value) => {
                          setFileInfo(value);
                        });
                      }}
                      onSuccess={(response, _) => {
                        setUploading(false);
                        toaster.push(
                          <Message type="success">
                            Uploaded successfully
                          </Message>
                        );
                        console.log(response);
                      }}
                      onError={(e) => {
                        console.log(e);
                        setFileInfo(null);
                        setUploading(false);
                        toaster.push(
                          <Message type="error">Upload failed</Message>
                        );
                      }}
                      onRemove={(file) => {
                        console.log(file);
                      }}
                    >
                      <button style={{ width: 300, height: 300 }}>
                        {uploading && <Loader backdrop center />}
                        {fileInfo ? (
                          <img
                            alt=""
                            src={fileInfo}
                            width="100%"
                            height="100%"
                            style={{ objectFit: "cover" }}
                          />
                        ) : (
                          <AvatarIcon style={{ fontSize: 80 }} />
                        )}
                      </button>
                    </Uploader>

                    <Uploader
                      disabled={!state.Attachment}
                      removable={!state.Attachment}
                      onRemove={(file) => {
                        console.log(file);
                      }}
                      listType="picture-text"
                      fileList={fileList1}
                      multiple={true}
                      data={{ UID, Type: "Policy" }}
                      onChange={setFileList1}
                      withCredentials={true}
                      headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                      action="https://backend.dahiyainsurance.com/Upload/UploadDocs.php"
                      renderFileInfo={(file, fileElement) => (
                        <span style={{ width: "100%", fontSize: 14 }}>
                          {file.name}
                        </span>
                      )}
                    />
                  </Col>
                )}
              </Row>
              <Row
                className="show-grid"
                style={{ margin: "10px 0px 10px 0px" }}
              >
                <Col xs={24} sm={24} md={24}>
                  {
                    <ul>
                      {docs.map((data) => (
                        <li key={data.UniqueID}>
                          {/* <Link to={data.UniqueID}>{data.Name}</Link> */}
                          <a
                            rel="noreferrer"
                            href={`https://backend.dahiyainsurance.com/Upload/assets/${data.Path}`}
                            target="_blank"
                            style={{ cursor: "pointer" }}
                            /* onClick={(e) => getDocs(e, data.UniqueID)} */
                          >
                            {data.Name}
                          </a>
                          {(permissions.includes(EDIT_POLICY) ||
                            user.Type === "SuperAdmin") && (
                            <a
                              href=""
                              style={{
                                paddingLeft: 5,
                                textDecoration: "none",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleRemoveDoc(data.UniqueID);
                              }}
                            >
                              {state.Attachment && (
                                <DeleteForever style={{ fontSize: 15 }} />
                              )}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  }
                </Col>
              </Row>
              <Row className="show-grid">
                <Col xs={24} sm={24} md={24}>
                  <>
                    {index > 0 && (
                      <Button
                        style={{
                          color: "white",
                          backgroundColor: "#2979ff",
                          margin: 10,
                        }}
                        onClick={goToPrevious}
                        startIcon={<SkipPrevious />}
                      >
                        Previous
                      </Button>
                    )}

                    {index < rowFiltereddata.length - 1 && (
                      <Button
                        style={{
                          color: "white",
                          backgroundColor: "#2979ff",
                          margin: 10,
                        }}
                        onClick={goToNext}
                        startIcon={<SkipNext />}
                      >
                        Next
                      </Button>
                    )}
                  </>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  );
}
