import { Fragment } from "react";
import { Card, Grid, styled, useTheme, lighten } from "@mui/material";
import RowCards from "./shared/RowCards";
import StatCards from "./shared/StatCards";
import Campaigns from "./shared/Campaigns";
import StatCards2 from "./shared/StatCards2";
import DoughnutChart from "./shared/Doughnut";
import React from "react";
import useAuth from "app/hooks/useAuth";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
// STYLED COMPONENTS
const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  marginRight: ".5rem",
  textTransform: "capitalize",
}));

const SubTitle = styled("span")(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.text.secondary,
}));

const H4 = styled("h4")(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  marginBottom: "16px",
  textTransform: "capitalize",
  color: theme.palette.text.secondary,
}));

export default function Analytics() {
  const { palette } = useTheme();
  const { permissions } = useAuth();
  console.log(permissions);
  const [rowData, setRowData] = React.useState([{}]);
  const [commData, setCommData] = React.useState([{}]);
  const axiosPrivate = useAxiosPrivate();
  const bgError = lighten(palette.error.main, 0.85);
  React.useEffect(() => {
    axiosPrivate
      .post("Dashboard.php")
      .then((responce) => {
        const result = responce.data;
        if (result.status === 1) {
          setRowData(result.Prospect);
          setCommData(result.Amount);
        } else setRowData([]);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const [rowData1, setRowData1] = React.useState([{}]);
  const [commData1, setCommData1] = React.useState([{}]);

  React.useEffect(() => {
    axiosPrivate
      .post("LoadAllNext10DaysPendingDiscussion.php")
      .then((responce) => {
        const result = responce.data;
        if (result.status === 1) {
          setRowData1(result.Data);
          setCommData1(result.DueCount);
        } else setRowData1([]);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={3}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <StatCards />
            {/* <TopSellingTable /> */}
            <StatCards2 Amount={commData} />

            <H4>Recent Prospects</H4>
            <RowCards data={rowData} />
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Card sx={{ px: 3, py: 2, mb: 3 }}>
              <Title>Followup Status</Title>
              <SubTitle>Last 30 days</SubTitle>

              <DoughnutChart
                height="300px"
                color={["#FF3D57", "blue", "#08ad6c"]}
                data={commData1}
              />
            </Card>

            {/* <UpgradeCard /> */}
            <Campaigns data={rowData1} />
          </Grid>
        </Grid>
      </ContentBox>
    </Fragment>
  );
}
