import AgGrid from "app/components/AgGrid";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import React from "react";
import { Panel } from "rsuite";
export default function DeletedReference() {
  const [loading, setLoading] = React.useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [referenceData, setReferenceData] = React.useState([{}]);

  const Referencecolumns = React.useMemo(() => {
    return [
      {
        headerName: "Ref ID",
        field: "ID",
        minWidth: 180,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      {
        headerName: "Name",
        field: "Name",
        minWidth: 180,
      },
      { field: "Date", minWidth: 150, type: "date" },
      { field: "Place", minWidth: 150 },
      { field: "RefBy", minWidth: 150 },
      { field: "Caste", minWidth: 150 },
      { field: "Address", minWidth: 150 },
      { field: "Village", minWidth: 150 },
      { field: "Tehsil", minWidth: 150 },
      { field: "District", minWidth: 150 },
      { field: "State", minWidth: 150 },
      { field: "WhatsAppNo", minWidth: 150, type: "numericColumn" },
      { field: "MobileNo", minWidth: 150, type: "numericColumn" },
      { field: "Profession", minWidth: 150 },
      { field: "Education", minWidth: 150 },
      { field: "Other", minWidth: 150, headerName: "Other/LIC Details" },
      { field: "FamilyMembers", minWidth: 200 },
      { field: "AddedBy", minWidth: 150 },
      { field: "AddedOn", hide: true },
      { field: "DeletedBy", minWidth: 150 },
      { field: "DeletedOn", hide: true },
      { field: "UniqueID", hide: true },
    ];
  }, []);

  const onReferenceGridReady = React.useCallback((params) => {
    setLoading(true);
    axiosPrivate
      .post("LoadDeletedReference.php")
      .then((responce) => {
        const result = responce.data;
        if (result.status === 1) setReferenceData(result.Data);
        else setReferenceData([]);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Panel>
        <AgGrid
          showLoading={loading}
          columns={Referencecolumns}
          rowData={referenceData}
          onGridReady={onReferenceGridReady}
          defaultColumn={["Name"]}
          GoTo="/References/ViewReference"
          QuickSearch={true}
          exportAllowed={false}
          ChooseColumns={false}
          onRowSelectedData={() => {}}
          Source="Reference"
        />
      </Panel>
    </>
  );
}
