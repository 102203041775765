import * as React from "react";
import AgGrid from "app/components/AgGrid";

export default function ListCustomer() {
  const [rowData, setRowData] = React.useState([{}]);

  const onGridReady = React.useCallback((params) => {
    fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
      .then((resp) => resp.json())
      .then((data) => setRowData(data));
  }, []);

  return (
    <AgGrid
      rowData={rowData}
      onGridReady={onGridReady}
      defaultColumn={["athlete"]}
    />
  );
}
