import * as React from "react";
import AgGrid from "app/components/AgGrid";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { DateRangePicker, Heading, Panel, Stack } from "rsuite";
import { useProps } from "./PolicyProvider";
import { filterParams } from "functions";
import { addDays } from "date-fns";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";

import { startOfToday } from "date-fns";
import moment from "moment";
function getLastFinancialYearDates() {
  const today = startOfToday();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  let startOfLastFY, endOfLastFY;

  if (currentMonth >= 3) {
    // From April to December
    startOfLastFY = new Date(currentYear - 1, 3, 1); // April 1st of last year
    endOfLastFY = new Date(currentYear, 2, 31); // March 31st of this year
  } else {
    // From January to March
    startOfLastFY = new Date(currentYear - 2, 3, 1); // April 1st two years ago
    endOfLastFY = new Date(currentYear - 1, 2, 31); // March 31st of last year
  }

  return {
    startOfLastFY,
    endOfLastFY,
  };
}

const { startOfLastFY, endOfLastFY } = getLastFinancialYearDates();

function getCurrentFinancialYearDates() {
  const today = startOfToday();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  let startOfCurrentFY, endOfCurrentFY;

  if (currentMonth >= 3) {
    // From April to December
    startOfCurrentFY = new Date(currentYear, 3, 1); // April 1st of this year
    endOfCurrentFY = new Date(currentYear + 1, 2, 31); // March 31st of next year
  } else {
    // From January to March
    startOfCurrentFY = new Date(currentYear - 1, 3, 1); // April 1st of last year
    endOfCurrentFY = new Date(currentYear, 2, 31); // March 31st of this year
  }

  return {
    startOfCurrentFY,
    endOfCurrentFY,
  };
}

const { startOfCurrentFY, endOfCurrentFY } = getCurrentFinancialYearDates();

const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
  },
  {
    label: "Last Fin Year",
    value: [startOfLastFY, endOfLastFY],
  },
  {
    label: "Current Fin Year",
    value: [startOfCurrentFY, endOfCurrentFY],
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
  },
  {
    label: "Tomorrow",
    value: [addDays(new Date(), 1), addDays(new Date(), 1)],
  },
  {
    label: "Next week",
    value: [
      startOfWeek(addDays(new Date(), 6)),
      endOfWeek(addDays(new Date(), 6)),
    ],
  },
  {
    label: "Next 7 days",
    value: [new Date(), addDays(new Date(), 6)],
  },
  {
    label: "Next 30 days",
    value: [new Date(), addDays(new Date(), 29)],
  },
  {
    label: "Next month",
    value: [
      startOfMonth(addMonths(new Date(), 1)),
      endOfMonth(addMonths(new Date(), 1)),
    ],
  },
];
export default function ListPolicy() {
  const axiosPrivate = useAxiosPrivate();

  const [rowData, setRowData] = React.useState([{}]);

  const [loading, setLoading] = React.useState(false);

  const formatNumber = (number) => {
    return Math.floor(number).toLocaleString();
  };
  const currencyFormatter = (params) => {
    return formatNumber(params.value);
  };
  const PerFormatter = (params) => {
    return (params.value || 0).toLocaleString() + "% ";
  };
  const [selectedRowData, setSelectedRowData] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [value, setValue] = React.useState([startOfCurrentFY, endOfCurrentFY]);
  const { updatePropsValue } = useProps();

  const columns = React.useMemo(() => {
    return [
      {
        field: "VIMPROID",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "VM Pro ID",
      },
      {
        field: "PolicyNo",
        width: 200,
        minWidth: 150,
        editable: false,
        headerName: "Policy No",
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      {
        headerName: "Ref ID",
        field: "RefID",
        width: 180,
        minWidth: 150,
        editable: false,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },

      { field: "RefBy", width: 150, minWidth: 150, editable: false },
      { field: "Place", width: 150, minWidth: 150, editable: false },
      {
        field: "Name",
        width: 200,
        minWidth: 150,
        editable: false,
        headerName: "Family Head Name",
      },
      {
        field: "PolicyHolderName",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "PolicyHolder",
      },
      {
        field: "DOB",
        width: 130,
        minWidth: 150,
        editable: false,
        headerName: "DOB",
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      {
        field: "Age",
        width: 180,
        minWidth: 150,
        editable: false,
        headerName: "Age",
      },
      { field: "Caste", width: 150, minWidth: 150, editable: false },
      {
        field: "Village",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "Village/City",
      },
      {
        field: "WhatsAppNo",
        width: 120,
        minWidth: 150,
        editable: false,
        headerName: "Mobile No",
      },
      {
        field: "Profession",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "Profession",
      },
      {
        field: "WorkType",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "Work Type",
      },
      {
        field: "AgencyCode",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "Agency Code",
      },
      {
        field: "CompletionDate",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "Completion Date",
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      {
        field: "AgeDOC",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "Age ON DOC",
        type: "date",
      },

      {
        field: "DOC1",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "DOC",
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      {
        field: "PayingTerm",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "Plan-Term",
      },

      {
        field: "SumAssured",
        width: 150,
        minWidth: 150,
        valueFormatter: currencyFormatter,
        editable: false,
        headerName: "SumAssured",
      },
      {
        field: "Mode",
        width: 100,
        minWidth: 150,
        editable: false,
        headerName: "Mode",
      },

      {
        field: "FirstBasic",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "FirstTotal",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "FirstYLYBasic",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },

      { field: "MinorPolicy", width: 150, minWidth: 150, editable: false },
      {
        field: "ENachMode",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "ENach",
      },
      {
        field: "PolicBondRecieved",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "BondRecieved",
      },
      {
        field: "FormSubmittedOnline",
        width: 150,
        minWidth: 150,
        editable: false,
      },
      {
        field: "GST",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: PerFormatter,
        headerName: "GST %",
      },
      {
        field: "CommPer",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: PerFormatter,
        headerName: "Comm %",
      },
      {
        field: "CommOfFirstBasisPremium",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "CommOfFirstBasisYLYPremium",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "CR2",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
        headerName: "DR2",
      },
      {
        field: "OtherComm",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
        headerName: "DAHIYA MITRA PAYOUT",
      },
      {
        field: "OtherExpenses",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
        headerName: "SERVICING EXPENCES",
      },
      {
        field: "CommOfFirstBasisYLYPremium",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "CommOfFirstBasisYLYPremium",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "NetSavingFirst",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "NetSavingYLY",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      { field: "AddedBy", minWidth: 150, editable: false },
      {
        field: "AddedOn",
        minWidth: 180,
        editable: false,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      {
        field: "AssignedToName",
        minWidth: 150,
        editable: false,
        headerName: "Assigned To",
      },
      { field: "UniqueID", hide: true, editable: false },
    ];
  }, []);

  const onGridReady = React.useCallback(
    (params) => {
      setLoading(true);
      axiosPrivate
        .post("LoadPolicy.php", {
          Start: moment(value[0]).format("YYYY-MM-DD"),
          End: moment(value[1]).format("YYYY-MM-DD"),
        })
        .then((responce) => {
          const result = responce.data;
          if (result.status === 1) setRowData(result.Data);
          else setRowData([]);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    },
    [value]
  );

  const handleUpdateMessage = () => {
    const NewData = filteredRows.map((dt) => dt.data.UniqueID);
    updatePropsValue(NewData);
    //console.log(propsValue);
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      handleUpdateMessage();
      //console.log(filteredRows);
    }
    return () => {
      isSubscribed = false;
    };
  }, [filteredRows.length]);
  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Stack
            direction="row"
            spacing={4}
            style={{ flex: 0.4, justifyContent: "flex-start", padding: 5 }}
          >
            <DateRangePicker
              ranges={predefinedRanges}
              value={value}
              onChange={setValue}
              cleanable={false}
              showMeridian
              format="dd-MM-yyyy"
              defaultCalendarValue={[
                new Date("2024-08-01"),
                new Date("2024-08-31"),
              ]}
            />
            <button type="button" className="Button" onClick={onGridReady}>
              Apply
            </button>
          </Stack>
          <Heading
            level={5}
            style={{
              textAlign: "center",
              flex: 0.4,
            }}
          >
            {`Policy List `}
          </Heading>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <Panel>
          <AgGrid
            columns={columns}
            rowData={rowData}
            onGridReady={onGridReady}
            defaultColumn={["Name"]}
            GoTo="/Policy/View"
            showLoading={loading}
            onRowSelectedData={setSelectedRowData}
            settingsName="Policy"
            headerComponent={() => <p></p>}
            setFilteredRows={setFilteredRows}
            Source="Policy"
            exportAllowed={true}
          />
        </Panel>
      </div>
    </>
  );
}
