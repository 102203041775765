import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Container, Content } from "rsuite";
import { Button } from "@mui/material";
import { Delete, ListAltRounded, PersonAdd, Upload } from "@mui/icons-material";
import Header from "app/components/Header";
import "../../pages/styles.css";
import useAuth from "app/hooks/useAuth";
import {
  ADD_REFERENCE,
  DELETE_PROSPECT,
  IMPORT_PROSPECT,
  VIEW_REFERENCE,
} from "Permissions";
import { Outlet, useNavigate } from "react-router-dom";
import SideDrawer from "app/components/SideDrawer";

import SideInputs from "../Inputs/SideInputs";
import { PropsProvider } from "./ReferenceContext";

export default function References() {
  const { permissions, user } = useAuth();
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  return (
    <Box
      component="section"
      sx={{
        p: 1,
        border: "0.5px dashed #ddd",
        width: "100%",
        backgroundColor: "#FEFAF6",
      }}
    >
      <Stack spacing={1}>
        <Header headerText="References">
          {(permissions.includes(ADD_REFERENCE) ||
            user.Type === "SuperAdmin") && (
            <>
              {/* <Button
                onClick={() => {
                  setOpen(true);
                }}
              >
                {" "}
                Inputs
              </Button> */}
            </>
          )}
          {(permissions.includes(ADD_REFERENCE) ||
            user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("AddReference");
                }}
                variant="contained"
                startIcon={<PersonAdd />}
              >
                Add
              </Button>
            </>
          )}
          {(permissions.includes(VIEW_REFERENCE) ||
            user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("ListReference");
                }}
                variant="contained"
                startIcon={<ListAltRounded />}
              >
                List
              </Button>
            </>
          )}
          {(permissions.includes(IMPORT_PROSPECT) ||
            user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("ImportReference");
                }}
                variant="contained"
                startIcon={<Upload />}
              >
                Import
              </Button>
            </>
          )}
          {(permissions.includes(DELETE_PROSPECT) ||
            user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("Deleted");
              }}
              variant="contained"
              startIcon={<Delete />}
            >
              Deleted
            </Button>
          )}
        </Header>

        <Container>
          <Content
            style={{
              flex: 1,
              width: "100%",
              height: 650,
              borderRadius: 10,
              border: "1px dashed #ddd",
            }}
          >
            <PropsProvider>
              <Outlet />
            </PropsProvider>
            <SideDrawer
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <SideInputs />
            </SideDrawer>
          </Content>
        </Container>
      </Stack>
    </Box>
  );
}
