import * as React from "react";

import Stack from "@mui/material/Stack";

import useAuth from "app/hooks/useAuth";
import {
  DELETE_POLICY,
  DELETE_POLICYSERVICE,
  EDIT_POLICY,
  EDIT_POLICYSERVICE,
  VIEW_POLICYSERVICE,
} from "Permissions";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  Autocomplete,
  InputLabel,
  FormControl,
  TextField,
  useTheme,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { Field, Formik } from "formik";
import "moment/locale/en-in";
import { Button, Col, Grid, Heading, Row } from "rsuite";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Uploader, Message, Loader, useToaster, Button as Btn } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";

import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import {
  Delete,
  DeleteForever,
  ListAlt,
  RemoveRedEye,
  RestoreFromTrash,
} from "@mui/icons-material";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import useAPINew from "app/hooks/useAPINew";
import { useProps } from "./ServiceProvider";
import { number2text } from "functions";

function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const filter = createFilterOptions();
const today = new Date();
today.setHours(0, 0, 0, 0);

export default function ViewPolicyService() {
  const { user, permissions } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [docs, setDocs] = React.useState([]);
  const [familyList, setFamilyList] = React.useState([]);
  const [policyList, setPolicyList] = React.useState([]);
  const [policyDataList, setPolicyDataList] = React.useState([]);
  const toaster = useToaster();
  const CasteList = useAPINew("GetCasteList.php");
  const BranchList = useAPINew("GetBranchList.php");
  const VillageList = useAPINew("GetVillageList.php");
  const TehsilList = useAPINew("GetTehsilList.php");
  const ServiceWorkList = useAPINew("GetServiceWorkList.php");
  const DistrictList = useAPINew("GetDistrictList.php");
  const StateList = useAPINew("GetStateList.php");
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(null);
  const [isDeleted, setIsDeleted] = React.useState(false);
  const [fileList1, setFileList1] = React.useState([]);
  const [data, setData] = React.useState({});
  const ReferenceList = useAPINew("GetAllReferenceList.php");
  const theme = useTheme();
  const navigate = useNavigate();
  const Status = ["PENDING", "PROCESS", "DONE", "REJECT"];
  const { UID } = useParams();

  const { rowFiltereddata, updateIndexValue, index } = useProps();
  const initialState = {
    EditMode: false,
    Attachment: false,
  };
  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "EDITABLE":
        return { ...initialState, EditMode: true };
      case "ATTACH":
        return { ...initialState, Attachment: true };
      case "DONE":
        return { ...initialState, Attachment: false };
      case "LOCKED":
        return { ...initialState, EditMode: false };

      case "RESET":
        return initialState;
      default:
        return state;
    }
  }, initialState);

  const [initialValues, setInitialValues] = React.useState({
    RefBy: null,
    Address: "",
    MobileNo: "",
    Caste: null,
    Village: null,
    Tehsil: null,
    District: null,
    State: null,
  });
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getPolicyService();
      //console.log(rowFiltereddata);
      updateIndexValue(rowFiltereddata.indexOf(UID));
    }
    return () => {
      isSubscribed = false;
    };
  }, []);
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getPolicyService();
    }
    return () => {
      isSubscribed = false;
    };
  }, [index]);

  const Remove = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this  details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemovePolicyService.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    navigate(-1);
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };
  const Restore = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to restore this details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RestorePolicyService.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    getPolicyService();
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  onClose();
                }
              }}
            >
              Yes, Restore it!
            </button>
          </div>
        );
      },
    });
  };
  const getPolicyService = async () => {
    try {
      const responce = await axiosPrivate.post("GetPolicyServiceByID.php", {
        UID,
      });
      const result = responce.data;
      if (result.status === 1) {
        const newData = {
          ...result.Data[0],
          Caste:
            result.Data[0].Caste === ""
              ? null
              : { value: result.Data[0].Caste, title: result.Data[0].Caste },
          Village:
            result.Data[0].Village === ""
              ? null
              : {
                  value: result.Data[0].Village,
                  title: result.Data[0].Village,
                },
          Tehsil:
            result.Data[0].Tehsil === ""
              ? null
              : {
                  value: result.Data[0].Tehsil,
                  title: result.Data[0].Tehsil,
                },
          District:
            result.Data[0].District === ""
              ? null
              : {
                  value: result.Data[0].District,
                  title: result.Data[0].District,
                },
          State:
            result.Data[0].State === ""
              ? null
              : { value: result.Data[0].State, title: result.Data[0].State },
          Branch:
            result.Data[0].Branch === ""
              ? null
              : { value: result.Data[0].Branch, title: result.Data[0].Branch },

          RefBy:
            result.Data[0].RefBy === ""
              ? null
              : {
                  value: result.Data[0].RefBy,
                  title: result.Data[0].RefBy,
                },
          FamilyMemberID: {
            value: result.Data[0].FamilyMemberID,
            title: result.Data[0].FamilyMember,
          },
          ReferenceID: {
            value: result.Data[0].ReferenceID,
            title: result.Data[0].Reference,
          },

          PolicyNo: {
            title: result.Data[0].PolicyNo,
            value: result.Data[0].PolicyNoID,
          },
          ServiceWork: {
            title: result.Data[0].ServiceWork,
            value: result.Data[0].ServiceWork,
          },

          EnquiryDate:
            result.Data[0].Date === "0000-00-00" || result.Data[0].Date === null
              ? null
              : new Date(result.Data[0].EnquiryDate),
          ServiceDate:
            result.Data[0].ServiceDate === "0000-00-00" ||
            result.Data[0].ServiceDate === null
              ? null
              : new Date(result.Data[0].ServiceDate),
          DOC:
            result.Data[0].Date === "0000-00-00" || result.Data[0].Date === null
              ? null
              : new Date(result.Data[0].DOC),
          FUP:
            result.Data[0].Date === "0000-00-00" || result.Data[0].Date === null
              ? null
              : new Date(result.Data[0].FUP),
          FormSubmitDate:
            result.Data[0].Date === "0000-00-00" || result.Data[0].Date === null
              ? null
              : new Date(result.Data[0].FormSubmitDate),
        };
        setInitialValues(newData);

        setFileInfo(
          `https://backend.dahiyainsurance.com/Upload/profile/${result.Data[0].Picture}`
        );
        setDocs(result.Docs);
        setData({ ...data, ...result.Data[0] });
        setIsDeleted(result.Data[0].Deleted);
      }
      if (result.status === 0) {
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Error!</strong> {result?.Error}
          </Message>
        );
      }
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  };

  function handleMobileCheck(e) {
    if (e.target.value === "") {
      return;
    } else {
      try {
        axiosPrivate
          .post("/CheckIfMobileNoExist.php", { Mobile: e.target.value })
          .then((response) => {
            const result = response.data;
            if (result.status === 1) {
              if (result.App.length > 0) {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="custom-ui">
                        <h1>{`Are you sure?`}</h1>
                        <p>{`This Mobile no already registered with ${result.App.length} account(s) - ${result.Data[0].SrNo}. Continue?`}</p>
                        <button onClick={onClose}>Continue</button>
                        <button
                          onClick={() => {
                            onClose();
                          }}
                        >
                          No
                        </button>
                      </div>
                    );
                  },
                });
                /*                 toaster.push(
                  <Message
                    showIcon
                    header="Are you sure?"
                    type="warning"
                    closable
                  >
                    <p>
                      {" "}
                      {`This Mobile no already registered with ${result.App.length} account(s) - ${result.Data[0].SrNo}. Continue?`}
                    </p>
                    <hr />
                    <ButtonToolbar>
                      <Button size="sm">Don't allow</Button>
                      <Button size="sm">Allow</Button>
                    </ButtonToolbar>
                  </Message>
                ); */
              }
            }
            if (result.status === 0) {
            }
          })
          .catch((err) => {
            console.error(err);
          });

        //navigate("/login", { state: { from: location }, replace: true });
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    }
  }

  function GetFamilyDetails(FamilyHeadID) {
    axiosPrivate
      .post("GetRelatedFamilyList.php", { UID: FamilyHeadID })
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          setFamilyList(result.Data);
        }
        if (result.status === 0) {
          setFamilyList([{ value: "", title: "" }]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleAttachment() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`Are you done with attaching documents?`}</p>
            <button
              onClick={() => {
                dispatch({ type: "DOC_ADDED" });
                onClose();
                setFileList1([]);
              }}
            >
              Yes
            </button>
            <button onClick={onClose}>No</button>
          </div>
        );
      },
    });
  }
  function handleRemoveDoc(ID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this document.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemovePolicyDoc.php",
                    datatosend
                  );
                  const result = response.data;
                  onClose();
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    const newDocs = docs.filter(function (item) {
                      return item.UniqueID !== UID;
                    });
                    setDocs(newDocs);
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }

  const RemovePermanently = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this details for forever.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemovePolicyServicePermanently.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Heading
            level={5}
            style={{
              textAlign: "center",
              flex: 0.9,
            }}
          >
            {`${state.EditMode ? "Edit" : "View"} Policy Service`}
          </Heading>
          <Stack direction="row" spacing={2} style={{ flex: 0.1 }}>
            {(permissions.includes(DELETE_POLICY) ||
              user.Type === "SuperAdmin") && (
              <>
                {isDeleted === "0" && (
                  <Btn
                    color="red"
                    appearance="link"
                    disabled={
                      !(
                        permissions.includes(DELETE_POLICYSERVICE) ||
                        user.Type === "SuperAdmin"
                      )
                    }
                    onClick={Remove}
                    sx={{ my: 2 }}
                    startIcon={<Delete />}
                  >
                    {"Delete"}
                  </Btn>
                )}
                {isDeleted === "1" && (
                  <>
                    <Btn
                      color="green"
                      appearance="link"
                      onClick={Restore}
                      sx={{ my: 2 }}
                      startIcon={<RestoreFromTrash />}
                      disabled={
                        !(
                          permissions.includes(DELETE_POLICYSERVICE) ||
                          user.Type === "SuperAdmin"
                        )
                      }
                    >
                      {"Restore"}
                    </Btn>
                    <Btn
                      color="red"
                      appearance="link"
                      onClick={RemovePermanently}
                      sx={{ my: 2 }}
                      startIcon={<DeleteForever />}
                      disabled={
                        !(
                          permissions.includes(DELETE_POLICYSERVICE) ||
                          user.Type === "SuperAdmin"
                        )
                      }
                    >
                      {"Delete Permanentaly"}
                    </Btn>
                  </>
                )}
                <Btn
                  color="black"
                  appearance="link"
                  onClick={() => {
                    navigate("/Services/PolicyServices");
                  }}
                  sx={{ my: 2 }}
                  startIcon={<ListAlt />}
                  disabled={
                    !(
                      permissions.includes(VIEW_POLICYSERVICE) ||
                      user.Type === "SuperAdmin"
                    )
                  }
                >
                  {"List"}
                </Btn>
              </>
            )}
          </Stack>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <Grid fluid style={{ padding: 10 }}>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={16}>
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                  // alert("Work in progress");
                  axiosPrivate
                    .post("EditPolicyService.php", {
                      ...values,
                      ReferenceID: values.ReferenceID.value,
                      FamilyMemberID: values.FamilyMemberID.value,
                      Caste: values.Caste?.value,
                      Village: values.Village?.value,
                      Tehsil: values.Tehsil?.value,
                      District: values.District?.value,
                      Branch: values.Branch?.value,
                      State: values.State?.value,
                      PolicyNoID: values.PolicyNo?.value,
                      PolicyNo: values.PolicyNo?.title,
                      ServiceWork: values.ServiceWork?.value,
                      EnquiryDate: values?.EnquiryDate
                        ? moment(values.EnquiryDate).format("YYYY-MM-DD")
                        : null,

                      DOC: values?.DOC
                        ? moment(values.DOC).format("YYYY-MM-DD")
                        : null,
                      ServiceDate: values?.ServiceDate
                        ? moment(values.ServiceDate).format("YYYY-MM-DD")
                        : null,
                      FUP: values?.FUP
                        ? moment(values.FUP).format("YYYY-MM-DD")
                        : null,
                      FormSubmitDate: values?.FormSubmitDate
                        ? moment(values.FormSubmitDate).format("YYYY-MM-DD")
                        : null,
                    })
                    .then((response) => {
                      const result = response.data;
                      if (result.status === 1) {
                        console.log(result);
                        toaster.push(
                          <Message type="success" bordered showIcon>
                            <strong>Success!</strong> {result?.Success}
                          </Message>
                        );
                        dispatch({ type: "ATTACH" });
                      }
                      if (result.status === 0) {
                        toaster.push(
                          <Message type="error" bordered showIcon>
                            <strong>Error!</strong> {result.Error}
                          </Message>
                        );
                      }
                      setSubmitting(false);
                      // resetForm();
                    })
                    .catch((error) => {
                      console.error("Error submitting data:", error);
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  errors,
                  isSubmitting,
                  isValidating,
                  setErrors,
                  setTouched,
                }) => (
                  <form className="target">
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading
                        level={6}
                        style={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Resources Details
                      </Heading>
                    </Row>
                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={2}>
                        <Field
                          name="ReferenceID"
                          // readOnly={!state.EditMode}
                          readOnly={!state.EditMode}
                          component={Autocomplete}
                          key="ID"
                          options={
                            ReferenceList?.error
                              ? [{ value: "", title: "" }]
                              : ReferenceList.data
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 300 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "ReferenceID",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                            GetFamilyDetails(value.value);
                            setFieldValue("FamilyMemberID", {
                              value: "",
                              title: "",
                            });
                            setFieldValue("AgencyCode", "");
                            setFieldValue("SumAssured", null);

                            setFieldValue("DOC", null);
                            setFieldValue("FUP", null);
                          }}
                          value={
                            Boolean(values.ReferenceID)
                              ? values.ReferenceID
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="ReferenceID"
                              label="ReferenceID"
                              variant="standard"
                              error={
                                touched.ReferenceID &&
                                Boolean(errors.ReferenceID)
                              }
                              helperText={
                                touched.ReferenceID && errors.ReferenceID
                              }
                            />
                          )}
                        />
                        &nbsp;
                        {values?.ReferenceID?.value && (
                          <Button
                            appearance="link"
                            onClick={() => {
                              console.log(values);
                              navigate(
                                "/Prospects/ViewProspect/" +
                                  values?.ReferenceID?.value
                              );
                            }}
                          >
                            <RemoveRedEye />
                          </Button>
                        )}
                        &nbsp; &nbsp;
                        <Field
                          name="FamilyMemberID"
                          readOnly={!state.EditMode}
                          component={Autocomplete}
                          key="ID"
                          options={
                            familyList?.length > 0
                              ? [
                                  ...familyList,
                                  { value: "Other", title: "Other" },
                                ]
                              : [{ value: "", title: "" }]
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={async (_, value) => {
                            setFieldValue(
                              "FamilyMemberID",
                              value || { value: "", title: "" }
                            );
                            setFieldValue("PolicyNo", { value: "", title: "" });
                            setFieldValue("AgencyCode", "");
                            setFieldValue("SumAssured", null);

                            setFieldValue("DOC", null);
                            setFieldValue("FUP", null);
                            console.log(values);
                            try {
                              const responce = await axiosPrivate.post(
                                "GetFamilyPolicyByID.php",
                                {
                                  UID: value.value,
                                  RefID: values.ReferenceID.value,
                                }
                              );

                              const result = responce.data;
                              if (result.status === 1) {
                                if (result.Data.length > 0) {
                                  const newData = result.Data.map((d) => {
                                    return {
                                      value: d.UniqueID,
                                      title: d.PolicyNo,
                                      ID: d.UniqueID,
                                    };
                                  });
                                  setPolicyList(newData);
                                  setPolicyDataList(result.Data);
                                  const RefData = result.REF[0];
                                  setFieldValue("Caste", {
                                    value: RefData.Caste,
                                    title: RefData.Caste,
                                  });
                                  setFieldValue("Village", {
                                    value: RefData.Village,
                                    title: RefData.Village,
                                  });
                                  setFieldValue("Tehsil", {
                                    value: RefData.Tehsil,
                                    title: RefData.Tehsil,
                                  });
                                  setFieldValue("District", {
                                    value: RefData.District,
                                    title: RefData.District,
                                  });
                                  setFieldValue("State", {
                                    value: RefData.State,
                                    title: RefData.State,
                                  });
                                  setFieldValue("Address", RefData.Address);
                                  setFieldValue("MobileNo", RefData.WhatsAppNo);
                                  setFieldValue("PIN", RefData.PIN);
                                  setFieldValue("SumAssured", "");
                                  setFieldValue(
                                    "VMProID",
                                    result.Data[0].VIMPROID
                                  );
                                } else {
                                  setPolicyList([]);
                                  const RefData = result.REF[0];
                                  setFieldValue("Caste", {
                                    value: RefData.Caste,
                                    title: RefData.Caste,
                                  });
                                  setFieldValue("Village", {
                                    value: RefData.Village,
                                    title: RefData.Village,
                                  });
                                  setFieldValue("Tehsil", {
                                    value: RefData.Tehsil,
                                    title: RefData.Tehsil,
                                  });
                                  setFieldValue("District", {
                                    value: RefData.District,
                                    title: RefData.District,
                                  });
                                  setFieldValue("State", {
                                    value: RefData.State,
                                    title: RefData.State,
                                  });
                                  setFieldValue("Address", RefData.Address);
                                  setFieldValue("MobileNo", RefData.WhatsAppNo);
                                  setFieldValue("PIN", RefData.PIN);
                                  setFieldValue("VMProID", "");
                                  setFieldValue("SumAssured", "");
                                }
                              }
                              if (result.status === 0) {
                                toaster.push(
                                  <Message type="error" bordered showIcon>
                                    <strong>Error!</strong> No Policy found
                                  </Message>
                                );
                                setPolicyList([]);
                              }
                            } catch (error) {
                              console.log(error);
                              toaster.push(
                                <Message type="error" bordered showIcon>
                                  <strong>Error!</strong> Error occured
                                </Message>
                              );
                              setPolicyList([]);
                            }
                          }}
                          value={
                            Boolean(values.FamilyMemberID)
                              ? values.FamilyMemberID
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="FamilyMemberID"
                              label="Family Member"
                              variant="standard"
                              error={
                                touched.FamilyMemberID &&
                                Boolean(errors.FamilyMemberID)
                              }
                              helperText={
                                touched.FamilyMemberID && errors.FamilyMemberID
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <Row
                      gutter={1}
                      className="show-grid"
                      style={{ marginTop: 10 }}
                    >
                      <Stack direction="row" spacing={3}>
                        <TextField
                          label="VIM Pro ID"
                          size="small"
                          variant="standard"
                          name="VMProID"
                          value={values.VMProID}
                          InputLabelProps={{
                            shrink: Boolean(values.VMProID),
                          }}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.VMProID && Boolean(errors.VMProID)}
                          helperText={touched.VMProID && errors.VMProID}
                          style={{ width: 100 }}
                        />{" "}
                        &nbsp;&nbsp;
                        <Field
                          readOnly={!state.EditMode}
                          name="Caste"
                          component={Autocomplete}
                          key="ID"
                          autoComplete={true}
                          autoHighlight={true}
                          options={CasteList.error ? [null] : CasteList.data}
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 150 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "Caste",
                              value || { value: "", title: "" }
                            );
                            console.log(errors);
                            console.log(values);
                          }}
                          value={
                            Boolean(values.Caste)
                              ? values.Caste
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Caste"
                              label="Caste"
                              variant="standard"
                              error={touched.Caste && Boolean(errors.Caste)}
                              helperText={touched.Caste && errors.Caste}
                            />
                          )}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            readOnly={!state.EditMode}
                            label="Date of Enquiry"
                            variant="standard"
                            size="small"
                            name="EnquiryDate"
                            format="dd-MM-yyyy"
                            value={values.EnquiryDate}
                            onChange={(value) =>
                              setFieldValue("EnquiryDate", value, true)
                            }
                            onBlur={handleBlur}
                            error={
                              touched.EnquiryDate && Boolean(errors.EnquiryDate)
                            }
                            helperText={
                              touched.EnquiryDate && errors.EnquiryDate
                            }
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Row>

                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Contact details</Heading>
                    </Row>
                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={2}>
                        <TextField
                          readOnly={!state.EditMode}
                          label="Address"
                          size="small"
                          variant="standard"
                          style={{ width: 300 }}
                          name="Address"
                          InputLabelProps={{
                            shrink: Boolean(values.Address),
                          }}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Address}
                          error={touched.Address && Boolean(errors.Address)}
                          helperText={touched.Address && errors.Address}
                        />{" "}
                        &nbsp;&nbsp;
                        <TextField
                          readOnly={!state.EditMode}
                          style={{ width: 100 }}
                          label="PIN Code"
                          size="small"
                          variant="standard"
                          autoCapitalize={true}
                          InputLabelProps={{
                            shrink: Boolean(values.PIN),
                          }}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          onBlur={handleBlur}
                          name="PIN"
                          value={values.PIN}
                          onChange={(e) => {
                            const pin = e.target.value;

                            setFieldValue("PIN", pin, true);
                            if (pin.length === 6) {
                              console.log(pin.length);
                              try {
                                axiosPrivate
                                  .post("/CheckPINForCustomer.php", {
                                    PIN: pin,
                                  })
                                  .then((response) => {
                                    const result = response.data;
                                    if (result.status === 1) {
                                      if (result.App.length > 0) {
                                        setFieldValue(
                                          "Tehsil",
                                          {
                                            value: result.Data[0].Tehsil,
                                            title: result.Data[0].Tehsil,
                                          },
                                          true
                                        );
                                        setFieldValue(
                                          "District",
                                          {
                                            value: result.Data[0].District,
                                            title: result.Data[0].District,
                                          },
                                          true
                                        );
                                        setFieldValue(
                                          "State",
                                          {
                                            value: result.Data[0].State,
                                            title: result.Data[0].State,
                                          },
                                          true
                                        );
                                      }
                                    }
                                    if (result.status === 0) {
                                      setFieldValue(
                                        "Tehsil",
                                        {
                                          value: "",
                                          title: "",
                                        },
                                        true
                                      );
                                      setFieldValue(
                                        "District",
                                        {
                                          value: "",
                                          title: "",
                                        },
                                        true
                                      );
                                      setFieldValue(
                                        "State",
                                        {
                                          value: "",
                                          title: "",
                                        },
                                        true
                                      );
                                    }
                                  })
                                  .catch((err) => {
                                    console.error(err);
                                  });

                                //navigate("/login", { state: { from: location }, replace: true });
                              } catch (err) {
                                console.error(err);
                                //navigate("/login", { state: { from: location }, replace: true });
                              }
                            }
                          }}
                          error={touched.PIN && Boolean(errors.PIN)}
                          helperText={touched.PIN && errors.PIN}
                        />
                        &nbsp;&nbsp;
                        <Field
                          readOnly={!state.EditMode}
                          name="Village"
                          component={Autocomplete}
                          key="ID"
                          options={
                            VillageList.error ? [null] : VillageList.data
                          }
                          autoComplete={true}
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "Village",
                              value || { value: "", title: "" }
                            );

                            try {
                              axiosPrivate
                                .post("/CheckVillageForCustomer.php", {
                                  village: value.value,
                                })
                                .then((response) => {
                                  const result = response.data;
                                  if (result.status === 1) {
                                    if (result.App.length > 0) {
                                      setFieldValue(
                                        "Tehsil",
                                        {
                                          value: result.Data[0].Tehsil,
                                          title: result.Data[0].Tehsil,
                                        },
                                        true
                                      );
                                      setFieldValue(
                                        "District",
                                        {
                                          value: result.Data[0].District,
                                          title: result.Data[0].District,
                                        },
                                        true
                                      );
                                      setFieldValue(
                                        "State",
                                        {
                                          value: result.Data[0].State,
                                          title: result.Data[0].State,
                                        },
                                        true
                                      );
                                      setFieldValue(
                                        "PIN",
                                        result.Data[0].PIN,
                                        true
                                      );
                                    }
                                  }
                                  if (result.status === 0) {
                                    setFieldValue(
                                      "Tehsil",
                                      {
                                        value: "",
                                        title: "",
                                      },
                                      true
                                    );
                                    setFieldValue(
                                      "District",
                                      {
                                        value: "",
                                        title: "",
                                      },
                                      true
                                    );
                                    setFieldValue(
                                      "State",
                                      {
                                        value: "",
                                        title: "",
                                      },
                                      true
                                    );
                                    setFieldValue("PIN", "", true);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });

                              //navigate("/login", { state: { from: location }, replace: true });
                            } catch (err) {
                              console.error(err);
                              //navigate("/login", { state: { from: location }, replace: true });
                            }
                          }}
                          value={
                            Boolean(values.Village)
                              ? values.Village
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Village"
                              label="Village/City"
                              variant="standard"
                              error={touched.Village && Boolean(errors.Village)}
                              helperText={touched.Village && errors.Village}
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Field
                          readOnly={!state.EditMode}
                          name="Tehsil"
                          component={Autocomplete}
                          key="ID"
                          options={TehsilList.error ? [null] : TehsilList.data}
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "Tehsil",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                          }}
                          value={
                            Boolean(values?.Tehsil)
                              ? values?.Tehsil
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Tehsil"
                              label="Tehsil"
                              variant="standard"
                              error={touched.Tehsil && Boolean(errors.Tehsil)}
                              helperText={touched.Tehsil && errors.Tehsil}
                            />
                          )}
                        />
                        <Field
                          readOnly={!state.EditMode}
                          name="District"
                          component={Autocomplete}
                          key="ID"
                          options={
                            DistrictList.error ? [null] : DistrictList.data
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur}
                          onChange={(_, value) => {
                            setFieldValue(
                              "District",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                          }}
                          value={
                            Boolean(values.District)
                              ? values.District
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="District"
                              label="District"
                              variant="standard"
                              error={
                                touched.District && Boolean(errors.District)
                              }
                              helperText={touched.District && errors.District}
                            />
                          )}
                        />
                        <Field
                          readOnly={!state.EditMode}
                          name="State"
                          component={Autocomplete}
                          key="ID"
                          options={StateList.error ? [null] : StateList.data}
                          getOptionLabel={(option) => option?.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "State",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                          }}
                          value={
                            Boolean(values.State)
                              ? values.State
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="State"
                              label="State"
                              variant="standard"
                              error={touched.State && Boolean(errors.State)}
                              helperText={touched.State && errors.State}
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={2}>
                        <TextField
                          readOnly={!state.EditMode}
                          label="Mobile No"
                          size="small"
                          variant="standard"
                          name="MobileNo"
                          value={values.MobileNo}
                          InputLabelProps={{
                            shrink: Boolean(values.MobileNo),
                          }}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            //handleMobileCheck(e);
                          }}
                          onChange={handleChange}
                          error={touched.MobileNo && Boolean(errors.MobileNo)}
                          helperText={touched.MobileNo && errors.MobileNo}
                        />{" "}
                      </Stack>
                    </Row>

                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Policy details</Heading>
                    </Row>
                    <Row
                      gutter={1}
                      className="show-grid"
                      style={{ marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={3}>
                        <FormControl variant="standard">
                          <InputLabel id="demo-simple-select-standard-POLICY-HOLDER">
                            POLICY HOLDER
                          </InputLabel>
                          <Select
                            readOnly={!state.EditMode}
                            labelId="demo-simple-select-standard-POLICY-HOLDER"
                            id="demo-simple-select-standard"
                            label="POLICY HOLDER"
                            name="PolicyHolder"
                            style={{ width: 150 }}
                            value={values?.PolicyHolder || ""}
                            onChange={handleChange}
                            error={
                              touched.PolicyHolder &&
                              Boolean(errors.PolicyHolder)
                            }
                            helperText={
                              touched.PolicyHolder && errors.PolicyHolder
                            }
                          >
                            {["SELF", "OTHER"].map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(
                                  name,
                                  ["SELF", "OTHER"],
                                  theme
                                )}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/*                         <TextField
                          readOnly={!state.EditMode}
                          label="Policy No"
                          size="small"
                          variant="standard"
                          name="PolicyNo"
                          InputLabelProps={{
                            shrink: Boolean(values.PolicyNo),
                          }}
                          value={values.PolicyNo}
                          onChange={(e) => {
                            const PolicyNo = e.target.value;

                            setFieldValue("PolicyNo", PolicyNo, true);
                            if (PolicyNo !== "" || !isNaN(PolicyNo)) {
                              try {
                                axiosPrivate
                                  .post("/CheckPolicyNo.php", {
                                    PolicyNo,
                                  })
                                  .then((response) => {
                                    const result = response.data;
                                    if (result.status === 1) {
                                      if (result.App.length > 0) {
                                        setFieldValue(
                                          "Tehsil",
                                          {
                                            value: result.Data[0].Tehsil,
                                            title: result.Data[0].Tehsil,
                                          },
                                          true
                                        );
                                        setFieldValue(
                                          "District",
                                          {
                                            value: result.Data[0].District,
                                            title: result.Data[0].District,
                                          },
                                          true
                                        );
                                        setFieldValue(
                                          "State",
                                          {
                                            value: result.Data[0].State,
                                            title: result.Data[0].State,
                                          },
                                          true
                                        );
                                      }
                                    }
                                    if (result.status === 0) {
                                      setFieldValue(
                                        "Tehsil",
                                        {
                                          value: "",
                                          title: "",
                                        },
                                        true
                                      );
                                      setFieldValue(
                                        "District",
                                        {
                                          value: "",
                                          title: "",
                                        },
                                        true
                                      );
                                      setFieldValue(
                                        "State",
                                        {
                                          value: "",
                                          title: "",
                                        },
                                        true
                                      );
                                    }
                                  })
                                  .catch((err) => {
                                    console.error(err);
                                  });

                                //navigate("/login", { state: { from: location }, replace: true });
                              } catch (err) {
                                console.error(err);
                                //navigate("/login", { state: { from: location }, replace: true });
                              }
                            }
                          }}
                          onBlur={handleBlur}
                          error={touched.PolicyNo && Boolean(errors.PolicyNo)}
                          helperText={touched.PolicyNo && errors.PolicyNo}
                          style={{ width: 120 }}
                        />{" "} */}
                        {/* <Field
                          readOnly={!state.EditMode}
                          name="PolicyNo"
                          component={Autocomplete}
                          key="ID"
                          freeSolo
                          style={{ width: 200 }}
                          options={
                            policyList.length === 0
                              ? [{ value: "", title: "" }]
                              : policyList
                          }
                          getOptionLabel={(option) => option.title || ""}
                          onBlur={handleBlur}
                          onChange={(_, value) => {
                            setFieldValue(
                              "PolicyNo",
                              value || { value: "", title: "" }
                            );
                            const newData = policyDataList.filter((a) => {
                              return a.UniqueID === value.value;
                            });
                            if (newData.length > 0) {
                              //console.log(newData[0]);
                              setPolicyDataList(newData[0]);
                              setFieldValue(
                                "AgencyCode",
                                newData[0].AgencyCode
                              );
                              setFieldValue(
                                "SumAssured",
                                newData[0].SumAssured
                              );
                              //setFieldValue("PPT", newData[0].PayingTerm);
                              setFieldValue(
                                "DOC",
                                newData[0].DOC === "0000-00-00" ||
                                  newData[0].DOC === null
                                  ? null
                                  : new Date(newData[0].DOC)
                              );
                              setFieldValue(
                                "FUP",
                                newData[0].FUP === "0000-00-00" ||
                                  newData[0].FUP === null
                                  ? null
                                  : new Date(newData[0].FUP)
                              );
                              const DOC = moment(
                                newData[0].DOC,
                                "YYYY-MM-DD",
                                true
                              ); // 'true' for strict parsing
                              const FUP = moment(
                                newData[0].FUP,
                                "YYYY-MM-DD",
                                true
                              ); // 'true' for strict parsing

                              // Check if the date is valid
                              if (DOC.isValid() && FUP.isValid()) {
                                const birthdate = moment(newData[0].DOC); // Replace with the actual birthdate

                                // Get the current date
                                const now = moment(newData[0].FUP);

                                // Calculate the difference in years, months, and days
                                const years = now.diff(birthdate, "years");
                                birthdate.add(years, "years"); // Update birthdate to the last birthday

                                const months = now.diff(birthdate, "months");
                                birthdate.add(months, "months"); // Update birthdate to the last birthday month

                                const days = now.diff(birthdate, "days");
                                setFieldValue(
                                  "INFORCE",
                                  `${years} years, ${months} months, and ${days} days`,
                                  true
                                );
                              } else {
                                setFieldValue(
                                  "INFORCE",
                                  `FUP & DOC Date required`,
                                  true
                                );
                              }
                            } else {
                              setPolicyDataList({});
                              setFieldValue("AgencyCode", "");
                              setFieldValue("SumAssured", "");

                              setFieldValue("DOC", "");
                              setFieldValue("FUP", "");
                            }
                          }}
                          value={
                            Boolean(values.PolicyNo)
                              ? values.PolicyNo
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="PolicyNo"
                              label="Policy No"
                              variant="standard"
                              error={
                                touched.PolicyNo && Boolean(errors.PolicyNo)
                              }
                              helperText={touched.PolicyNo && errors.PolicyNo}
                            />
                          )}
                        /> */}
                        <Field
                          readOnly={!state.EditMode}
                          name="PolicyNo"
                          component={Autocomplete}
                          key="ID"
                          freeSolo
                          style={{ width: 200 }}
                          options={
                            policyList.length === 0
                              ? [{ value: "", title: "" }]
                              : policyList
                          }
                          getOptionLabel={(option) => option.title || ""}
                          onBlur={handleBlur}
                          onChange={(_, value) => {
                            console.log(values);
                            if (typeof value === "string") {
                              setFieldValue("PolicyNo", {
                                value: "",
                                title: value,
                              });
                            } else if (value && value?.value) {
                              setFieldValue(
                                "PolicyNo",
                                value || { value: "", title: "" }
                              );
                              const newData = policyDataList.filter((a) => {
                                return a.UniqueID === value.value;
                              });
                              if (newData.length > 0) {
                                setPolicyDataList(newData[0]);
                                setFieldValue(
                                  "AgencyCode",
                                  newData[0].AgencyCode
                                );
                                setFieldValue(
                                  "SumAssured",
                                  newData[0].SumAssured
                                );
                                //setFieldValue("PPT", newData[0].PayingTerm);
                                setFieldValue(
                                  "DOC",
                                  newData[0].DOC === "0000-00-00" ||
                                    newData[0].DOC === null
                                    ? null
                                    : new Date(newData[0].DOC)
                                );
                                setFieldValue(
                                  "FUP",
                                  newData[0].FUP === "0000-00-00" ||
                                    newData[0].FUP === null
                                    ? null
                                    : new Date(newData[0].FUP)
                                );
                                const DOC = moment(
                                  newData[0].DOC,
                                  "YYYY-MM-DD",
                                  true
                                ); // 'true' for strict parsing
                                const FUP = moment(
                                  newData[0].FUP,
                                  "YYYY-MM-DD",
                                  true
                                ); // 'true' for strict parsing

                                // Check if the date is valid
                                if (DOC.isValid() && FUP.isValid()) {
                                  const birthdate = moment(newData[0].DOC); // Replace with the actual birthdate

                                  // Get the current date
                                  const now = moment(newData[0].FUP);

                                  // Calculate the difference in years, months, and days
                                  const years = now.diff(birthdate, "years");
                                  birthdate.add(years, "years"); // Update birthdate to the last birthday

                                  const months = now.diff(birthdate, "months");
                                  birthdate.add(months, "months"); // Update birthdate to the last birthday month

                                  const days = now.diff(birthdate, "days");
                                  setFieldValue(
                                    "INFORCE",
                                    `${years} years, ${months} months, and ${days} days`,
                                    true
                                  );
                                } else {
                                  setFieldValue(
                                    "INFORCE",
                                    `FUP & DOC Date required`,
                                    true
                                  );
                                }
                              } else {
                                setPolicyDataList({});
                                setFieldValue("AgencyCode", "");
                                setFieldValue("SumAssured", "");

                                setFieldValue("DOC", null);
                                setFieldValue("FUP", null);
                              }
                            } else {
                              setFieldValue(
                                "PolicyNo",
                                value || { value: "", title: "" }
                              );
                            }
                          }}
                          value={
                            Boolean(values.PolicyNo)
                              ? values.PolicyNo
                              : { value: "", title: "" }
                          }
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            console.log(params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                              (option) => inputValue === option.title
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                value: inputValue,
                                title: inputValue,
                              });
                            }

                            return filtered;
                          }}
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="PolicyNo"
                              label="Policy No"
                              variant="standard"
                              error={
                                touched.PolicyNo && Boolean(errors.PolicyNo)
                              }
                              helperText={touched.PolicyNo && errors.PolicyNo}
                            />
                          )}
                        />
                        &nbsp; &nbsp;
                        <TextField
                          readOnly={!state.EditMode}
                          label="Agency Code"
                          size="small"
                          variant="standard"
                          name="AgencyCode"
                          InputLabelProps={{
                            shrink: Boolean(values.AgencyCode),
                          }}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          value={values.AgencyCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.AgencyCode && Boolean(errors.AgencyCode)
                          }
                          helperText={touched.AgencyCode && errors.AgencyCode}
                          style={{ width: 120 }}
                        />{" "}
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Other details</Heading>
                    </Row>
                    <Row
                      gutter={1}
                      style={{
                        marginTop: 20,
                        marginBottom: 10,
                        alignItems: "flex-end",
                        display: "flex",
                      }}
                    >
                      <Stack direction="row" spacing={3}>
                        <Field
                          readOnly={!state.EditMode}
                          name="ServiceWork"
                          component={Autocomplete}
                          key="ID"
                          style={{ width: 200 }}
                          options={
                            ServiceWorkList?.error
                              ? [{ value: "", title: "" }]
                              : ServiceWorkList.data
                          }
                          getOptionLabel={(option) => option.title || ""}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "ServiceWork",
                              value || { value: "", title: "" }
                            );
                          }}
                          value={
                            Boolean(values.ServiceWork)
                              ? values.ServiceWork
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="ServiceWork"
                              label="Service Type"
                              variant="standard"
                              error={
                                touched.ServiceWork &&
                                Boolean(errors.ServiceWork)
                              }
                              helperText={
                                touched.ServiceWork && errors.ServiceWork
                              }
                            />
                          )}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            readOnly={!state.EditMode}
                            label="Service Date"
                            variant="standard"
                            size="small"
                            name="ServiceDate"
                            format="dd-MM-yyyy"
                            InputLabelProps={{
                              shrink: Boolean(values.ServiceDate),
                            }}
                            value={values.ServiceDate}
                            onChange={(value) => {
                              setFieldValue("ServiceDate", value, true);
                            }}
                            onBlur={handleBlur}
                            error={
                              touched.ServiceDate && Boolean(errors.ServiceDate)
                            }
                            helperText={
                              touched.ServiceDate && errors.ServiceDate
                            }
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Row>
                    <Row
                      gutter={1}
                      style={{
                        marginTop: 20,
                        marginBottom: 10,
                        alignItems: "flex-end",
                        display: "flex",
                      }}
                    >
                      <Stack direction="row" spacing={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            readOnly={!state.EditMode}
                            label="DOC"
                            variant="standard"
                            size="small"
                            name="DOC"
                            format="dd-MM-yyyy"
                            InputLabelProps={{
                              shrink: Boolean(values.DOC),
                            }}
                            value={values.DOC}
                            onChange={(value) => {
                              setFieldValue("DOC", value, true);
                              const birthdate = moment(value); // Replace with the actual birthdate

                              // Get the current date
                              const now = moment(values.FUP);

                              // Calculate the difference in years, months, and days
                              const years = now.diff(birthdate, "years");
                              birthdate.add(years, "years"); // Update birthdate to the last birthday

                              const months = now.diff(birthdate, "months");
                              birthdate.add(months, "months"); // Update birthdate to the last birthday month

                              const days = now.diff(birthdate, "days");
                              setFieldValue(
                                "INFORCE",
                                `${years} years, ${months} months, and ${days} days`,
                                true
                              );
                            }}
                            onBlur={handleBlur}
                            error={touched.DOC && Boolean(errors.DOC)}
                            helperText={touched.DOC && errors.DOC}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            readOnly={!state.EditMode}
                            label="FUP"
                            variant="standard"
                            size="small"
                            name="FUP"
                            format="dd-MM-yyyy"
                            InputLabelProps={{
                              shrink: Boolean(values.FUP),
                            }}
                            value={values.FUP}
                            onChange={(value) => {
                              setFieldValue("FUP", value, true);
                              const birthdate = moment(values.DOC); // Replace with the actual birthdate

                              // Get the current date
                              const now = moment(value);

                              // Calculate the difference in years, months, and days
                              const years = now.diff(birthdate, "years");
                              birthdate.add(years, "years"); // Update birthdate to the last birthday

                              const months = now.diff(birthdate, "months");
                              birthdate.add(months, "months"); // Update birthdate to the last birthday month

                              const days = now.diff(birthdate, "days");
                              setFieldValue(
                                "INFORCE",
                                `${years} years, ${months} months, and ${days} days`,
                                true
                              );
                            }}
                            onBlur={handleBlur}
                            error={touched.FUP && Boolean(errors.FUP)}
                            helperText={touched.FUP && errors.FUP}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Row>
                    <Row
                      gutter={1}
                      style={{
                        marginTop: 20,
                        marginBottom: 10,
                        alignItems: "flex-end",
                        display: "flex",
                      }}
                    >
                      <Stack direction="row" spacing={3}>
                        <TextField
                          readOnly={!state.EditMode}
                          label="Inforce"
                          size="small"
                          variant="standard"
                          name="INFORCE"
                          InputLabelProps={{
                            shrink: Boolean(values.INFORCE),
                          }}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          value={values.INFORCE}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.INFORCE && Boolean(errors.INFORCE)}
                          helperText={touched.INFORCE && errors.INFORCE}
                          style={{ width: 220 }}
                        />{" "}
                        <TextField
                          readOnly={!state.EditMode}
                          label="P. P. T."
                          size="small"
                          variant="standard"
                          name="PPT"
                          InputLabelProps={{
                            shrink: Boolean(values.PPT),
                          }}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          value={values.PPT}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.PPT && Boolean(errors.PPT)}
                          helperText={touched.PPT && errors.PPT}
                          style={{ width: 120 }}
                        />{" "}
                      </Stack>
                    </Row>
                    <Row
                      gutter={1}
                      style={{
                        marginTop: 20,
                        marginBottom: 10,
                        alignItems: "flex-end",
                        display: "flex",
                      }}
                    >
                      <Stack
                        direction="row"
                        spacing={2}
                        style={{ alignItems: "flex-end" }}
                      >
                        <TextField
                          readOnly={!state.EditMode}
                          label="Sum Assured"
                          size="small"
                          variant="standard"
                          name="SumAssured"
                          InputLabelProps={{
                            shrink: Boolean(values.SumAssured),
                          }}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          value={values.SumAssured}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.SumAssured && Boolean(errors.SumAssured)
                          }
                          helperText={touched.SumAssured && errors.SumAssured}
                          style={{ width: 120 }}
                        />{" "}
                        <label>{number2text(values.SumAssured)}</label>
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Other details</Heading>
                    </Row>
                    <Row
                      gutter={1}
                      style={{
                        marginTop: 20,
                        marginBottom: 10,
                        alignItems: "flex-end",
                        display: "flex",
                      }}
                    >
                      <Stack direction="row" spacing={2}>
                        <TextField
                          readOnly={!state.EditMode}
                          label="Quotation Amount"
                          size="small"
                          variant="standard"
                          name="MaturityAmount"
                          InputLabelProps={{
                            shrink: Boolean(values.MaturityAmount),
                          }}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          value={values.MaturityAmount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.MaturityAmount &&
                            Boolean(errors.MaturityAmount)
                          }
                          helperText={
                            touched.MaturityAmount && errors.MaturityAmount
                          }
                          style={{ width: 220 }}
                        />{" "}
                        &nbsp; &nbsp;
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            readOnly={!state.EditMode}
                            label="Form Submit Date"
                            variant="standard"
                            size="small"
                            name="FormSubmitDate"
                            format="dd-MM-yyyy"
                            value={values.FormSubmitDate}
                            onChange={(value) =>
                              setFieldValue("FormSubmitDate", value, true)
                            }
                            onBlur={handleBlur}
                            error={
                              touched.FormSubmitDate &&
                              Boolean(errors.FormSubmitDate)
                            }
                            helperText={
                              touched.FormSubmitDate && errors.FormSubmitDate
                            }
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Row>
                    <Row
                      gutter={1}
                      style={{
                        marginTop: 20,
                        marginBottom: 10,
                        alignItems: "flex-end",
                        display: "flex",
                      }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Field
                          readOnly={!state.EditMode}
                          name="Branch"
                          component={Autocomplete}
                          key="ID"
                          autoComplete={true}
                          autoHighlight={true}
                          options={BranchList.error ? [null] : BranchList.data}
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 150 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "Branch",
                              value || { value: "", title: "" }
                            );
                            console.log(errors);
                            console.log(values);
                          }}
                          value={
                            Boolean(values.Branch)
                              ? values.Branch
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Branch"
                              label="Branch"
                              variant="standard"
                              error={touched.Branch && Boolean(errors.Branch)}
                              helperText={touched.Branch && errors.Branch}
                            />
                          )}
                        />
                        &nbsp; &nbsp;
                        <TextField
                          readOnly={!state.EditMode}
                          label="Medium - By"
                          size="small"
                          variant="standard"
                          name="Medium"
                          InputLabelProps={{
                            shrink: Boolean(values.Medium),
                          }}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          value={values.Medium}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.Medium && Boolean(errors.Medium)}
                          helperText={touched.Medium && errors.Medium}
                          style={{ width: 120 }}
                        />{" "}
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-standard-label">
                            Status
                          </InputLabel>
                          <Select
                            readOnly={!state.EditMode}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="Status"
                            name="Status"
                            value={values?.Status || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.Status && Boolean(errors.Status)}
                            helperText={touched.Status && errors.Status}
                          >
                            {Status.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, Status, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                    </Row>
                    <Row
                      gutter={1}
                      style={{
                        marginTop: 20,
                        marginBottom: 10,
                        alignItems: "flex-end",
                        display: "flex",
                      }}
                    >
                      <TextField
                        label="Other Details"
                        size="small"
                        variant="standard"
                        readOnly={!state.EditMode}
                        name="Other"
                        multiline={true}
                        maxRows={4}
                        style={{ width: 300 }}
                        value={values.Other}
                        onChange={handleChange}
                        error={touched.Other && Boolean(errors.Other)}
                        helperText={touched.Other && errors.Other}
                      />{" "}
                    </Row>
                    <Row gutter={1} style={{ marginTop: 10, marginBottom: 10 }}>
                      <Stack direction="row" spacing={4}>
                        {state.EditMode ? (
                          <>
                            <LoadingButton
                              disabled={isSubmitting}
                              type="submit"
                              color="secondary"
                              variant="contained"
                              onClick={handleSubmit}
                              loading={isValidating}
                              sx={{ my: 2 }}
                            >
                              {isSubmitting ? "Submitting..." : "Submit"}
                            </LoadingButton>
                            <Btn
                              color="secondary"
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                setErrors({});
                                setTouched({}, false);

                                dispatch({ type: "DONE" });
                              }}
                              sx={{ my: 2 }}
                            >
                              {"CANCEL"}
                            </Btn>
                          </>
                        ) : (
                          <>
                            <Btn
                              color="secondary"
                              variant="contained"
                              disabled={
                                !(
                                  permissions.includes(EDIT_POLICYSERVICE) ||
                                  user.Type === "SuperAdmin"
                                )
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch({ type: "EDITABLE" });
                              }}
                              sx={{ my: 2 }}
                            >
                              {"Edit"}
                            </Btn>
                            {state.Attachment && (
                              <Btn
                                color="secondary"
                                variant="contained"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleAttachment();
                                  dispatch({ type: "DONE" });
                                }}
                                sx={{ my: 2 }}
                              >
                                {"DONE"}
                              </Btn>
                            )}
                          </>
                        )}
                      </Stack>
                    </Row>
                  </form>
                )}
              </Formik>
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Row className="show-grid">
                {UID && (
                  <Col xs={24} sm={24} md={24}>
                    <Uploader
                      fileListVisible={false}
                      disabled={!state.Attachment}
                      multiple={false}
                      listType="picture"
                      action="https://backend.dahiyainsurance.com/Upload/AddProfilePic.php"
                      withCredentials={true}
                      headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                      data={{ UID, Type: "PolicyService" }}
                      onUpload={(File) => {
                        setUploading(true);
                        previewFile(File.blobFile, (value) => {
                          setFileInfo(value);
                        });
                      }}
                      onSuccess={(response, _) => {
                        setUploading(false);
                        toaster.push(
                          <Message type="success">
                            Uploaded successfully
                          </Message>
                        );
                        console.log(response);
                      }}
                      onError={(e) => {
                        console.log(e);
                        setFileInfo(null);
                        setUploading(false);
                        toaster.push(
                          <Message type="error">Upload failed</Message>
                        );
                      }}
                      onRemove={(file) => {
                        console.log(file);
                      }}
                    >
                      <button style={{ width: 300, height: 300 }}>
                        {uploading && <Loader backdrop center />}
                        {fileInfo ? (
                          <img
                            alt=""
                            src={fileInfo}
                            width="100%"
                            height="100%"
                            style={{ objectFit: "cover" }}
                          />
                        ) : (
                          <AvatarIcon style={{ fontSize: 80 }} />
                        )}
                      </button>
                    </Uploader>

                    <Uploader
                      disabled={!state.Attachment}
                      removable={!state.Attachment}
                      onRemove={(file) => {
                        console.log(file);
                      }}
                      listType="picture-text"
                      fileList={fileList1}
                      multiple={true}
                      data={{ UID, Type: "PolicyService" }}
                      onChange={setFileList1}
                      withCredentials={true}
                      headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                      action="https://backend.dahiyainsurance.com/Upload/UploadDocs.php"
                      renderFileInfo={(file, fileElement) => (
                        <span style={{ width: "100%", fontSize: 14 }}>
                          {file.name}
                        </span>
                      )}
                    />
                  </Col>
                )}
              </Row>
              <Row
                className="show-grid"
                style={{ margin: "10px 0px 10px 0px" }}
              >
                <Col xs={24} sm={24} md={24}>
                  {
                    <ul>
                      {docs.map((data) => (
                        <li key={data.UniqueID}>
                          {/* <Link to={data.UniqueID}>{data.Name}</Link> */}
                          <a
                            rel="noreferrer"
                            href={`https://backend.dahiyainsurance.com/Upload/assets/${data.Path}`}
                            target="_blank"
                            style={{ cursor: "pointer" }}
                            /* onClick={(e) => getDocs(e, data.UniqueID)} */
                          >
                            {data.Name}
                          </a>
                          {(permissions.includes(EDIT_POLICYSERVICE) ||
                            user.Type === "SuperAdmin") && (
                            <a
                              href=""
                              style={{
                                paddingLeft: 5,
                                textDecoration: "none",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleRemoveDoc(data.UniqueID);
                              }}
                            >
                              {state.Attachment && (
                                <DeleteForever style={{ fontSize: 15 }} />
                              )}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  }
                </Col>
              </Row>
              {/*               <Row
                className="show-grid"
                style={{ margin: "10px 0px 10px 0px" }}
              >
                <Col xs={24} sm={24} md={24}>
                  {
                    <ul>
                      {docs.map((data) => (
                        <li key={data.UniqueID}>
                           <Link to={data.UniqueID}>{data.Name}</Link> 
                          <a
                            rel="noreferrer"
                            href={`https://backend.dahiyainsurance.com/Upload/assets/${data.Path}`}
                            target="_blank"
                            style={{ cursor: "pointer" }}
                             onClick={(e) => getDocs(e, data.UniqueID)} 
                          >
                            {data.Name}
                          </a>
                          {(permissions.includes(EDIT_POLICYSERVICE) ||
                            user.Type === "SuperAdmin") && (
                            <a
                              href=""
                              style={{
                                paddingLeft: 5,
                                textDecoration: "none",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleRemoveDoc(data.UniqueID);
                              }}
                            >
                              {state.Attachment && (
                                <DeleteForever style={{ fontSize: 15 }} />
                              )}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  }
                </Col>
              </Row> */}
              {/*               <Row className="show-grid">
                <Col xs={24} sm={24} md={24}>
                  <>
                    {index > 0 && (
                      <Button
                        style={{
                          color: "white",
                          backgroundColor: "#2979ff",
                          margin: 10,
                        }}
                        onClick={goToPrevious}
                        startIcon={<SkipPrevious />}
                      >
                        Previous
                      </Button>
                    )}

                    {index < rowFiltereddata.length - 1 && (
                      <Button
                        style={{
                          color: "white",
                          backgroundColor: "#2979ff",
                          margin: 10,
                        }}
                        onClick={goToNext}
                        startIcon={<SkipNext />}
                      >
                        Next
                      </Button>
                    )}
                  </>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  );
}
