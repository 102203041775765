import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  useTheme,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useReducer } from "react";
import { Formik, Field } from "formik";
import "moment/locale/en-in";
import { Col, Grid, Heading, Row, Stack } from "rsuite";

import { Uploader, Message, Loader, useToaster, Button } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { useApi } from "app/hooks/useApi";
import useAPINew from "app/hooks/useAPINew";
import useAuth from "app/hooks/useAuth";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { AttachFile, Delete } from "@mui/icons-material";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useLocation } from "react-router-dom";

import { Status } from "app/DataSource";
import SideDrawer from "app/components/SideDrawer";
import SideInputs from "../Inputs/SideInputs";
import { ADD_INPUT } from "Permissions";
// TODO: Get tehsil, dist and state from Village name
function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const today = new Date();
today.setHours(0, 0, 0, 0);

const validationSchema = yup.object({
  Place: yup.string().required("Required field"),

  PIN: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits")
    .nullable(true),
  Name: yup.string().required("Name is required"),
  RefBy: yup.object().shape({
    title: yup
      .string("Error")
      .required("You must select one of the valid options"),
  }),
  WhatsAppNo: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number is not valid")
    .required("Phone number is required"),
  MobileNo: yup
    .string()
    .matches(/^\d{10}$/, "Phone number is not valid")
    .nullable(true),

  Prospect: yup.string().required("Add to Prospect is required"),
});

export default function NewReference() {
  const { user, permissions } = useAuth();
  const location = useLocation();
  const [refresh, setRefresh] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const axiosPrivate = useAxiosPrivate();
  const CasteList = useAPINew("GetCasteList.php");
  const VillageList = useAPINew("GetVillageList.php");
  const TehsilList = useAPINew("GetTehsilList.php");
  const DistrictList = useAPINew("GetDistrictList.php");
  const StateList = useAPINew("GetStateList.php");
  const ProfessionList = useAPINew("GetProfessionList.php");
  const WorkTypeList = useAPINew("GetWorkTypeList.php");
  const RefList = useAPINew("GetRefDahiyaMitraList.php");
  const toaster = useToaster();
  //const uploader = React.useRef();
  const [fileList1, setFileList1] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(null);

  const [loading, setLoading] = React.useState(false);
  const [UID, setUID] = React.useState(null);
  const theme = useTheme();

  const initialState = {
    DataAdded: false,
    FamilyAdded: false,
    DocumentAdded: false,
  };
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "New":
        return { ...initialState };
      case "NEW_ENTRY_DONE":
        return { ...initialState, DataAdded: true };
      case "FAMILY_ADDED":
        return { ...initialState, FamilyAdded: true };
      case "DOC_ADDED":
        return { ...initialState, DocumentAdded: true };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  }, initialState);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
    }
    return () => {};
  }, [refresh]);

  /*   const getOfficeList = () => {
    axiosPrivate
      .post("/LoadOfficeList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.((item) => ({
              label: item.Office,
              value: item.Code,
            }));
            // console.log(data);
            setOfficeList(data);
          }
        }
        if (
          response &&
          (response["status"] === 401 || response["status"] === 403)
        ) {
          //console.log(result.Data);
          setOfficeList([]);
        }
      })
      .catch((e) => {
        setOfficeList([]);
      });
  }; */
  const initialValues = React.useMemo(
    () =>
      location.state
        ? {
            ...location.state,
            Caste:
              location.state.Caste === ""
                ? null
                : { value: location.state.Caste, title: location.state.Caste },
            Village:
              location.state.Village === ""
                ? null
                : {
                    value: location.state.Village,
                    title: location.state.Village,
                  },
            Tehsil:
              location.state.Tehsil === ""
                ? null
                : {
                    value: location.state.Tehsil,
                    title: location.state.Tehsil,
                  },
            District:
              location.state.District === ""
                ? null
                : {
                    value: location.state.District,
                    title: location.state.District,
                  },
            State:
              location.state.State === ""
                ? null
                : { value: location.state.State, title: location.state.State },
            Work:
              location.state.Work === ""
                ? null
                : {
                    value: location.state.Work,
                    title: location.state.Work,
                  },

            Date:
              location.state.DiscussionDate === "0000-00-00"
                ? null
                : new Date(location.state.DiscussionDate),

            WhatsAppNo: location.state.WhatsApp,
            MobileNo: location.state.Mobile,
          }
        : {
            Date: new Date(),
            Place: "",
            RefBy: null,
            Name: "",
            Address: "",
            WhatsAppNo: "",
            MobileNo: "",
            Other: "",
            Caste: null,
            Village: null,
            Tehsil: null,
            District: null,
            State: null,
          },
    []
  );
  /*   const [initialValues, setInitialValues] = React.useState(
    location.state || {
      Date: new Date(),
      Place: "",
      RefBy: "",
      Name: "",
      Address: "",
      WhatsAppNo: "",
      MobileNo: "",
      Education: "",
      Other: "",
      Caste: null,
      Village: null,
      Tehsil: null,
      District: null,
      State: null,
    }
  ); */

  function handleAttachment() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`Are you done with attaching documents?`}</p>
            <button
              onClick={() => {
                dispatch({ type: "DOC_ADDED" });
                onClose();
              }}
            >
              Yes
            </button>
            <button onClick={onClose}>No</button>
          </div>
        );
      },
    });
  }

  function handleMobileCheck(e) {
    if (e.target.value === "") {
      return;
    } else {
      try {
        axiosPrivate
          .post("/CheckIfMobileNoExist.php", { Mobile: e.target.value })
          .then((response) => {
            const result = response.data;
            if (result.status === 1) {
              if (result.App.length > 0) {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="custom-ui">
                        <h1>{`Are you sure?`}</h1>
                        <p>{`This Mobile no already registered with ${result.App.length} account(s) - ${result.Data[0].SrNo}. Continue?`}</p>
                        <button onClick={onClose}>Continue</button>
                        <button
                          onClick={() => {
                            onClose();
                          }}
                        >
                          No
                        </button>
                      </div>
                    );
                  },
                });
                /*                 toaster.push(
                  <Message
                    showIcon
                    header="Are you sure?"
                    type="warning"
                    closable
                  >
                    <p>
                      {" "}
                      {`This Mobile no already registered with ${result.App.length} account(s) - ${result.Data[0].SrNo}. Continue?`}
                    </p>
                    <hr />
                    <ButtonToolbar>
                      <Button size="sm">Don't allow</Button>
                      <Button size="sm">Allow</Button>
                    </ButtonToolbar>
                  </Message>
                ); */
              }
            }
            if (result.status === 0) {
            }
          })
          .catch((err) => {
            console.error(err);
          });

        //navigate("/login", { state: { from: location }, replace: true });
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    }
  }

  function checkIfPINNoExists(pin, setValues, values) {
    if (pin === "") {
      console.log(pin);
      return;
    } else {
      try {
        axiosPrivate
          .post("/CheckPINForCustomer.php", { PIN: pin })
          .then((response) => {
            const result = response.data;
            if (result.status === 1) {
              if (result.App.length > 0) {
                setValues({
                  ...values,
                  Tehsil: {
                    value: result.Data[0].Tehsil,
                    title: result.Data[0].Tehsil,
                  },
                  District: {
                    value: result.Data[0].District,
                    title: result.Data[0].District,
                  },
                  State: {
                    value: result.Data[0].State,
                    title: result.Data[0].State,
                  },
                });
              }
            }
            if (result.status === 0) {
            }
          })
          .catch((err) => {
            console.error(err);
          });

        //navigate("/login", { state: { from: location }, replace: true });
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    }
  }

  function checkIfVillageExists(village, setValues, values) {
    if (!village || village === "") {
    } else {
      try {
        axiosPrivate
          .post("/CheckVillageForCustomer.php", { village: village })
          .then((response) => {
            const result = response.data;
            if (result.status === 1) {
              if (result.App.length > 0) {
                setValues({
                  ...values,
                  Tehsil: {
                    value: result.Data[0].Tehsil,
                    title: result.Data[0].Tehsil,
                  },
                  District: {
                    value: result.Data[0].District,
                    title: result.Data[0].District,
                  },
                  State: {
                    value: result.Data[0].State,
                    title: result.Data[0].State,
                  },
                  PIN: {
                    value: result.Data[0].PIN,
                    title: result.Data[0].PIN,
                  },
                });
              }
            }
            if (result.status === 0) {
              setValues({
                ...values,
                Tehsil: {
                  value: "",
                  title: "",
                },
                District: {
                  value: "",
                  title: "",
                },
                State: {
                  value: "",
                  title: "",
                },
                PIN: {
                  value: "",
                  title: "",
                },
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });

        //navigate("/login", { state: { from: location }, replace: true });
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    }
  }

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Heading
            level={5}
            style={{
              textAlign: "center",
              flex: 0.9,
            }}
          >
            Add Reference
          </Heading>
          {(permissions.includes(ADD_INPUT) || user.Type === "SuperAdmin") && (
            <Button
              appearance="link"
              style={{ flex: 0.1 }}
              onClick={() => {
                setOpen(true);
              }}
            >
              {" "}
              Inputs
            </Button>
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <Grid fluid style={{ padding: 10 }}>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={16}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const datatosend = {
                    ...values,
                    Caste: values.Caste?.value,
                    Village: values.Village?.value,
                    Tehsil: values.Tehsil?.value,
                    District: values.District?.value,
                    WorkType: values.WorkType?.value,
                    State: values.State?.value,
                    RefBy: values.RefBy?.value,
                    RefByName:
                      values.RefBy?.value === "OTHER" ||
                      values.RefBy?.value === "CUSTOMER"
                        ? values.RefByName
                        : values.RefBy?.title,

                    Date: moment(values.Date).format("YYYY-MM-DD"),
                    DOB: values?.DOB
                      ? moment(values.DOB).format("YYYY-MM-DD")
                      : null,
                  };
                  console.log(datatosend);
                  axiosPrivate
                    .post("AddReference.php", {
                      ...values,
                      Caste: values.Caste?.value,
                      Village: values.Village?.value,
                      Tehsil: values.Tehsil?.value,
                      District: values.District?.value,
                      WorkType: values.WorkType?.value,
                      State: values.State?.value,
                      RefBy: values.RefBy?.value,
                      RefByName:
                        values.RefBy?.value === "OTHER" ||
                        values.RefBy?.value === "CUSTOMER"
                          ? values.RefByName
                          : values.RefBy?.title,

                      Date: moment(values.Date).format("YYYY-MM-DD"),
                      DOB: values?.DOB
                        ? moment(values.DOB).format("YYYY-MM-DD")
                        : null,
                    })
                    .then((response) => {
                      const result = response.data;
                      if (result.status === 1) {
                        console.log(result);
                        toaster.push(
                          <Message type="success" bordered showIcon>
                            <strong>Success!</strong> {result?.Success}
                          </Message>
                        );
                        dispatch({ type: "NEW_ENTRY_ADDED" });
                        setUID(result.ID);
                      }
                      if (result.status === 0) {
                        toaster.push(
                          <Message type="error" bordered showIcon>
                            <strong>Error!</strong> {result.Error}
                          </Message>
                        );
                      }
                      setSubmitting(false);
                      // resetForm();
                    })
                    .catch((error) => {
                      console.error("Error submitting data:", error);
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  errors,
                  isSubmitting,
                  isValidating,
                  setValues,
                }) => (
                  <form className="target">
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading
                        level={6}
                        style={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Category
                      </Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-standard-label">
                            Status
                          </InputLabel>
                          <Select
                            disabled={UID}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="Status"
                            name="Status"
                            value={values?.Status || ""}
                            onChange={handleChange}
                            error={touched.Status && Boolean(errors.Status)}
                            helperText={touched.Status && errors.Status}
                          >
                            {[...Status, "POLICY SERVICE", "PERSONAL"].map(
                              (name) => (
                                <MenuItem
                                  key={name}
                                  value={name}
                                  style={getStyles(
                                    name,
                                    [...Status, "POLICY SERVICE", "PERSONAL"],
                                    theme
                                  )}
                                >
                                  {name}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            disabled={UID}
                            label="Date Of Birth"
                            variant="standard"
                            size="small"
                            name="DOB"
                            format="dd-MM-yyyy"
                            value={values.DOB}
                            onChange={(value) =>
                              setFieldValue("DOB", value, true)
                            }
                            onBlur={handleBlur}
                            error={touched.DOB && Boolean(errors.DOB)}
                            helperText={touched.DOB && errors.DOB}
                          />
                        </LocalizationProvider>
                        &nbsp; &nbsp;
                        <b>
                          {values.DOB &&
                            `${moment().diff(
                              values.DOB ? new Date(values.DOB) : null,
                              "years"
                            )} Year(s)`}
                        </b>
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Visit / Ref details</Heading>
                    </Row>
                    {/* <br /> */}
                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            style={{ width: 100 }}
                            disabled={UID}
                            label="Date"
                            variant="standard"
                            size="small"
                            name="Date"
                            format="dd-MM-yyyy"
                            value={values.Date}
                            onChange={(value) =>
                              setFieldValue("Date", value, true)
                            }
                            onBlur={handleBlur}
                            error={touched.Date && Boolean(errors.Date)}
                            helperText={touched.Date && errors.Date}
                          />
                        </LocalizationProvider>
                        &nbsp;&nbsp;
                        <TextField
                          style={{ width: 150 }}
                          disabled={UID}
                          label="Place"
                          size="small"
                          variant="standard"
                          name="Place"
                          value={values.Place}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.Place && Boolean(errors.Place)}
                          helperText={touched.Place && errors.Place}
                        />{" "}
                        &nbsp;&nbsp;
                        <Field
                          disabled={UID}
                          name="RefBy"
                          component={Autocomplete}
                          key="ID"
                          autoComplete={true}
                          autoHighlight={true}
                          options={RefList.error ? [null] : RefList.data}
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "RefBy",
                              value || { value: "", title: "" }
                            );
                            console.log(errors);
                            console.log(values);
                          }}
                          value={
                            Boolean(values.RefBy)
                              ? values.RefBy
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="RefBy"
                              label="RefBy"
                              variant="standard"
                              error={touched.RefBy && Boolean(errors.RefBy)}
                              helperText={touched.RefBy && errors.RefBy}
                            />
                          )}
                        />
                        &nbsp;&nbsp;
                        {(values.RefBy?.value === "CUSTOMER" ||
                          values.RefBy?.value === "OTHER") && (
                          <TextField
                            disabled={UID}
                            label={`${
                              values.RefBy?.value === "CUSTOMER"
                                ? "Customer"
                                : "Ref By "
                            } Name`}
                            size="small"
                            variant="standard"
                            onBlur={handleBlur}
                            name="RefByName"
                            value={values.RefByName}
                            onChange={handleChange}
                            error={
                              touched.RefByName && Boolean(errors.RefByName)
                            }
                            helperText={touched.RefByName && errors.RefByName}
                          />
                        )}
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Personal details</Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <TextField
                          disabled={UID}
                          label="Name"
                          size="small"
                          variant="standard"
                          style={{ width: 250 }}
                          name="Name"
                          value={values.Name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.Name && Boolean(errors.Name)}
                          helperText={touched.Name && errors.Name}
                        />{" "}
                        &nbsp;&nbsp;
                        <Field
                          disabled={UID}
                          name="Caste"
                          component={Autocomplete}
                          key="ID"
                          autoComplete={true}
                          autoHighlight={true}
                          options={CasteList.error ? [null] : CasteList.data}
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 150 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "Caste",
                              value || { value: "", title: "" }
                            );
                            console.log(errors);
                            console.log(values);
                          }}
                          value={
                            Boolean(values.Caste)
                              ? values.Caste
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Caste"
                              label="Caste"
                              variant="standard"
                              error={touched.Caste && Boolean(errors.Caste)}
                              helperText={touched.Caste && errors.Caste}
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <FormControl>
                          <FormLabel id="radio-buttons-group-label">
                            Gender
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="radio-buttons-group-label"
                            name="Gender"
                            value={values.Gender}
                            onChange={handleChange}
                            variant="standard"
                          >
                            <FormControlLabel
                              value="Male"
                              control={<Radio disabled={UID} size="small" />}
                              label="Male"
                            />
                            <FormControlLabel
                              value="Female"
                              control={<Radio disabled={UID} size="small" />}
                              label="Female"
                            />
                          </RadioGroup>
                        </FormControl>
                        &nbsp; &nbsp;
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <InputLabel
                            shrink={Boolean(values.MaritalStatus)}
                            id="demo-simple-select-MaritalStatus"
                          >
                            Marital Status
                          </InputLabel>
                          <Select
                            disabled={UID}
                            labelId="demo-simple-select-MaritalStatus"
                            id="simple-select-MaritalStatus"
                            label="Marital Status"
                            name="MaritalStatus"
                            value={values?.MaritalStatus || null}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: Boolean(values.MaritalStatus),
                            }}
                            error={
                              touched.MaritalStatus &&
                              Boolean(errors.MaritalStatus)
                            }
                            helperText={
                              touched.MaritalStatus && errors.MaritalStatus
                            }
                          >
                            <MenuItem value="Single">Single</MenuItem>
                            <MenuItem value="Married">Married</MenuItem>
                            <MenuItem value="Divorced">Divorced</MenuItem>
                            <MenuItem value="Widowed">Widowed</MenuItem>
                          </Select>
                        </FormControl>
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Contact details</Heading>
                    </Row>

                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={6}>
                        <TextField
                          disabled={UID}
                          label="Address"
                          size="small"
                          variant="standard"
                          style={{ width: 300 }}
                          name="Address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Address}
                          error={touched.Address && Boolean(errors.Address)}
                          helperText={touched.Address && errors.Address}
                        />{" "}
                        &nbsp;&nbsp;
                        <TextField
                          disabled={UID}
                          style={{ width: 100 }}
                          label="PIN Code"
                          size="small"
                          variant="standard"
                          autoCapitalize={true}
                          InputLabelProps={{
                            shrink: Boolean(values.PIN),
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            checkIfPINNoExists(e);
                          }}
                          name="PIN"
                          value={values.PIN}
                          onChange={(e) => {
                            const pin = e.target.value;

                            setFieldValue("PIN", pin, true);
                            if (pin.length === 6) {
                              console.log(pin.length);
                              try {
                                axiosPrivate
                                  .post("/CheckPINForCustomer.php", {
                                    PIN: pin,
                                  })
                                  .then((response) => {
                                    const result = response.data;
                                    if (result.status === 1) {
                                      if (result.App.length > 0) {
                                        setFieldValue(
                                          "Tehsil",
                                          {
                                            value: result.Data[0].Tehsil,
                                            title: result.Data[0].Tehsil,
                                          },
                                          true
                                        );
                                        setFieldValue(
                                          "District",
                                          {
                                            value: result.Data[0].District,
                                            title: result.Data[0].District,
                                          },
                                          true
                                        );
                                        setFieldValue(
                                          "State",
                                          {
                                            value: result.Data[0].State,
                                            title: result.Data[0].State,
                                          },
                                          true
                                        );
                                      }
                                    }
                                    if (result.status === 0) {
                                      setFieldValue(
                                        "Tehsil",
                                        {
                                          value: "",
                                          title: "",
                                        },
                                        true
                                      );
                                      setFieldValue(
                                        "District",
                                        {
                                          value: "",
                                          title: "",
                                        },
                                        true
                                      );
                                      setFieldValue(
                                        "State",
                                        {
                                          value: "",
                                          title: "",
                                        },
                                        true
                                      );
                                    }
                                  })
                                  .catch((err) => {
                                    console.error(err);
                                  });

                                //navigate("/login", { state: { from: location }, replace: true });
                              } catch (err) {
                                console.error(err);
                                //navigate("/login", { state: { from: location }, replace: true });
                              }
                            }
                          }}
                          error={touched.PIN && Boolean(errors.PIN)}
                          helperText={touched.PIN && errors.PIN}
                        />
                        &nbsp;&nbsp;
                        <Field
                          disabled={UID}
                          name="Village"
                          component={Autocomplete}
                          key="ID"
                          options={
                            VillageList.error ? [null] : VillageList.data
                          }
                          autoComplete={true}
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "Village",
                              value || { value: "", title: "" }
                            );

                            try {
                              axiosPrivate
                                .post("/CheckVillageForCustomer.php", {
                                  village: value.value,
                                })
                                .then((response) => {
                                  const result = response.data;
                                  if (result.status === 1) {
                                    if (result.App.length > 0) {
                                      setFieldValue(
                                        "Tehsil",
                                        {
                                          value: result.Data[0].Tehsil,
                                          title: result.Data[0].Tehsil,
                                        },
                                        true
                                      );
                                      setFieldValue(
                                        "District",
                                        {
                                          value: result.Data[0].District,
                                          title: result.Data[0].District,
                                        },
                                        true
                                      );
                                      setFieldValue(
                                        "State",
                                        {
                                          value: result.Data[0].State,
                                          title: result.Data[0].State,
                                        },
                                        true
                                      );
                                      setFieldValue(
                                        "PIN",
                                        result.Data[0].PIN,
                                        true
                                      );
                                    }
                                  }
                                  if (result.status === 0) {
                                    setFieldValue(
                                      "Tehsil",
                                      {
                                        value: "",
                                        title: "",
                                      },
                                      true
                                    );
                                    setFieldValue(
                                      "District",
                                      {
                                        value: "",
                                        title: "",
                                      },
                                      true
                                    );
                                    setFieldValue(
                                      "State",
                                      {
                                        value: "",
                                        title: "",
                                      },
                                      true
                                    );
                                    setFieldValue("PIN", "", true);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });

                              //navigate("/login", { state: { from: location }, replace: true });
                            } catch (err) {
                              console.error(err);
                              //navigate("/login", { state: { from: location }, replace: true });
                            }
                          }}
                          value={
                            Boolean(values.Village)
                              ? values.Village
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Village"
                              label="Village/City"
                              variant="standard"
                              error={touched.Village && Boolean(errors.Village)}
                              helperText={touched.Village && errors.Village}
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={6}>
                        <Field
                          disabled={UID}
                          name="Tehsil"
                          component={Autocomplete}
                          key="ID"
                          options={TehsilList.error ? [null] : TehsilList.data}
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "Tehsil",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                          }}
                          value={
                            Boolean(values?.Tehsil)
                              ? values?.Tehsil
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Tehsil"
                              label="Tehsil"
                              variant="standard"
                              error={touched.Tehsil && Boolean(errors.Tehsil)}
                              helperText={touched.Tehsil && errors.Tehsil}
                            />
                          )}
                        />
                        <Field
                          disabled={UID}
                          name="District"
                          component={Autocomplete}
                          key="ID"
                          options={
                            DistrictList.error ? [null] : DistrictList.data
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "District",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                          }}
                          value={
                            Boolean(values.District)
                              ? values.District
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="District"
                              label="District"
                              variant="standard"
                              error={
                                touched.District && Boolean(errors.District)
                              }
                              helperText={touched.District && errors.District}
                            />
                          )}
                        />
                        <Field
                          disabled={UID}
                          name="State"
                          component={Autocomplete}
                          key="ID"
                          options={StateList.error ? [null] : StateList.data}
                          getOptionLabel={(option) => option?.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "State",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                          }}
                          value={
                            Boolean(values.State)
                              ? values.State
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="State"
                              label="State"
                              variant="standard"
                              error={touched.State && Boolean(errors.State)}
                              helperText={touched.State && errors.State}
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={6}>
                        <TextField
                          disabled={UID}
                          label="WhatsAppNo"
                          size="small"
                          variant="standard"
                          name="WhatsAppNo"
                          value={values.WhatsAppNo}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleMobileCheck(e);
                          }}
                          onChange={handleChange}
                          error={
                            touched.WhatsAppNo && Boolean(errors.WhatsAppNo)
                          }
                          helperText={touched.WhatsAppNo && errors.WhatsAppNo}
                        />{" "}
                        &nbsp;&nbsp;
                        <TextField
                          disabled={UID}
                          label="RESI. MOB. NO."
                          size="small"
                          variant="standard"
                          name="MobileNo"
                          value={values.MobileNo}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleMobileCheck(e);
                          }}
                          onChange={handleChange}
                          error={touched.MobileNo && Boolean(errors.MobileNo)}
                          helperText={touched.MobileNo && errors.MobileNo}
                        />{" "}
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Work Details</Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <Autocomplete
                          disabled={UID}
                          key="ID"
                          options={
                            ProfessionList.error ? [null] : ProfessionList.data
                          }
                          getOptionLabel={(option) => option?.title}
                          autoComplete={true}
                          autoHighlight={true}
                          blurOnSelect={true}
                          disableClearable={true}
                          onBlur={handleBlur}
                          name="Work"
                          value={values.Work}
                          onChange={(_, newValue) => {
                            setFieldValue("Work", newValue?.value, true);
                          }}
                          error={touched.Work && Boolean(errors.Work)}
                          helperText={touched.Work && errors.Work}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Profession"
                              variant="standard"
                            />
                          )}
                          style={{ width: 200 }}
                        />
                        &nbsp;&nbsp;
                        <Field
                          disabled={UID}
                          name="WorkType"
                          component={Autocomplete}
                          key="ID"
                          options={
                            WorkTypeList.error ? [null] : WorkTypeList.data
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "WorkType",
                              value || { value: "", title: "" }
                            );
                          }}
                          value={
                            Boolean(values?.WorkType)
                              ? values?.WorkType
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="WorkType"
                              label="WorkType"
                              variant="standard"
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Other Details</Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <TextField
                          label="Other/LIC Details"
                          size="small"
                          variant="standard"
                          name="Other"
                          value={values.Other}
                          onChange={handleChange}
                          error={touched.Other && Boolean(errors.Other)}
                          helperText={touched.Other && errors.Other}
                          disabled={UID}
                        />{" "}
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-standard-label">
                            In Prospect?
                          </InputLabel>
                          <Select
                            disabled={UID}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="In Prospect?"
                            name="Prospect"
                            value={values?.Prospect || null}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.Prospect && Boolean(errors.Prospect)}
                            helperText={touched.Prospect && errors.Prospect}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Stack>
                    </Row>
                    {/*                     <br />
                    <Row gutter={1} className="show-grid">
                      <FormControl variant="standard" sx={{ minWidth: 120 }}>
                        <InputLabel id="select-standard-family-label">
                          Family Members
                        </InputLabel>
                        <Select
                          disabled={UID}
                          labelId="select-standard-family-label"
                          id="simple-select--family-label"
                          multiple
                          label="Family Members"
                          MenuProps={MenuProps}
                          style={{ minWidth: 200 }}
                          name="FamilyList"
                          value={values?.FamilyList || []}
                          onChange={(e) => {
                            handleChange(e);
                            var newData = [];
                            for (
                              var i = 0;
                              i < (values?.FamilyList || []).length;
                              i++
                            ) {
                              newData[i] = {}; // creates a new object
                              newData[i].Relation = values?.FamilyList[i];
                            }
                            //console.log(newData);
                            setFamilyMembers(() => {
                              return newData;
                            });
                            setData(values);
                          }}
                          error={
                            touched.FamilyList && Boolean(errors.FamilyList)
                          }
                          helperText={touched.FamilyList && errors.FamilyList}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {(FamilyList.status === "fetched"
                            ? FamilyList.data
                            : [null]
                          ).((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, personName, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Row> */}
                    <Row gutter={1} style={{ marginTop: 10, marginBottom: 10 }}>
                      <Stack direction="row" spacing={4}>
                        {UID ? (
                          <>
                            {!state.DocumentAdded && (
                              <LoadingButton
                                color="secondary"
                                onClick={handleAttachment}
                                loading={loading}
                                loadingPosition="Done"
                                startIcon={<AttachFile />}
                                variant="contained"
                              >
                                <span>Save</span>
                              </LoadingButton>
                            )}
                          </>
                        ) : (
                          <LoadingButton
                            disabled={isSubmitting}
                            type="submit"
                            color="secondary"
                            variant="contained"
                            onClick={handleSubmit}
                            loading={isValidating}
                            sx={{ my: 2 }}
                          >
                            {isSubmitting ? "Submitting..." : "Submit"}
                          </LoadingButton>
                        )}
                      </Stack>
                    </Row>
                  </form>
                )}
              </Formik>
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Row className="show-grid">
                {UID && (
                  <Col xs={24} sm={24} md={24}>
                    <Uploader
                      fileListVisible={false}
                      disabled={state.DocumentAdded}
                      multiple={false}
                      listType="picture"
                      action="https://backend.dahiyainsurance.com/Upload/AddProfilePic.php"
                      withCredentials={true}
                      headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                      data={{ UID, Type: "Reference" }}
                      onUpload={(File) => {
                        setUploading(true);
                        previewFile(File.blobFile, (value) => {
                          setFileInfo(value);
                        });
                      }}
                      onSuccess={(response, file) => {
                        setUploading(false);
                        toaster.push(
                          <Message type="success">
                            Uploaded successfully
                          </Message>
                        );
                        console.log(response);
                      }}
                      onError={(e) => {
                        console.log(e);
                        setFileInfo(null);
                        setUploading(false);
                        toaster.push(
                          <Message type="error">Upload failed</Message>
                        );
                      }}
                      onRemove={(file) => {
                        console.log(file);
                      }}
                    >
                      <button style={{ width: 300, height: 300 }}>
                        {uploading && <Loader backdrop center />}
                        {fileInfo ? (
                          <img
                            alt=""
                            src={fileInfo}
                            width="100%"
                            height="100%"
                            style={{ objectFit: "cover" }}
                          />
                        ) : (
                          <AvatarIcon style={{ fontSize: 80 }} />
                        )}
                      </button>
                    </Uploader>

                    <Uploader
                      disabled={state.DocumentAdded}
                      removable={!state.DocumentAdded}
                      onRemove={(file) => {
                        console.log(file);
                      }}
                      listType="picture-text"
                      fileList={fileList1}
                      multiple={true}
                      data={{ UID, Type: "Reference" }}
                      onChange={setFileList1}
                      withCredentials={true}
                      headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                      action="https://backend.dahiyainsurance.com/Upload/UploadDocs.php"
                      renderFileInfo={(file, fileElement) => (
                        <span style={{ width: "100%", fontSize: 14 }}>
                          Name: {file.name}
                        </span>
                      )}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Grid>
        <SideDrawer
          open={open}
          onClose={() => {
            setOpen(false);
            setRefresh(!refresh);
            CasteList.reload();
            VillageList.reload();
            TehsilList.reload();
            DistrictList.reload();
            StateList.reload();
            ProfessionList.reload();
            WorkTypeList.reload();
          }}
        >
          <SideInputs />
        </SideDrawer>
      </div>
    </>
  );
}
