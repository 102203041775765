import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Container, Content } from "rsuite";
import { Button } from "@mui/material";
import { Delete, ListAltRounded, PersonAdd } from "@mui/icons-material";
import Header from "app/components/Header";

import useAuth from "app/hooks/useAuth";
import { ADD_REFERENCE, DELETE_PROSPECT, VIEW_PROSPECT } from "Permissions";
import { Outlet, useNavigate } from "react-router-dom";
import SideDrawer from "app/components/SideDrawer";
import SideInputs from "../Inputs/SideInputs";
import { ProspectProvider } from "./ProspectProvider";
import { CalendarIcon } from "@mui/x-date-pickers";

export default function Prospect() {
  const { permissions, user } = useAuth();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  return (
    <Box
      component="section"
      sx={{
        p: 1,
        border: "0.5px dashed #ddd",
        width: "100%",
        backgroundColor: "#FEFAF6",
      }}
    >
      <Stack spacing={1}>
        <Header headerText="Prospects">
          {(permissions.includes(ADD_REFERENCE) ||
            user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("/References/AddReference");
                }}
                variant="contained"
                startIcon={<PersonAdd />}
              >
                Add Reference
              </Button>
            </>
          )}

          {(permissions.includes(VIEW_PROSPECT) ||
            user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("ProspectFollowup");
                }}
                variant="contained"
                startIcon={<CalendarIcon />}
              >
                Followups List
              </Button>
            </>
          )}
          {(permissions.includes(VIEW_PROSPECT) ||
            user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("ListProspects");
                }}
                variant="contained"
                startIcon={<ListAltRounded />}
              >
                List
              </Button>
            </>
          )}
          {/*           {(permissions.includes(IMPORT_PROSPECT) ||
            user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("ImportProspects");
                }}
                variant="contained"
                startIcon={<Upload />}
              >
                Import
              </Button>
            </>
          )} */}
          {(permissions.includes(DELETE_PROSPECT) ||
            user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("Deleted");
              }}
              variant="contained"
              startIcon={<Delete />}
            >
              Deleted
            </Button>
          )}
        </Header>

        <Container>
          <Content
            style={{
              flex: 1,
              width: "100%",
              height: 650,
              borderRadius: 10,
              border: "1px dashed #ddd",
            }}
          >
            <ProspectProvider>
              <Outlet />
            </ProspectProvider>
            <SideDrawer
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <SideInputs />
            </SideDrawer>
          </Content>
        </Container>
      </Stack>
    </Box>
  );
}
