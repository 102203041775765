import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Container, Content } from "rsuite";
import { Button } from "@mui/material";
import { Delete, ListAltRounded, PersonAdd } from "@mui/icons-material";
import Header from "app/components/Header";
import useAuth from "app/hooks/useAuth";
import { ADD_USER, DELETE_USER, VIEW_USER } from "Permissions";
import { Outlet, useNavigate } from "react-router-dom";

export default function Users() {
  const { permissions, user } = useAuth();

  const navigate = useNavigate();
  return (
    <Box
      component="section"
      sx={{
        p: 1,
        border: "0.5px dashed #ddd",
        width: "100%",
        backgroundColor: "#FEFAF6",
      }}
    >
      <Stack spacing={1}>
        <Header headerText="Manage Users">
          {(permissions.includes(ADD_USER) || user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("Add");
              }}
              variant="contained"
              startIcon={<PersonAdd />}
            >
              Add
            </Button>
          )}
          {(permissions.includes(VIEW_USER) || user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("List");
              }}
              variant="contained"
              startIcon={<ListAltRounded />}
            >
              List
            </Button>
          )}
          {/*           {(permissions.includes(IMPORT_USER) ||
            user.Type === "SuperAdmin") && (
            <Button variant="contained" startIcon={<ImportContacts />}>
              Import
            </Button>
          )} */}
          {(permissions.includes(DELETE_USER) ||
            user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("Disabled");
              }}
              variant="contained"
              startIcon={<Delete />}
            >
              Disabled
            </Button>
          )}
        </Header>

        <Container>
          <Content
            style={{
              flex: 1,
              width: "100%",
              height: 650,
              borderRadius: 10,
              border: "1px dashed #ddd",
            }}
          >
            <Outlet />
          </Content>
        </Container>
      </Stack>
    </Box>
  );
}
