import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Container, Content } from "rsuite";
import { Button } from "@mui/material";
import {
  CalendarMonth,
  DateRange,
  ListAltRounded,
  People,
  PersonAdd,
  VerifiedUserSharp,
} from "@mui/icons-material";
import Header from "app/components/Header";
import "../../pages/styles.css";
import useAuth from "app/hooks/useAuth";
import { ADD_CLIA, VIEW_CLIA } from "Permissions";
import { Outlet, useNavigate } from "react-router-dom";
import SideDrawer from "app/components/SideDrawer";

import SideInputs from "../Inputs/SideInputs";
import { PropsProvider } from "./CLIAContext";

export default function CLIAReferences() {
  const { permissions, user } = useAuth();
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  return (
    <Box
      component="section"
      sx={{
        p: 1,
        border: "0.5px dashed #ddd",
        width: "100%",
        backgroundColor: "#FEFAF6",
      }}
    >
      <Stack spacing={1}>
        <Header headerText="CLIA Agency">
          {(permissions.includes(ADD_CLIA) || user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("Add");
                }}
                variant="contained"
                startIcon={<PersonAdd />}
              >
                Add
              </Button>
            </>
          )}
          {(permissions.includes(VIEW_CLIA) || user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("ListCLIAReference");
                }}
                variant="contained"
                startIcon={<ListAltRounded />}
              >
                Reference
              </Button>
            </>
          )}
          {(permissions.includes(VIEW_CLIA) || user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("ListCLIAProspect");
                }}
                variant="contained"
                startIcon={<People />}
              >
                Prospect
              </Button>
            </>
          )}
          {(permissions.includes(VIEW_CLIA) || user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("ListCLIAAgencyData");
                }}
                variant="contained"
                startIcon={<VerifiedUserSharp />}
              >
                Agency Data
              </Button>
            </>
          )}
          {(permissions.includes(VIEW_CLIA) || user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("ProspectFollowup");
                }}
                variant="contained"
                startIcon={<CalendarMonth />}
              >
                Followups
              </Button>
            </>
          )}
          {(permissions.includes(VIEW_CLIA) || user.Type === "SuperAdmin") && (
            <>
              <Button
                onClick={() => {
                  navigate("ListCLIAMBGDueList");
                }}
                variant="contained"
                startIcon={<DateRange />}
              >
                MBG Due
              </Button>
            </>
          )}
          {/*           {(permissions.includes(IMPORT_CLIA) ||
            user.Type === "SuperAdmin") && (
            <>
              <Button
                disabled={true}
                onClick={() => {
                  navigate("Import");
                }}
                variant="contained"
                startIcon={<Upload />}
              >
                Import
              </Button>
            </>
          )}
          {(permissions.includes(DELETE_CLIA) ||
            user.Type === "SuperAdmin") && (
            <Button
              disabled={true}
              onClick={() => {
                navigate("Deleted");
              }}
              variant="contained"
              startIcon={<Delete />}
            >
              Deleted
            </Button>
          )} */}
        </Header>

        <Container>
          <Content
            style={{
              flex: 1,
              width: "100%",
              height: 650,
              borderRadius: 10,
              border: "1px dashed #ddd",
            }}
          >
            <PropsProvider>
              <Outlet />
            </PropsProvider>
            <SideDrawer
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <SideInputs />
            </SideDrawer>
          </Content>
        </Container>
      </Stack>
    </Box>
  );
}
