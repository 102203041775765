import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  Container,
  Content,
  Dropdown,
  IconButton,
  Popover,
  Whisper,
  Button as Btn,
  ButtonGroup,
} from "rsuite";
import { Button } from "@mui/material";
import {
  AddModerator,
  Delete,
  ImportContacts,
  PersonAdd,
} from "@mui/icons-material";
import Header from "app/components/Header";

import useAuth from "app/hooks/useAuth";
import {
  ADD_PROSPECT,
  DELETE_PROSPECT,
  IMPORT_PROSPECT,
  VIEW_PROSPECT,
} from "Permissions";
import { Outlet, useNavigate } from "react-router-dom";
import ArrowDownIcon from "@rsuite/icons/ArrowDown";
const options = ["List Reference", "List Prospect"];
const Addoptions = ["Add Reference", "Add Prospect"];
const Importoptions = ["Import Reference", "Import Prospect"];

export default function Greetings() {
  const [action, setAction] = React.useState(0);
  const { permissions, user } = useAuth();

  const navigate = useNavigate();
  return (
    <Box
      component="section"
      sx={{
        p: 1,
        border: "0.5px dashed #ddd",
        width: "100%",
        backgroundColor: "#FEFAF6",
      }}
    >
      <Stack spacing={1}>
        <Header headerText="Greetings">
          {/* {(permissions.includes(ADD_PROSPECT) ||
            user.Type === "SuperAdmin") && (
            <>
              <ButtonGroup>
                <Btn appearance="primary">{Addoptions[action]}</Btn>
                <Whisper
                  placement="bottomEnd"
                  trigger="click"
                  speaker={({ onClose, left, top, className }, ref) => {
                    const handleSelect = (eventKey) => {
                      onClose();
                      //setAction(eventKey);
                      if (eventKey === 0) navigate("AddReference");
                      else navigate("AddProspect");
                    };
                    return (
                      <Popover
                        ref={ref}
                        className={className}
                        style={{ left, top }}
                        full
                      >
                        <Dropdown.Menu onSelect={handleSelect}>
                          {Addoptions.map((item, index) => (
                            <Dropdown.Item key={index} eventKey={index}>
                              {item}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Popover>
                    );
                  }}
                >
                  <IconButton appearance="primary" icon={<ArrowDownIcon />} />
                </Whisper>
              </ButtonGroup>
            </>
          )}
          {(permissions.includes(VIEW_PROSPECT) ||
            user.Type === "SuperAdmin") && (
            <>
              <ButtonGroup>
                <Btn appearance="primary">{options[action]}</Btn>
                <Whisper
                  placement="bottomEnd"
                  trigger="click"
                  speaker={({ onClose, left, top, className }, ref) => {
                    const handleSelect = (eventKey) => {
                      onClose();
                      if (eventKey === 0) navigate("ListReference");
                      else navigate("ListProspects");
                    };
                    return (
                      <Popover
                        ref={ref}
                        className={className}
                        style={{ left, top }}
                        full
                      >
                        <Dropdown.Menu onSelect={handleSelect}>
                          {options.map((item, index) => (
                            <Dropdown.Item key={index} eventKey={index}>
                              {item}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Popover>
                    );
                  }}
                >
                  <IconButton appearance="primary" icon={<ArrowDownIcon />} />
                </Whisper>
              </ButtonGroup>
            </>
          )}
          {(permissions.includes(IMPORT_PROSPECT) ||
            user.Type === "SuperAdmin") && (
            <>
              <ButtonGroup>
                <Btn appearance="primary">{Importoptions[action]}</Btn>
                <Whisper
                  placement="bottomEnd"
                  trigger="click"
                  speaker={({ onClose, left, top, className }, ref) => {
                    const handleSelect = (eventKey) => {
                      onClose();
                      //setAction(eventKey);
                      if (eventKey === 0) navigate("ImportReference");
                      else navigate("ImportProspects");
                    };
                    return (
                      <Popover
                        ref={ref}
                        className={className}
                        style={{ left, top }}
                        full
                      >
                        <Dropdown.Menu onSelect={handleSelect}>
                          {Importoptions.map((item, index) => (
                            <Dropdown.Item key={index} eventKey={index}>
                              {item}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Popover>
                    );
                  }}
                >
                  <IconButton appearance="primary" icon={<ArrowDownIcon />} />
                </Whisper>
              </ButtonGroup>
            </>
          )}
          {(permissions.includes(DELETE_PROSPECT) ||
            user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("Deleted");
              }}
              variant="contained"
              startIcon={<Delete />}
            >
              Deleted
            </Button>
          )} */}
        </Header>

        <Container>
          <Content
            style={{
              flex: 1,
              width: "100%",
              height: 650,
              borderRadius: 10,
              border: "1px dashed #ddd",
            }}
          >
            <Outlet />
          </Content>
        </Container>
      </Stack>
    </Box>
  );
}
