import AgGrid from "app/components/AgGrid";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { filterParams } from "functions";
import React from "react";
import { Panel } from "rsuite";
export default function DeletedProspect() {
  const [loading, setLoading] = React.useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [prospectData, setProspectData] = React.useState([{}]);
  const Prospectcolumns = React.useMemo(() => {
    return [
      {
        headerName: "Ref ID",
        field: "ID",
        minWidth: 180,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      {
        headerName: "Name",
        field: "Name",
        minWidth: 180,
      },

      { field: "Caste", minWidth: 150 },
      { field: "Address", minWidth: 150 },
      { field: "Village", minWidth: 150 },
      { field: "Tehsil", minWidth: 150 },
      { field: "District", minWidth: 150 },
      { field: "State", minWidth: 150 },
      { field: "WhatsAppNo", minWidth: 150 },
      { field: "MobileNo", minWidth: 150 },
      { field: "Profession", minWidth: 150 },
      { field: "Education", minWidth: 150 },
      { field: "Other", minWidth: 150 },
      { field: "YearlyIncome", minWidth: 150 },
      { field: "Investment", minWidth: 150 },
      { field: "OtherProperty", minWidth: 150 },
      { field: "BankLiability", minWidth: 150 },
      { field: "OtherLiability", minWidth: 150 },
      { field: "ConceptDiscussed", minWidth: 300 },
      { field: "DiscussionDate", minWidth: 150 },
      { field: "Status", minWidth: 100 },
      { field: "Result", minWidth: 150 },
      { field: "LeadStatus", minWidth: 150 },
      { field: "FamilyMembers", minWidth: 200 },
      { field: "AddedBy", minWidth: 150 },
      {
        field: "AddedOn",
        hide: true,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      { field: "DeletedBy", minWidth: 150 },
      {
        field: "DeletedOn",
        hide: true,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      { field: "UniqueID", hide: true },
    ];
  }, []);

  const onProspectGridReady = React.useCallback((params) => {
    setLoading(true);
    axiosPrivate
      .post("LoadDeletedProspect.php")
      .then((responce) => {
        const result = responce.data;
        if (result.status === 1) setProspectData(result.Data);
        else setProspectData([]);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Panel>
        <AgGrid
          columns={Prospectcolumns}
          rowData={prospectData}
          onGridReady={onProspectGridReady}
          defaultColumn={["Name"]}
          GoTo="/Prospects/ViewProspect"
          QuickSearch={true}
          exportAllowed={false}
          ChooseColumns={false}
          showLoading={loading}
          onRowSelectedData={() => {}}
          Source="Prospect"
        />
      </Panel>
    </>
  );
}
