import * as React from "react";
import AgGrid from "app/components/AgGrid";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { Panel } from "rsuite";

export default function ListPolicy() {
  const axiosPrivate = useAxiosPrivate();

  const [rowData, setRowData] = React.useState([{}]);

  const [loading, setLoading] = React.useState(false);

  // const abc = useReference();
  // console.log(abc);
  const ColorStatus = React.useMemo(() => {
    return ["#FC6E51", "#A0D468"];
  }, []);
  const formatNumber = (number) => {
    return Math.floor(number).toLocaleString();
  };
  const currencyFormatter = (params) => {
    return "₹" + formatNumber(params.value);
  };
  const columns = React.useMemo(() => {
    return [
      {
        field: "VIMPROID",
        minWidth: 150,
        editable: false,
        headerName: "VM Pro ID",
      },
      {
        field: "PolicyNo",
        minWidth: 200,
        editable: false,
        headerName: "Policy No",
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      {
        headerName: "Ref ID",
        field: "RefID",
        minWidth: 180,
        editable: false,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },

      { field: "RefBy", minWidth: 100, editable: false },
      { field: "Place", minWidth: 150, editable: false },
      { field: "Name", minWidth: 100, editable: false },
      {
        field: "PolicyHolderName",
        minWidth: 150,
        editable: false,
        headerName: "PolicyHolder",
      },
      {
        field: "DOB",
        minWidth: 130,
        editable: false,
        headerName: "DOB",
        type: "date",
      },
      {
        field: "Age",
        minWidth: 100,
        editable: false,
        headerName: "Age",
      },
      { field: "Caste", minWidth: 150, editable: false },
      {
        field: "Village",
        minWidth: 150,
        editable: false,
        headerName: "Village/City",
      },
      {
        field: "WhatsAppNo",
        minWidth: 120,
        editable: false,
        headerName: "Mobile No",
      },
      {
        field: "Profession",
        minWidth: 150,
        editable: false,
        headerName: "Profession",
      },
      {
        field: "WorkType",
        minWidth: 150,
        editable: false,
        headerName: "Work Type",
      },
      {
        field: "AgencyCode",
        minWidth: 150,
        editable: false,
        headerName: "Agency Code",
      },
      {
        field: "CompletionDate",
        minWidth: 100,
        editable: false,
        headerName: "Completion Date",
        type: "date",
      },
      {
        field: "AgencyCode",
        minWidth: 150,
        editable: false,
        headerName: "Agency Code",
      },
      {
        field: "PolicyNo",
        minWidth: 200,
        editable: false,
        headerName: "Policy No",
      },
      {
        field: "DOC",
        minWidth: 10,
        editable: false,
        headerName: "DOC",
        type: "date",
      },

      {
        field: "PayingTerm",
        minWidth: 150,
        editable: false,
        headerName: "PayingTerm",
      },

      {
        field: "SumAssured",
        minWidth: 150,
        valueFormatter: currencyFormatter,

        editable: false,
        headerName: "SumAssured",
      },
      {
        field: "Mode",
        minWidth: 100,
        editable: false,
        headerName: "Mode",
      },

      {
        field: "FirstBasic",
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "FirstTotal",
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "FirstYLYBasic",
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "FirstYLYTotal",
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      { field: "MinorPolicy", minWidth: 150, editable: false },
      { field: "ENachMode", minWidth: 150, editable: false },
      { field: "PolicBondRecieved", minWidth: 150, editable: false },
      { field: "VIMPROID", minWidth: 150, editable: false },
      { field: "AddedBy", minWidth: 150, editable: false },
      { field: "AddedOn", minWidth: 150, editable: false },

      { field: "UniqueID", hide: true, editable: false },
    ];
  }, []);
  const onGridReady = React.useCallback((params) => {
    setLoading(true);
    axiosPrivate
      .post("LoadDeletedPolicy.php")
      .then((responce) => {
        const result = responce.data;
        if (result.status === 1) setRowData(result.Data);
        else setRowData([]);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  const [selectedRowData, setSelectedRowData] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);
  return (
    <>
      <Panel>
        <AgGrid
          columns={columns}
          rowData={rowData}
          onGridReady={onGridReady}
          defaultColumn={["Name"]}
          GoTo="/Policy/View"
          showLoading={loading}
          onRowSelectedData={setSelectedRowData}
          settingsName="CLIA"
          headerComponent={() => <p></p>}
          setFilteredRows={setFilteredRows}
          Source="Policy"
          exportAllowed={false}
        />
      </Panel>
    </>
  );
}
