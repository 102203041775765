import { Fragment } from "react";
import React from "react";
import { Box, Card, Grid, styled } from "@mui/material";

import { Span } from "app/components/Typography";
import { Link } from "react-router-dom";
import { Stack } from "rsuite";

// STYLED COMPONENTS
const ProjectName = styled(Span)(({ theme }) => ({
  marginLeft: 0,
  fontWeight: "500",
  textTransform: "capitalize",
  [theme.breakpoints.down("sm")]: { marginLeft: 4 },
}));

export default function RowCards2({ data = [{}] }) {
  return data.map((data, _) => (
    <Fragment key={data.SrNo}>
      <Card sx={{ py: 1, px: 1 }} className="project-card">
        <Grid container>
          <Stack direction="column" style={{ alignItems: "flex-start" }}>
            <Box display="flex">
              {data.Type === "Prospect" ? (
                <Link to={`/Prospects/ViewProspect/${data.ProspectID}`}>
                  <ProjectName>{`${data.Name} - (${data.ID})`}</ProjectName>
                </Link>
              ) : (
                <Link to={`/CLIAProspect/EditCLIAProspect/${data.ProspectID}`}>
                  <ProjectName>{`${data.Name} - (${data.ID})`}</ProjectName>
                </Link>
              )}
            </Box>
            <Box color="text.secondary">{data.NextAppointmentDate}</Box>
            <Box color="text.primary">{data.Discussed}</Box>
          </Stack>

          {/*           <Grid item xs={1}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton>
                <MoreVert />
              </IconButton>
            </Box>
          </Grid> */}
        </Grid>
      </Card>

      <Box py={1} />
    </Fragment>
  ));
}
