import { ExpandLess, StarOutline, TrendingUp } from "@mui/icons-material";
import { Card, Fab, Grid, lighten, styled, useTheme } from "@mui/material";
import useAuth from "app/hooks/useAuth";
import { toAmnt } from "functions";

// STYLED COMPONENTS
const ContentBox = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
}));

const FabIcon = styled(Fab)(() => ({
  width: "44px !important",
  height: "44px !important",
  boxShadow: "none !important",
}));

const H3 = styled("h3")(() => ({
  margin: 0,
  fontWeight: "500",
  marginLeft: "12px",
}));
const H5 = styled("h5")(() => ({
  margin: 0,
  fontWeight: "500",
  marginLeft: "12px",
}));

const H1 = styled("h3")(({ theme }) => ({
  margin: 0,
  flexGrow: 1,
  color: theme.palette.text.secondary,
}));

const Span = styled("span")(() => ({
  fontSize: "13px",
  marginLeft: "4px",
}));

const IconBox = styled("div")(() => ({
  width: 16,
  height: 16,
  color: "#fff",
  display: "flex",
  overflow: "hidden",
  borderRadius: "300px ",
  justifyContent: "center",
  "& .icon": { fontSize: "14px" },
}));

export default function StatCards2({ Amount = {} }) {
  const { palette } = useTheme();
  const { user } = useAuth();
  const bgError = lighten(palette.error.main, 0.85);

  return (
    <Grid container spacing={3} sx={{ mb: 3 }}>
      <Grid item xs={12} md={6}>
        <Card elevation={3} sx={{ p: 2 }}>
          <ContentBox>
            <FabIcon
              size="medium"
              sx={{ background: "rgba(9, 182, 109, 0.15)" }}
            >
              <TrendingUp color="success" />
            </FabIcon>

            <H5 color="#08ad6c">First Premium (No GST)</H5>
          </ContentBox>

          <ContentBox sx={{ pt: 2 }}>
            <H1>{toAmnt(Amount?.Current)}</H1>
            &nbsp;
            <IconBox sx={{ backgroundColor: "success.main" }}>
              <ExpandLess className="icon" />
            </IconBox>
            {user.Type === "SuperAdmin" && (
              <Span
                color={`${
                  Amount?.Last - Amount?.Current > 0 ? "red" : "#08ad6c"
                } `}
              >
                (
                {Amount?.Last === null
                  ? 100
                  : (Amount?.Current * 100) / Amount?.Last}
                %)
              </Span>
            )}
          </ContentBox>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card elevation={3} sx={{ p: 2 }}>
          <ContentBox>
            <FabIcon
              size="medium"
              sx={{ backgroundColor: bgError, overflow: "hidden" }}
            >
              <StarOutline color="error" />
            </FabIcon>

            <H5 color="error.main">First Premium Commission</H5>
          </ContentBox>

          <ContentBox sx={{ pt: 2 }}>
            <H1>{toAmnt(Amount?.ComCurrent)}</H1>
            &nbsp;
            <IconBox sx={{ backgroundColor: "success.main" }}>
              <ExpandLess className="icon" />
            </IconBox>
            {user.Type === "SuperAdmin" && (
              <Span
                color={`${
                  Amount?.ComLast - Amount?.ComCurrent > 0 ? "red" : "#08ad6c"
                } `}
              >
                (
                {Amount?.ComLast === null
                  ? 100
                  : (Amount?.ComCurrent * 100) / Amount?.ComLast}
                %)
              </Span>
            )}
          </ContentBox>
        </Card>
      </Grid>
    </Grid>
  );
}
