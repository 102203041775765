import React from "react";
import { useLocation } from "react-router-dom";

const PathDisplay = () => {
  const location = useLocation();

  return <h6>Current Path: {location.pathname}</h6>;
};

export default PathDisplay;
