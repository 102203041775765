import * as React from "react";
import AgGrid from "app/components/AgGrid";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { Panel } from "rsuite";
import { useProps } from "./PolicyProvider";
import { filterParams } from "functions";

export default function ListPendingUpdatePolicy() {
  const axiosPrivate = useAxiosPrivate();

  const [rowData, setRowData] = React.useState([{}]);

  const [loading, setLoading] = React.useState(false);

  const formatNumber = (number) => {
    return Math.floor(number).toLocaleString();
  };
  const currencyFormatter = (params) => {
    return "₹ " + formatNumber(params.value);
  };
  const PerFormatter = (params) => {
    return (params.value || 0).toLocaleString() + "% ";
  };
  const columns = React.useMemo(() => {
    return [
      {
        field: "VIMPROID",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "VM Pro ID",
      },
      {
        field: "PolicyNo",
        width: 200,
        minWidth: 150,
        editable: false,
        headerName: "Policy No",
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      {
        headerName: "Ref ID",
        field: "RefID",
        width: 180,
        minWidth: 150,
        editable: false,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },

      { field: "RefBy", width: 150, minWidth: 150, editable: false },
      { field: "Place", width: 150, minWidth: 150, editable: false },
      {
        field: "Name",
        width: 200,
        minWidth: 150,
        editable: false,
        headerName: "Family Head Name",
      },
      {
        field: "PolicyHolderName",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "PolicyHolder",
      },
      {
        field: "DOB",
        width: 130,
        minWidth: 150,
        editable: false,
        headerName: "DOB",
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      {
        field: "Age",
        width: 180,
        minWidth: 150,
        editable: false,
        headerName: "Age",
      },
      { field: "Caste", width: 150, minWidth: 150, editable: false },
      {
        field: "Village",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "Village/City",
      },
      {
        field: "WhatsAppNo",
        width: 120,
        minWidth: 150,
        editable: false,
        headerName: "Mobile No",
      },
      {
        field: "Profession",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "Profession",
      },
      {
        field: "RefByName",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "Ref By",
      },
      {
        field: "WorkType",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "Work Type",
      },
      {
        field: "AgencyCode",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "Agency Code",
      },
      {
        field: "CompletionDate",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "Completion Date",
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      {
        field: "AgeDOC",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "Age ON DOC",
        type: "date",
      },

      {
        field: "DOC",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "DOC",
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      {
        field: "PayingTerm",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "Plan-Term",
      },

      {
        field: "SumAssured",
        width: 150,
        minWidth: 150,
        valueFormatter: currencyFormatter,
        editable: false,
        headerName: "SumAssured",
      },
      {
        field: "Mode",
        width: 100,
        minWidth: 150,
        editable: false,
        headerName: "Mode",
      },

      {
        field: "FirstBasic",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "FirstTotal",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "FirstYLYBasic",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },

      { field: "MinorPolicy", width: 150, minWidth: 150, editable: false },
      {
        field: "ENachMode",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "ENach",
      },
      {
        field: "PolicBondRecieved",
        width: 150,
        minWidth: 150,
        editable: false,
        headerName: "BondRecieved",
      },
      {
        field: "FormSubmittedOnline",
        width: 150,
        minWidth: 150,
        editable: false,
      },
      {
        field: "GST",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: PerFormatter,
        headerName: "GST %",
      },
      {
        field: "CommPer",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: PerFormatter,
        headerName: "Comm %",
      },
      {
        field: "CommOfFirstBasisPremium",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "CommOfFirstBasisYLYPremium",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "CR2",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
        headerName: "DR2",
      },
      {
        field: "OtherComm",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
        headerName: "DAHIYA MITRA PAYOUT",
      },
      {
        field: "OtherExpenses",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
        headerName: "SERVICING EXPENCES",
      },
      {
        field: "CommOfFirstBasisYLYPremium",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "CommOfFirstBasisYLYPremium",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "NetSavingFirst",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      {
        field: "NetSavingYLY",
        width: 150,
        minWidth: 150,
        editable: false,
        valueFormatter: currencyFormatter,
      },
      { field: "AddedBy", minWidth: 150, editable: false },
      {
        field: "AddedOn",
        minWidth: 180,
        editable: false,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      { field: "UniqueID", hide: true, editable: false },
    ];
  }, []);

  const onGridReady = React.useCallback((params) => {
    setLoading(true);
    axiosPrivate
      .post("LoadPendingPolicy.php")
      .then((responce) => {
        const result = responce.data;
        if (result.status === 1) setRowData(result.Data);
        else setRowData([]);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  const [selectedRowData, setSelectedRowData] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);

  const { updatePropsValue } = useProps();

  const handleUpdateMessage = () => {
    const NewData = filteredRows.map((dt) => dt.data.UniqueID);
    updatePropsValue(NewData);
    //console.log(propsValue);
  };
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      handleUpdateMessage();
      //console.log(filteredRows);
    }
    return () => {
      isSubscribed = false;
    };
  }, [filteredRows.length]);
  return (
    <>
      <Panel>
        <AgGrid
          columns={columns}
          rowData={rowData}
          onGridReady={onGridReady}
          defaultColumn={["Name"]}
          GoTo="/Policy/View"
          showLoading={loading}
          onRowSelectedData={setSelectedRowData}
          settingsName="PendingPolicy"
          headerComponent={() => <p></p>}
          setFilteredRows={setFilteredRows}
          Source="Policy"
          exportAllowed={true}
        />
      </Panel>
    </>
  );
}
