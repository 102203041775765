import * as React from "react";
import { Container, Content } from "rsuite";
import { Delete, ListAltRounded } from "@mui/icons-material";
import Header from "app/components/Header";
import useAuth from "app/hooks/useAuth";

import { useNavigate } from "react-router-dom";
import { DELETE_PREMIUMPOINT, VIEW_PREMIUMPOINT } from "Permissions";
import { MatxLoading } from "app/components";
//import { PolicyProvider } from "./PolicyProvider";
import { Button, Col, Grid, Heading, Message, Row, toaster } from "rsuite";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as XLSX from "xlsx";

import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { findDuplicateIds, hasInvalidIds, mergeArraysById } from "functions";
import { LoadingButton } from "@mui/lab";

import AgGrid from "app/components/AgGrid";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Box, Stack } from "@mui/material";
//`PolicyNo`, `PolicyHolder`, `CommDate`, `PTP`, `SumAssured`, `MD`, `BRN`, `FUP`, `PremiumNo`, `Premium`, `IntRt`,
//`IntAmount`, `TotalPayable`, `ValidUpto`, `Comm`, `DateOfCall`, `Responce`, `NextDate`, `Status`
const columns = [
  { id: "ID", label: "ID", Width: 200 },
  { id: "PolicyNo", label: "PolicyNo", Width: 170 },
  { id: "PolicyHolder", label: "PolicyHolder", Width: 200 },
  { id: "CommDate", label: "CommDate", Width: 200 },
  { id: "PTP", label: "PTP", Width: 200 },
  { id: "SumAssured", label: "SumAssured", Width: 200 },
  { id: "MD", label: "MD", minWidth: 200 },
  { id: "BRN", label: "BRN", minWidth: 200 },
  { id: "FUP", label: "FUP", Width: 200 },
  { id: "PremiumNo", label: "PremiumNo", minWidth: 200 },
  { id: "Premium", label: "Premium", minWidth: 200 },
  { id: "IntRt", label: "IntRt", minWidth: 200 },
  { id: "IntAmount", label: "IntAmount", minWidth: 200 },
  { id: "TotalPayable", label: "TotalPayable", minWidth: 200 },
  { id: "ValidUpto", label: "ValidUpto", minWidth: 200 },
  { id: "Comm", label: "Comm", minWidth: 200 },
];
const columns1 = [
  { id: "ID", field: "ID", minWidth: 150 },
  { id: "PolicyNo", field: "PolicyNo", minWidth: 150 },
  { id: "PolicyHolder", field: "PolicyHolder", Width: 200 },
  { id: "CommDate", field: "CommDate", minWidth: 200 },
  { id: "SumAssured", field: "SumAssured", minWidth: 200 },
  { id: "PTP", field: "PTP", minWidth: 200 },
  { id: "FUP", field: "FUP", minWidth: 200 },
  { id: "MD", field: "MD", minWidth: 200 },
  { id: "BRN", field: "BRN", minWidth: 200 },
  { id: "PremiumNo", field: "PremiumNo", minWidth: 200 },
  { id: "Premium", field: "Premium", minWidth: 200 },
  { id: "IntRt", field: "IntRt", minWidth: 200 },
  { id: "IntAmount", field: "IntAmount", minWidth: 200 },
  { id: "TotalPayable", field: "TotalPayable", minWidth: 200 },
  { id: "ValidUpto", field: "ValidUpto", minWidth: 200 },
  { id: "Comm", field: "Comm", minWidth: 200 },

  { id: "UploadStatus", field: "UploadStatus", Width: 100 },
  { id: "Message", field: "Message", minWidth: 500 },
];
const rows = [];
export default function LapsedPolicyDueImport() {
  const { permissions, user } = useAuth();
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [status, setStatus] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const axiosPrivate = useAxiosPrivate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const DownloadTemplate = () => {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("TableData");

    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);

    // Process Data (add a new row)
    //var ws = workbook.Sheets["Sheet1"];
    /* XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], {
        origin: -1,
      }); */

    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    XLSX.writeFile(workbook, "LapsedPolicyDueListTemplate.xlsb");
  };

  const handleUpload = (e) => {
    try {
      setData(rows);
      e.preventDefault();

      var files = e.target.files,
        f = files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 0 });
        console.log(dataParse);
        const newData = dataParse.map((row) => {
          let excelDate = row.CommDate; // Replace with the correct cell reference

          // Convert Excel date to JavaScript Date
          let jsDate = new Date((excelDate - 25569) * 86400 * 1000);

          // Format the date as needed (e.g., YYYY-MM-DD for MySQL)
          let CommDate = jsDate.toISOString().split("T")[0];
          let FUP;
          if (row?.FUP) {
            let excelDate1 = row.FUP; // Replace with the correct cell reference

            // Convert Excel date to JavaScript Date
            let jsDate1 = new Date((excelDate1 - 25569) * 86400 * 1000);

            // Format the date as needed (e.g., YYYY-MM-DD for MySQL)
            FUP = jsDate1.toISOString().split("T")[0];
          }

          let excelDate2 = row.ValidUpto; // Replace with the correct cell reference

          // Convert Excel date to JavaScript Date
          let jsDate2 = new Date((excelDate2 - 25569) * 86400 * 1000);

          // Format the date as needed (e.g., YYYY-MM-DD for MySQL)
          let ValidUpto = jsDate2.toISOString().split("T")[0];

          return {
            ...row,
            CommDate: moment(CommDate).format("YYYY-MM-DD"),
            FUP: row?.FUP ? moment(FUP).format("YYYY-MM-DD") : "",
            ValidUpto: moment(ValidUpto).format("YYYY-MM-DD"),
          };
        });
        setData(newData);
        updateQueryParams(newData);
      };
      reader.readAsBinaryString(f);
    } catch (error) {
      toaster.push(<Message type="error">Error occured</Message>);
    }
  };
  function onGridReady() {}
  const history = useNavigate();
  const location = useLocation();
  const updateQueryParams = React.useCallback(
    (activeKey) => {
      const params = new URLSearchParams(location.search);
      params.set("data", JSON.stringify(activeKey));
      history(`?${params.toString()}`, { replace: true });
    },
    [history, location.search]
  );

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const nameFromParams = params.get("data");

    if (nameFromParams) {
      setData(JSON.parse(nameFromParams));
    }
  }, [location.search]);

  async function handleImport() {
    setStatus(true);

    if (data.length > 0) {
      const duplicates = findDuplicateIds(data);
      if (hasInvalidIds(data)) {
        console.log("Invalid IDs found:");
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Invalid IDs!</strong> {`There are some invalid IDs`}
          </Message>
        );
        setStatus(false);
        return;
      } else if (duplicates.length > 0) {
        console.log("Duplicate IDs found:");
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Duplicate IDs!</strong>{" "}
            {`Duplicate IDs found - ${duplicates.toString()}`}
          </Message>
        );
        setStatus(false);
        return;
      } else {
        try {
          //  alert("Work in progress");
          // return;
          axiosPrivate
            .post("ImportLapsedDueList.php", {
              data,
            })
            .then((response) => {
              const result = response.data;
              if (result.status === 1) {
                toaster.push(
                  <Message type="success" bordered showIcon>
                    <strong>Success!</strong> {result.Success}
                  </Message>
                );
                const newData = mergeArraysById(data, result.Data);
                console.log(newData);
                setData(newData);
                updateQueryParams(newData);
              }
              if (result.status === 0) {
                toaster.push(
                  <Message type="error" bordered showIcon>
                    <strong>Error!</strong> {result.Error}
                  </Message>
                );
              }
              setStatus(false);
              // resetForm();
            })
            .catch((error) => {
              console.error("Error submitting data:", error);
              setStatus(false);
            });
        } catch (error) {
          console.error(error);
          setStatus(false);
        }
      }
    } else {
      toaster.push(
        <Message type="error" bordered showIcon>
          Please upload a file first
        </Message>
      );
      setStatus(false);
    }
  }
  const navigate = useNavigate();
  return (
    <Box
      component="section"
      sx={{
        p: 1,
        border: "0.5px dashed #ddd",
        width: "100%",
        backgroundColor: "#FEFAF6",
      }}
    >
      <Stack spacing={1}>
        <Header headerText="Lapsed Policy Due">
          {(permissions.includes(VIEW_PREMIUMPOINT) ||
            user.Type === "SuperAdmin") && (
            <Button
              onClick={() => {
                navigate("/LapsedPolicyDueList");
              }}
              variant="contained"
              startIcon={<ListAltRounded />}
            >
              List
            </Button>
          )}

          {/*           {(permissions.includes(DELETE_PREMIUMPOINT) ||
            user.Type === "SuperAdmin") && (
            <Button
              disabled={true}
              onClick={() => {
                navigate("Deleted");
              }}
              variant="contained"
              startIcon={<Delete />}
            >
              Deleted
            </Button>
          )} */}
        </Header>

        <Container>
          <Content
            style={{
              flex: 1,
              width: "100%",
              height: 650,
              borderRadius: 10,
              border: "1px dashed #ddd",
            }}
          >
            {navigate.state === "loading" ? (
              <MatxLoading />
            ) : (
              <>
                <div
                  style={{
                    width: "100%",
                    border: "0.5px dashed #999",
                    borderRadius: 10,
                  }}
                >
                  <Heading level={5} style={{ textAlign: "center" }}>
                    Lapsed Policy Due Import
                  </Heading>
                </div>
                <div
                  style={{
                    width: "100%",
                    margin: "10px 0px 10px 0px",
                    padding: 5,
                    border: "0.5px dashed #999",
                    borderRadius: 10,
                  }}
                >
                  <Stack direction="row" spacing={4}>
                    <Button onClick={DownloadTemplate}>
                      Download Template
                    </Button>
                    {/*           <input
              type="file"
              style={{ display: "none" }}
              ref={fileInput}
              accept="xls*"
            />
            <button onClick={handleUpload} className="btn btn-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-cloud-upload m-1"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
                />
                <path
                  fillRule="evenodd"
                  d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"
                />
              </svg>
              <span className="ms-2">Select File</span>
            </button> */}
                    <input
                      type="file"
                      onChange={handleUpload}
                      onInput={handleUpload}
                      onSelect={handleUpload}
                    />
                    <LoadingButton
                      disabled={status}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      onClick={handleImport}
                      loading={status}
                      sx={{ my: 2 }}
                    >
                      {status ? "Uploading..." : "Import"}
                    </LoadingButton>
                  </Stack>
                </div>
                <div
                  style={{
                    width: "100%",
                    minHeight: 600,
                    border: "0.5px dotted #999",
                    marginTop: 5,
                    borderRadius: 10,
                  }}
                >
                  <Grid fluid style={{ padding: 10 }}>
                    <Row className="show-grid">
                      <Col xs={24} sm={24} md={24}>
                        <Paper style={{ display: "none" }}>
                          <TableContainer
                            sx={{ maxHeight: 440, overflowX: "auto" }}
                          >
                            <Table
                              stickyHeader
                              className={{}}
                              aria-label="sticky table"
                              id="TableData"
                            >
                              <TableHead>
                                <TableRow>
                                  {columns.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(data || rows)
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((row) => {
                                    return (
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.Name}
                                      >
                                        {columns.map((column) => {
                                          const value = row[column.id];
                                          return (
                                            <TableCell
                                              key={column.id}
                                              align={column.align}
                                            >
                                              {column.format &&
                                              typeof value === "number"
                                                ? column.format(value)
                                                : value}
                                            </TableCell>
                                          );
                                        })}
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[
                              10,
                              50,
                              100,
                              { value: -1, label: "All" },
                            ]}
                          />
                        </Paper>
                        <Row className="show-grid" style={{ maxHeight: 350 }}>
                          <AgGrid
                            columns={columns1}
                            rowData={data}
                            onGridReady={onGridReady}
                            defaultColumn={["Name"]}
                            showHeaderItems={false}
                            GoTo="/Prospects/AddProspect"
                            passdata={true}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Grid>
                </div>
              </>
            )}
          </Content>
        </Container>
      </Stack>
    </Box>
  );
}
