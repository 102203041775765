import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Card,
  Checkbox,
  Grid,
  TextField,
  Box,
  styled,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";

import useAuth from "app/hooks/useAuth";
import { Paragraph } from "app/components/Typography";
import useNavigatorOnLine from "app/hooks/useNavigationOnline";
import { useToaster } from "rsuite";

// STYLED COMPONENTS
const FlexBox = styled(Box)(() => ({
  display: "flex",
}));

const ContentBox = styled("div")(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
  background: "rgba(0, 0, 0, 0.01)",
}));

const StyledRoot = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#1A2038",
  height: "100vh !important",
  "& .card": {
    maxWidth: 800,
    minHeight: 400,
    margin: "1rem",
    display: "flex",
    borderRadius: 12,
    alignItems: "center",
  },

  ".img-wrapper": {
    height: "100%",
    minWidth: 320,
    display: "flex",
    padding: "2rem",
    alignItems: "center",
    justifyContent: "center",
  },
}));

// form field validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(4, "Password must be 6 character length")
    .required("Password is required!"),
  email: Yup.string().required("Email is required!"),
  otp: Yup.string().min(6, "6 Digits OTP required"),
});

export default function Login() {
  const theme = useTheme();
  const toaster = useToaster();
  const navigate = useNavigate();
  const { login, verifyOTP } = useAuth();
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const isOnline = useNavigatorOnLine();

  const handleFormSubmit = async (values) => {
    setLoading(true);
    if (authenticated) {
      try {
        await verifyOTP(values.email, values.password, values.otp);

        navigate("/dashboard", { replace: true });
      } catch (e) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await login(values.email, values.password);
        setAuthenticated(true);
        //navigate("/Dashboard");
        //toaster("Loggedin successfully, please verify OTP",)
        /*         toaster.push("Loggedin successfully, please verify OTP", {
          placement: "topCenter",
          duration: 5000,
        }); */
      } catch (e) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      otp: "",
      remember: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  return (
    <StyledRoot>
      <Card className="card">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <div className="img-wrapper">
              <img
                src="/assets/images/illustrations/dreamer.svg"
                width="100%"
                alt=""
              />
            </div>
          </Grid>

          <Grid item sm={6} xs={12}>
            <ContentBox>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  disabled={authenticated}
                  fullWidth
                  size="small"
                  type="text"
                  name="email"
                  label="Email/Username"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  helperText={formik.touched.email && formik.errors.email}
                  error={Boolean(formik.errors.email && formik.touched.email)}
                  sx={{ mb: 3 }}
                />

                <TextField
                  disabled={authenticated}
                  fullWidth
                  size="small"
                  name="password"
                  type="password"
                  label="Password"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  helperText={formik.touched.password && formik.errors.password}
                  error={Boolean(
                    formik.errors.password && formik.touched.password
                  )}
                  sx={{ mb: 1.5 }}
                />

                {authenticated && (
                  <TextField
                    fullWidth
                    size="small"
                    name="otp"
                    type="text"
                    label="OTP"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    value={formik.values.otp}
                    onChange={formik.handleChange}
                    helperText={formik.touched.otp && formik.errors.otp}
                    error={Boolean(formik.errors.otp && formik.touched.otp)}
                    sx={{ mb: 1.5 }}
                  />
                )}

                <FlexBox justifyContent="space-between">
                  <FlexBox gap={1}>
                    <Checkbox
                      size="small"
                      name="remember"
                      onChange={formik.handleChange}
                      checked={formik.values.remember}
                      sx={{ padding: 0 }}
                    />

                    <Paragraph>Remember Me</Paragraph>
                  </FlexBox>

                  <NavLink to="#" style={{ color: theme.palette.primary.main }}>
                    Forgot password?
                  </NavLink>
                </FlexBox>

                <LoadingButton
                  disabled={!isOnline}
                  type="submit"
                  color="primary"
                  loading={loading}
                  variant="contained"
                  sx={{ my: 2 }}
                >
                  Login
                </LoadingButton>
              </form>
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
    </StyledRoot>
  );
}
