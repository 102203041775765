import * as React from "react";
import AgGrid from "app/components/AgGrid";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { Panel, Grid, Row, Col, Message, useToaster } from "rsuite";
import SideDrawer from "app/components/SideDrawer";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import * as yup from "yup";

import { Status } from "app/DataSource";
import { useProps } from "./CLIAContext";
import { filterParams } from "functions";

const validationSchema = yup.object({
  Status: yup.string().required("Status is required"),
});
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function ListCLIAReference() {
  const axiosPrivate = useAxiosPrivate();
  const toaster = useToaster();
  const [oldStatus, setOldStatus] = React.useState("");

  const [rowHotData, setHotRowData] = React.useState([{}]);
  const [loading, setLoading] = React.useState(false);
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const handleViewModalOpen = () => setOpenViewModal(true);
  const handleViewModalClose = () => setOpenViewModal(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({
    Status: null,
  });
  const { updatePropsValue } = useProps();

  const handleUpdateMessage = () => {
    const NewData = filteredRows.map((dt) => dt.data.UniqueID);
    updatePropsValue(NewData);
    //console.log(propsValue);
  };
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      handleUpdateMessage();
      //console.log(filteredRows);
    }
    return () => {
      isSubscribed = false;
    };
  }, [filteredRows.length]);
  const theme = useTheme();

  const [childData, setChildData] = React.useState({});
  const UpdateStatus = (data) => {
    axiosPrivate
      .post("UpdateStatusCLIA.php", data)
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          console.log(result);
          toaster.push(
            <Message type="success" bordered showIcon>
              <strong>Success!</strong> {result?.Success}
            </Message>
          );
        }
        if (result.status === 0) {
          toaster.push(
            <Message type="error" bordered showIcon>
              <strong>Error!</strong> {result.Error}
            </Message>
          );
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  const handleChildData = (data) => {
    setChildData(data);
    UpdateStatus(data);
  };
  React.useEffect(() => {
    // console.log(selectedRowData);
    let isSubscribed = true;
    if (isSubscribed)
      if (selectedRowData?.Name) {
        setInitialValues({
          UID: selectedRowData?.UniqueID,
          Status: selectedRowData?.Status,
        });
        setOldStatus(selectedRowData?.Status);
      }
    return () => {
      isSubscribed = false;
    };
  }, [selectedRowData?.Name]);

  // const abc = useReference();
  // console.log(abc);
  const ColorStatus = React.useMemo(() => {
    return ["#FC6E51", "#FFCE54", "#A0D468", "#4FC1E9"];
  }, []);
  const columns = React.useMemo(() => {
    return [
      {
        headerName: "Ref ID",
        field: "ID",
        minWidth: 180,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      {
        field: "Status",
        minWidth: 150,
        editable: true,
        cellStyle: (params) => {
          if (params.value === "HOT" || params.value === "Hot") {
            //mark police cells as red
            return { color: ColorStatus[0], fontWeight: "bold" };
          } else if (params.value === "WARM" || params.value === "Warm") {
            //mark police cells as red
            return { color: ColorStatus[1], fontWeight: "bold" };
          } else if (params.value === "COLD" || params.value === "Cold") {
            //mark police cells as red
            return { color: ColorStatus[2], fontWeight: "bold" };
          } else {
            //mark police cells as red
            return { color: ColorStatus[3] };
          }
        },
      },
      {
        field: "Date",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        editable: false,
        filterParams: filterParams,
      },
      { field: "Place", minWidth: 150, editable: false },

      {
        field: "RefByName",
        minWidth: 150,
        editable: false,
        headerName: "Ref By",
      },
      { field: "Gender", minWidth: 150, editable: false },
      { field: "MaritalStatus", minWidth: 150, editable: false },
      {
        field: "DOB",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
        editable: false,
      },
      { field: "Age", minWidth: 150, editable: false },
      {
        headerName: "Name",
        field: "Name",
        minWidth: 180,
        editable: false,
      },
      { field: "Caste", minWidth: 150, editable: false },
      { field: "CasteCategory", minWidth: 150, editable: false },
      { field: "Address", minWidth: 150, editable: false },
      { field: "Village", minWidth: 150, editable: false },
      { field: "PIN", minWidth: 150, type: "numericColumn", editable: false },
      { field: "Tehsil", minWidth: 150, editable: false },
      { field: "District", minWidth: 150, editable: false },
      { field: "State", minWidth: 150, editable: false },
      {
        field: "WhatsAppNo",
        minWidth: 150,
        type: "numericColumn",
        editable: false,
      },
      {
        field: "MobileNo",
        minWidth: 150,
        type: "numericColumn",
        editable: false,
      },
      { field: "Work", minWidth: 150, editable: false },
      { field: "WorkType", minWidth: 150, editable: false },

      { field: "ExistingAgency", minWidth: 150, editable: false },
      { field: "ExistingAgencyDetails", minWidth: 150, editable: false },
      { field: "FamilyMemberInGovt", minWidth: 150, editable: false },
      { field: "FamilyMemberInGovtDetails", minWidth: 150, editable: false },
      {
        field: "OtherDetails",
        minWidth: 150,
        headerName: "Other Details",
        editable: false,
      },
      { field: "AddedBy", minWidth: 150, editable: false },
      {
        field: "AddedOn",
        minWidth: 150,
        editable: false,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      {
        field: "AssignedTo",
        minWidth: 150,
        editable: false,
        headerName: "Assigned To",
      },
      { field: "UniqueID", hide: true, editable: false },
    ];
  }, []);

  const onGridReady = React.useCallback((params) => {
    setLoading(true);
    axiosPrivate
      .post("LoadCLIAReference.php")
      .then((responce) => {
        const result = responce.data;
        if (result.status === 1) setHotRowData(result.Data);
        else setHotRowData([]);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Panel>
        <AgGrid
          columns={columns}
          rowData={rowHotData}
          onGridReady={onGridReady}
          defaultColumn={["Name"]}
          GoTo="/CLIAProspect/EditCLIAReference"
          showLoading={loading}
          handleViewModalOpen={handleViewModalOpen}
          onRowSelectedData={setSelectedRowData}
          settingsName="CLIA"
          onDataChange={handleChildData}
          headerComponent={() => <p></p>}
          setFilteredRows={setFilteredRows}
          Source="CLIAReference"
        />
      </Panel>
      <SideDrawer
        open={openViewModal}
        onClose={handleViewModalClose}
        title="Change status"
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log(values);
            axiosPrivate
              .post("UpdateStatusReference.php", {
                UID: values.UID,
                Status: values.Status,
              })
              .then((response) => {
                const result = response.data;
                if (result.status === 1) {
                  console.log(result);
                  toaster.push(
                    <Message type="success" bordered showIcon>
                      <strong>Success!</strong> {result?.Success}
                    </Message>
                  );
                }
                if (result.status === 0) {
                  toaster.push(
                    <Message type="error" bordered showIcon>
                      <strong>Error!</strong> {result.Error}
                    </Message>
                  );
                }
                setSubmitting(false);
                resetForm();
              })
              .catch((error) => {
                console.error("Error submitting data:", error);
                setSubmitting(false);
              });
            console.log(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            isValidating,
            isSubmitting,
            touched,
            setFieldValue,
            handleBlur,
            handleChange,
          }) => {
            console.log(errors);
            console.log(values);
            return (
              <form>
                <div>
                  <div className="row">
                    <Grid fluid style={{ padding: 4 }}>
                      <Row className="show-grid">
                        <Col xs={24} sm={24} md={24}>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <FormControl
                              variant="standard"
                              sx={{ minWidth: 120 }}
                            >
                              <InputLabel id="demo-simple-select-standard-label">
                                Status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Status"
                                name="Status"
                                value={values?.Status || ""}
                                onChange={handleChange}
                                error={touched.Status && Boolean(errors.Status)}
                                helperText={touched.Status && errors.Status}
                              >
                                {Status.map((name) => (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, Status, theme)}
                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Row>
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                  &nbsp; &nbsp;
                  <>
                    <LoadingButton
                      disabled={isSubmitting}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                      }}
                      loading={isValidating}
                      sx={{ my: 2 }}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </LoadingButton>
                  </>
                </div>
              </form>
            );
          }}
        </Formik>
      </SideDrawer>
    </>
  );
}
