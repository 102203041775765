import useAuth from "app/hooks/useAuth";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequireAuth = ({ allowedRoles }) => {
  const { user, roles } = useAuth();

  //console.log("Auth inside requireAuth=>" + JSON.stringify(user));
  const location = useLocation();

  return roles?.find((role) => allowedRoles?.includes(role)) && user ? (
    <Outlet />
  ) : user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
