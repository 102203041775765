import * as React from "react";
import AgGrid from "app/components/AgGrid";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import {
  Panel,
  Grid,
  Row,
  Col,
  Message,
  useToaster,
  Stack,
  DateRangePicker,
  Heading,
} from "rsuite";
import SideDrawer from "app/components/SideDrawer";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import * as yup from "yup";

import { Status } from "app/DataSource";
import { useProps } from "./CLIAContext";
import { DeleteForever } from "@mui/icons-material";

import { addDays } from "date-fns";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";

import { startOfToday } from "date-fns";
import moment from "moment";
import { filterParams } from "functions";
function getLastFinancialYearDates() {
  const today = startOfToday();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  let startOfLastFY, endOfLastFY;

  if (currentMonth >= 3) {
    // From April to December
    startOfLastFY = new Date(currentYear - 1, 3, 1); // April 1st of last year
    endOfLastFY = new Date(currentYear, 2, 31); // March 31st of this year
  } else {
    // From January to March
    startOfLastFY = new Date(currentYear - 2, 3, 1); // April 1st two years ago
    endOfLastFY = new Date(currentYear - 1, 2, 31); // March 31st of last year
  }

  return {
    startOfLastFY,
    endOfLastFY,
  };
}

const { startOfLastFY, endOfLastFY } = getLastFinancialYearDates();

function getCurrentFinancialYearDates() {
  const today = startOfToday();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  let startOfCurrentFY, endOfCurrentFY;

  if (currentMonth >= 3) {
    // From April to December
    startOfCurrentFY = new Date(currentYear, 3, 1); // April 1st of this year
    endOfCurrentFY = new Date(currentYear + 1, 2, 31); // March 31st of next year
  } else {
    // From January to March
    startOfCurrentFY = new Date(currentYear - 1, 3, 1); // April 1st of last year
    endOfCurrentFY = new Date(currentYear, 2, 31); // March 31st of this year
  }

  return {
    startOfCurrentFY,
    endOfCurrentFY,
  };
}

const { startOfCurrentFY, endOfCurrentFY } = getCurrentFinancialYearDates();

const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
  },
  {
    label: "Last Fin Year",
    value: [startOfLastFY, endOfLastFY],
  },
  {
    label: "Current Fin Year",
    value: [startOfCurrentFY, endOfCurrentFY],
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
  },
  {
    label: "Tomorrow",
    value: [addDays(new Date(), 1), addDays(new Date(), 1)],
  },
  {
    label: "Next week",
    value: [
      startOfWeek(addDays(new Date(), 6)),
      endOfWeek(addDays(new Date(), 6)),
    ],
  },
  {
    label: "Next 7 days",
    value: [new Date(), addDays(new Date(), 6)],
  },
  {
    label: "Next 30 days",
    value: [new Date(), addDays(new Date(), 29)],
  },
  {
    label: "Next month",
    value: [
      startOfMonth(addMonths(new Date(), 1)),
      endOfMonth(addMonths(new Date(), 1)),
    ],
  },
];

const validationSchema = yup.object({
  Status: yup.string().required("Status is required"),
});
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function MBGDueList() {
  const axiosPrivate = useAxiosPrivate();
  const toaster = useToaster();
  const [oldStatus, setOldStatus] = React.useState("");
  const [value, setValue] = React.useState([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  const [rowHotData, setHotRowData] = React.useState([{}]);
  const [loading, setLoading] = React.useState(false);
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const handleViewModalOpen = () => setOpenViewModal(true);
  const handleViewModalClose = () => setOpenViewModal(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({
    Status: null,
  });
  const { updatePropsValue } = useProps();

  const handleUpdateMessage = () => {
    const NewData = filteredRows.map((dt) => dt.data.UniqueID);
    updatePropsValue(NewData);
    //console.log(propsValue);
  };
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      handleUpdateMessage();
      //console.log(filteredRows);
    }
    return () => {
      isSubscribed = false;
    };
  }, [filteredRows.length]);
  const theme = useTheme();

  const [childData, setChildData] = React.useState({});
  const UpdateStatus = (data) => {
    axiosPrivate
      .post("UpdateStatusCLIA.php", data)
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          console.log(result);
          toaster.push(
            <Message type="success" bordered showIcon>
              <strong>Success!</strong> {result?.Success}
            </Message>
          );
        }
        if (result.status === 0) {
          toaster.push(
            <Message type="error" bordered showIcon>
              <strong>Error!</strong> {result.Error}
            </Message>
          );
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  const handleChildData = (data) => {
    setChildData(data);
    UpdateStatus(data);
  };
  React.useEffect(() => {
    // console.log(selectedRowData);
    let isSubscribed = true;
    if (isSubscribed)
      if (selectedRowData?.Name) {
        setInitialValues({
          UID: selectedRowData?.UniqueID,
          Status: selectedRowData?.Status,
        });
        setOldStatus(selectedRowData?.Status);
      }
    return () => {
      isSubscribed = false;
    };
  }, [selectedRowData?.Name]);

  const columns = React.useMemo(() => {
    return [
      {
        headerName: "Ref ID",
        field: "ID",
        minWidth: 180,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      { field: "AgencyStatus", minWidth: 150, editable: false },
      {
        field: "Date",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        editable: false,
        filterParams: filterParams,
      },
      { field: "Place", minWidth: 150, editable: false },

      {
        field: "RefByName",
        minWidth: 150,
        editable: false,
        headerName: "Ref By",
      },
      { field: "Gender", minWidth: 150, editable: false },
      {
        field: "DOB",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
        editable: false,
      },
      { field: "Age", minWidth: 150, editable: false },
      {
        headerName: "Name",
        field: "Name",
        minWidth: 180,
        editable: false,
      },
      { field: "Caste", minWidth: 150, editable: false },
      { field: "Address", minWidth: 150, editable: false },
      { field: "Village", minWidth: 150, editable: false },
      { field: "PIN", minWidth: 150, type: "numericColumn", editable: false },
      { field: "Tehsil", minWidth: 150, editable: false },
      { field: "District", minWidth: 150, editable: false },
      { field: "State", minWidth: 150, editable: false },
      {
        field: "WhatsAppNo",
        minWidth: 150,
        type: "numericColumn",
        editable: false,
      },
      {
        field: "MobileNo",
        minWidth: 150,
        type: "numericColumn",
        editable: false,
      },
      { field: "Profession", minWidth: 150, editable: false },
      { field: "WorkType", minWidth: 150, editable: false },
      { field: "ExistingAgency", minWidth: 150, editable: false },
      { field: "ExistingAgencyDetails", minWidth: 150, editable: false },
      { field: "FamilyMemberInGovt", minWidth: 150, editable: false },
      { field: "FamilyMemberInGovtDetails", minWidth: 150, editable: false },

      { field: "Branch", minWidth: 150, editable: false },
      { field: "CertificateBy", minWidth: 150, editable: false },
      {
        field: "MRDate",
        minWidth: 150,
        editable: false,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      { field: "MrNo", minWidth: 150, editable: false, headerName: "MR No" },
      { field: "URNNo", minWidth: 150, editable: false },
      {
        field: "ExamDate",
        minWidth: 150,
        editable: false,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      { field: "ExamCenter", minWidth: 150, editable: false },
      { field: "Result", minWidth: 150, editable: false },
      { field: "AgencyCategory", minWidth: 150, editable: false },
      { field: "AgencyCode", minWidth: 150, editable: false },

      {
        field: "AppointmentDate",
        minWidth: 150,
        editable: false,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      { field: "Branch", minWidth: 150, editable: false },
      { field: "CLIACode", minWidth: 150, editable: false },
      {
        field: "MBGDate1",
        headerName: "MBG Date",
        minWidth: 150,
        editable: false,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      {
        field: "OtherDetails",
        minWidth: 150,
        headerName: "Other Details",
        editable: false,
      },
      { field: "AddedBy", minWidth: 150, editable: false },
      {
        field: "AddedOn",
        minWidth: 150,
        editable: false,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      {
        field: "AssignedToName",
        minWidth: 150,
        editable: false,
        headerName: "Assigned To",
      },
      { field: "UniqueID", hide: true, editable: false },
    ];
  }, []);

  const onGridReady = React.useCallback(
    (params) => {
      console.log(value);
      setLoading(true);
      axiosPrivate
        .post("LoadCLIAAgencyDataMBGDate.php", {
          Start: moment(value[0]).format("YYYY-MM-DD"),
          End: moment(value[1]).format("YYYY-MM-DD"),
        })
        .then((responce) => {
          const result = responce.data;
          if (result.status === 1) setHotRowData(result.Data);
          else setHotRowData([]);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    },
    [value[0], value[1]]
  );

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Stack
            direction="row"
            spacing={4}
            style={{ flex: 0.3, justifyContent: "flex-start", padding: 5 }}
          >
            <DateRangePicker
              ranges={predefinedRanges}
              value={value}
              onChange={(e) => {
                console.log(e);
                setValue(e);
              }}
              cleanable={false}
              format="dd-MM-yyyy"
            />
            <button type="button" className="Button" onClick={onGridReady}>
              Apply
            </button>
          </Stack>
          <Heading
            level={5}
            style={{
              textAlign: "center",
              flex: 0.5,
            }}
          >
            {`MBG Due List`}
          </Heading>
          <Stack
            direction="row"
            spacing={2}
            style={{ flex: 0.2, justifyContent: "flex-end" }}
          >
            {/* <Button
              disabled={!permissions.includes(10000)}
              onClick={clearAll}
              appearance="link"
              startIcon={<DeleteForever />}
            >
              Clear All
            </Button> */}
          </Stack>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <Panel>
          <AgGrid
            columns={columns}
            rowData={rowHotData}
            onGridReady={onGridReady}
            defaultColumn={["Name"]}
            GoTo="/CLIAProspect/EditCLIAAgencyData"
            showLoading={loading}
            handleViewModalOpen={handleViewModalOpen}
            onRowSelectedData={setSelectedRowData}
            settingsName="CLIA"
            onDataChange={handleChildData}
            headerComponent={() => <p></p>}
            setFilteredRows={setFilteredRows}
            Source="CLIAAgencyData"
          />
        </Panel>
      </div>
      <SideDrawer
        open={openViewModal}
        onClose={handleViewModalClose}
        title="Change status"
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log(values);
            axiosPrivate
              .post("UpdateStatusProspect1.php", {
                UID: values.UID,
                Status: values.Status,
              })
              .then((response) => {
                const result = response.data;
                if (result.status === 1) {
                  console.log(result);
                  toaster.push(
                    <Message type="success" bordered showIcon>
                      <strong>Success!</strong> {result?.Success}
                    </Message>
                  );
                }
                if (result.status === 0) {
                  toaster.push(
                    <Message type="error" bordered showIcon>
                      <strong>Error!</strong> {result.Error}
                    </Message>
                  );
                }
                setSubmitting(false);
                resetForm();
              })
              .catch((error) => {
                console.error("Error submitting data:", error);
                setSubmitting(false);
              });
            console.log(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            isValidating,
            isSubmitting,
            touched,
            setFieldValue,
            handleBlur,
            handleChange,
          }) => {
            console.log(errors);
            console.log(values);
            return (
              <form>
                <div>
                  <div className="row">
                    <Grid fluid style={{ padding: 4 }}>
                      <Row className="show-grid">
                        <Col xs={24} sm={24} md={24}>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <FormControl
                              variant="standard"
                              sx={{ minWidth: 120 }}
                            >
                              <InputLabel id="demo-simple-select-standard-label">
                                Status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Status"
                                name="Status"
                                value={values?.Status || ""}
                                onChange={handleChange}
                                error={touched.Status && Boolean(errors.Status)}
                                helperText={touched.Status && errors.Status}
                              >
                                {Status.map((name) => (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, Status, theme)}
                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Row>
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                  &nbsp; &nbsp;
                  <>
                    <LoadingButton
                      disabled={isSubmitting}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                      }}
                      loading={isValidating}
                      sx={{ my: 2 }}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </LoadingButton>
                  </>
                </div>
              </form>
            );
          }}
        </Formik>
      </SideDrawer>
    </>
  );
}
