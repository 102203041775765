import {
  Button,
  Col,
  Divider,
  Grid,
  Heading,
  Message,
  Row,
  Stack,
  toaster,
} from "rsuite";
import * as React from "react";

import { v4 as uuidv4 } from "uuid";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { findDuplicateIds, hasInvalidIds, mergeArraysById } from "functions";
import { LoadingButton } from "@mui/lab";

import AgGrid from "app/components/AgGrid";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import SideDrawer from "app/components/SideDrawer";
import { Field, Formik } from "formik";
import { Autocomplete, TextField } from "@mui/material";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import * as yup from "yup";
import useAPINew from "app/hooks/useAPINew";
import { Balance } from "@mui/icons-material";
const validationSchema = yup.object({});
const columns1 = [
  { id: "ID", field: "ID", minWidth: 100 },
  {
    id: "Date",
    field: "Date",
    minWidth: 200,
    type: "date",
    filter: "agDateColumnFilter",
    filterParams: {
      // pass in additional parameters to the Date Filter
    },
  },
  { id: "CashierName", field: "CashierName", minWidth: 200 },
  { id: "PolicyNo", field: "PolicyNo", minWidth: 200 },
  { id: "Name", field: "Name", minWidth: 200 },
  { id: "FUP", field: "FUP", minWidth: 200 },
  { id: "ServiceBranch", field: "ServiceBranch", minWidth: 200 },
  { id: "PremiumAmount", field: "PremiumAmount", minWidth: 200 },
  { id: "Inst", field: "Inst", minWidth: 200 },
  { id: "CDACharges", field: "CDACharges", minWidth: 200 },
  { id: "XCharges", field: "XCharges", minWidth: 200 },
  { id: "CessAmt", field: "CessAmt", minWidth: 200 },
  { id: "LateFee", field: "LateFee", minWidth: 200 },
  { id: "LateFeeDiscount", field: "LateFeeDiscount", minWidth: 200 },
  { id: "GSTAmt", field: "GSTAmt", minWidth: 200 },
  { id: "TotAmtDR", field: "TotAmtDR", minWidth: 200 },
  { id: "Cash", field: "Cash", minWidth: 200 },
  { id: "Bank", field: "Bank", minWidth: 200 },
  { id: "Balance", field: "Balance", minWidth: 200 },
  { id: "PaymentBy", field: "PaymentBy", minWidth: 200 },
  { id: "Remark", field: "Remark", minWidth: 200 },
  { id: "UploadStatus", field: "UploadStatus", Width: 100 },
  { id: "Message", field: "Message", minWidth: 500 },
];

export default function ImportByPastePremiumPoint() {
  const [data, setData] = React.useState([]);
  const [status, setStatus] = React.useState(false);
  const PaymentByList = useAPINew("GetPaymentByList.php");
  const CashierList = useAPINew("GetCashierList.php");
  const axiosPrivate = useAxiosPrivate();
  const [initialValues, setInitialValues] = React.useState({
    Name: null,
    FUP: null,

    Health: null,
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedRowData, setSelectedRowData] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);

  async function GetDataByID1(ID) {
    try {
      const oldData = data.filter((a) => {
        return a.UniqueID === ID;
      });
      // console.log(oldData);
      if (oldData.length > 0) {
        let parts = oldData[0].FUP.split("/");
        let formattedDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
        let parts2 = oldData[0].Date.split("-");
        let formattedDateString1 = `${parts2[2]}-${parts2[1]}-${parts2[0]}`;
        console.log(oldData);
        // Create a Date object
        let customDateObject = new Date(formattedDateString);
        let customDateObject1 = new Date(formattedDateString1);
        const newData = {
          UniqueID: oldData[0]?.UniqueID,
          ID: oldData[0]?.ID,
          CashierName: oldData[0]?.CashierName
            ? { title: oldData[0]?.CashierName, value: oldData[0]?.CashierName }
            : { title: "", value: "" },
          CDACharges: oldData[0]?.CDACharges,
          CessAmt: oldData[0]?.CessAmt,
          GSTAmt: oldData[0]?.GSTAmt,
          Inst: oldData[0]?.Inst,
          LateFee: oldData[0]?.LateFee,
          LateFeeDiscount: oldData[0]?.LateFeeDiscount,
          Name: oldData[0]?.Name,
          PolicyNo: oldData[0]?.PolicyNo,
          PremiumAmount: oldData[0]?.PremiumAmount,
          ServiceBranch: oldData[0]?.ServiceBranch,
          TotAmtDR: parseFloat(oldData[0]?.TotAmtDR.replace(",", "")),
          XCharges: oldData[0]?.XCharges,
          Cash: oldData[0]?.Cash,
          Bank: oldData[0]?.Bank,
          Balance: oldData[0]?.Balance,
          Remark: oldData[0]?.Remark,
          PaymentBy: oldData[0]?.PaymentBy
            ? { value: oldData[0]?.PaymentBy, title: oldData[0]?.PaymentBy }
            : { value: "", title: "" },

          FUP:
            oldData[0].FUP === "0000-00-00" || oldData[0].FUP === null
              ? null
              : customDateObject,
          Date:
            oldData[0].Date === "0000-00-00" || oldData[0].Date === null
              ? null
              : customDateObject1,
        };
        console.log(newData);
        setInitialValues(newData);
      }
    } catch (error) {
      // console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  }

  const GetDataByID = React.useCallback(async (ID) => {
    try {
      const oldData = data.filter((a) => {
        return a.UniqueID === ID;
      });
      // console.log(oldData);
      if (oldData.length > 0) {
        let parts = oldData[0].FUP.split("/");
        let formattedDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;

        // Create a Date object
        let customDateObject = new Date(formattedDateString);
        const newData = {
          UniqueID: oldData[0]?.UniqueID,
          ID: oldData[0]?.ID,
          CashierName: oldData[0]?.CashierName
            ? { title: oldData[0]?.CashierName, value: oldData[0]?.CashierName }
            : { title: "", value: "" },
          CDACharges: oldData[0]?.CDACharges,
          CessAmt: oldData[0]?.CessAmt,
          GSTAmt: oldData[0]?.GSTAmt,
          Inst: oldData[0]?.Inst,
          LateFee: oldData[0]?.LateFee,
          LateFeeDiscount: oldData[0]?.LateFeeDiscount,
          Name: oldData[0]?.Name,
          PolicyNo: oldData[0]?.PolicyNo,
          PremiumAmount: oldData[0]?.PremiumAmount,
          ServiceBranch: oldData[0]?.ServiceBranch,
          TotAmtDR: oldData[0]?.TotAmtDR,
          XCharges: oldData[0]?.XCharges,
          Cash: oldData[0]?.Cash,
          Bank: oldData[0]?.Bank,
          Balance: oldData[0]?.Balance,
          Remark: oldData[0]?.Remark,
          PaymentBy: oldData[0]?.PaymentBy
            ? { value: oldData[0]?.PaymentBy, title: oldData[0]?.PaymentBy }
            : { value: "", title: "" },

          FUP:
            oldData[0].FUP === "0000-00-00" || oldData[0].FUP === null
              ? null
              : customDateObject,
        };

        setInitialValues(newData);
      }
    } catch (error) {
      // console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  }, []);
  function updateDatainGrid(rawdata, ID) {
    const olddata = data.map((d) =>
      d.UniqueID === ID ? { ...d, ...rawdata } : d
    );
    setData(olddata);
  }
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed)
      if (selectedRowData?.UniqueID) {
        GetDataByID1(selectedRowData.UniqueID);
      }
    return () => {
      isSubscribed = false;
    };
  }, [selectedRowData?.UniqueID, selectedRowData]);

  const handlePaste = async (event) => {
    event.preventDefault();
    // Get the clipboard data
    const clipboardData = await window.navigator.clipboard.readText();
    console.log(clipboardData);
    const pastedData = clipboardData;

    // Parse the pasted data
    const parsedData = parseExcelData(pastedData);

    // Update the row data
    setData(parsedData);
  };

  const parseExcelData = (data) => {
    try {
      // Split the data into rows and columns
      console.log(data);
      const rows = data.split("\n");
      return rows.map((row, index) => {
        const columns = row.split("\t");
        let parts = columns[3].split("/");
        let formattedDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;

        // Create a Date object
        let customDateObject = moment(new Date(formattedDateString)).format(
          "YYYY-MM-DD"
        );
        return {
          Date: moment().format("YYYY-MM-DD"),
          CashierName: "DIFS",
          PolicyNo: columns[1],
          Name: columns[2],
          FUP: customDateObject,
          ServiceBranch: columns[4],
          PremiumAmount: columns[5],
          Inst: columns[6],
          CDACharges: columns[7],
          XCharges: columns[8],
          CessAmt: columns[9],
          LateFee: columns[10],
          LateFeeDiscount: columns[11],
          GSTAmt: columns[12],
          TotAmtDR: columns[13].replace(",", ""),
          Cash: 0,
          Bank: 0,
          Balance: columns[13].replace(",", ""),
          UniqueID: uuidv4(),
          ID: index + 1,
        };
      });
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error</strong> {`Error Occured - Invalid copied data`}
        </Message>
      );
    }
  };

  function onGridReady() {}
  const history = useNavigate();
  const location = useLocation();
  const updateQueryParams = React.useCallback(
    (activeKey) => {
      const params = new URLSearchParams(location.search);
      params.set("data", JSON.stringify(activeKey));
      history(`?${params.toString()}`, { replace: true });
    },
    [history, location.search]
  );

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const nameFromParams = params.get("data");

    if (nameFromParams) {
      setData(JSON.parse(nameFromParams));
    }
  }, [location.search]);

  async function handleImport() {
    setStatus(true);
    if (data.length > 0) {
      const duplicates = findDuplicateIds(data);
      if (hasInvalidIds(data)) {
        console.log("Invalid IDs found:");
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Invalid IDs!</strong> {`There are some invalid IDs`}
          </Message>
        );
        setStatus(false);
        return;
      } else if (duplicates.length > 0) {
        console.log("Duplicate IDs found:");
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Duplicate IDs!</strong>{" "}
            {`Duplicate IDs found - ${duplicates.toString()}`}
          </Message>
        );
        setStatus(false);
        return;
      } else {
        try {
          axiosPrivate
            .post("ImportPremiumPoint.php", {
              data,
            })
            .then((response) => {
              const result = response.data;
              if (result.status === 1) {
                toaster.push(
                  <Message type="success" bordered showIcon>
                    <strong>Success!</strong> {result.Success}
                  </Message>
                );
                const newData = mergeArraysById(data, result.Data);
                console.log(newData);
                setData(newData);
                updateQueryParams(newData);
              }
              if (result.status === 0) {
                toaster.push(
                  <Message type="error" bordered showIcon>
                    <strong>Error!</strong> {result.Error}
                  </Message>
                );
              }
              setStatus(false);
              // resetForm();
            })
            .catch((error) => {
              console.error("Error submitting data:", error);
              setStatus(false);
            });
        } catch (error) {
          console.error(error);
          setStatus(false);
        }
      }
    } else {
      toaster.push(
        <Message type="error" bordered showIcon>
          Please upload a file first
        </Message>
      );
      setStatus(false);
    }
  }

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <Heading level={5} style={{ textAlign: "center" }}>
          Import Premium Point
        </Heading>
      </div>
      <div
        style={{
          width: "100%",
          margin: "10px 0px 10px 0px",
          padding: 5,
          border: "0.5px dashed #999",
          borderRadius: 10,
        }}
      >
        <Stack direction="row" spacing={4}>
          <Button onClick={handlePaste}>Paste</Button>

          <LoadingButton
            disabled={status}
            type="submit"
            color="secondary"
            variant="contained"
            onClick={handleImport}
            loading={status}
            sx={{ my: 2 }}
          >
            {status ? "Uploading..." : "Import"}
          </LoadingButton>
          <Button
            onClick={() => {
              setData([]);
            }}
          >
            Clear
          </Button>
        </Stack>
      </div>
      <div
        style={{
          width: "100%",
          minHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
        }}
      >
        <Grid fluid style={{ padding: 10 }}>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={24}>
              <Row className="show-grid" style={{ maxHeight: 350 }}>
                <AgGrid
                  onRowSelectedData={setSelectedRowData}
                  handleViewModalOpen={handleOpen}
                  settingsName="PastePremiumPoint"
                  headerComponent={() => <p></p>}
                  setFilteredRows={setFilteredRows}
                  Source="PastePremiumPoint"
                  exportAllowed={true}
                  columns={columns1}
                  rowData={data}
                  onGridReady={onGridReady}
                  defaultColumn={["Name"]}
                  showHeaderItems={false}
                  passdata={true}
                />
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
      <SideDrawer
        open={open}
        onClose={handleClose}
        title="Update Premium Point"
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            const newData = {
              ...values,

              CashierName: values.CashierName.value,
              PaymentBy: values.PaymentBy.value,
              FUP: moment(values.FUP).format("YYYY-MM-DD"),
              Date: moment(values.Date).format("YYYY-MM-DD"),
            };
            console.log(newData);
            updateDatainGrid(newData, values.UniqueID);
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleSubmit,
            isValidating,
            isSubmitting,
            touched,
            handleBlur,
            handleChange,
          }) => {
            // console.log(errors);
            // console.log(values);
            return (
              <form>
                <div>
                  <div className="row">
                    <Grid fluid style={{ padding: 4 }}>
                      <Row className="show-grid">
                        <Col xs={24} sm={24} md={24}>
                          <Row
                            gutter={0}
                            style={{
                              marginBottom: 10,
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateField
                                label="Date"
                                variant="standard"
                                size="small"
                                name="Date"
                                format="dd-MM-yyyy"
                                value={values.Date}
                                onChange={(value) =>
                                  setFieldValue("Date", value, true)
                                }
                                onBlur={handleBlur}
                                error={touched.Date && Boolean(errors.Date)}
                                helperText={touched.Date && errors.Date}
                              />
                            </LocalizationProvider>
                          </Row>
                          <Row
                            gutter={0}
                            style={{
                              marginBottom: 10,
                              marginLeft: -10,
                            }}
                          >
                            <Autocomplete
                              key="ID"
                              name={`CashierName`}
                              options={
                                CashierList.error ? [null] : CashierList.data
                              }
                              getOptionLabel={(option) => option?.title}
                              disableClearable={true}
                              value={
                                Boolean(values.CashierName)
                                  ? values.CashierName
                                  : { value: "", title: "" }
                              }
                              onBlur={handleBlur}
                              error={
                                touched.CashierName &&
                                Boolean(errors.CashierName)
                              }
                              helperText={
                                touched.CashierName && errors.CashierName
                              }
                              onChange={(_, newValue) => {
                                console.log(values);
                                console.log(errors);
                                setFieldValue(`CashierName`, newValue, true);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="CashierName"
                                  onBlur={handleBlur}
                                  error={
                                    touched.CashierName &&
                                    Boolean(errors.CashierName)
                                  }
                                  helperText={
                                    touched.CashierName && errors.CashierName
                                  }
                                  label="CashierName"
                                  variant="standard"
                                />
                              )}
                              style={{ width: 200 }}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              fullWidth
                              name={`PolicyNo`}
                              variant="standard"
                              InputLabelProps={{
                                shrink: Boolean(values.PolicyNo),
                              }}
                              label="Policy No"
                              size="small"
                              as={TextField}
                              placeholder="Enter Policy No"
                              onBlur={handleBlur}
                              error={
                                touched.PolicyNo && Boolean(errors.PolicyNo)
                              }
                              helperText={touched.PolicyNo && errors.PolicyNo}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              fullWidth
                              name={`Name`}
                              variant="standard"
                              InputLabelProps={{
                                shrink: Boolean(values.Name),
                              }}
                              label="Name"
                              size="small"
                              as={TextField}
                              placeholder="Enter Name"
                              onBlur={handleBlur}
                              error={touched.Name && Boolean(errors.Name)}
                              helperText={touched.Name && errors.Name}
                            />
                          </Row>
                          <Row
                            gutter={0}
                            style={{
                              marginBottom: 10,
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateField
                                label="FUP"
                                variant="standard"
                                size="small"
                                name="FUP"
                                format="dd-MM-yyyy"
                                value={values.FUP}
                                onChange={(value) =>
                                  setFieldValue("FUP", value, true)
                                }
                                onBlur={handleBlur}
                                error={touched.FUP && Boolean(errors.FUP)}
                                helperText={touched.FUP && errors.FUP}
                              />
                            </LocalizationProvider>
                          </Row>

                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`ServiceBranch`}
                              fullWidth
                              label="ServiceBranch"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.ServiceBranch),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Service Branch"
                              onBlur={handleBlur}
                              error={
                                touched.ServiceBranch &&
                                Boolean(errors.ServiceBranch)
                              }
                              helperText={
                                touched.ServiceBranch && errors.ServiceBranch
                              }
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`PremiumAmount`}
                              fullWidth
                              label="Premium Amount"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.PremiumAmount),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Premium Amount"
                              onBlur={handleBlur}
                              error={
                                touched.PremiumAmount &&
                                Boolean(errors.PremiumAmount)
                              }
                              helperText={
                                touched.PremiumAmount && errors.PremiumAmount
                              }
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`Inst`}
                              fullWidth
                              label="Inst"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.Inst),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Inst"
                              onBlur={handleBlur}
                              error={touched.Inst && Boolean(errors.Inst)}
                              helperText={touched.Inst && errors.Inst}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`CDACharges`}
                              fullWidth
                              label="CDACharges"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.CDACharges),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter CDACharges"
                              onBlur={handleBlur}
                              error={
                                touched.CDACharges && Boolean(errors.CDACharges)
                              }
                              helperText={
                                touched.CDACharges && errors.CDACharges
                              }
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`XCharges`}
                              fullWidth
                              label="XCharges"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.XCharges),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter XCharges"
                              onBlur={handleBlur}
                              error={
                                touched.XCharges && Boolean(errors.XCharges)
                              }
                              helperText={touched.XCharges && errors.XCharges}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`CessAmt`}
                              fullWidth
                              label="CessAmt"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.CessAmt),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter CessAmt"
                              onBlur={handleBlur}
                              error={touched.CessAmt && Boolean(errors.CessAmt)}
                              helperText={touched.CessAmt && errors.CessAmt}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`LateFee`}
                              fullWidth
                              label="LateFee"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.LateFee),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Late Fee"
                              onBlur={handleBlur}
                              error={touched.LateFee && Boolean(errors.LateFee)}
                              helperText={touched.LateFee && errors.LateFee}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`LateFeeDiscount`}
                              fullWidth
                              label="Late Fee Discount"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.LateFeeDiscount),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Late Fee Discount"
                              onBlur={handleBlur}
                              error={
                                touched.LateFeeDiscount &&
                                Boolean(errors.LateFeeDiscount)
                              }
                              helperText={
                                touched.LateFeeDiscount &&
                                errors.LateFeeDiscount
                              }
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`GSTAmt`}
                              fullWidth
                              label="GSTAmt"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.GSTAmt),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter GST Amt"
                              onBlur={handleBlur}
                              error={touched.GSTAmt && Boolean(errors.GSTAmt)}
                              helperText={touched.GSTAmt && errors.GSTAmt}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`TotAmtDR`}
                              fullWidth
                              label="Total Amt DR"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.TotAmtDR),
                              }}
                              variant="standard"
                              as={TextField}
                              onChange={(E) => {
                                handleChange(E);
                                let Amnt = E.target.value;
                                let Balance =
                                  parseFloat(Amnt) -
                                  parseFloat(values.Cash || 0) -
                                  parseFloat(values.Bank || 0);
                                setFieldValue("Balance", Balance);
                              }}
                              placeholder="Enter Total Amt DR"
                              onBlur={handleBlur}
                              error={
                                touched.TotAmtDR && Boolean(errors.TotAmtDR)
                              }
                              helperText={touched.TotAmtDR && errors.TotAmtDR}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`Cash`}
                              fullWidth
                              label="Cash"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.Cash),
                              }}
                              onChange={(E) => {
                                handleChange(E);
                                let Amnt = E.target.value;
                                let Balance =
                                  parseFloat(values.TotAmtDR) -
                                  parseFloat(Amnt || 0) -
                                  parseFloat(values.Bank || 0);
                                setFieldValue("Balance", Balance);
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Cash"
                              onBlur={handleBlur}
                              error={touched.Cash && Boolean(errors.Cash)}
                              helperText={touched.Cash && errors.Cash}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`Bank`}
                              fullWidth
                              label="Bank"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.Bank),
                              }}
                              variant="standard"
                              as={TextField}
                              onChange={(E) => {
                                handleChange(E);
                                let Amnt = E.target.value;
                                let Balance =
                                  parseFloat(values.TotAmtDR) -
                                  parseFloat(values.Cash || 0) -
                                  parseFloat(Amnt);
                                setFieldValue("Balance", Balance);
                              }}
                              placeholder="Enter Bank"
                              onBlur={handleBlur}
                              error={touched.Bank && Boolean(errors.Bank)}
                              helperText={touched.Bank && errors.Bank}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`Balance`}
                              fullWidth
                              label="Total Balance"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.Balance),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Balance"
                              onBlur={handleBlur}
                              error={touched.Balance && Boolean(errors.Balance)}
                              helperText={touched.Balance && errors.Balance}
                            />
                          </Row>
                          <Row
                            gutter={0}
                            style={{
                              marginBottom: 10,
                              marginLeft: -10,
                            }}
                          >
                            <Autocomplete
                              key="ID"
                              name={`PaymentBy`}
                              options={
                                PaymentByList.error
                                  ? [null]
                                  : PaymentByList.data
                              }
                              getOptionLabel={(option) => option?.title}
                              disableClearable={true}
                              value={
                                Boolean(values.PaymentBy)
                                  ? values.PaymentBy
                                  : { value: "", title: "" }
                              }
                              onBlur={handleBlur}
                              error={
                                touched.PaymentBy && Boolean(errors.PaymentBy)
                              }
                              helperText={touched.PaymentBy && errors.PaymentBy}
                              onChange={(_, newValue) => {
                                console.log(values);
                                console.log(errors);
                                setFieldValue(`PaymentBy`, newValue, true);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="PaymentBy"
                                  onBlur={handleBlur}
                                  error={
                                    touched.PaymentBy &&
                                    Boolean(errors.PaymentBy)
                                  }
                                  helperText={
                                    touched.PaymentBy && errors.PaymentBy
                                  }
                                  label="Payment By"
                                  variant="standard"
                                />
                              )}
                              style={{ width: 200 }}
                            />
                          </Row>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <Field
                              name={`Remark`}
                              fullWidth
                              label="Remark"
                              size="small"
                              InputLabelProps={{
                                shrink: Boolean(values.Remark),
                              }}
                              variant="standard"
                              as={TextField}
                              placeholder="Enter Remark"
                              onBlur={handleBlur}
                              error={touched.Remark && Boolean(errors.Remark)}
                              helperText={touched.Remark && errors.Remark}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                  <Divider />
                  &nbsp; &nbsp;
                  <>
                    <LoadingButton
                      disabled={isSubmitting}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                      }}
                      loading={isValidating}
                      sx={{ my: 2 }}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </LoadingButton>
                    &nbsp; &nbsp;
                    <Button color="red" appearance="primary" sx={{ my: 2 }}>
                      {"Delete"}
                    </Button>
                  </>
                </div>
              </form>
            );
          }}
        </Formik>
      </SideDrawer>
    </>
  );
}
