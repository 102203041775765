import { useLocation, Navigate } from "react-router-dom";
import React, { useCallback } from "react";
import ExportIcon from "@rsuite/icons/Export";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { Button } from "rsuite";
import moment from "moment";

export function Table2XLSX({
  tableID = "tabledata",
  exportFileName = "Export",
}) {
  /* Callback invoked when the button is clicked */
  /* Create worksheet from HTML DOM TABLE */
  const handleExport = useCallback(async () => {
    const table = document.getElementById(tableID);
    const wb = XLSX.utils.table_to_book(table);
    XLSX.utils.sheet_add_aoa(wb, [["Name", "Birthday", "Age", "City"]], {
      origin: "A1",
    });
    XLSX.utils.sheet_add_aoa(wb, [["Created " + new Date().toISOString()]], {
      origin: -1,
    });

    XLSX.writeFile(wb, `${exportFileName}.xlsx`);
  }, []);
  /*   const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";


  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension); */

  return (
    <>
      <Button color="green" appearance="subtle" onClick={handleExport}>
        <ExportIcon style={{ margin: "5px" }} /> Export
      </Button>
    </>
  );
}

export function exportjson(Sheet = "Data", data) {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);

  XLSX.utils.book_append_sheet(wb, ws, Sheet);
  // Bold the first row (header row)
  const boldFirstRow = { bold: true };
  wb["!rows"] = [{}, boldFirstRow];
  // XLSX.utils.book_append_sheet(wb, ws, "Data");
  // Bold the first row (header row)
  ws["!cols"] = [
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 15 },
    { wch: 25 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
  ];

  const startCell = { r: 1, c: 9 }; // Replace with the starting row and column indices
  const endCell = { r: 100, c: 21 }; // Replace with the ending row and column indices

  // Iterate through the range and set the format for each cell
  for (let rowNum = startCell.r; rowNum <= endCell.r; rowNum++) {
    for (let colNum = startCell.c; colNum <= endCell.c; colNum++) {
      const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });

      // Set the desired format for the cell
      if (ws[cellAddress]) {
        ws[cellAddress].z = "₹ #,##0.00;[Red]₹ -#,##0.00"; // Replace with your desired format, e.g., '0.00' for two decimal places
        ws[cellAddress].s = { font: { bold: true } };
      }
    }
  }
  XLSX.utils.sheet_add_aoa(ws, [], { origin: -1 });
  XLSX.utils.sheet_add_aoa(
    ws,
    [["Created " + moment().format("DD-MM-YYYY h:mm:ss a")]],
    {
      origin: -1,
    }
  );

  // Package and Release Data (`writeFile` tries to write and save an XLSB file)
  XLSX.writeFile(wb, `${Sheet}-${moment().format("DDMMYYYYhmmssa")}.xlsx`);
  XLSX.write(wb, { Props: { Author: "WebCRMPro" } });
}
export function ellipsify(str) {
  if (str && str.length > 20) {
    return str.substring(0, 20) + "...";
  } else {
    return str;
  }
}
export function toAmnt(amnt, ifZero = "0") {
  if (isNaN(amnt)) {
    return Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    }).format(0);
  } else if (amnt === 0 || amnt === "0.00") {
    return Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    }).format(ifZero);
  } else {
    return Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    }).format(amnt);
  }
}

export function balanceAmnt(amnt, ifZero = "0") {
  if (isNaN(amnt)) {
    return Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    }).format(0);
  } else if (amnt === 0 || amnt === "0.00") {
    return Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    }).format(ifZero);
  } else {
    if (amnt < 0) {
      return (
        Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 2,
        }).format(amnt * -1) + " CR"
      );
    } else {
      return (
        Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 2,
        }).format(amnt) + " DR"
      );
    }
  }
}

export function balanceAmntService(amnt, ifZero = "0") {
  if (isNaN(amnt)) {
    return Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    }).format(0);
  } else if (amnt === 0 || amnt === "0.00") {
    return Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    }).format(ifZero);
  } else {
    if (amnt < 0) {
      return (
        Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 2,
        }).format(amnt * -1) + " DR"
      );
    } else {
      return (
        Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 2,
        }).format(amnt) + " CR"
      );
    }
  }
}
export function toPercent(num, ifZero = "0.00 %") {
  if (isNaN(num)) {
    return "0.00 %";
  } else if (num === 0 || num === "0.00") {
    return ifZero;
  } else {
    return parseFloat(num).toFixed(2) + " %";
  }
}

export function calculateAge(dateOfBirth) {
  const dob = new Date(dateOfBirth);
  const today = new Date();

  const years = today.getFullYear() - dob.getFullYear();

  // Check if the birthday hasn't occurred this year yet
  if (
    today.getMonth() < dob.getMonth() ||
    (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())
  ) {
    return years - 1;
  } else {
    return years;
  }
}

export function ValidateEmail(inputText) {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (inputText.match(mailformat)) {
    return true;
  } else {
    return false;
  }
}

export function validateNumber(val) {
  var phoneno = /^\d{10}$/;
  if (val.match(phoneno)) {
    return true;
  } else {
    return false;
  }
}

export function validateOnlyNumber(val) {
  var no = /^\d$/;
  console.log(val.match(no));
  if (val.match(no)) {
    return true;
  } else {
    return false;
  }
}

export function number2text(value) {
  var fraction = Math.round(frac(value) * 100);
  var f_text = "";

  if (fraction > 0) {
    f_text = "AND " + convert_number(fraction) + " PAISE";
  }

  return convert_number(value) + " RUPEE(S) " + f_text + " ONLY";
}

function frac(f) {
  return f % 1;
}

function convert_number(number) {
  if (number < 0 || number > 999999999) {
    return "NUMBER OUT OF RANGE!";
  }
  var Gn = Math.floor(number / 10000000); /* Crore */
  number -= Gn * 10000000;
  var kn = Math.floor(number / 100000); /* lakhs */
  number -= kn * 100000;
  var Hn = Math.floor(number / 1000); /* thousand */
  number -= Hn * 1000;
  var Dn = Math.floor(number / 100); /* Tens (deca) */
  number = number % 100; /* Ones */
  var tn = Math.floor(number / 10);
  var one = Math.floor(number % 10);
  var res = "";

  if (Gn > 0) {
    res += convert_number(Gn) + " CRORE";
  }
  if (kn > 0) {
    res += (res === "" ? "" : " ") + convert_number(kn) + " LAKH";
  }
  if (Hn > 0) {
    res += (res === "" ? "" : " ") + convert_number(Hn) + " THOUSAND";
  }

  if (Dn) {
    res += (res === "" ? "" : " ") + convert_number(Dn) + " HUNDRED";
  }

  var ones = Array(
    "",
    "ONE",
    "TWO",
    "THREE",
    "FOUR",
    "FIVE",
    "SIX",
    "SEVEN",
    "EIGHT",
    "NINE",
    "TEN",
    "ELEVEN",
    "TWELVE",
    "THIRTEEN",
    "FOURTEEN",
    "FIFTEEN",
    "SIXTEEN",
    "SEVENTEEN",
    "EIGHTEEN",
    "NINETEEN"
  );
  var tens = Array(
    "",
    "",
    "TWENTY",
    "THIRTY",
    "FOURTY",
    "FIFTY",
    "SIXTY",
    "SEVENTY",
    "EIGHTY",
    "NINETY"
  );

  if (tn > 0 || one > 0) {
    if (!(res === "")) {
      res += " AND ";
    }
    if (tn < 2) {
      res += ones[tn * 10 + one];
    } else {
      res += tens[tn];
      if (one > 0) {
        res += "-" + ones[one];
      }
    }
  }

  if (res === "") {
    res = "zero";
  }
  return res;
}
export function GetLastDay(date) {
  var year = date.getFullYear(),
    month = date.getMonth();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  return lastDay;
}

function getOperatingSystem(window) {
  let operatingSystem = "Not known";
  if (window.navigator.appVersion.indexOf("Win") !== -1) {
    operatingSystem = "Windows OS";
  }
  if (window.navigator.appVersion.indexOf("Mac") !== -1) {
    operatingSystem = "MacOS";
  }
  if (window.navigator.appVersion.indexOf("X11") !== -1) {
    operatingSystem = "UNIX OS";
  }
  if (window.navigator.appVersion.indexOf("Linux") !== -1) {
    operatingSystem = "Linux OS";
  }

  return operatingSystem;
}

function getBrowser(window) {
  let currentBrowser = "Not known";
  if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
    currentBrowser = "Google Chrome";
  } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
    currentBrowser = "Mozilla Firefox";
  } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
    currentBrowser = "Internet Exployer";
  } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
    currentBrowser = "Edge";
  } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
    currentBrowser = "Safari";
  } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
    currentBrowser = "Opera";
  } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
    currentBrowser = "YaBrowser";
  } else {
    console.log("Others");
  }

  return currentBrowser;
}
function compare(a, b) {
  let nameA = a.toUpperCase();
  let nameB = b.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}
export const OS = (window) => getOperatingSystem(window);
export const currentBrowser = (window) => getBrowser(window);
export { compare };

export const hasDuplicateIds = (array) => {
  const ids = array.map((item) => item.ID);
  const uniqueIds = new Set(ids);
  return ids.length !== uniqueIds.size;
};

// Alternatively, return the duplicate IDs
export const findDuplicateIds = (array) => {
  const idCount = {};
  const duplicates = [];
  array.forEach((item) => {
    if (idCount[item.ID]) {
      idCount[item.ID]++;
    } else {
      idCount[item.ID] = 1;
    }
  });
  for (const id in idCount) {
    if (idCount[id] > 1) {
      duplicates.push(id);
    }
  }
  return duplicates;
};

export const hasInvalidIds = (array) => {
  return array.some(
    (item) => item.ID === "" || item.ID === 0 || item.ID == null
  );
};

export const mergeArraysById = (arr1, arr2) => {
  const merged = {};

  arr1.forEach((item) => {
    merged[item.ID] = { ...item };
  });

  arr2.forEach((item) => {
    if (merged[item.ID]) {
      merged[item.ID] = { ...merged[item.ID], ...item };
    } else {
      merged[item.ID] = { ...item };
    }
  });

  return Object.values(merged);
};
// Debounce function to limit the rate of updating the URL
export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  minValidYear: 1950,
  maxValidYear: 2050,
  inRangeFloatingFilterDateFormat: "DD/MM/YYYY",
};
