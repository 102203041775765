import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
  CalendarIcon,
  DateField,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import React, { useReducer, useState } from "react";
import { Formik, Field } from "formik";
import "moment/locale/en-in";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  TextField,
  useTheme,
} from "@mui/material";
import {
  Col,
  Divider,
  Grid,
  Heading,
  Row,
  Stack,
  Radio,
  RadioGroup,
} from "rsuite";
import { Uploader, Message, Loader, useToaster, Button } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";

import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import useAuth from "app/hooks/useAuth";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import {
  CalendarMonth,
  DeleteForever,
  Image,
  NoteAdd,
  People,
  PictureAsPdf,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useNavigate, useParams } from "react-router-dom";
import {
  ADD_INPUT,
  DELETE_CLIA,
  EDIT_CLIA,
  EDIT_PROSPECT,
  VIEW_CLIA,
} from "Permissions";
import { useProps } from "./CLIAContext";
import { LeadStatus, Status } from "app/DataSource";
import useAPINew from "app/hooks/useAPINew";
import SideDrawer from "app/components/SideDrawer";
import SideInputs from "../Inputs/SideInputs";
import { styled, lighten, darken } from "@mui/system";
const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const today = new Date();
today.setHours(0, 0, 0, 0);

const validationSchema = yup.object({
  Place: yup.string("Valid place"),

  Name: yup.string("valid name is required").required("Name is required"),
  PIN: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits")
    .nullable(true),
  Status: yup.string().required("Status is required"),

  WhatsAppNo: yup.string().matches(/^\d{10}$/, "Mobile number is not valid"),
  MobileNo: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number is not valid")
    .nullable(true),
  RefBy: yup.object().shape({
    title: yup
      .string("Error")
      .required("You must select one of the valid options"),
  }),
});

export default function EditCLIAProspect() {
  const { user, permissions } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const CasteList = useAPINew("GetCasteList.php");
  const VillageList = useAPINew("GetVillageList.php");
  const TehsilList = useAPINew("GetTehsilList.php");
  const DistrictList = useAPINew("GetDistrictList.php");
  const StateList = useAPINew("GetStateList.php");
  const ProfessionList = useAPINew("GetProfessionList.php");
  const WorkTypeList = useAPINew("GetWorkTypeList.php");
  const RefList = useAPINew("GetRefDahiyaMitraList.php");

  const BranchList = useAPINew("GetBranchList.php");

  const UsersList = useAPINew("GetListOfUsersToAssign.php");
  const options = (UsersList.error ? [null] : UsersList.data).map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  const [isDeleted, setIsDeleted] = useState("0");
  const [docs, setDocs] = React.useState([]);
  const [fileList1, setFileList1] = React.useState([]);
  const [data, setData] = useState({});
  const toaster = useToaster();
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const { UID } = useParams();

  const theme = useTheme();
  const navigate = useNavigate();

  const { rowFiltereddata, updateIndexValue, index, setIndex } = useProps();
  const initialState = {
    EditMode: false,
    Attachment: false,
  };
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "EDITABLE":
        return { ...initialState, EditMode: true };
      case "ATTACH":
        return { ...initialState, Attachment: true };
      case "DONE":
        return { ...initialState, Attachment: false };
      case "LOCKED":
        return { ...initialState, EditMode: false };

      case "RESET":
        return initialState;
      default:
        return state;
    }
  }, initialState);

  const [initialValues, setInitialValues] = useState({
    Date: new Date(),
    MRDate: null,
    Place: "",
    RefBy: null,
    Name: "",
    Address: "",
    WhatsAppNo: "",
    MobileNo: "",
    Other: "",
    Caste: null,
    Village: null,
    Tehsil: null,
    District: null,
    State: null,
  });

  function handleMobileCheck(e) {
    if (e.target.value === "") {
      return;
    } else {
      try {
        axiosPrivate
          .post("/CheckIfMobileNoExistInEditCLIA.php", {
            Mobile: e.target.value,
          })
          .then((response) => {
            const result = response.data;
            if (result.status === 1) {
              if (result.App.length > 0) {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="custom-ui">
                        <h1>{`Are you sure?`}</h1>
                        <p>{`This Mobile no already registered with ${result.App.length} account(s) - ${result.Data[0].SrNo}. Continue?`}</p>
                        <button onClick={onClose}>Continue</button>
                        <button
                          onClick={() => {
                            onClose();
                          }}
                        >
                          No
                        </button>
                      </div>
                    );
                  },
                });
                /*                 toaster.push(
                    <Message
                      showIcon
                      header="Are you sure?"
                      type="warning"
                      closable
                    >
                      <p>
                        {" "}
                        {`This Mobile no already registered with ${result.App.length} account(s) - ${result.Data[0].SrNo}. Continue?`}
                      </p>
                      <hr />
                      <ButtonToolbar>
                        <Button size="sm">Don't allow</Button>
                        <Button size="sm">Allow</Button>
                      </ButtonToolbar>
                    </Message>
                  ); */
              }
            }
            if (result.status === 0) {
            }
          })
          .catch((err) => {
            console.error(err);
          });

        //navigate("/login", { state: { from: location }, replace: true });
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    }
  }
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getReference();
      //console.log(rowFiltereddata);
      updateIndexValue(rowFiltereddata.indexOf(UID));
    }
    return () => {
      isSubscribed = false;
    };
  }, []);
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getReference();
    }
    return () => {
      isSubscribed = false;
    };
  }, [index]);

  const goToPrevious = () => {
    setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    console.log(index);
    console.log(rowFiltereddata[index]);
    navigate(
      `/CLIAProspect/EditCLIAProspect/${
        rowFiltereddata[index > 0 ? index - 1 : index]
      }`
    );
  };

  const goToNext = () => {
    setIndex((prevIndex) =>
      prevIndex < rowFiltereddata.length - 1 ? prevIndex + 1 : prevIndex
    );
    console.log(index);
    console.log(rowFiltereddata[index]);
    navigate(
      `/CLIAProspect/EditCLIAProspect/${
        rowFiltereddata[index < rowFiltereddata.length - 1 ? index + 1 : index]
      }`
    );
  };

  const Remove = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this  details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemoveCLIAReference.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    navigate(-1);
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };
  const Restore = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to restore this details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RestoreCLIAReference.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    getReference();
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  onClose();
                }
              }}
            >
              Yes, Restore it!
            </button>
          </div>
        );
      },
    });
  };

  const getReference = async () => {
    try {
      const responce = await axiosPrivate.post("GetCLIAReferenceByID.php", {
        UID,
      });
      //console.log(responce);
      const result = responce.data;
      if (result.status === 1) {
        const newData = {
          ...result.Data[0],
          Caste:
            result.Data[0].Caste === ""
              ? null
              : { value: result.Data[0].Caste, title: result.Data[0].Caste },
          Branch:
            result.Data[0].Branch === ""
              ? null
              : { value: result.Data[0].Branch, title: result.Data[0].Branch },
          Village:
            result.Data[0].Village === ""
              ? null
              : {
                  value: result.Data[0].Village,
                  title: result.Data[0].Village,
                },
          Tehsil:
            result.Data[0].Tehsil === ""
              ? null
              : {
                  value: result.Data[0].Tehsil,
                  title: result.Data[0].Tehsil,
                },
          District:
            result.Data[0].District === ""
              ? null
              : {
                  value: result.Data[0].District,
                  title: result.Data[0].District,
                },
          State:
            result.Data[0].State === ""
              ? null
              : { value: result.Data[0].State, title: result.Data[0].State },
          Work:
            result.Data[0].Profession === ""
              ? null
              : {
                  value: result.Data[0].Profession,
                  title: result.Data[0].Profession,
                },
          WorkType:
            result.Data[0].WorkType === ""
              ? null
              : {
                  value: result.Data[0].WorkType,
                  title: result.Data[0].WorkType,
                },
          RefBy:
            result.Data[0].RefBy === ""
              ? null
              : {
                  value: result.Data[0].RefBy,
                  title: result.Data[0].RefBy,
                },
          Date:
            result.Data[0].Date === "0000-00-00"
              ? null
              : new Date(result.Data[0].Date),
          DOB:
            result.Data[0].Date === "0000-00-00"
              ? null
              : new Date(result.Data[0].DOB),
          ExamDate:
            result.Data[0].ExamDate === "0000-00-00"
              ? null
              : new Date(result.Data[0].ExamDate),

          MRDate:
            result.Data[0].MRDate === "0000-00-00"
              ? null
              : new Date(result.Data[0].MRDate),
          NextExamDate:
            result.Data[0].NextExamDate === "0000-00-00"
              ? null
              : new Date(result.Data[0].NextExamDate),
          AssignedTo:
            result.Data[0].AssignedTo === ""
              ? null
              : {
                  value: result.Data[0].AssignedTo,
                  title: result.Data[0].AssignedToName,
                },
        };
        setInitialValues(newData);
        setIsDeleted(result.Data[0].Deleted);
        setFileInfo(
          `https://backend.dahiyainsurance.com/Upload/profile/${result.Data[0].Picture}`
        );
        setDocs(result.Docs);
        setData({ ...data, ...result.Data[0] });
      }
      if (result.status === 0) {
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Error!</strong> {result?.Error}
          </Message>
        );
      }
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  };
  /*   function checkIfPINNoExists() {
      if (!addCustomerData?.pin || addCustomerData?.pin === "") {
      } else {
        try {
          axiosPrivate
            .post("/CheckPINForCustomer.php", { PIN: addCustomerData?.pin })
            .then((response) => {
              const result = response.data;
              if (result.status === 1) {
                if (result.App.length > 0) {
                  setAddCustomerData((a) => {
                    return {
                      ...a,
                      tehsil: result.Data[0].tehsil,
                      district: result.Data[0].district,
                      state: result.Data[0].state,
                    };
                  });
                }
              }
              if (result.status === 0) {
                setAddCustomerData((a) => {
                  return {
                    ...a,
                    tehsil: null,
                    district: null,
                    state: null,
                  };
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
  
          //navigate("/login", { state: { from: location }, replace: true });
        } catch (err) {
          console.error(err);
          //navigate("/login", { state: { from: location }, replace: true });
        }
      }
    } */

  /*   function checkIfVillageExists() {
      if (!addCustomerData?.village || addCustomerData?.village === "") {
      } else {
        try {
          axiosPrivate
            .post("/CheckVillageForCustomer.php", { village: addCustomerData?.village })
            .then((response) => {
              const result = response.data;
              if (result.status === 1) {
                if (result.App.length > 0) {
                  setAddCustomerData((a) => {
                    return {
                      ...a,
                      tehsil: result.Data[0].tehsil,
                      district: result.Data[0].district,
                      state: result.Data[0].state,
                      pin: result.Data[0].pin,
                    };
                  });
                }
              }
              if (result.status === 0) {
                setAddCustomerData((a) => {
                  return {
                    ...a,
                    tehsil: null,
                    district: null,
                    state: null,
                    pin: null,
                  };
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
  
          //navigate("/login", { state: { from: location }, replace: true });
        } catch (err) {
          console.error(err);
          //navigate("/login", { state: { from: location }, replace: true });
        }
      }
    } */
  function handleRemoveDoc(ID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this document.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemovePolicyDoc.php",
                    datatosend
                  );
                  const result = response.data;
                  onClose();
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    const newDocs = docs.filter(function (item) {
                      return item.UniqueID !== ID;
                    });
                    setDocs(newDocs);
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Heading
            level={5}
            style={{
              textAlign: "center",
              flex: 0.8,
            }}
          >
            {`CLIA Prospect - (${initialValues.ID})`}
          </Heading>
          <Stack direction="row" spacing={2} style={{ flex: 0.2 }}>
            <Button
              onClick={() => {
                navigate(`/FamilyMember/CLIAAgent/${UID}`);
              }}
              appearance="link"
              startIcon={<People />}
            >
              Family Details
            </Button>
            <Button
              onClick={() => {
                navigate(`/CLIAProspect/ConceptDiscussion/${UID}`);
              }}
              appearance="link"
              startIcon={<NoteAdd />}
            >
              Discussion
            </Button>
            {(permissions.includes(ADD_INPUT) ||
              user.Type === "SuperAdmin") && (
              <Button
                appearance="link"
                style={{ flex: 0.1 }}
                onClick={() => {
                  setOpen(true);
                }}
              >
                {" "}
                Inputs
              </Button>
            )}
          </Stack>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <Grid fluid style={{ padding: 10 }}>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={16}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                  console.log(values);
                  confirmAlert({
                    customUI: ({ onClose }) => {
                      return (
                        <div className="custom-ui">
                          <h1>{`Confirm?`}</h1>
                          <p>{`Are you sure to update the changes?`}</p>
                          <button
                            onClick={() => {
                              axiosPrivate
                                .post("EditCLIAProspect.php", {
                                  ...values,
                                  Caste: values.Caste?.value,
                                  Village: values.Village?.value,
                                  Tehsil: values.Tehsil?.value,
                                  District: values.District?.value,
                                  State: values.State?.value,
                                  Gender: values?.Gender,
                                  Work: values.Work?.value,
                                  WorkType: values.WorkType?.value,
                                  RefBy: values.RefBy?.value,
                                  Branch: values.Branch?.value,
                                  AssignedTo: values.AssignedTo?.value,
                                  RefByName:
                                    values.RefBy?.value === "OTHER" ||
                                    values.RefBy?.value === "CUSTOMER"
                                      ? values.RefByName
                                      : values.RefBy?.title,

                                  Date:
                                    Boolean(values?.Date) &&
                                    moment(values?.Date).format("YYYY-MM-DD"),
                                  DOB:
                                    Boolean(values?.DOB) &&
                                    moment(values?.DOB).format("YYYY-MM-DD"),

                                  ExamDate:
                                    Boolean(values?.ExamDate) &&
                                    moment(values?.ExamDate).format(
                                      "YYYY-MM-DD"
                                    ),

                                  MRDate:
                                    Boolean(values?.MRDate) &&
                                    moment(values?.MRDate).format("YYYY-MM-DD"),
                                  NextExamDate:
                                    Boolean(values?.NextExamDate) &&
                                    moment(values?.NextExamDate).format(
                                      "YYYY-MM-DD"
                                    ),

                                  UID: values?.UniqueID,
                                })
                                .then((response) => {
                                  const result = response.data;
                                  if (result.status === 1) {
                                    toaster.push(
                                      <Message type="success" bordered showIcon>
                                        <strong>Success!</strong>{" "}
                                        {result.Success}
                                      </Message>
                                    );

                                    dispatch({ type: "LOCKED" });
                                    dispatch({ type: "ATTACH" });
                                    onClose();
                                    setSubmitting(false);
                                  }
                                  if (result.status === 0) {
                                    toaster.push(
                                      <Message type="error" bordered showIcon>
                                        <strong>Error!</strong> {result.Error}
                                      </Message>
                                    );
                                  }
                                  setSubmitting(false);
                                  // resetForm();
                                })
                                .catch((error) => {
                                  console.error(
                                    "Error submitting data:",
                                    error
                                  );
                                  setSubmitting(false);
                                });
                            }}
                          >
                            Yes
                          </button>
                          <button
                            onClick={() => {
                              onClose();
                              setSubmitting(false);
                            }}
                          >
                            No
                          </button>
                        </div>
                      );
                    },
                  });
                }}
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  errors,
                  isSubmitting,
                  isValidating,
                  setErrors,
                  setTouched,
                }) => (
                  <form className="target">
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading
                        level={6}
                        style={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Category
                      </Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-standard-label">
                            Status
                          </InputLabel>
                          <Select
                            readOnly={!state.EditMode}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="Status"
                            name="Status"
                            value={values?.Status || ""}
                            onChange={handleChange}
                            error={touched.Status && Boolean(errors.Status)}
                            helperText={touched.Status && errors.Status}
                          >
                            {Status.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, Status, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            readOnly={!state.EditMode}
                            label="Date Of Birth"
                            variant="standard"
                            size="small"
                            name="DOB"
                            format="dd-MM-yyyy"
                            value={values.DOB}
                            onChange={(value) =>
                              setFieldValue("DOB", value, true)
                            }
                            onBlur={handleBlur}
                            error={touched.DOB && Boolean(errors.DOB)}
                            helperText={touched.DOB && errors.DOB}
                          />
                        </LocalizationProvider>
                        &nbsp; &nbsp;
                        <b>
                          {values.DOB !== null &&
                            `${moment().diff(
                              values.DOB ? new Date(values.DOB) : null,
                              "years"
                            )} Year(s)`}
                        </b>
                        &nbsp; &nbsp;
                        <FormControl variant="standard" sx={{ minWidth: 150 }}>
                          <InputLabel id="demo-simple-select-standard-label-LeadStatus">
                            Lead Status
                          </InputLabel>
                          <Select
                            readOnly={!state.EditMode}
                            labelId="demo-simple-select-standard-label-LeadStatus"
                            id="demo-simple-select-LeadStatus"
                            label="LeadStatus"
                            name="LeadStatus"
                            value={values?.LeadStatus || ""}
                            onChange={handleChange}
                            error={
                              touched.LeadStatus && Boolean(errors.LeadStatus)
                            }
                            helperText={touched.LeadStatus && errors.LeadStatus}
                          >
                            {LeadStatus.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, LeadStatus, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Visit / Ref details</Heading>
                    </Row>
                    {/* <br /> */}
                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            readOnly={!state.EditMode}
                            label="Date"
                            variant="standard"
                            size="small"
                            name="Date"
                            format="dd-MM-yyyy"
                            value={values.Date}
                            style={{ width: 100 }}
                            onChange={(value) =>
                              setFieldValue("Date", value, true)
                            }
                            onBlur={handleBlur}
                            error={touched.Date && Boolean(errors.Date)}
                            helperText={touched.Date && errors.Date}
                          />
                        </LocalizationProvider>
                        &nbsp;&nbsp;
                        <TextField
                          readOnly={!state.EditMode}
                          label="Place"
                          size="small"
                          variant="standard"
                          autoCapitalize={true}
                          style={{ width: 150 }}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          name="Place"
                          value={values.Place}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.Place && Boolean(errors.Place)}
                          helperText={touched.Place && errors.Place}
                        />{" "}
                        &nbsp;&nbsp;
                        <Field
                          readOnly={!state.EditMode}
                          name="RefBy"
                          component={Autocomplete}
                          key="ID"
                          style={{ width: 150 }}
                          autoComplete={true}
                          autoHighlight={true}
                          options={RefList.error ? [null] : RefList.data}
                          getOptionLabel={(option) => option.title || ""}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "RefBy",
                              value || { value: "", title: "" }
                            );
                            console.log(errors);
                            console.log(values);
                          }}
                          value={
                            Boolean(values.RefBy)
                              ? values.RefBy
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="RefBy"
                              label="RefBy"
                              variant="standard"
                              error={touched.RefBy && Boolean(errors.RefBy)}
                              helperText={touched.RefBy && errors.RefBy}
                            />
                          )}
                        />
                        &nbsp;&nbsp;
                        {(values.RefBy?.value === "CUSTOMER" ||
                          values.RefBy?.value === "OTHER") && (
                          <TextField
                            readOnly={!state.EditMode}
                            label={`${
                              values.RefBy?.value === "CUSTOMER"
                                ? "Customer"
                                : "Ref By "
                            } Name`}
                            size="small"
                            variant="standard"
                            onBlur={handleBlur}
                            name="RefByName"
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            value={values.RefByName}
                            onChange={handleChange}
                            error={
                              touched.RefByName && Boolean(errors.RefByName)
                            }
                            helperText={touched.RefByName && errors.RefByName}
                          />
                        )}
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Personal details</Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <TextField
                          readOnly={!state.EditMode}
                          label="Name"
                          size="small"
                          variant="standard"
                          style={{ width: 250 }}
                          name="Name"
                          value={values.Name}
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          onBlur={handleBlur}
                          error={touched.Name && Boolean(errors.Name)}
                          helperText={touched.Name && errors.Name}
                        />{" "}
                        &nbsp;&nbsp;
                        <Field
                          readOnly={!state.EditMode}
                          name="Caste"
                          component={Autocomplete}
                          key="ID"
                          options={CasteList.error ? [null] : CasteList.data}
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 150 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "Caste",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                          }}
                          value={
                            Boolean(values.Caste)
                              ? values.Caste
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Caste"
                              label="Caste"
                              variant="standard"
                              error={touched.Caste && Boolean(errors.Caste)}
                              helperText={touched.Caste && errors.Caste}
                            />
                          )}
                        />
                        &nbsp;&nbsp;
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <InputLabel
                            shrink={Boolean(values?.CasteCategory)}
                            id="demo-simple-select-category"
                          >
                            Caste Category
                          </InputLabel>
                          <Select
                            readOnly={!state.EditMode}
                            labelId="demo-simple-select-category"
                            id="simple-select-category"
                            label="Caste Category"
                            name="CasteCategory"
                            value={values?.CasteCategory || null}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.CasteCategory &&
                              Boolean(errors.CasteCategory)
                            }
                            helperText={
                              touched.CasteCategory && errors.CasteCategory
                            }
                          >
                            <MenuItem value="GENERAL">GENERAL</MenuItem>
                            <MenuItem value="OBC">OBC</MenuItem>
                            <MenuItem value="SC">SC</MenuItem>
                            <MenuItem value="ST">ST</MenuItem>
                            <MenuItem value="OTHER">OTHER</MenuItem>
                          </Select>
                        </FormControl>
                      </Stack>
                    </Row>
                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 20, marginBottom: 10 }}
                    >
                      <Stack spacing={1} direction="row">
                        <FormControl>
                          <FormLabel id="radio-buttons-group-label-MaritalStatus">
                            Gender
                          </FormLabel>
                          <RadioGroup
                            name="Gender"
                            value={values.Gender}
                            onChange={(e) => {
                              setFieldValue("Gender", e);
                            }}
                            inline
                            readOnly={!state.EditMode}
                          >
                            <Radio value="Male">Male</Radio>
                            <Radio value="Female">Female</Radio>
                          </RadioGroup>
                        </FormControl>
                        &nbsp;&nbsp; &nbsp;&nbsp;
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <InputLabel
                            shrink={Boolean(values.MaritalStatus)}
                            id="demo-simple-select-MaritalStatus"
                          >
                            Marital Status
                          </InputLabel>
                          <Select
                            readOnly={!state.EditMode}
                            labelId="demo-simple-select-MaritalStatus"
                            id="simple-select-MaritalStatus"
                            label="Marital Status"
                            name="MaritalStatus"
                            value={values?.MaritalStatus || null}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: Boolean(values.MaritalStatus),
                            }}
                            error={
                              touched.MaritalStatus &&
                              Boolean(errors.MaritalStatus)
                            }
                            helperText={
                              touched.MaritalStatus && errors.MaritalStatus
                            }
                          >
                            <MenuItem value="Single">Single</MenuItem>
                            <MenuItem value="Married">Married</MenuItem>
                            <MenuItem value="Divorced">Divorced</MenuItem>
                            <MenuItem value="Widowed">Widowed</MenuItem>
                          </Select>
                        </FormControl>
                      </Stack>
                    </Row>

                    <br />

                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Contact details</Heading>
                    </Row>

                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={6}>
                        <TextField
                          readOnly={!state.EditMode}
                          label="Address"
                          size="small"
                          variant="standard"
                          style={{ width: 300 }}
                          name="Address"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          onBlur={handleBlur}
                          value={values.Address}
                          error={touched.Address && Boolean(errors.Address)}
                          helperText={touched.Address && errors.Address}
                        />{" "}
                        &nbsp;&nbsp;
                        <TextField
                          readOnly={!state.EditMode}
                          style={{ width: 100 }}
                          label="PIN Code"
                          size="small"
                          variant="standard"
                          autoCapitalize={true}
                          onBlur={handleBlur}
                          InputLabelProps={{
                            shrink: Boolean(values.PIN),
                          }}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          name="PIN"
                          value={values.PIN}
                          onChange={handleChange}
                          error={touched.PIN && Boolean(errors.PIN)}
                          helperText={touched.PIN && errors.PIN}
                        />
                        &nbsp;&nbsp;
                        <Field
                          readOnly={!state.EditMode}
                          name="Village"
                          component={Autocomplete}
                          key="ID"
                          options={
                            VillageList.error ? [null] : VillageList.data
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "Village",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                          }}
                          value={
                            Boolean(values.Village)
                              ? values.Village
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Village"
                              label="Village/City"
                              variant="standard"
                              error={touched.Village && Boolean(errors.Village)}
                              helperText={touched.Village && errors.Village}
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={6}>
                        <Field
                          readOnly={!state.EditMode}
                          name="Tehsil"
                          component={Autocomplete}
                          key="ID"
                          options={TehsilList.error ? [null] : TehsilList.data}
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "Tehsil",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                          }}
                          value={
                            Boolean(values?.Tehsil)
                              ? values?.Tehsil
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Tehsil"
                              label="Tehsil"
                              variant="standard"
                              error={touched.Tehsil && Boolean(errors.Tehsil)}
                              helperText={touched.Tehsil && errors.Tehsil}
                            />
                          )}
                        />
                        <Field
                          readOnly={!state.EditMode}
                          name="District"
                          component={Autocomplete}
                          key="ID"
                          options={
                            DistrictList.error ? [null] : DistrictList.data
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "District",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                          }}
                          value={
                            Boolean(values.District)
                              ? values.District
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="District"
                              label="District"
                              variant="standard"
                              error={
                                touched.District && Boolean(errors.District)
                              }
                              helperText={touched.District && errors.District}
                            />
                          )}
                        />
                        <Field
                          readOnly={!state.EditMode}
                          name="State"
                          component={Autocomplete}
                          key="ID"
                          options={StateList.error ? [null] : StateList.data}
                          getOptionLabel={(option) => option?.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "State",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                          }}
                          value={
                            Boolean(values.State)
                              ? values.State
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="State"
                              label="State"
                              variant="standard"
                              error={touched.State && Boolean(errors.State)}
                              helperText={touched.State && errors.State}
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={6}>
                        <TextField
                          readOnly={!state.EditMode}
                          label="WhatsAppNo"
                          size="small"
                          variant="standard"
                          name="WhatsAppNo"
                          value={values.WhatsAppNo}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleMobileCheck(e);
                          }}
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          error={
                            touched.WhatsAppNo && Boolean(errors.WhatsAppNo)
                          }
                          helperText={touched.WhatsAppNo && errors.WhatsAppNo}
                        />{" "}
                        &nbsp;&nbsp;
                        <TextField
                          readOnly={!state.EditMode}
                          label="RESI. MOB. NO."
                          size="small"
                          variant="standard"
                          name="MobileNo"
                          value={values.MobileNo}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleMobileCheck(e);
                          }}
                          onChange={handleChange}
                          error={touched.MobileNo && Boolean(errors.MobileNo)}
                          helperText={touched.MobileNo && errors.MobileNo}
                        />{" "}
                        &nbsp;&nbsp;
                        <TextField
                          readOnly={!state.EditMode}
                          label="Email"
                          size="small"
                          variant="standard"
                          name="Email"
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          value={values.Email}
                          InputLabelProps={{
                            shrink: Boolean(values?.Email),
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.Email && Boolean(errors.Email)}
                          helperText={touched.Email && errors.Email}
                        />{" "}
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Work Details</Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <Field
                          readOnly={!state.EditMode}
                          name="Work"
                          component={Autocomplete}
                          key="ID"
                          options={
                            ProfessionList.error ? [null] : ProfessionList.data
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "Work",
                              value || { value: "", title: "" }
                            );
                            // console.log(values);
                          }}
                          value={
                            Boolean(values.Work)
                              ? values.Work
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Work"
                              label="Work"
                              variant="standard"
                              error={touched.Work && Boolean(errors.Work)}
                              helperText={touched.Work && errors.Work}
                            />
                          )}
                        />
                        &nbsp;&nbsp;
                        <Field
                          readOnly={!state.EditMode}
                          name="WorkType"
                          component={Autocomplete}
                          key="ID"
                          options={
                            WorkTypeList.error ? [null] : WorkTypeList.data
                          }
                          getOptionLabel={(option) => option?.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "WorkType",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                          }}
                          value={
                            Boolean(values.WorkType)
                              ? values.WorkType
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="WorkType"
                              label="WorkType"
                              variant="standard"
                              error={
                                touched.WorkType && Boolean(errors.WorkType)
                              }
                              helperText={touched.WorkType && errors.WorkType}
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 15, marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={6}>
                        <FormControl variant="standard" sx={{ minWidth: 180 }}>
                          <InputLabel
                            shrink={Boolean(values?.ExistingAgency)}
                            id="select-standard-ExistingAgency"
                          >
                            Existing Agency ?
                          </InputLabel>
                          <Select
                            readOnly={!state.EditMode}
                            labelId="select-standard-ExistingAgency"
                            id="demo-simple-select-ExistingAgency"
                            label="Existing Agency?"
                            name="ExistingAgency"
                            value={values?.ExistingAgency || null}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.ExistingAgency &&
                              Boolean(errors.ExistingAgency)
                            }
                            helperText={
                              touched.ExistingAgency && errors.ExistingAgency
                            }
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                        &nbsp; &nbsp;
                        {values.ExistingAgency === "Yes" && (
                          <TextField
                            label="Agency Details"
                            size="small"
                            variant="standard"
                            name="ExistingAgencyDetails"
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            InputLabelProps={{
                              shrink: Boolean(values?.ExistingAgency),
                            }}
                            value={values.ExistingAgencyDetails}
                            onChange={handleChange}
                            error={
                              touched.ExistingAgencyDetails &&
                              Boolean(errors.ExistingAgencyDetails)
                            }
                            helperText={
                              touched.ExistingAgencyDetails &&
                              errors.ExistingAgencyDetails
                            }
                            readOnly={!state.EditMode}
                          />
                        )}
                      </Stack>
                    </Row>
                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 15, marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={6}>
                        <FormControl variant="standard" sx={{ minWidth: 200 }}>
                          <InputLabel
                            shrink={Boolean(values?.FamilyMemberInGovt)}
                            id="select-standard-FamilyMemberInGovt"
                          >
                            Family Member in Govt/LIC ?
                          </InputLabel>
                          <Select
                            readOnly={!state.EditMode}
                            labelId="select-standard-FamilyMemberInGovt"
                            id="demo-simple-select-FamilyMemberInGovt"
                            label="Family Member In Govt?"
                            name="FamilyMemberInGovt"
                            value={values?.FamilyMemberInGovt || null}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.FamilyMemberInGovt &&
                              Boolean(errors.FamilyMemberInGovt)
                            }
                            helperText={
                              touched.FamilyMemberInGovt &&
                              errors.FamilyMemberInGovt
                            }
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                        &nbsp; &nbsp;
                        {values.FamilyMemberInGovt === "Yes" && (
                          <TextField
                            label="Family Member In Govt Details"
                            size="small"
                            style={{ width: 200 }}
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            variant="standard"
                            name="FamilyMemberInGovtDetails"
                            value={values.FamilyMemberInGovtDetails}
                            onChange={handleChange}
                            error={
                              touched.FamilyMemberInGovtDetails &&
                              Boolean(errors.FamilyMemberInGovtDetails)
                            }
                            helperText={
                              touched.FamilyMemberInGovtDetails &&
                              errors.FamilyMemberInGovtDetails
                            }
                            readOnly={!state.EditMode}
                          />
                        )}
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Other Details</Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <TextField
                          label="Other Details"
                          size="small"
                          variant="standard"
                          name="OtherDetails"
                          value={values.OtherDetails}
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          InputLabelProps={{
                            shrink: Boolean(values?.OtherDetails),
                          }}
                          error={
                            touched.OtherDetails && Boolean(errors.OtherDetails)
                          }
                          helperText={
                            touched.OtherDetails && errors.OtherDetails
                          }
                          readOnly={!state.EditMode}
                        />{" "}
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <InputLabel
                            id="select-standard-Prospect"
                            shrink={Boolean(values?.Prospect)}
                          >
                            Agent Prospect?
                          </InputLabel>
                          <Select
                            readOnly={!state.EditMode}
                            labelId="select-standard-Prospect"
                            id="demo-simple-select-Prospect"
                            label="In Prospect?"
                            name="Prospect"
                            value={values?.Prospect || null}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.Prospect && Boolean(errors.Prospect)}
                            helperText={touched.Prospect && errors.Prospect}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Certification Details</Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={2}>
                        <Field
                          readOnly={!state.EditMode}
                          name="Branch"
                          component={Autocomplete}
                          key="ID"
                          autoComplete={true}
                          autoHighlight={true}
                          options={BranchList.error ? [null] : BranchList.data}
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 150 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "Branch",
                              value || { value: "", title: "" }
                            );
                            console.log(errors);
                            console.log(values);
                          }}
                          value={
                            Boolean(values.Branch)
                              ? values.Branch
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Branch"
                              label="Branch"
                              variant="standard"
                              error={touched.Branch && Boolean(errors.Branch)}
                              helperText={touched.Branch && errors.Branch}
                            />
                          )}
                        />
                        &nbsp; &nbsp;
                        <TextField
                          readOnly={!state.EditMode}
                          label="IRDA CERTIFICATE BY"
                          size="small"
                          variant="standard"
                          name="CertificateBy"
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          InputLabelProps={{
                            shrink: Boolean(values.CertificateBy),
                          }}
                          value={values.CertificateBy}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.CertificateBy &&
                            Boolean(errors.CertificateBy)
                          }
                          helperText={
                            touched.CertificateBy && errors.CertificateBy
                          }
                          style={{ width: 220 }}
                        />{" "}
                        &nbsp; &nbsp;
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            readOnly={!state.EditMode}
                            label="MR Date"
                            variant="standard"
                            size="small"
                            name="MRDate"
                            format="dd-MM-yyyy"
                            value={values.MRDate}
                            style={{ width: 100 }}
                            onChange={(value) =>
                              setFieldValue("MRDate", value, true)
                            }
                            onBlur={handleBlur}
                            error={touched.MRDate && Boolean(errors.MRDate)}
                            helperText={touched.MRDate && errors.MRDate}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Row>
                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 15, marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={6}>
                        <TextField
                          readOnly={!state.EditMode}
                          label="MR No"
                          size="small"
                          variant="standard"
                          name="MrNo"
                          InputLabelProps={{
                            shrink: Boolean(values.MrNo),
                          }}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          value={values.MrNo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.MrNo && Boolean(errors.MrNo)}
                          helperText={touched.MrNo && errors.MrNo}
                          style={{ width: 120 }}
                        />{" "}
                        &nbsp; &nbsp;
                        <TextField
                          readOnly={!state.EditMode}
                          label="URN No"
                          size="small"
                          variant="standard"
                          name="URNNo"
                          InputLabelProps={{
                            shrink: Boolean(values.URNNo),
                          }}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          value={values.URNNo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.URNNo && Boolean(errors.URNNo)}
                          helperText={touched.URNNo && errors.URNNo}
                          style={{ width: 120 }}
                        />{" "}
                        &nbsp; &nbsp;
                        <TextField
                          readOnly={!state.EditMode}
                          label="Exam Center"
                          size="small"
                          variant="standard"
                          name="ExamCenter"
                          InputLabelProps={{
                            shrink: Boolean(values.ExamCenter),
                          }}
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          value={values.ExamCenter}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.ExamCenter && Boolean(errors.ExamCenter)
                          }
                          helperText={touched.ExamCenter && errors.ExamCenter}
                          style={{ width: 120 }}
                        />{" "}
                      </Stack>
                    </Row>
                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 15, marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            readOnly={!state.EditMode}
                            label="Exam Date"
                            variant="standard"
                            size="small"
                            name="ExamDate"
                            format="dd-MM-yyyy"
                            value={values.ExamDate}
                            style={{ width: 100 }}
                            onChange={(value) =>
                              setFieldValue("ExamDate", value, true)
                            }
                            onBlur={handleBlur}
                            error={touched.ExamDate && Boolean(errors.ExamDate)}
                            helperText={touched.ExamDate && errors.ExamDate}
                          />
                        </LocalizationProvider>
                        &nbsp; &nbsp;
                        <FormControl variant="standard" sx={{ minWidth: 180 }}>
                          <InputLabel
                            id="select-standard-Result"
                            shrink={Boolean(values?.Result)}
                          >
                            Result
                          </InputLabel>
                          <Select
                            readOnly={!state.EditMode}
                            labelId="select-standard-Result"
                            id="demo-simple-select-Result"
                            label="Result"
                            name="Result"
                            value={values?.Result || null}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.Result && Boolean(errors.Result)}
                            helperText={touched.Result && errors.Result}
                          >
                            <MenuItem value="PASS">PASS</MenuItem>
                            <MenuItem value="FAIL">FAIL</MenuItem>
                            <MenuItem value="ABSENT">ABSENT</MenuItem>
                            <MenuItem value="PENDING">PENDING</MenuItem>
                            <MenuItem value="PROCESS">PROCESS</MenuItem>
                          </Select>
                        </FormControl>
                        &nbsp; &nbsp;
                        {values.Result !== "PASS" && (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateField
                              readOnly={!state.EditMode}
                              label="Next Exam Date"
                              variant="standard"
                              size="small"
                              name="NextExamDate"
                              format="dd-MM-yyyy"
                              value={values.NextExamDate}
                              style={{ width: 150 }}
                              onChange={(value) =>
                                setFieldValue("NextExamDate", value, true)
                              }
                              onBlur={handleBlur}
                              error={
                                touched.NextExamDate &&
                                Boolean(errors.NextExamDate)
                              }
                              helperText={
                                touched.NextExamDate && errors.NextExamDate
                              }
                            />
                          </LocalizationProvider>
                        )}
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Permission</Heading>
                    </Row>
                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={0}>
                        {user.Type === "SuperAdmin" && (
                          <Field
                            readOnly={!state.EditMode}
                            name="AssignedTo"
                            component={Autocomplete}
                            key="ID"
                            options={options.sort(
                              (a, b) =>
                                -b.firstLetter.localeCompare(a.firstLetter)
                            )}
                            groupBy={(option) => option.UserType}
                            sx={{ width: 300 }}
                            renderGroup={(params) => (
                              <li key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                              </li>
                            )}
                            getOptionLabel={(option) => option?.title || ""}
                            style={{ width: 200 }}
                            onBlur={handleBlur} // so formik can see the forms touched state
                            onChange={(_, value) => {
                              setFieldValue(
                                "AssignedTo",
                                value || { value: "", title: "" }
                              );
                              console.log(values);
                            }}
                            value={
                              Boolean(values.AssignedTo)
                                ? values.AssignedTo
                                : { value: "", title: "" }
                            }
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="AssignedTo"
                                label="AssignedTo"
                                variant="standard"
                                error={
                                  touched.AssignedTo &&
                                  Boolean(errors.AssignedTo)
                                }
                                helperText={
                                  touched.AssignedTo && errors.AssignedTo
                                }
                              />
                            )}
                          />
                        )}
                      </Stack>
                    </Row>
                    <br />

                    <Row gutter={1} style={{ marginTop: 10, marginBottom: 10 }}>
                      <Stack direction="row" spacing={4}>
                        {state.EditMode ? (
                          <>
                            <LoadingButton
                              disabled={isSubmitting}
                              type="submit"
                              color="secondary"
                              variant="contained"
                              onClick={handleSubmit}
                              loading={isValidating}
                              sx={{ my: 2 }}
                            >
                              {isSubmitting ? "Submitting..." : "Submit"}
                            </LoadingButton>
                            <Button
                              color="blue"
                              appearance="primary"
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch({ type: "LOCKED" });
                                setErrors({});
                                setTouched({}, false);
                              }}
                              sx={{ my: 2 }}
                            >
                              {"Cancel"}
                            </Button>
                          </>
                        ) : (
                          <>
                            {(permissions.includes(EDIT_CLIA) ||
                              user.Type === "SuperAdmin") &&
                              isDeleted === "0" && (
                                <Button
                                  color="blue"
                                  appearance="primary"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    dispatch({ type: "EDITABLE" });
                                  }}
                                  sx={{ my: 2 }}
                                >
                                  {"Edit"}
                                </Button>
                              )}

                            {(permissions.includes(DELETE_CLIA) ||
                              user.Type === "SuperAdmin") && (
                              <>
                                {isDeleted === "0" && (
                                  <Button
                                    color="red"
                                    appearance="primary"
                                    onClick={Remove}
                                    sx={{ my: 2 }}
                                  >
                                    {"Delete"}
                                  </Button>
                                )}
                                {isDeleted === "1" && (
                                  <Button
                                    color="red"
                                    appearance="primary"
                                    onClick={Restore}
                                    sx={{ my: 2 }}
                                  >
                                    {"UnDelete"}
                                  </Button>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </Stack>
                    </Row>
                  </form>
                )}
              </Formik>
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Row className="show-grid">
                <p>
                  <b>Added By : </b>
                  {data.AddedBy}
                </p>
              </Row>
              <Row className="show-grid">
                <p>
                  <b>Added On : </b>
                  {moment(data.AddedOn).format("DD-MM-YYYY HH:mm:ss")}
                </p>
              </Row>
              <Divider />
              <Row className="show-grid">
                <Col xs={24} sm={24} md={24}>
                  <Uploader
                    fileListVisible={false}
                    disabled={!state.Attachment}
                    multiple={false}
                    listType="picture"
                    action="https://backend.dahiyainsurance.com/Upload/AddProfilePic.php"
                    withCredentials={true}
                    headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                    data={{ UID, Type: "CLIAAgent" }}
                    onUpload={(File) => {
                      setUploading(true);
                      previewFile(File.blobFile, (value) => {
                        setFileInfo(value);
                      });
                    }}
                    onSuccess={(response, file) => {
                      setUploading(false);
                      toaster.push(
                        <Message type="success">Uploaded successfully</Message>
                      );
                      console.log(response);
                    }}
                    onError={(e) => {
                      console.log(e);
                      setFileInfo(null);
                      setUploading(false);
                      toaster.push(
                        <Message type="error">Upload failed</Message>
                      );
                    }}
                    onRemove={(file) => {
                      console.log(file);
                    }}
                  >
                    <button style={{ width: 300, height: 300 }}>
                      {uploading && <Loader backdrop center />}
                      {fileInfo ? (
                        <img
                          alt=""
                          src={fileInfo}
                          width="100%"
                          height="100%"
                          style={{ objectFit: "cover" }}
                        />
                      ) : (
                        <AvatarIcon style={{ fontSize: 80 }} />
                      )}
                    </button>
                  </Uploader>

                  <Uploader
                    disabled={!state.Attachment}
                    removable={!state.Attachment}
                    onRemove={(file) => {
                      console.log(file);
                    }}
                    listType="picture-text"
                    fileList={fileList1}
                    multiple={true}
                    data={{ UID, Type: "Reference" }}
                    onChange={setFileList1}
                    withCredentials={true}
                    headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                    action="https://backend.dahiyainsurance.com/Upload/UploadDocs.php"
                    renderFileInfo={(file, fileElement) => (
                      <span style={{ width: "100%", fontSize: 14 }}>
                        {file.name}
                      </span>
                    )}
                  />
                </Col>
              </Row>
              <Row
                className="show-grid"
                style={{ margin: "10px 0px 10px 0px" }}
              >
                <Col xs={24} sm={24} md={24}>
                  {
                    <ul>
                      {docs.map((data) => (
                        <li key={data.UniqueID}>
                          {/* <Link to={data.UniqueID}>{data.Name}</Link> */}
                          <a
                            rel="noreferrer"
                            href={`https://backend.dahiyainsurance.com/Upload/assets/${data.Path}`}
                            target="_blank"
                            style={{ cursor: "pointer" }}
                            /* onClick={(e) => getDocs(e, data.UniqueID)} */
                          >
                            {data?.DocType === "pdf" ? (
                              <PictureAsPdf />
                            ) : (
                              <Image />
                            )}{" "}
                            {data.Name}
                          </a>
                          {(permissions.includes(EDIT_PROSPECT) ||
                            user.Type === "SuperAdmin") && (
                            <a
                              href=""
                              style={{
                                paddingLeft: 5,
                                textDecoration: "none",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleRemoveDoc(data.UniqueID);
                              }}
                            >
                              {state.Attachment && (
                                <DeleteForever style={{ fontSize: 15 }} />
                              )}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  }
                </Col>
              </Row>
              <Row className="show-grid">
                <Col xs={24} sm={24} md={24}>
                  {
                    <>
                      {index > 0 && (
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: "#2979ff",
                            margin: 10,
                          }}
                          onClick={() => {
                            //navigate(`/Prospects/ViewProspect/${data.Previous}`)
                            goToPrevious();
                          }}
                          startIcon={<SkipPrevious />}
                        >
                          Previous
                        </Button>
                      )}

                      {index < rowFiltereddata.length - 1 && (
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: "#2979ff",
                            margin: 10,
                          }}
                          onClick={() => {
                            //navigate(`/Prospects/ViewProspect/${data.Next}`)
                            goToNext();
                          }}
                          startIcon={<SkipNext />}
                        >
                          Next
                        </Button>
                      )}
                    </>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
        <SideDrawer
          open={open}
          onClose={() => {
            setOpen(false);
            CasteList.reload();
            VillageList.reload();
            TehsilList.reload();
            DistrictList.reload();
            StateList.reload();
            ProfessionList.reload();
            WorkTypeList.reload();
          }}
        >
          <SideInputs />
        </SideDrawer>
      </div>
    </>
  );
}
