import React from "react";

import { Routes, Route } from "react-router-dom";
import NotFound from "./views/sessions/NotFound";
import Layout from "./Layout";
import JwtLogin from "./views/sessions/JwtLogin";
import PersistLogin from "./views/sessions/PersistLogin";
import RequireAuth from "./views/sessions/RequireAuth";
import Main from "./pages/main";
import Analytics from "./views/dashboard/Analytics";

import DahiyaMitra from "./pages/DahiyaMitra";
import Customers from "./pages/Customers";
import Inputs from "./pages/Inputs";
import Prospects from "./pages/Prospects";
import Users from "./pages/Users";
import AddCustomer from "./pages/Customers/AddCustomer";
import ListCustomer from "./pages/Customers/ListCustomer";
import ImportCustomer from "./pages/Customers/ImportCustomer";
import DeletedCustomer from "./pages/Customers/DeletedCustomer";
import AddDahiyaMitra from "./pages/DahiyaMitra/AddDahiyaMitra";
import DeletedDahiyaMitra from "./pages/DahiyaMitra/DeletedDahiyaMitra";
import ListDahiyaMitra from "./pages/DahiyaMitra/ListDahiyaMitra";
import ViewDahiyaMitra from "./pages/DahiyaMitra/ViewDahiyaMitra";
import ViewCustomer from "./pages/Customers/ViewCustomer";
import AddUser from "./pages/Users/AddUser";
import DisabledUser from "./pages/Users/DisabledUser";
import ViewUser from "./pages/Users/ViewUser";
import ListUsers from "./pages/Users/ListUsers";
import NewProspect from "./pages/Prospects/NewProspect";
import ViewProspect from "./pages/Prospects/ViewProspect";
import ImportProspect from "./pages/Prospects/ImportProspect";
import DeletedProspect from "./pages/Prospects/DeletedProspect";
import NewReference from "./pages/References/NewReference";
import CalendarView from "./pages/Calender";
import ListReference from "./pages/References/ListReference";
import ImportReference from "./pages/References/ImportReference";
import ViewReference from "./pages/References/EditReference";
import ListFamilyMembers from "./pages/Prospects/ListFamilyMembers";
import Greetings from "./pages/Greetings";
import References from "./pages/References";
import DeletedReference from "./pages/References/DeletedReference";
import ConceptDiscussed from "./pages/Prospects/ConceptDiscussion";
import ListProspects from "./pages/Prospects/ListProspects";
import FollowupList from "./pages/Prospects/FollowupList";
import AgencyWorks from "./pages/AgencyWorks";
import CLIA from "./pages/CLIA";
import AddCLIA from "./pages/CLIA/AddCLIAAgent";
import ListCLIA from "./pages/CLIA/ListCLIAAgent";
import DeletedCLIA from "./pages/CLIA/DeletedCLIAAgent";
import ViewCLIA from "./pages/CLIA/ViewCLIAAgent";
import Policy from "./pages/Policy";
import AddPolicy from "./pages/Policy/AddPolicy";
import ListPolicy from "./pages/Policy/ListPolicy";
import DeletedPolicy from "./pages/Policy/DeletedPolicy";
import EditPolicy from "./pages/Policy/ViewPolicy";
import Services from "./pages/Services";
import Bond from "./pages/Services/Bond";
import ENach from "./pages/Services/ENach";
import MinorNomination from "./pages/Services/MinorNomination";
import MinorDAB from "./pages/Services/MinorDAB";
import PolicyServices from "./pages/Services/PolicyServices";
import UpdateBondStatus from "./pages/Services/UpdateBondStatus";
import UpdateENachStatus from "./pages/Services/UpdateENachStatus";
import ListPendingUpdatePolicy from "./pages/Policy/ListPendingUpdatePolicy";
import UpdateNomination from "./pages/Services/UpdateNomination";
import UpdateDAB from "./pages/Services/UpdateDAB";
import AddPolicyService from "./pages/Services/AddPolicyService";
import ViewPolicyService from "./pages/Services/ViewPolicyService";
import PremiumPoint from "./pages/PremiumPoint";
import ListPremiumPoint from "./pages/PremiumPoint/ListPremiumPoint";
import ImportPremiumPoint from "./pages/PremiumPoint/ImportPremiumPoint";
import CurrentMonthDueImport from "./pages/PremiumPoint/CurrentMonthDueImport";
import CurrentMonthDueList from "./pages/PremiumPoint/CurrentMonthDueList";
import OutStandingDueImport from "./pages/PremiumPoint/OutStandingDueImport";
import OutstandingDueList from "./pages/PremiumPoint/OutstandingDueList";

import LapsedPolicyDueList from "./pages/PremiumPoint/LapsedPolicyDueList";
import SBPolicyImport from "./pages/PremiumPoint/SBPolicyImport";
import SBBonusList from "./pages/PremiumPoint/SBPolicyList";
import LICMaturityImport from "./pages/PremiumPoint/LICMaturityImport";
import LapsedPolicyDueImport from "./pages/PremiumPoint/LapsedPolicyDueImport";
import LICMaturityList from "./pages/PremiumPoint/LICMaturityList";
import PendingPolicyServices from "./pages/Services/PendingPolicyServices";
import AddCLIANewReference from "./pages/CliaTeam/AddCLIA";
import EditCLIAReference from "./pages/CliaTeam/EditCLIAReference";
import ListCLIAReference from "./pages/CliaTeam/ListCLIAReference";
import CLIAReferences from "./pages/CliaTeam";
import EditCLIAProspect from "./pages/CliaTeam/EditCLIAProspect";
import ListCLIAProspect from "./pages/CliaTeam/ListCLIAProspect";
import ListCLIAAgencyData from "./pages/CliaTeam/ListCLIAAgencyData";
import EditCLIAAgencyData from "./pages/CliaTeam/EditAgencyData";
import ImportByPastePremiumPoint from "./pages/PremiumPoint/ImportByPastePremiumPoint";
import ListCLIAFamilyMembers from "./pages/CliaTeam/ListFamilyMembers";
import CLIAConceptDiscussed from "./pages/CliaTeam/ConceptDiscussion";
import CLIAFollowupList from "./pages/CliaTeam/FollowupList";
import FamilyMembers from "./pages/Family";
import MBGDueList from "./pages/CliaTeam/MBGDueList";
import Followups from "./pages/Followups";
const ROLES = {
  STAFF: 1984,
  ADMIN: 5150,
};
export default function secureRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<JwtLogin />} />
        {/* protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
            <Route path="/" element={<Main />}>
              <Route
                exact
                path="Followups"
                element={<Followups />}
                key="Followups"
              />
              <Route
                exact
                path="dashboard"
                element={<Analytics />}
                key="dashboard"
              />
              <Route
                exact
                path="Services"
                element={<Services />}
                key="Services"
              >
                <Route exact path="Bond" element={<Bond />} key="Bond" />
                <Route
                  exact
                  path="UpdateBondStatus/:UID"
                  element={<UpdateBondStatus />}
                  key="UpdateBondStatus"
                />
                <Route
                  exact
                  path="UpdateENachStatus/:UID"
                  element={<UpdateENachStatus />}
                  key="UpdateENachStatus"
                />
                <Route exact path="Enach" element={<ENach />} key="ENach" />
                <Route
                  exact
                  path="AddPolicyService"
                  element={<AddPolicyService />}
                  key="AddPolicyService"
                />
                <Route
                  exact
                  path="ViewPolicyService/:UID"
                  element={<ViewPolicyService />}
                  key="ViewPolicyService"
                />
                <Route
                  exact
                  path="MinorNomination"
                  element={<MinorNomination />}
                  key="MinorNomination"
                />
                <Route
                  exact
                  path="UpdateDAB/:UID"
                  element={<UpdateDAB />}
                  key="UpdateDAB"
                />
                <Route
                  exact
                  path="UpdateMinorNomination/:UID"
                  element={<UpdateNomination />}
                  key="UpdateMinorNomination"
                />
                <Route
                  exact
                  path="MinorDAB"
                  element={<MinorDAB />}
                  key="MinorDAB"
                />
                <Route
                  exact
                  path="PolicyServices"
                  element={<PolicyServices />}
                  key="PolicyServices"
                />
                <Route
                  exact
                  path="PendingPolicyServices"
                  element={<PendingPolicyServices />}
                  key="PendingPolicyServices"
                />
              </Route>
              <Route exact path="Users" element={<Users />} key="Users">
                <Route exact path="Add" element={<AddUser />} key="AddUsers" />
                <Route
                  exact
                  path="Disabled"
                  element={<DisabledUser />}
                  key="DisabledUsers"
                />
                <Route
                  exact
                  path="View/:UID"
                  element={<ViewUser />}
                  key="ViewUsers"
                />
                <Route
                  exact
                  path="List"
                  element={<ListUsers />}
                  key="ViewUsers"
                />
              </Route>
              <Route
                exact
                path="DahiyaMitra"
                element={<DahiyaMitra />}
                key="DahiyaMitra"
              >
                <Route
                  exact
                  path="Add"
                  element={<AddDahiyaMitra />}
                  key="AddDahiyaMitra"
                />
                <Route
                  exact
                  path="List"
                  element={<ListDahiyaMitra />}
                  key="ListDahiyaMitra"
                />
                <Route
                  exact
                  path="Deleted"
                  element={<DeletedDahiyaMitra />}
                  key="DeletedDahiyaMitra"
                />
                <Route
                  exact
                  path="View/:UID"
                  element={<ViewDahiyaMitra />}
                  key="ViewDahiyaMitra"
                />
              </Route>
              <Route exact path="Policy" element={<Policy />} key="Policy">
                <Route
                  exact
                  path="Add"
                  element={<AddPolicy />}
                  key="AddPolicy"
                />
                <Route
                  exact
                  path="List"
                  element={<ListPolicy />}
                  key="listpolicy"
                />
                <Route
                  exact
                  path="ListPendingUpdatePolicy"
                  element={<ListPendingUpdatePolicy />}
                  key="ListPendingUpdatePolicy"
                />
                <Route
                  exact
                  path="Deleted"
                  element={<DeletedPolicy />}
                  key="DeletedPolicy"
                />
                <Route
                  exact
                  path="View/:UID"
                  element={<EditPolicy />}
                  key="EditPolicy"
                />
              </Route>
              <Route
                exact
                path="CurrentMonthDueImport"
                element={<CurrentMonthDueImport />}
                key="CurrentMonthDueImport"
              />
              <Route
                exact
                path="CurrentMonthDueList"
                element={<CurrentMonthDueList />}
                key="CurrentMonthDueList"
              />
              <Route
                exact
                path="OutstandingDueImport"
                element={<OutStandingDueImport />}
                key="OutstandingDueImport"
              />
              <Route
                exact
                path="OutstandingDueList"
                element={<OutstandingDueList />}
                key="OutstandingDueList"
              />
              <Route
                exact
                path="LapsedPolicyDueImport"
                element={<LapsedPolicyDueImport />}
                key="LapsedPolicyDueImport"
              />
              <Route
                exact
                path="LapsedPolicyDueList"
                element={<LapsedPolicyDueList />}
                key="LapsedPolicyDueList"
              />
              <Route
                exact
                path="SBPolicyImport"
                element={<SBPolicyImport />}
                key="SBPolicyImport"
              />
              <Route
                exact
                path="SBBonusList"
                element={<SBBonusList />}
                key="SBBonusList"
              />
              <Route
                exact
                path="LICMaturityImport"
                element={<LICMaturityImport />}
                key="LICMaturityImport"
              />
              <Route
                exact
                path="LICMaturityList"
                element={<LICMaturityList />}
                key="LICMaturityList"
              />
              <Route
                exact
                path="PremiumPoint"
                element={<PremiumPoint />}
                key="PremiumPoint"
              >
                <Route
                  exact
                  path="List"
                  element={<ListPremiumPoint />}
                  key="ListPremiumPoint"
                />
                <Route
                  exact
                  path="Import"
                  element={<ImportPremiumPoint />}
                  key="ImportPremiumPoint"
                />
                <Route
                  exact
                  path="ImportByPaste"
                  element={<ImportByPastePremiumPoint />}
                  key="ImportByPastePremiumPoint"
                />
              </Route>
              <Route exact path="CLIA" element={<CLIA />} key="CLIALogin">
                <Route exact path="Add" element={<AddCLIA />} key="AddCLIA" />
                <Route
                  exact
                  path="List"
                  element={<ListCLIA />}
                  key="ListCLIA"
                />
                <Route
                  exact
                  path="Deleted"
                  element={<DeletedCLIA />}
                  key="DeletedCLIA"
                />
                <Route
                  exact
                  path="View/:UID"
                  element={<ViewCLIA />}
                  key="ViewCLIA"
                />
              </Route>

              <Route
                exact
                path="Customers"
                element={<Customers />}
                key="Customers"
              >
                <Route
                  exact
                  path="Add"
                  element={<AddCustomer />}
                  key="AddCustomer"
                />
                <Route
                  exact
                  path="View"
                  element={<ViewCustomer />}
                  key="ViewCustomer"
                />
                <Route
                  exact
                  path="List"
                  element={<ListCustomer />}
                  key="ListCustomer"
                />
                <Route
                  exact
                  path="Deleted"
                  element={<DeletedCustomer />}
                  key="DeletedCustomer"
                />
                <Route
                  exact
                  path="Import"
                  element={<ImportCustomer />}
                  key="ImportCustomer"
                />
                <Route
                  exact
                  path="List"
                  element={<ListCustomer />}
                  key="ListCustomer"
                />
              </Route>
              <Route
                exact
                path="Calendar"
                element={<CalendarView />}
                key="Calendar"
              />
              <Route
                exact
                path="Greeting"
                element={<Greetings />}
                key="Greeting"
              />
              <Route
                exact
                path="Folloups"
                element={<Followups />}
                key="Folloups"
              />
              <Route exact path="Inputs" element={<Inputs />} key="Inputs" />
              <Route
                exact
                path="SelfLICAgencyWork"
                element={<AgencyWorks />}
                key="SelfLICAgencyWork"
              />
              <Route
                exact
                path="FamilyMember/:Ref/:UID"
                element={<FamilyMembers />}
                key="FamilyMember"
              />
              <Route
                exact
                path="CLIAProspect"
                element={<CLIAReferences />}
                key="CLIAProspect"
              >
                <Route
                  exact
                  path="Add"
                  element={<AddCLIANewReference />}
                  key="CLIAProspect"
                />
                <Route
                  exact
                  path="CLIAFamilyMembers/:UID"
                  element={<ListCLIAFamilyMembers />}
                  key="CLIAFamilyMembers"
                />

                <Route
                  exact
                  path={`View/:UID`}
                  element={<ViewReference />}
                  key="View"
                />
                <Route
                  exact
                  path={`EditCLIAReference/:UID`}
                  element={<EditCLIAReference />}
                  key="EditCLIAReference"
                />
                <Route
                  exact
                  path={`EditCLIAProspect/:UID`}
                  element={<EditCLIAProspect />}
                  key="EditCLIAProspect"
                />
                <Route
                  exact
                  path={`EditCLIAAgencyData/:UID`}
                  element={<EditCLIAAgencyData />}
                  key="EditCLIAAgencyData"
                />
                <Route
                  exact
                  path="ListCLIAReference"
                  element={<ListCLIAReference />}
                  key="ListCLIAReference"
                />

                <Route
                  exact
                  path="ListCLIAProspect"
                  element={<ListCLIAProspect />}
                  key="ListCLIAProspect"
                />
                <Route
                  exact
                  path="ListCLIAAgencyData"
                  element={<ListCLIAAgencyData />}
                  key="ListCLIAAgencyData"
                />
                <Route
                  exact
                  path="ListCLIAMBGDueList"
                  element={<MBGDueList />}
                  key="ListCLIAMBGDueList"
                />

                <Route
                  exact
                  path="Import"
                  element={<ImportReference />}
                  key="Import"
                />
                <Route
                  exact
                  path="Deleted"
                  element={<DeletedReference />}
                  key="DeletedReference"
                />
                <Route
                  exact
                  path="ConceptDiscussion/:UID"
                  element={<CLIAConceptDiscussed />}
                  key="CLIAConceptDiscussion"
                />
                <Route
                  exact
                  path="ProspectFollowup"
                  element={<CLIAFollowupList />}
                  key="CLIAProspectFollowup"
                />
              </Route>
              <Route
                exact
                path="Prospects"
                element={<Prospects />}
                key="Prospects"
              >
                <Route
                  exact
                  path="ListFamilyMembers/:UID"
                  element={<ListFamilyMembers />}
                  key="ListFamilyMembers"
                />
                <Route
                  exact
                  path="AddProspect"
                  element={<NewProspect />}
                  key="Prospects"
                />
                <Route
                  exact
                  path={`ViewProspect/:UID`}
                  element={<ViewProspect />}
                  key="ViewProspect"
                />
                <Route
                  exact
                  path="ListProspects"
                  element={<ListProspects />}
                  key="ListProspects"
                />
                <Route
                  exact
                  path="ConceptDiscussion/:UID"
                  element={<ConceptDiscussed />}
                  key="ConceptDiscussion"
                />
                <Route
                  exact
                  path="ProspectFollowup"
                  element={<FollowupList />}
                  key="ProspectFollowup"
                />

                <Route
                  exact
                  path="ImportProspects"
                  element={<ImportProspect />}
                  key="ImportProspects"
                />

                <Route
                  exact
                  path="Deleted"
                  element={<DeletedProspect />}
                  key="DeletedProspects"
                />
              </Route>
              <Route
                exact
                path="References"
                element={<References />}
                key="References"
              >
                <Route
                  exact
                  path="AddReference"
                  element={<NewReference />}
                  key="AddReference"
                />
                <Route
                  exact
                  path="ListFamilyMembers/:UID"
                  element={<ListFamilyMembers />}
                  key="ListFamilyMembers"
                />

                <Route
                  exact
                  path={`ViewReference/:UID`}
                  element={<ViewReference />}
                  key="ViewReference"
                />
                <Route
                  exact
                  path="ListReference"
                  element={<ListReference />}
                  key="ListReference"
                />

                <Route
                  exact
                  path="ImportReference"
                  element={<ImportReference />}
                  key="ImportReference"
                />
                <Route
                  exact
                  path="Deleted"
                  element={<DeletedReference />}
                  key="DeletedReference"
                />
              </Route>
            </Route>
          </Route>
        </Route>
        {/* catch all */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
