/* <!---------------------------
Name: Web
File: List Fam View
-----------------------------
Author: Ashok Vishwakarma
Data:   6/23/2024, 11:53:28 PM
----------------------------> */
import * as React from "react";
import AgGrid from "app/components/AgGrid";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Grid,
  Heading,
  Message,
  Row,
  Stack,
  useToaster,
} from "rsuite";
import * as yup from "yup";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Field, Formik } from "formik";
import "dayjs/locale/en-in";
import moment from "moment";

import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import useAuth from "app/hooks/useAuth";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LeadStatus, Results } from "app/DataSource";
import { filterParams } from "functions";
const validationSchema = yup.object({
  Place: yup.string("valid place is required"),
  Discussed: yup.string("valid Discussion note is required"),
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function FollowupList() {
  const axiosPrivate = useAxiosPrivate();
  const { UID } = useParams();
  const [discussionID, setDiscussionID] = React.useState(null);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [familyHeadData, setFamilyHeadData] = React.useState({});
  const [rowData, setRowData] = React.useState([{}]);
  const [dueCount, setDueCount] = React.useState([{}]);
  const [loading, setLoading] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openViewModal, setOpenViewModal] = React.useState(false);

  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);

  const { user, permissions } = useAuth();
  const handleOpen = () => setOpenModal(true);
  const theme = useTheme();
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleViewModalOpen = () => setOpenViewModal(true);

  const handleViewModalClose = () => {
    setOpenViewModal(false);
  };

  const [initialValues, setInitialValues] = React.useState({
    Place: null,
    DateTime: new Date(),
    NextAppointment: new Date(),
    Discussed: null,
    Result: null,
    NextPlace: null,
    Subject: null,
  });

  const GetDiscussionByID = async (ID) => {
    try {
      const responce = await axiosPrivate.post("GetDiscussionByID.php", {
        UID: ID,
      });
      //console.log(responce);
      const result = responce.data;
      if (result.status === 1) {
        const newData = {
          ...result.Data[0],
          Place: result.Data[0].Place,
          Discussed: result.Data[0].Discussed,
          Subject: result.Data[0].Subject,
          Result: result.Data[0].Result,
          NextPlace: result.Data[0].NextPlace,
          DateTime:
            result.Data[0].DateTime === "0000-00-00 00:00:00" ||
            result.Data[0].DateTime === null
              ? null
              : new Date(result.Data[0].DateTime),
          NextAppointment:
            result.Data[0].NextAppointment === "0000-00-00 00:00:00" ||
            result.Data[0].NextAppointment === null
              ? null
              : new Date(result.Data[0].NextAppointment),

          UID: ID,
        };
        setInitialValues(newData);
      }
      if (result.status === 0) {
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Error!</strong> {result?.Error}
          </Message>
        );
      }
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  };

  /*   const GetFamilyHeadByID = async () => {
    try {
      const responce = await axiosPrivate.post("GetFamilyHeadByID.php", {
        UID,
      });
      //console.log(responce);
      const result = responce.data;
      if (result.status === 1) {
        setFamilyHeadData(result.Data[0]);
      }
      if (result.status === 0) {
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Error!</strong> {result?.Error}
          </Message>
        );
        setFamilyHeadData({});
      }
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) GetFamilyHeadByID();
    return () => {
      isSubscribed = false;
    };
  }, []); */

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed)
      if (selectedRowData?.UniqueID) {
        GetDiscussionByID(selectedRowData.UniqueID);
      }
    return () => {
      isSubscribed = false;
    };
  }, [selectedRowData?.UniqueID, selectedRowData]);

  const ColorStatus = React.useMemo(() => {
    return ["red", "orange", "green", "purple"];
  }, []);
  function convertTextToDateTime(dateTimeString = "") {
    // Trim any whitespace from the input string
    //  dateTimeString = dateTimeString.trim();

    // Split the date and time parts
    let datePart = dateTimeString.split(" ")[0];
    // Split the date part by '/'
    let [day, month, year] = datePart.split("/");

    // Split the time part by ':'
    // let [hours, minutes, seconds] = timePart ? timePart.split(':') : [0, 0, 0]; // default to 00:00:00 if time is not provided

    // Convert the split values to integers
    day = parseInt(day, 10);
    month = parseInt(month, 10) - 1; // Months are zero-based in JavaScript (0 = January, 11 = December)
    year = parseInt(year, 10);
    // console.log(dateTimeString);
    // console.log(`${day} - ${month} - ${year}`);
    // Create and return the Date object
    return new Date(year, month, day);
  }
  const columns = React.useMemo(() => {
    return [
      {
        field: "ID",
        minWidth: 150,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      { field: "LeadStatus", minWidth: 150, editable: true },
      {
        field: "NextAppointmentDate",
        minWidth: 200,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
        cellStyle: (params) => {
          if (params.value === "00/00/0000 12:00 AM") {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          } else if (convertTextToDateTime(params.value) > new Date()) {
            //mark police cells as red
            return { color: ColorStatus[2], fontWeight: "bold" };
          } else if (convertTextToDateTime(params.value) < new Date()) {
            //mark police cells as red
            return { color: "red", fontWeight: "bold" };
          } else {
            //mark police cells as red
            return { color: "blue", fontWeight: "bold" };
          }
        },
      },
      { field: "Name", minWidth: 200 },
      { field: "Village", minWidth: 150, headerName: "Village/City" },
      { field: "Mobile", minWidth: 150 },
      { field: "Profession", minWidth: 150 },
      { field: "Place", minWidth: 150 },
      { field: "WorkType", minWidth: 150 },
      {
        field: "Discussed",
        minWidth: 150,
        tooltipValueGetter: (params) => `Discussed: ${params.value}`,
      },

      {
        field: "DateTime",
        minWidth: 200,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },

      {
        field: "Result",
        minWidth: 150,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },

      { field: "NextPlace", minWidth: 150 },
      { field: "Subject", minWidth: 250 },

      { field: "AddedBy", minWidth: 150 },
      {
        field: "AddedOn",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      { field: "UniqueID", hide: true },
    ];
  }, []);

  const onGridReady = React.useCallback((params) => {
    setLoading(true);
    axiosPrivate
      .post("LoadPendingDiscussion.php")
      .then((responce) => {
        const result = responce.data;
        if (result.status === 1) {
          setRowData(result.Data);
          setDueCount(result.DueCount);
        } else {
          setRowData([]);
          setDueCount({ Past: "0", Today: "0", Future: "0" });
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  const toaster = useToaster();

  function SaveDiscussion(data) {
    const newData = {
      ...data,
      DateTime: data.DateTime
        ? moment(data.DateTime).format("YYYY-MM-DD HH:mm")
        : null,
      NextAppointment: data.NextAppointment
        ? moment(data.NextAppointment).format("YYYY-MM-DD HH:mm")
        : null,

      Place: data.Place,
      Discussed: data.Discussed,
      Result: data.Result,
      NextPlace: data.NextPlace,
      Subject: data.Subject,
      ReferenceID: UID,
    };

    try {
      axiosPrivate
        .post("AddDiscussion.php", newData)
        .then((response) => {
          const result = response.data;
          if (result.status === 1) {
            toaster.push(
              <Message type="success" bordered showIcon>
                <strong>Success!</strong> {result.Success}
              </Message>
            );
            setDiscussionID(result.ID);
            onGridReady();
          }
          if (result.status === 0) {
            toaster.push(
              <Message type="error" bordered showIcon>
                <strong>Error!</strong> {result.Error}
              </Message>
            );
          }

          // resetForm();
        })
        .catch((error) => {
          console.error("Error submitting data:", error);
        });
    } catch (e) {
      console.log(e);
    }
  }

  function UpdateDiscussion(data) {
    const newData = {
      ...data,
      DateTime: data.DateTime
        ? moment(data.DateTime).format("YYYY-MM-DD HH:mm")
        : null,
      NextAppointment: data.NextAppointment
        ? moment(data.NextAppointment).format("YYYY-MM-DD HH:mm")
        : null,

      Place: data.Place,
      Discussed: data.Discussed,
      Result: data.Result,
      NextPlace: data.NextPlace,
      Subject: data.Subject,
    };

    axiosPrivate
      .post("EditDiscussion.php", newData)
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          toaster.push(
            <Message type="success" bordered showIcon>
              <strong>Success!</strong> {result.Success}
            </Message>
          );
          setDiscussionID(result.ID);
          onGridReady();
          handleViewModalClose();
        }
        if (result.status === 0) {
          toaster.push(
            <Message type="error" bordered showIcon>
              <strong>Error!</strong> {result.Error}
            </Message>
          );
        }

        // resetForm();
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  }

  const UpdateStatus = (data) => {
    axiosPrivate
      .post("UpdateLeadStatusProspect.php", data)
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          console.log(result);
          toaster.push(
            <Message type="success" bordered showIcon>
              <strong>Success!</strong> {result?.Success}
            </Message>
          );
        }
        if (result.status === 0) {
          toaster.push(
            <Message type="error" bordered showIcon>
              <strong>Error!</strong> {result.Error}
            </Message>
          );
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  const handleChildData = (data) => {
    UpdateStatus(data);
  };
  const Remove = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this  details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: initialValues.UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemoveDiscussion.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  const Restore = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to restore this details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RestoreReference.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  onClose();
                }
              }}
            >
              Yes, Restore it!
            </button>
          </div>
        );
      },
    });
  };

  function headerComponent() {
    console.log(familyHeadData);
    return (
      <p>
        {/* <b>Family Head : </b> {`${familyHeadData.Name} - ${familyHeadData.ID}`}{" "} */}
      </p>
    );
  }

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Heading
            level={4}
            style={{
              textAlign: "center",
              flex: 0.7,
            }}
          >
            {`Followup Lists`}
          </Heading>
          <Stack direction="row" spacing={7} style={{ flex: 0.3 }}>
            <p style={{ fontSize: 20 }}>
              <b>Past:</b>
              <span style={{ color: "red" }}>{dueCount.Past}</span>
            </p>{" "}
            &nbsp;&nbsp;
            <p style={{ fontSize: 20 }}>
              <b>Today:</b>
              <span style={{ color: "blue" }}>{dueCount.Today}</span>
            </p>{" "}
            &nbsp;&nbsp;
            <p style={{ fontSize: 20 }}>
              <b>Future:</b>
              <span style={{ color: "green" }}>{dueCount.Future}</span>
            </p>
          </Stack>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <AgGrid
          rowData={rowData}
          columns={columns}
          onGridReady={onGridReady}
          defaultColumn={["Name"]}
          showLoading={loading}
          GoTo="/Prospects/ViewProspect"
          addDiscussion={false}
          handleOpen={handleOpen}
          onDataChange={handleChildData}
          onRowSelectedData={setSelectedRowData}
          handleViewModalOpen={handleViewModalOpen}
          headerComponent={headerComponent}
          setFilteredRows={setFilteredRows}
          Source="Followup"
        />
      </div>
      <Drawer
        backdrop="static"
        open={openModal}
        onClose={handleClose}
        size="xs"
      >
        <Drawer.Header>
          <Drawer.Title>Add New</Drawer.Title>
          <Drawer.Actions>
            <Button
              onClick={() => {
                handleClose();
                setDiscussionID(null);
              }}
              appearance="primary"
            >
              Close
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              Place: null,
              Discussed: null,
              Subject: null,
              Result: null,
              NextPlace: null,
            }}
            onSubmit={(values, { setSubmitting }) => {
              console.log("Test");
              SaveDiscussion(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              setFieldValue,
              handleSubmit,
              isValidating,
              isSubmitting,
              touched,
              handleBlur,
              handleChange,
            }) => {
              return (
                <form>
                  <div>
                    <div className="row">
                      <Grid fluid style={{ padding: 4 }}>
                        <Row className="show-grid">
                          <Col xs={24} sm={24} md={24}>
                            <Row gutter={0} style={{ marginBottom: 10 }}>
                              <Field
                                disabled={discussionID}
                                fullWidth
                                name={`Place`}
                                variant="standard"
                                InputLabelProps={{
                                  shrink: Boolean(values.Place),
                                }}
                                label="Place"
                                size="small"
                                as={TextField}
                                placeholder="Enter Place"
                                onBlur={handleBlur}
                                error={touched.Place && Boolean(errors.Place)}
                                helperText={touched.Place && errors.Place}
                              />
                            </Row>
                            <Row
                              gutter={0}
                              style={{
                                marginBottom: 10,
                                display: "flex",
                                alignItems: "flex-end",
                              }}
                            >
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <Field
                                  disabled={discussionID}
                                  name={`DateTime`}
                                  label="Date Time of Discussion"
                                  variant="standard"
                                  size="small"
                                  format="dd-MM-yyyy hh:mm aa"
                                  InputLabelProps={{
                                    shrink: Boolean(values.DateTime),
                                  }}
                                  as={DateField}
                                  value={values.DateTime}
                                  onChange={(value) => {
                                    setFieldValue(`DateTime`, value, true);
                                    console.log(errors);
                                  }}
                                  onBlur={handleBlur}
                                  error={
                                    touched.DateTime && Boolean(errors.DateTime)
                                  }
                                  helperText={
                                    touched.DateTime && errors.DateTime
                                  }
                                />
                              </LocalizationProvider>
                            </Row>

                            <Row gutter={0} style={{ marginBottom: 10 }}>
                              <Field
                                disabled={discussionID}
                                name={`Discussed`}
                                fullWidth
                                label="Discussed"
                                size="small"
                                multiline
                                InputLabelProps={{
                                  shrink: Boolean(values.Discussed),
                                }}
                                maxRows={4}
                                variant="standard"
                                as={TextField}
                                placeholder="Enter Discussion Details"
                                onBlur={handleBlur}
                                error={
                                  touched.Discussed && Boolean(errors.Discussed)
                                }
                                helperText={
                                  touched.Discussed && errors.Discussed
                                }
                              />
                            </Row>
                            <Row gutter={0} style={{ marginBottom: 10 }}>
                              <FormControl
                                variant="standard"
                                sx={{ mt: 3, minWidth: 200 }}
                              >
                                <InputLabel id="demo-simple-select-standard-label-Result">
                                  Result
                                </InputLabel>
                                <Select
                                  disabled={discussionID}
                                  labelId="demo-simple-select-standard-label-Result"
                                  id="demo-simple-select-Result"
                                  label="Result"
                                  name="Result"
                                  value={values?.Result || ""}
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    shrink: Boolean(values.Result),
                                  }}
                                  error={
                                    touched.Result && Boolean(errors.Result)
                                  }
                                  helperText={touched.Result && errors.Result}
                                >
                                  {[...Results, ...LeadStatus].map((name) => (
                                    <MenuItem
                                      key={name}
                                      value={name}
                                      style={getStyles(
                                        name,
                                        [...Results, ...LeadStatus],
                                        theme
                                      )}
                                    >
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Row>
                            {(values?.Result === "PROCESS" ||
                              values?.Result === "Process") && (
                              <>
                                <Row gutter={0} style={{ marginBottom: 10 }}>
                                  <Row
                                    gutter={0}
                                    style={{
                                      marginBottom: 10,
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                    >
                                      <Field
                                        InputLabelProps={{
                                          shrink: Boolean(
                                            values.NextAppointment
                                          ),
                                        }}
                                        disabled={discussionID}
                                        name={`NextAppointment`}
                                        label="Next Appointment"
                                        variant="standard"
                                        size="small"
                                        format="dd-MM-yyyy hh:mm aa"
                                        as={DateField}
                                        value={values.NextAppointment}
                                        onChange={(value) => {
                                          setFieldValue(
                                            `NextAppointment`,
                                            value,
                                            true
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        error={
                                          touched.NextAppointment &&
                                          Boolean(errors.NextAppointment)
                                        }
                                        helperText={
                                          touched.NextAppointment &&
                                          errors.NextAppointment
                                        }
                                      />
                                    </LocalizationProvider>
                                  </Row>
                                </Row>

                                <Row gutter={0} style={{ marginBottom: 10 }}>
                                  <Field
                                    disabled={discussionID}
                                    fullWidth
                                    name={`Subject`}
                                    variant="standard"
                                    multiline
                                    maxRows={4}
                                    label="Subject"
                                    size="small"
                                    as={TextField}
                                    placeholder="Enter Next meeting Subject"
                                    InputLabelProps={{
                                      shrink: Boolean(values.Subject),
                                    }}
                                    onBlur={handleBlur}
                                    error={
                                      touched.Subject && Boolean(errors.Subject)
                                    }
                                    helperText={
                                      touched.Subject && errors.Subject
                                    }
                                  />
                                </Row>
                                <Row gutter={0} style={{ marginBottom: 10 }}>
                                  <Field
                                    disabled={discussionID}
                                    fullWidth
                                    name={`NextPlace`}
                                    variant="standard"
                                    label="Next Meeting Place"
                                    size="small"
                                    as={TextField}
                                    InputLabelProps={{
                                      shrink: Boolean(values.NextPlace),
                                    }}
                                    placeholder="Enter NextPlace"
                                    onBlur={handleBlur}
                                    error={
                                      touched.NextPlace &&
                                      Boolean(errors.NextPlace)
                                    }
                                    helperText={
                                      touched.NextPlace && errors.NextPlace
                                    }
                                  />
                                </Row>
                              </>
                            )}
                          </Col>
                        </Row>
                      </Grid>
                    </div>
                    &nbsp; &nbsp;
                    {!discussionID && (
                      <>
                        <LoadingButton
                          // disabled={isSubmitting}
                          type="submit"
                          color="secondary"
                          variant="contained"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                          }}
                          loading={isValidating}
                          sx={{ my: 2 }}
                        >
                          {/*  {isSubmitting ? "Submitting..." : "Submit"} */}
                          Submit
                        </LoadingButton>
                      </>
                    )}
                  </div>
                </form>
              );
            }}
          </Formik>
        </Drawer.Body>
      </Drawer>
      <Drawer
        backdrop="static"
        open={openViewModal}
        onClose={handleViewModalClose}
        size="xs"
      >
        <Drawer.Header>
          <Drawer.Title>Update Discussion</Drawer.Title>
          <Drawer.Actions>
            <Button
              onClick={() => {
                handleViewModalClose();
                setDiscussionID(null);

                setEditMode(false);
              }}
              appearance="primary"
            >
              Close
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              UpdateDiscussion(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              setFieldValue,
              handleSubmit,
              isValidating,
              isSubmitting,
              touched,
              handleBlur,
              handleChange,
            }) => {
              // console.log(errors);
              // console.log(values);
              return (
                <form>
                  <div>
                    <div className="row">
                      <Grid fluid style={{ padding: 4 }}>
                        <Row className="show-grid">
                          <Col xs={24} sm={24} md={24}>
                            <Row gutter={0} style={{ marginBottom: 10 }}>
                              <Field
                                disabled={!editMode}
                                fullWidth
                                name={`Place`}
                                variant="standard"
                                label="Place"
                                InputLabelProps={{
                                  shrink: Boolean(values.Place),
                                }}
                                size="small"
                                as={TextField}
                                placeholder="Enter Place"
                                onBlur={handleBlur}
                                error={touched.Place && Boolean(errors.Place)}
                                helperText={touched.Place && errors.Place}
                              />
                            </Row>
                            <Row
                              gutter={0}
                              style={{
                                marginBottom: 10,
                                display: "flex",
                                alignItems: "flex-end",
                              }}
                            >
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <Field
                                  disabled={!editMode}
                                  name={`DateTime`}
                                  label="Date Time of Discussion"
                                  variant="standard"
                                  size="small"
                                  InputLabelProps={{
                                    shrink: Boolean(values.DateTime),
                                  }}
                                  format="dd-MM-yyyy hh:mm aa"
                                  as={DateField}
                                  value={values.DateTime}
                                  onChange={(value) => {
                                    setFieldValue(`DateTime`, value, true);
                                  }}
                                  onBlur={handleBlur}
                                  error={
                                    touched.DateTime && Boolean(errors.DateTime)
                                  }
                                  helperText={
                                    touched.DateTime && errors.DateTime
                                  }
                                />
                              </LocalizationProvider>
                            </Row>

                            <Row gutter={0} style={{ marginBottom: 10 }}>
                              <Field
                                disabled={!editMode}
                                name={`Discussed`}
                                fullWidth
                                InputLabelProps={{
                                  shrink: Boolean(values.Discussed),
                                }}
                                label="Discussed"
                                size="small"
                                variant="standard"
                                as={TextField}
                                placeholder="Enter Discussion Details"
                                onBlur={handleBlur}
                                error={
                                  touched.Discussed && Boolean(errors.Discussed)
                                }
                                helperText={
                                  touched.Discussed && errors.Discussed
                                }
                              />
                            </Row>
                            <Row gutter={0} style={{ marginBottom: 10 }}>
                              <FormControl
                                variant="standard"
                                sx={{ mt: 3, minWidth: 200 }}
                              >
                                <InputLabel id="demo-simple-select-standard-label-Result">
                                  Result
                                </InputLabel>
                                <Select
                                  disabled={!editMode}
                                  labelId="demo-simple-select-standard-label-Result"
                                  id="demo-simple-select-Result"
                                  label="Result"
                                  name="Result"
                                  value={values?.Result || ""}
                                  onChange={(value) => {
                                    setFieldValue(
                                      `Result`,
                                      value.target.value,
                                      true
                                    );
                                  }}
                                  error={
                                    touched.Result && Boolean(errors.Result)
                                  }
                                  helperText={touched.Result && errors.Result}
                                >
                                  {[
                                    "PENDING",
                                    "POSTPONE",
                                    "REJECT",
                                    "DONE",
                                    "NEXT APPOINTMENT",
                                  ].map((name) => (
                                    <MenuItem
                                      key={name}
                                      value={name}
                                      style={getStyles(
                                        name,
                                        [
                                          "PENDING",
                                          "POSTPONE",
                                          "REJECT",
                                          "DONE",
                                          "NEXT APPOINTMENT",
                                        ],
                                        theme
                                      )}
                                    >
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Row>
                            {values?.Result === "NEXT APPOINTMENT" && (
                              <>
                                <Row gutter={0} style={{ marginBottom: 10 }}>
                                  <Row
                                    gutter={0}
                                    style={{
                                      marginBottom: 10,
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                    >
                                      <Field
                                        disabled={!editMode}
                                        InputLabelProps={{
                                          shrink: Boolean(
                                            values.NextAppointment
                                          ),
                                        }}
                                        name={`NextAppointment`}
                                        label="Next Appointment"
                                        variant="standard"
                                        size="small"
                                        format="dd-MM-yyyy hh:mm aa"
                                        as={DateField}
                                        value={values.NextAppointment}
                                        onChange={(value) => {
                                          setFieldValue(
                                            `NextAppointment`,
                                            value,
                                            true
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        error={
                                          touched.NextAppointment &&
                                          Boolean(errors.NextAppointment)
                                        }
                                        helperText={
                                          touched.NextAppointment &&
                                          errors.NextAppointment
                                        }
                                      />
                                    </LocalizationProvider>
                                  </Row>
                                </Row>

                                <Row gutter={0} style={{ marginBottom: 10 }}>
                                  <Field
                                    disabled={!editMode}
                                    fullWidth
                                    name={`Subject`}
                                    variant="standard"
                                    label="Subject"
                                    size="small"
                                    InputLabelProps={{
                                      shrink: Boolean(values.Subject),
                                    }}
                                    as={TextField}
                                    onChange={(value) => {
                                      setFieldValue(
                                        `Subject`,
                                        value.target.value,
                                        true
                                      );
                                    }}
                                    value={values.Subject}
                                    placeholder="Enter Next meeting Subject"
                                    onBlur={handleBlur}
                                    error={
                                      touched.Subject && Boolean(errors.Subject)
                                    }
                                    helperText={
                                      touched.Subject && errors.Subject
                                    }
                                  />
                                </Row>
                                <Row gutter={0} style={{ marginBottom: 10 }}>
                                  <Field
                                    disabled={!editMode}
                                    fullWidth
                                    name={`NextPlace`}
                                    variant="standard"
                                    label="Next Meeting Place"
                                    size="small"
                                    as={TextField}
                                    value={values.NextPlace}
                                    InputLabelProps={{
                                      shrink: Boolean(values.NextPlace),
                                    }}
                                    placeholder="Enter NextPlace"
                                    onBlur={handleBlur}
                                    onChange={(value) => {
                                      setFieldValue(
                                        `NextPlace`,
                                        value.target.value,
                                        true
                                      );
                                    }}
                                    error={
                                      touched.NextPlace &&
                                      Boolean(errors.NextPlace)
                                    }
                                    helperText={
                                      touched.NextPlace && errors.NextPlace
                                    }
                                  />
                                </Row>
                              </>
                            )}
                          </Col>
                        </Row>
                      </Grid>
                    </div>
                    <Divider />
                    &nbsp; &nbsp;
                    {editMode ? (
                      <>
                        <LoadingButton
                          disabled={isSubmitting}
                          type="submit"
                          color="secondary"
                          variant="contained"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                          }}
                          loading={isValidating}
                          sx={{ my: 2 }}
                        >
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </LoadingButton>
                        &nbsp; &nbsp;
                        <Button
                          color="red"
                          appearance="primary"
                          onClick={Remove}
                          sx={{ my: 2 }}
                        >
                          {"Delete"}
                        </Button>
                      </>
                    ) : (
                      <Button
                        color="blue"
                        appearance="primary"
                        onClick={() => {
                          setEditMode(true);
                        }}
                        sx={{ my: 2 }}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                </form>
              );
            }}
          </Formik>
        </Drawer.Body>
      </Drawer>
    </>
  );
}
