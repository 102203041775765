import React from "react";
import { Button, Drawer } from "rsuite";

export default function SideDrawer({
  open,
  onClose,
  title,
  children,
  size = "xs",
}) {
  return (
    <Drawer backdrop="false" open={open} onClose={onClose} size={size}>
      <Drawer.Header>
        <Drawer.Title>{title}</Drawer.Title>
        <Drawer.Actions>
          <Button onClick={onClose} appearance="primary">
            Close
          </Button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body>{children}</Drawer.Body>
    </Drawer>
  );
}
