import {
  Button,
  Col,
  Grid,
  Heading,
  Message,
  Row,
  Stack,
  toaster,
} from "rsuite";
import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as XLSX from "xlsx";
import { useRef } from "react";
import {
  debounce,
  findDuplicateIds,
  hasInvalidIds,
  mergeArraysById,
} from "functions";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { LoadingButton } from "@mui/lab";
import AgGrid from "app/components/AgGrid";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

const columns = [
  { id: "ID", label: "ID", minWidth: 200 },
  { id: "Date", label: "Date", minWidth: 200 },
  { id: "Place", label: "Place", minWidth: 200 },
  { id: "RefBy", label: "RefBy", minWidth: 200 },
  { id: "Status", label: "Status", minWidth: 200 },
  { id: "Gender", label: "Gender", minWidth: 200 },
  { id: "DOB", label: "DOB", minWidth: 200 },
  { id: "Name", label: "Name", minWidth: 200 },
  { id: "Caste", label: "Caste", minWidth: 200 },
  { id: "Address", label: "Address", minWidth: 200 },
  {
    id: "Village",
    label: "Village",
    minWidth: 200,
    headerName: "Village/City",
  },
  { id: "Tehsil", label: "Tehsil", minWidth: 200 },
  { id: "District", label: "District", minWidth: 200 },
  { id: "State", label: "State", minWidth: 200 },
  { id: "PIN", label: "PIN", minWidth: 200 },
  { id: "WhatsApp", label: "WhatsApp", minWidth: 200 },
  { id: "Mobile", label: "Mobile", minWidth: 200 },
  { id: "Work", label: "Work", minWidth: 200 },
  { id: "WorkType", label: "WorkType", minWidth: 200 },
  { id: "Other", label: "Other", minWidth: 200 },
];
const columns1 = [
  { id: "ID", field: "ID", minWidth: 100 },
  { id: "Date", field: "Date", minWidth: 150, type: "date" },
  { id: "Place", field: "Place", minWidth: 200 },
  { id: "RefBy", field: "RefBy", minWidth: 200 },
  { id: "Status", field: "Status", minWidth: 150 },
  { id: "Gender", field: "Gender", minWidth: 150 },
  { id: "DOB", field: "DOB", minWidth: 150, type: "date" },
  { id: "Name", field: "Name", minWidth: 200 },
  { id: "Caste", field: "Caste", minWidth: 200 },
  { id: "Address", field: "Address", minWidth: 200 },
  {
    id: "Village",
    field: "Village",
    minWidth: 200,
    headerName: "Village/City",
  },
  { id: "Tehsil", field: "Tehsil", minWidth: 200 },
  { id: "District", field: "District", minWidth: 200 },
  { id: "State", field: "State", minWidth: 200 },
  { id: "PIN", field: "PIN", minWidth: 100, type: "numericColumn" },
  { id: "WhatsApp", field: "WhatsApp", minWidth: 200 },
  { id: "Mobile", field: "Mobile", minWidth: 200 },
  { id: "Work", field: "Work", minWidth: 200 },
  { id: "WorkType", field: "WorkType", minWidth: 150 },
  {
    id: "Other",
    field: "Other",
    minWidth: 200,
    headerName: "Other/LIC Details",
  },
  { id: "UploadStatus", field: "UploadStatus", minWidth: 150 },
  { id: "Message", field: "Message", minWidth: 500 },
];
const rows = [];

export default function ImportReference() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const fileInput = useRef();

  const DownloadTemplate = () => {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("TableData");

    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);

    // Process Data (add a new row)
    var ws = workbook.Sheets["Sheet1"];
    /* XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], {
      origin: -1,
    }); */

    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    XLSX.writeFile(workbook, "ReferenceTemplate.xlsb");
  };

  const handleUpload = (e) => {
    try {
      setData(rows);
      e.preventDefault();

      var files = e.target.files,
        f = files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        onGridReady();
        /* Convert array to json*/

        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 0 });

        const newData = dataParse.map((row) => {
          let formattedDate;
          if (row.Date) {
            const excelDate = row.Date; // Replace with the correct cell reference

            // Convert Excel date to JavaScript Date
            const jsDate = new Date((excelDate - 25569) * 86400 * 1000);

            // Format the date as needed (e.g., YYYY-MM-DD for MySQL)
            formattedDate = jsDate.toISOString().split("T")[0];
          } else {
            formattedDate = new Date();
          }

          let formattedDOB;
          if (row.DOB) {
            const excelDate = row.DOB; // Replace with the correct cell reference

            // Convert Excel date to JavaScript Date
            const jsDate = new Date((excelDate - 25569) * 86400 * 1000);

            // Format the date as needed (e.g., YYYY-MM-DD for MySQL)
            formattedDOB = jsDate.toISOString().split("T")[0];
          } else {
            formattedDOB = new Date();
          }

          return {
            ...row,
            Date: row.Date ? moment(formattedDate).format("YYYY-MM-DD") : null,
            DOB: row.DOB ? moment(formattedDOB).format("YYYY-MM-DD") : null,
            WhatsApp: row?.WhatsApp ? row?.WhatsApp : null,

            Mobile: row?.Mobile ? row?.Mobile : null,
            Place: row?.Place ? row?.Place : null,
            RefBy: row?.RefBy ? row?.RefBy : null,
            Address: row?.Address ? row?.Address : null,
            Tehsil: row?.Tehsil ? row?.Tehsil : null,
            District: row?.District ? row?.District : null,
            State: row?.State ? row?.State : null,
            PIN: row?.PIN ? row?.PIN : null,
            Work: row?.Work ? row?.Work : null,
            WorkType: row?.WorkType ? row?.WorkType : null,
            Other: row?.Other ? row?.Other : null,
            Gender: row?.Gender ? row?.Gender : null,
          };
        });
        updateQueryParams(newData);
        //console.log(newData);
        setData(newData);
        //console.log(dataParse);
      };
      reader.readAsBinaryString(f);
    } catch (error) {
      toaster.push(<Message type="error">Error occured</Message>);
    }
  };
  function onGridReady() {}
  const [status, setStatus] = React.useState(false);
  const axiosPrivate = useAxiosPrivate();
  const history = useNavigate();
  const location = useLocation();
  const updateQueryParams = React.useCallback(
    (activeKey) => {
      const params = new URLSearchParams(location.search);
      params.set("data", JSON.stringify(activeKey));
      history(`?${params.toString()}`, { replace: true });
    },
    [history, location.search]
  );

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const nameFromParams = params.get("data");

    if (nameFromParams) {
      setData(JSON.parse(nameFromParams));
    }
  }, [location.search]);

  async function handleImport() {
    setStatus(true);
    if (data.length > 0) {
      const duplicates = findDuplicateIds(data);
      if (hasInvalidIds(data)) {
        console.log("Invalid IDs found:");
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Invalid IDs!</strong> {`There are some invalid IDs`}
          </Message>
        );
        setStatus(false);
        return;
      } else if (duplicates.length > 0) {
        console.log("Duplicate IDs found:");
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Duplicate IDs!</strong>{" "}
            {`Duplicate IDs found - ${duplicates.toString()}`}
          </Message>
        );
        setStatus(false);
        return;
      } else {
        try {
          axiosPrivate
            .post("AddImportedReference.php", {
              data,
            })
            .then((response) => {
              const result = response.data;
              if (result.status === 1) {
                toaster.push(
                  <Message type="success" bordered showIcon>
                    <strong>Success!</strong> {result.Success}
                  </Message>
                );
                const newData = mergeArraysById(data, result.Data);
                console.log(newData);
                updateQueryParams(newData);
                setData(newData);
              }
              if (result.status === 0) {
                toaster.push(
                  <Message type="error" bordered showIcon>
                    <strong>Error!</strong> {result.Error}
                  </Message>
                );
              }
              setStatus(false);
              // resetForm();
            })
            .catch((error) => {
              console.error("Error submitting data:", error);
              setStatus(false);
            });
        } catch (error) {
          console.error(error);
          setStatus(false);
        }
      }
    } else {
      toaster.push(
        <Message type="error" bordered showIcon>
          Please upload a file first
        </Message>
      );
      setStatus(false);
    }
  }

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <Heading level={5} style={{ textAlign: "center" }}>
          Import Reference
        </Heading>
      </div>
      <div
        style={{
          width: "100%",
          margin: "5px 0px 5px 0px",
          padding: 2,
          border: "0.5px dashed #999",
          borderRadius: 10,
        }}
      >
        <Stack direction="row" spacing={4}>
          <Button onClick={DownloadTemplate}>Download Template</Button>
          {/*           <input
            type="file"
            style={{ display: "none" }}
            ref={fileInput}
            accept="xls*"
          />
          <button onClick={handleUpload} className="btn btn-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-cloud-upload m-1"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
              />
              <path
                fillRule="evenodd"
                d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"
              />
            </svg>
            <span className="ms-2">Select File</span>
          </button> */}
          <input
            type="file"
            onChange={handleUpload}
            onInput={handleUpload}
            onSelect={handleUpload}
          />
          {data.length > 0 && (
            <LoadingButton
              disabled={status}
              type="submit"
              color="secondary"
              variant="contained"
              onClick={handleImport}
              loading={status}
              sx={{ my: 2 }}
            >
              {status ? "Uploading..." : "Import"}
            </LoadingButton>
          )}
        </Stack>
      </div>
      <div
        style={{
          width: "100%",
          minHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
        }}
      >
        <Grid fluid style={{ padding: 10 }}>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={24}>
              <Paper sx={{ overflow: "auto", display: "none" }}>
                <TableContainer sx={{ maxHeight: 440, overflow: "auto" }}>
                  <Table stickyHeader aria-label="sticky table" id="TableData">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(data || rows)
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.Name}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
              <Row className="show-grid" style={{ maxHeight: 350 }}>
                <AgGrid
                  columns={columns1}
                  rowData={data}
                  onGridReady={onGridReady}
                  defaultColumn={["Name"]}
                  showHeaderItems={false}
                  GoTo="/Prospects/AddReference"
                  passdata={true}
                />
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  );
}
