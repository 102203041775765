import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";

import materialRoutes from "app/views/material-kit/MaterialRoutes";
import Main from "./pages/main";
import Users from "./pages/Users";
import DahiyaMitra from "./pages/DahiyaMitra";
import Customers from "./pages/Customers";
import Prospects from "./pages/Prospects";
import Inputs from "./pages/Inputs";
import CalendarView from "./pages/Calender";
import Greetings from "./pages/Greetings";
import AgencyWorks from "./pages/AgencyWorks";
import CLIA from "./pages/CLIA";
import Policy from "./pages/Policy";
import Services from "./pages/Services";
import CurrentMonthDueImport from "./pages/PremiumPoint/CurrentMonthDueImport";
import CurrentMonthDueList from "./pages/PremiumPoint/CurrentMonthDueList";
import OutstandingDueList from "./pages/PremiumPoint/OutstandingDueList";
import OutStandingDueImport from "./pages/PremiumPoint/OutStandingDueImport";
import LapsedPolicyDueList from "./pages/PremiumPoint/LapsedPolicyDueList";
import LapsedPolicyDueImport from "./pages/PremiumPoint/LapsedPolicyDueImport";
import SBBonusList from "./pages/PremiumPoint/SBPolicyList";
import SBPolicyImport from "./pages/PremiumPoint/SBPolicyImport";
import LICMaturityList from "./pages/PremiumPoint/LICMaturityList";
import LICMaturityImport from "./pages/PremiumPoint/LICMaturityImport";
import Followups from "./pages/Followups";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
/* const JwtRegister = Loadable(
  lazy(() => import("app/views/sessions/JwtRegister"))
);
const ForgotPassword = Loadable(
  lazy(() => import("app/views/sessions/ForgotPassword"))
); */
// E-CHART PAGE
/* const AppEchart = Loadable(
  lazy(() => import("app/views/charts/echarts/AppEchart"))
); */
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));

const routes = [
  {
    element: (
      <AuthGuard>
        <Main />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      {
        path: "/Main/dashboard",
        element: <Analytics />,
        auth: authRoles.admin,
      },
      { path: "/Services", element: <Services />, auth: authRoles.admin },
      { path: "/Users", element: <Users />, auth: authRoles.admin },
      { path: "/DahiyaMitra", element: <DahiyaMitra />, auth: authRoles.admin },
      { path: "/CLIA", element: <CLIA />, auth: authRoles.admin },
      { path: "/Customers", element: <Customers />, auth: authRoles.admin },
      { path: "/Inputs", element: <Inputs />, auth: authRoles.admin },
      { path: "/References", element: <Prospects />, auth: authRoles.admin },
      { path: "/Prospects", element: <Prospects />, auth: authRoles.admin },
      { path: "/Policy", element: <Policy />, auth: authRoles.admin },
      { path: "/PremiumPoint", element: <Policy />, auth: authRoles.admin },
      {
        path: "/CurrentMonthDueImport",
        element: <CurrentMonthDueImport />,
        auth: authRoles.admin,
      },
      {
        path: "/OutstandingDueList",
        element: <OutstandingDueList />,
        auth: authRoles.admin,
      },
      {
        path: "/OutstandingDueImport",
        element: <OutStandingDueImport />,
        auth: authRoles.admin,
      },
      {
        path: "/LapsedPolicyDueList",
        element: <LapsedPolicyDueList />,
        auth: authRoles.admin,
      },
      {
        path: "/LapsedPolicyDueImport",
        element: <LapsedPolicyDueImport />,
        auth: authRoles.admin,
      },
      {
        path: "/CurrentMonthDueList",
        element: <CurrentMonthDueList />,
        auth: authRoles.admin,
      },
      {
        path: "/SBBonusList",
        element: <SBBonusList />,
        auth: authRoles.admin,
      },
      {
        path: "/SBPolicyImport",
        element: <SBPolicyImport />,
        auth: authRoles.admin,
      },
      {
        path: "/LICMaturityList",
        element: <LICMaturityList />,
        auth: authRoles.admin,
      },
      {
        path: "/LICMaturityImport",
        element: <LICMaturityImport />,
        auth: authRoles.admin,
      },
      {
        path: "/SelfLICAgencyWork",
        element: <AgencyWorks />,
        auth: authRoles.admin,
      },

      { path: "/Calendar", element: <CalendarView />, auth: authRoles.admin },
      { path: "/Greeting", element: <Greetings />, auth: authRoles.admin },
      { path: "/Followups", element: <Followups />, auth: authRoles.admin },
      // e-chart route
      /*       {
        path: "/charts/echarts",
        element: <AppEchart />,
        auth: authRoles.editor,
      }, */
    ],
  },

  // session pages route
  { path: "/404", element: <NotFound /> },
  { path: "/login", element: <JwtLogin /> },

  { path: "/", element: <Navigate to="/login" /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
